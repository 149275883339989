/* eslint-disable @typescript-eslint/no-misused-promises */
import { ICreatePasswordData } from 'api/auth/useCreatePassword/types'
import { useMutate } from 'hooks/api'
import { useAuth } from 'hooks/auth/useAuth'

export const useCreatePassword = () => {
  const {
    actions: { chooseNextStep },
  } = useAuth()

  const { mutate, isLoading, error } = useMutate<unknown, unknown, ICreatePasswordData>(
    'post',
    {
      url: '/api/Users/password',
      headers: {
        Accept: 'application/json',
      },
    },
    chooseNextStep
  )

  const createPassword = (data: ICreatePasswordData) => mutate(data)

  return { createPassword, isLoading, error }
}
