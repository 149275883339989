import { ServiceCategory } from 'models/service';
import { Site } from 'models/site/Site';
import React, { FC, memo } from 'react';
import { FirebaseService } from 'services/firebase';
import { IntakePeriodsTable } from './components/IntakePeriodsTable/IntakePeriodsTable';
import { SiteInformationItem } from './components/SiteInformationItem/SiteInformationItem';
import { UnderlinedHeading } from './components/UnderlinedHeading/UnderlinedHeading';
import {
  SiteBasicInformationBlock,
  SiteTwoColumnsBlock,
  SiteImage,
  SiteInformationSection,
  LengthOfStayRow,
  SiteOneColumnBlock,
  EditSiteButton,
} from './styled';
import { Link } from 'react-router-dom';
import { routes } from 'router/data';
import { useAuth } from 'hooks/auth/useAuth';
import { useIsUserPrivileged } from 'hooks/auth/useIsUserPrivileged';

interface Props {
  /** Site. */
  readonly site: Site;
}

/**
 * Join item names to a single string separating them with comma.
 * @param items Items to join.
 */
function joinItemNames<T extends {name: string}>(items: readonly T[]): string {
  return items.length === 0 ? '-' : items.map(item => item.name).join(', ');
}

const SiteInformationComponent: FC<Props> = ({ site }) => {

  const isUserPrivileged = useIsUserPrivileged(site.organizationId.toString());

  return (
    <>
      <SiteBasicInformationBlock>
        <SiteInformationSection>
          <UnderlinedHeading text="Basic Info" />
          <SiteTwoColumnsBlock>
            <SiteInformationItem heading="Name" text={site.name} />
            {site.alias !== null && <SiteInformationItem heading="Also known as" text={site.alias} />}
            <SiteInformationItem heading="Description" text={site.description} shouldTakeAllColumns />
          </SiteTwoColumnsBlock>
        </SiteInformationSection>
        <SiteInformationSection>
          <UnderlinedHeading text="Image" />
          <SiteImage src={FirebaseService.getImageSrc(site.coverImage?.imagePath)} alt="Preview" />
        </SiteInformationSection>
      </SiteBasicInformationBlock>
      <SiteInformationSection>
        <UnderlinedHeading text="Contacts" />
        <SiteTwoColumnsBlock>
          <SiteInformationItem heading="Address" text={site.address} shouldTakeAllColumns />
          <SiteInformationItem heading="Phone" text={site.contactPhone} />
          <SiteInformationItem heading="Website" text={site.contactWeb} isLink />
          <SiteInformationItem heading="Email" text={site.contactEmail} />
        </SiteTwoColumnsBlock>
      </SiteInformationSection>
      <SiteInformationSection>
        <UnderlinedHeading text="Intake hours" />
        <IntakePeriodsTable intakePeriods={site.intakePeriods} />
      </SiteInformationSection>
      <SiteInformationSection>
        <UnderlinedHeading text="Services" />
        <SiteOneColumnBlock>
          <SiteInformationItem
            heading="Services"
            text={joinItemNames(site.services.filter((service) => service.category === ServiceCategory.General))}
          />
          <SiteInformationItem
            heading="Disabilities"
            text={joinItemNames(
              site.services.filter((service) => service.category === ServiceCategory.Disability)
            )}
          />
          <SiteInformationItem
            heading="Allowed genders"
            text={joinItemNames(site.services.filter((service) => service.category === ServiceCategory.Gender))}
          />
          <SiteInformationItem
            heading="Allowed age ranges"
            text={joinItemNames(site.services.filter((service) => service.category === ServiceCategory.Age))}
          />
        </SiteOneColumnBlock>
      </SiteInformationSection>
      <SiteInformationSection>
        <UnderlinedHeading text="Other" />
        <SiteOneColumnBlock>
          <SiteInformationItem heading="Languages" text={joinItemNames(site.languages)} />
          <SiteInformationItem heading="Max screening period" text={`${site.screeningPeriod.totalHours} h`} />
        </SiteOneColumnBlock>
      </SiteInformationSection>
      {
        isUserPrivileged && (
          <EditSiteButton component={Link} to={routes.SiteEdit(site.id)}>Edit Site</EditSiteButton>
        )
      }
    </>
  );
};

export const SiteInformation = memo(SiteInformationComponent);
