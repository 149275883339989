import React, { FC, memo } from 'react';
import { TableRow } from '@mui/material';
import { LogsTableCell } from '../TableCells/TableCells';
import { getTimeFromDate } from '../../../../utils/getTimeFromDate';
import { User } from '../../../../models/user/User';

interface Props {

  /** User. */
  readonly user: User;
}

const InternalTableRowComponent: FC<Props> = ({ user }) => {
  return (
    <TableRow>
      <LogsTableCell>{user.organization?.name}</LogsTableCell>
      <LogsTableCell>{user.siteName}</LogsTableCell>
      <LogsTableCell>{user.userInfo.email}</LogsTableCell>
      <LogsTableCell>{user.role}</LogsTableCell>
      <LogsTableCell>{user.createdAt.toLocaleDateString()}</LogsTableCell>
      <LogsTableCell>{getTimeFromDate(user.createdAt)}</LogsTableCell>
    </TableRow>
  )
}

export const InternalTableRow = memo(InternalTableRowComponent);
