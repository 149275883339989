/* eslint-disable no-irregular-whitespace */
import React, { FC, useEffect } from 'react';
import { InputField } from 'components/InputField';
import { Gap, RowView } from 'components/Layout/Grid';
import { FormSectionWrap } from 'components/Layout/Grid/FormSectionWrap';
import { MainHeading, SecondaryHeading } from 'components/Typography';
import { SiteFormBlockProps } from 'pages/SiteEdit';
import { LocationInput } from 'components/LocationInput/LocationInput';
import { useFormikContext } from 'formik';
import { IContactsForm } from 'services/types';
import { PhoneInput } from 'components/PhoneInput/PhoneInput';
import { useParams } from 'react-router-dom';

interface Params {

  /** Organization id. */
  readonly organizationId?: string;
}

interface Props {

  /** Whether we should not add admin email field. */
  readonly withoutAdminEmailField?: boolean;
}

export const ContactsForm: FC<SiteFormBlockProps & Props> = ({ withoutMainHeader, withoutAdminEmailField }) => {
  const { values, setFieldValue } = useFormikContext<IContactsForm>();

  const { organizationId } = useParams<Params>();

  useEffect(() => {
    if (values.organizationId === '' && organizationId != null) {
      setFieldValue('organizationId', organizationId);
    }
  }, [organizationId, setFieldValue, values.organizationId]);

  return (
    <FormSectionWrap>
      {!withoutMainHeader && (
        <MainHeading>Add details so people can contact you</MainHeading>
      )}
      <SecondaryHeading>Contacts</SecondaryHeading>

      <RowView mt={10}>
        <LocationInput
          locationType="address"
          name="address"
          label="Address"
          placeholder="Enter address here"
        />
      </RowView>
      <RowView>
        <PhoneInput
          name="contactPhone"
          label="Phone"
        />
        <Gap />
        <InputField
          name="contactWeb"
          label="Web"
          placeholder="https://www.yourdomain.com"
        />
      </RowView>

      <InputField
        name="contactEmail"
        label="Email"
        placeholder="maincontact@yourdomain.com"
      />
      {withoutAdminEmailField ||
        <InputField
          name="adminEmail"
          label="Admin Email"
          placeholder='adminemail@yourdomain.com'
        />
      }
    </FormSectionWrap>
  );
};
