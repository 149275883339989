import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const StyledIntakePeriodsTable = styled.table`
  border-collapse: collapse;
  width: 50%;
`;

export const StyledIntakePeriodsTableRow = styled.tr`
  border-bottom: 1px solid ${theme.colors.grayBorderColor};
`;

export const IntakePeriodsTableCell = styled.td`
  padding-block: 10px;
  font-size: var(--font-size-md);
  font-weight: 500;
`;
