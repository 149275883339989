import { TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { FC, memo } from 'react';
import { useOrganizationSubscription } from '../../../../api/subscription/useOrganizationSubscription';
import { Loader } from '../../../../components/Loader/Loader';
import { Organization } from '../../../../models/organization/Organization';
import { routes } from '../../../../router/data';
import { formatDateToReadableString } from '../../../../utils/formatDateToString';
import { LoaderWrapper, PaymentInfoFlexContainer, PaymentInfoTable, PaymentInfoTableCell, PaymentInfoTableHeader, PaymentInfoTableLink } from './styled';

interface Props {

  /** Organization id. */
  readonly organizationId: Organization['id'];
}

const PaymentDetailsInfoTableComponent: FC<Props> = ({ organizationId }) => {

  const { data: subscription } = useOrganizationSubscription(organizationId);

  if (subscription === undefined) {
    return (
      <LoaderWrapper>
        <Loader size="medium" isPrimary />
      </LoaderWrapper>
    );
  };

  if (subscription === null) {
    return <p>There is no active subscription.</p>
  }

  return (
    <TableContainer>
      <PaymentInfoTable stickyHeader>
        <TableHead>
          <TableRow>
            <PaymentInfoTableHeader>Next Invoice Issue Date</PaymentInfoTableHeader>
            <PaymentInfoTableHeader>Invoice Total</PaymentInfoTableHeader>
            <PaymentInfoTableHeader>Beds Total</PaymentInfoTableHeader>
            <PaymentInfoTableHeader>Payment Method</PaymentInfoTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <PaymentInfoTableCell>
              {formatDateToReadableString(subscription.nextMonthlyPaymentDate)}
            </PaymentInfoTableCell>
            <PaymentInfoTableCell>
              {subscription.totalInvoice}
            </PaymentInfoTableCell>
            <PaymentInfoTableCell>
              {subscription.totalPaidBedCount}
            </PaymentInfoTableCell>
            <PaymentInfoTableCell>
              <PaymentInfoFlexContainer>
                <img src={subscription.defaultSavedCard.brandLogoImageSrc} alt={subscription.defaultSavedCard.brand} />
                <p>{`•• ${subscription.defaultSavedCard.lastFourDigits}`}</p>
              </PaymentInfoFlexContainer>
            </PaymentInfoTableCell>
          </TableRow>
          <TableRow>
            <PaymentInfoTableCell />
            <PaymentInfoTableCell />
            <PaymentInfoTableCell />
            <PaymentInfoTableCell>
              <PaymentInfoTableLink to={routes.OrganizationCardsDetails(organizationId)}>Change Payment Method</PaymentInfoTableLink>
            </PaymentInfoTableCell>
          </TableRow>
        </TableBody>
      </PaymentInfoTable>
    </TableContainer>
  )
}

export const PaymentDetailsInfoTable = memo(PaymentDetailsInfoTableComponent);
