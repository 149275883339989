import styled from '@emotion/styled';
import { FormSectionWrap } from 'components/Layout/Grid/FormSectionWrap';
import { StyledButton } from 'components/StyledButton/styled';

export const PlaceholderElement = styled.div`
  --placeholder-height: 50px;
  width: 100%;
  height: var(--placeholder-height);
  border-radius: var(--border-radius);
  background-color: var(--faint-color);
  color: var(--faint-dark-color);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlaceholderText = styled.p`
  display: flex;
  align-items: center;
`

export const RoomsPlaceholder = styled(PlaceholderElement)`
  --placeholder-height: 250px;
  margin-bottom: 20px;
`;

export const RoomsActionButtons = styled.div`
  display: flex;
  gap: 10px;
`;

export const RoomsReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-inline: 20px;
`;

export const RoomsListContainer = styled(FormSectionWrap)`
  @media print {
    padding-inline: 10px;
  }
`;

export const RoomsListButton = styled(StyledButton)`
  @media print {
    display: none;
  }
`;

export const PrintHeaderContainer = styled.div`

  display: none;

  @media print {
    display: block;
  }
`;
