import styled from '@emotion/styled'
import CSS from 'csstype'
import { theme } from 'styles/theme'

export const SelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  position: relative;
  height: auto;
  margin-bottom: 15px;
`

export const customStyles = (
  isCutLeft?: boolean,
  minWidth?: CSS.Property.Width,
  hasErrors?: boolean,
) => {
  return ({
    option: (provided: any, state: any) => ({
      ...provided,
      color: theme.colors.dark,
      fontWeight: state.isSelected ? 800 : 400,
      padding: '12px 16px',
    }),
    control: (_props: any, state: any) => ({
      display: 'flex',
      border: `1px solid ${hasErrors ? theme.colors.red : theme.colors.middleGray}`,
      // width: '100%',
      borderRadius: isCutLeft ? '0 4px 4px 0' : '4px',
      borderLeft: isCutLeft ? 'none' : undefined,
      background: state.isDisabled ? theme.colors.lightGray : 'transparent',
      minWidth: minWidth ? minWidth : undefined,
    }),
    menu: ({ ...css }) => ({ ...css }),
    dropdownindicator: () => ({}),
  })
}

export const customTheme = {
  primary25: theme.colors.lightGray,
  primary: theme.colors.lightGray,
  neutral0: theme.colors.white,
  danger: theme.colors.red,
}
