import React, { FC, memo, useState } from 'react';
import { useCreateInsuranceCompany } from '../../api/insuranceCompany/useCreateInsuranceCompany';
import { useInsuranceCompanies } from '../../api/insuranceCompany/useInsuranceCompanies';
import { ButtonIcon } from '../../components/ButtonIcon/styled';
import { CardItemsGridContainer } from '../../components/CardItemsGridContainer/CardItemsGridContainer';
import { ModalWindow } from '../../components/Dialogs/ModalWindow/ModalWindow';
import { Layout } from '../../components/Layout';
import { PageHeaderLineContainer } from '../../components/PageHeaderLineContainer/PageHeaderLineContainer';
import { StyledButton } from '../../components/StyledButton/styled';
import { InsuranceCompany } from '../../models/insuranceCompany/InsuranceCompany';
import { InsuranceCompanyCreationValues } from '../../models/insuranceCompany/InsuranceCompanyCreationValues';
import { LoadingPage } from '../LoadingPage';
import { InsuranceCompanyCard } from './components/InsuranceCompanyCard';
import { InsuranceCompanyForm, InsuranceCompanyFormValues } from './components/InsuranceCompanyForm';

/**
 * Sorts insurance companies in alphabetical order.
 * @param companies Insurance companies to sort.
 */
function sortInsuranceCompaniesAlphabetically(companies: readonly InsuranceCompany[]): InsuranceCompany[] {
  return [...companies]
    .sort((firstCompany, secondCompany) =>
      firstCompany.name.toLowerCase() > secondCompany.name.toLowerCase() ? 1 : -1
    )
};

const InsuranceCompaniesPageComponent: FC = () => {

  const { data: insuranceCompanies, revalidate: revalidateInsuranceCompanies } = useInsuranceCompanies();
  const { handleCreate: createInsuranceCompany } = useCreateInsuranceCompany();

  const [isCreateInsuranceModalOpen, setIsCreateInsuranceModalOpen] = useState(false);

  const openCreateInsuranceModal = () => setIsCreateInsuranceModalOpen(true);
  const closeCreateInsuranceModal = () => setIsCreateInsuranceModalOpen(false);

  if (insuranceCompanies == null) {
    return <LoadingPage />;
  }

  const handleCreateInsuranceCompany = async(formValues: InsuranceCompanyFormValues) => {
    const insuranceCompanyCreationValues: InsuranceCompanyCreationValues = {
      name: formValues.name,
      isPrimary: formValues.isPrimary,
      logoPath: formValues.logoPath,
    };
    await createInsuranceCompany(insuranceCompanyCreationValues);
    await revalidateInsuranceCompanies();
    closeCreateInsuranceModal();
  }

  const primaryInsuranceCompanies = sortInsuranceCompaniesAlphabetically(
    insuranceCompanies.filter(company => company.isPrimary),
  );

  const secondaryInsuranceCompanies = sortInsuranceCompaniesAlphabetically(
    insuranceCompanies.filter(company => !company.isPrimary),
  );

  const sortedInsuranceCompanies: readonly InsuranceCompany[] = [
    ...primaryInsuranceCompanies,
    ...secondaryInsuranceCompanies
  ];

  return (
    <Layout withSideBar>
      <PageHeaderLineContainer>
        <h1>Insurance Companies</h1>
        <StyledButton type="button" onClick={openCreateInsuranceModal}>
            <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
            New Insurance
          </StyledButton>
      </PageHeaderLineContainer>

      <CardItemsGridContainer>
        {sortedInsuranceCompanies.map(company => (
          <InsuranceCompanyCard
            key={company.id}
            company={company}
            revalidateCompanies={revalidateInsuranceCompanies}
          />
        ))}
      </CardItemsGridContainer>

      <ModalWindow isOpen={isCreateInsuranceModalOpen} isScrollable onClose={closeCreateInsuranceModal}>
        <InsuranceCompanyForm
          onSubmit={handleCreateInsuranceCompany}
          onCancel={closeCreateInsuranceModal}
        />
      </ModalWindow>
    </Layout>
  );
};

export const InsuranceCompaniesPage = memo(InsuranceCompaniesPageComponent);
