import { useUpdateGuestStatus } from 'api/guests';
import { EGuestStatus, GuestMethods, IGuestType } from 'api/guests/types';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';
import React, { FC, useCallback, useState } from 'react';
import { getDaysDifference } from 'utils/getDaysDifference';
import {
  DischargeDialogInfo,
  DischargeDialogRoomInfo,
  DischargeDialogRoomInfoItem,
  DischargeFeedbackField,
  RoomInfoItemText,
  RoomInfoItemTitle,
} from './styled';

interface DischargeDialogProps {
  /** Whether dialog is opened or not. */
  readonly isOpen: boolean;

  /** Handle dialog close event. */
  readonly onClose: () => void;

  /** Guest whos gonna be discharged. */
  readonly guest: IGuestType;

  /** Name of the guest's room. */
  readonly roomName: string;

  /** Index of the guest's bed */
  readonly bedIndex: number;

  /** Handle click on confirmation button click. */
  readonly onConfirmationButtonClick?: () => void;
}

const DischargeDialogComponent: FC<DischargeDialogProps> = ({
  isOpen,
  onClose,
  guest,
  onConfirmationButtonClick,
  bedIndex,
  roomName,
}) => {

  const [feedback, setFeedback] = useState('');

  const { handleUpdateStatus } = useUpdateGuestStatus(
    guest.siteId,
    onConfirmationButtonClick ? onConfirmationButtonClick : () => {}
  );
  if (guest.acceptedAt == null) {
    return <></>;
  }
  const acceptedAtDate = new Date(guest.acceptedAt);
  const currentDate = new Date();

  const days = getDaysDifference(currentDate, acceptedAtDate);

  const handleConfirmButtonClick = useCallback(() => handleUpdateStatus({
    guestId: guest.id,
    status: EGuestStatus.DISCHARGED,
    feedback,
    bedId: null,
  }), [guest, feedback]);

  return (
    <ConfirmDialog
      isOpened={isOpen}
      onClose={onClose}
      title="Are you sure to Discharge the Bed?"
      cancelButtonText="Cancel"
      onConfirmationButtonClick={() => {
        handleConfirmButtonClick();
        onClose();
      }}
      confirmButtonText="Discharge Bed"
      confirmButtonColor="error"
    >
      <DischargeDialogInfo>
        <DischargeDialogRoomInfo>
          {`The bed #${bedIndex} in ${roomName} is occupied`}
        </DischargeDialogRoomInfo>
        <DischargeDialogRoomInfoItem>
          <RoomInfoItemTitle>Guest</RoomInfoItemTitle>
          <RoomInfoItemText>{GuestMethods.getFullName(guest)}</RoomInfoItemText>
        </DischargeDialogRoomInfoItem>
        <DischargeDialogRoomInfoItem>
          <RoomInfoItemTitle>Days</RoomInfoItemTitle>
          <RoomInfoItemText>{`${days}`}</RoomInfoItemText>
        </DischargeDialogRoomInfoItem>
        <DischargeFeedbackField
          value={feedback}
          onChange={setFeedback}
        />
      </DischargeDialogInfo>
    </ConfirmDialog>
  );
};

export const DischargeDialog = DischargeDialogComponent;
