import React from 'react'
import { Switch, Route } from 'react-router-dom'
import 'styles/App.css'
import { Flash } from 'components/Flash'
import { CreatePassword } from 'pages/CreatePassword'
import { HistoryPage } from 'pages/History'
import { Home } from 'pages/Home'
import { CreateSite } from 'pages/SiteCreate'
import { EditSite } from 'pages/SiteEdit'
import { SiteList } from 'pages/SiteList'
import { ReservationsPage } from 'pages/Reservation'
import { ReservationMainPage } from 'pages/Reservation/MainList'
import { ResetPassword } from 'pages/ResetPassword'
import { RestorePassword } from 'pages/RestorePassword'
import { SignIn } from 'pages/SignIn'
import { SingInCallback } from 'pages/SignInCallback'
import { TwoFactorConfirm } from 'pages/TwoFactorConfirm'
import { TwoFactorSetup } from 'pages/TwoFactorSetup'
import { UserManagementPage } from 'pages/UserManagement'
import { UserSettingsPage } from 'pages/UserSettings'
import { AuthRoute } from 'router/AuthRoute'
import { ERoute } from 'router/data'
import { HistoryList } from 'pages/History/list'
import { UserManagementList } from 'pages/UserManagement/list'
import { ReservationRequestPage } from 'pages/ReservationRequest/ReservationRequest'
import { GuestDetailsPage } from 'pages/GuestDetails/GuestDetails'
import { OrganizationsListPage } from 'pages/OrganizationsList/OrganizationsList'
import { OrganizationDetailsPage } from 'pages/OrganizationDetails/OrganizationDetails'
import { OrganizationSiteDetailsPage } from 'pages/OrganizationSiteDetails/OrganizationSiteDetails'
import { RoleOwnerAndGlobalRoute } from './RoleOwnerAndGlobalRoute'
import { ErrorPage } from '../pages/ErrorPage/ErrorPage'
import { OrganizationsRoute } from './OrganizationsRoute'
import { RoleGlobalRoute } from './RoleGlobalRoute'
import { InsuranceCompaniesPage } from '../pages/InsuranceCompanies/InsuranceCompanies'
import { OrganizationInsurancesPage } from 'pages/OrganizationInsurances/OrganizationInsurancesPage'
import { OrganizationCardsDetailsPage } from '../pages/OrganizationCardsDetails/OrganizationCardsDetailsPage'
import { PaymentDetailsPage } from '../pages/PaymentDetails/PaymentDetails'
import { AuditLogPage } from '../pages/AuditLog/AutitLogPage'

// TODO: implement partial (logged in) and full (authorized) route protection
export function Routes() {
  return (
    <>
      <Flash />
      <Switch>
        <Route
          path={ERoute.USER_SETTINGS}
          exact
          component={UserSettingsPage}
        />
        <Route
          path={ERoute.USER_MANAGEMENT}
          exact
          component={UserManagementList}
        />
        <AuthRoute
          path={ERoute.USER_MANAGEMENT_DETAIL}
          component={UserManagementPage}
        />
        <Route
          path={ERoute.HISTORY}
          exact
          component={HistoryList}
        />
        <AuthRoute
          path={ERoute.HISTORY_DETAIL}
          component={HistoryPage}
        />
        <Route
          path={ERoute.HOMEPAGE}
          exact
          component={Home}
        />
        <Route
          path={ERoute.SIGN_IN_CALLBACK}
          exact
          component={SingInCallback}
        />
        <Route
          path={ERoute.TWO_FACTOR_SETUP}
          exact
          component={TwoFactorSetup}
        />
        <Route
          path={ERoute.RESTORE_PASSWORD}
          exact
          component={RestorePassword}
        />
        <Route
          path={ERoute.RESET_PASSWORD}
          exact
          component={ResetPassword}
        />
        <Route
          path={ERoute.CREATE_PASSWORD}
          exact
          component={CreatePassword}
        />
        <Route
          path={ERoute.TWO_FACTOR_CONFIRM}
          exact
          component={TwoFactorConfirm}
        />
        <AuthRoute
          path={ERoute.LOGIN}
          isPublic
          component={SignIn}
        />
        <RoleGlobalRoute
          path={ERoute.INSURANCE_COMPANIES}
          component={InsuranceCompaniesPage}
        />
        <RoleGlobalRoute
          path={ERoute.AUDIT_LOG}
          component={AuditLogPage}
        />
        <RoleOwnerAndGlobalRoute
          path={ERoute.SITE_CREATE}
          component={CreateSite}
        />
        <AuthRoute
          path={ERoute.SITE_EDIT}
          component={EditSite}
        />
        <AuthRoute
          path={ERoute.GUEST_DETAILS_PAGE}
          component={GuestDetailsPage}
        />
        <AuthRoute
          path={ERoute.RESERVATION_DETAIL}
          component={ReservationsPage}
        />
        <AuthRoute
          path={ERoute.RESERVATION}
          component={ReservationMainPage}
        />
        <AuthRoute
          path={ERoute.RESERVATION_REQUEST}
          component={ReservationRequestPage}
        />
        <RoleOwnerAndGlobalRoute
          path={ERoute.ORGANIZATION_INSURANCES}
          component={OrganizationInsurancesPage}
        />
        <AuthRoute
          path={ERoute.ORGANIZATION_CARDS_DETAILS}
          component={OrganizationCardsDetailsPage}
          isPubic
        />
        <AuthRoute
          path={ERoute.ORGANIZATION_PAYMENT_DETAILS}
          component={PaymentDetailsPage}
        />
        <OrganizationsRoute
          path={ERoute.ORGANIZATION_SITE_DETAILS}
          component={OrganizationSiteDetailsPage}
        />
        <OrganizationsRoute
          path={ERoute.ORGANIZATION_DETAILS}
          component={OrganizationDetailsPage}
        />
        <OrganizationsRoute
          path={ERoute.ORGANIZATIONS_LIST}
          component={OrganizationsListPage}
        />
        <AuthRoute
          path={ERoute.ERROR}
          isPublic
          component={ErrorPage}
        />
      </Switch>
    </>
  )
}
