/** Payment method brand dto. */
export enum PaymentMethodBrandDto {
  Visa = 'visa',
  Mastercard = 'mastercard',
}

/** Saved payment method dto. */
export interface SavedPaymentMethodDto {

  /** ID. */
  readonly id: number;

  /** Brand of the card. */
  readonly brand: PaymentMethodBrandDto;

  /** Last four digits of the card number. */
  readonly lastFourDigits: string;

  /** Whether the payment method is default one. */
  readonly isDefault: boolean;

  /** Expiration date in `mm/yy` format. */
  readonly expirationDate: string;
}
