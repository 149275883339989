import styled from '@emotion/styled';
import { StyledLabel } from 'components/InputField/styled';

export const LogoUploadContainer = styled.div`
  margin-bottom: 16px;
`;

export const LogoUploadRow = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  grid-template-rows: 16px 64px;
  grid-column-gap: 16px;
  grid-row-gap: 5px;
`;

export const LogoUploadLabel = styled(StyledLabel)`
  grid-row: 1;
  grid-column: 1/3;
`;

export const LogoUploadInfo = styled.p`
  grid-row: 2;
  grid-column: 2;
  font-size: var(--font-size-xs);
  font-weight: 500;
  color: hsla(0, 0%, 0%, 0.3);
  display: flex;
  align-items: center;
`
