import { UserRole } from '../user/User';

type GuestCreationLogConstructorData = GuestCreationLog;

/** Guest creation log. */
export class GuestCreationLog {

  /** ID. */
  public readonly id: number;

  /** First name. */
  public readonly firstName: string;

  /** Last name. */
  public readonly lastName: string;

  /** Site name. */
  public readonly siteName: string;

  /** Email of staff who created guest. */
  public readonly createdByEmail: string | null;

  /** Role of staff who created guest. */
  public readonly createdByRole: UserRole | null;

  /** Guest creation date. */
  public readonly guestCreatedAt: Date;

  public constructor(data: GuestCreationLogConstructorData) {
    this.id = data.id;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.siteName = data.siteName;
    this.createdByEmail = data.createdByEmail;
    this.createdByRole = data.createdByRole;
    this.guestCreatedAt = data.guestCreatedAt;
  }
}
