import React, { FC, useCallback, useMemo, useState } from 'react'
import {
  Stepper,
  BackButton,
  StyledOl,
  StyledStep,
} from 'pages/SiteCreate/components/WizardSideNavigation/styled'
import { routes } from 'router/data'
import { useHistory, useParams } from 'react-router-dom'
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog'

interface Props {
  steps: any[]
  currentStep: string
  status: any
}

interface RouteParams {
  /** Organization id. */
  readonly organizationId?: string;
}

export const WizardSideNavigation: FC<Props> = ({
  currentStep,
  steps,
}) => {

  const history = useHistory();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const toggleDialog = useCallback(() => setIsDialogOpen(isOpen => !isOpen), [setIsDialogOpen]);

  const currentIndex = steps.findIndex((step) => step.id === currentStep)

  const { organizationId } = useParams<RouteParams>();

  const backRoute = useMemo(() => routes.OrganizationDetails(organizationId ?? ''), [organizationId]);

  const handleBackNavigation = useCallback(() => history.push(backRoute), [history, backRoute]);

  return (
    <Stepper>
      <BackButton type="button" onClick={toggleDialog}>⟵ Back</BackButton>

      <StyledOl>
        {steps.map((step, index) => (
          <StyledStep
            key={step.id}
            active={step.id === currentStep}
            completed={currentIndex >= index + 1}
          >
            {step.label}
          </StyledStep>
        ))}
      </StyledOl>

      <ConfirmDialog
        isOpened={isDialogOpen}
        onClose={toggleDialog}
        onConfirmationButtonClick={handleBackNavigation}
        confirmButtonVariant="contained"
      >
        Are you sure you want to leave this page? You will lose all progress.
      </ConfirmDialog>
    </Stepper>
  )
}
