import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useSavedPaymentMethods } from '../../api/Subscriptions/useSavedPaymentMethods';
import { AddCardForm } from '../../components/AddCardForm/AddCardForm';
import { ButtonIcon } from '../../components/ButtonIcon/styled';
import { ModalWindow } from '../../components/Dialogs/ModalWindow/ModalWindow';
import { ErrorMessage } from '../../components/FetchErrorMessage/FetchErrorMessage';
import { Layout } from '../../components/Layout';
import { OrganizationsNav, OrganizationsNavItem } from '../../components/OrganizationsNav/OrganizationsNav';
import { PageHeaderLineContainer } from '../../components/PageHeaderLineContainer/PageHeaderLineContainer';
import { SavedPaymentMethodsTable } from '../../components/SavedPaymentMethodsTable/SavedPaymentMethodsTable';
import { StyledButton } from '../../components/StyledButton/styled';
import { Organization } from '../../models/organization/Organization';
import { LoadingPage } from '../LoadingPage';

interface RouteProps {

  /** ID of the organization. */
  readonly organizationId: Organization['id'];
}

export const OrganizationCardsDetailsPage: FC<RouteComponentProps<RouteProps>> = ({ match }) => {

  const { organizationId } = match.params;

  const { data: paymentMethods, error: paymentMethodsError, revalidate: revalidatePaymentMethods } = useSavedPaymentMethods(Number(organizationId));

  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);

  const handleAddCardModalOpen = () => setIsAddCardModalOpen(true);
  const handleAddCardModalClose = () => setIsAddCardModalOpen(false);

  if (paymentMethodsError != null) {
    return (
      <Layout withSideBar>
        <ErrorMessage message={paymentMethodsError.toString()} />
      </Layout>
    );
  };

  if (paymentMethods == null) {
    return <LoadingPage />;
  };

  const navigationItems: readonly OrganizationsNavItem[] = [
    {
      name: 'Credit cards'
    },
  ];

  const handleAddCardSave = async() => {
    await revalidatePaymentMethods()
    handleAddCardModalClose();
  }

  return (
    <Layout withSideBar>
      <OrganizationsNav items={navigationItems} />
      <PageHeaderLineContainer>
        <h1>Credit cards</h1>
        <StyledButton onClick={handleAddCardModalOpen} type="button">
          <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
          Add new card
        </StyledButton>
      </PageHeaderLineContainer>
      <SavedPaymentMethodsTable paymentMethods={paymentMethods} organizationId={organizationId} />
      <ModalWindow
        isOpen={isAddCardModalOpen}
        onClose={handleAddCardModalClose}
        isScrollable
      >
        <AddCardForm
          onClose={handleAddCardModalClose}
          onSave={handleAddCardSave}
          organizationId={Number(organizationId)}
        />
      </ModalWindow>
    </Layout>
  )
}
