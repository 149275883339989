import { SiteEditingValues } from 'models/site/SiteEditingValues';
import React, { createContext, FC, memo, PropsWithChildren, useContext, useMemo, useState } from 'react';

type EditedSiteDraftContext = SiteEditingValues | null;

type DispatchEditedSiteDraftContext = ((newContext: SiteEditingValues | null) => void) | undefined;

export const EditedSiteDraftContext = createContext<EditedSiteDraftContext>(null);
export const SetEditedSiteDraftContext = createContext<DispatchEditedSiteDraftContext | undefined>(undefined);

const EditedSiteDraftContextProviderComponent: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [editedSiteDraftContext, setEditedSiteDraftContext] = useState<EditedSiteDraftContext | null>(null);

  return (
    <EditedSiteDraftContext.Provider value={editedSiteDraftContext}>
      <SetEditedSiteDraftContext.Provider value={setEditedSiteDraftContext}>
        {children}
      </SetEditedSiteDraftContext.Provider>
    </EditedSiteDraftContext.Provider>
  );
};

export const EditedSiteDraftContextProvider = memo(EditedSiteDraftContextProviderComponent);

export const useEditedSiteDraftContext = (): [
  EditedSiteDraftContext,
  NonNullable<DispatchEditedSiteDraftContext>,
] => {
  const editedSiteContext = useContext(EditedSiteDraftContext);
  const setEditedSiteContext = useContext(SetEditedSiteDraftContext);

  if (setEditedSiteContext == null) {
    throw new Error('You must use useEditedSiteDraftContext hook inside of EditedSiteDraftContextProvider.');
  }

  return [editedSiteContext, setEditedSiteContext];
};
