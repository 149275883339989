import { Formik } from 'formik';
import React, { FC } from 'react';
import { CancelLink } from 'components/CancelLink';
import { ErrorTooltip } from 'components/ErrorTooltip';
import { InputField } from 'components/InputField';
import { ThemedButton } from 'components/ThemedButton';
import {
  Footer,
  FormHeading,
  StyledForm,
  FormText,
  Center,
  Header,
  Content,
  ButtonContainer,
} from 'forms/SignIn/styled';
import { initialValues, validationSchema } from 'forms/TwoFactorConfirm/data';
import { Props } from 'forms/TwoFactorConfirm/types';
import { ERoute } from 'router/data';

export const TwoFactorConfirmForm: FC<Props> = ({
  handleSubmit,
  isLoading,
  phone,
  errorText,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
  >
    {({ submitForm }) => (
      <StyledForm>
        <Header>
          {phone && <CancelLink to={ERoute.TWO_FACTOR_SETUP} label="Back" />}
          <FormHeading>Check your phone</FormHeading>
          <FormText>
            A message with your confirmation code has been sent to{' '}
            {phone ?? 'your phone'}. Provide the confirmation code below:
          </FormText>
        </Header>

        <Center>
          <Content aria-autocomplete="none">
            <InputField name="code" label="Confirmation code" type="text" />
          </Content>
        </Center>

        <Footer>
          <ButtonContainer>
            <ThemedButton
              disabled={isLoading}
              type="submit"
              width="100%"
              onClick={submitForm}
            >
              Continue
            </ThemedButton>
            {errorText && (
              <ErrorTooltip isOpen text={errorText} isRight centered />
            )}
          </ButtonContainer>
        </Footer>
      </StyledForm>
    )}
  </Formik>
);
