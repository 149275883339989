import { GuestBedDto } from 'dtos/bed/GuestBedDto';
import { GuestBed, GuestBedRoomInfo } from 'models/bed/GuestBed';

export namespace GuestBedMapper {
  export function fromDto(dto: GuestBedDto) {
    return new GuestBed({
      id: dto.id,
      imagePath: dto.imagePath ?? null,
      type: dto.type,
      index: dto.index,
      room: new GuestBedRoomInfo({
        id: dto.room.id,
        name: dto.room.name,
        imagePath: dto.room.imagePath ?? null,
      }),
    });
  }
}
