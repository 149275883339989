import { OrganizationDto } from '../organizationDto/OrganizationDto';

/** User membership status dto. */
export enum UserMembershipStatusDto {
  PendingInvitation = 'PendingInvitation',
  Active = 'Active',
  Disabled = 'Disabled',
}

/** User role dto. */
export enum UserRoleDto {
  Admin = 'Admin',
  Staff = 'Staff',
  Intake = 'Intake',
  GlobalAdmin = 'GlobalAdmin',
  Owner = 'Owner',
}

interface UserInfoDto {

  /** Id. */
  readonly id: string;

  /** Email. */
  readonly email: string;
}

/** User dto. */
export interface UserDto {

  /** Id. */
  readonly id: number;

  // TODO (Maxim K.): Remove null when backend fix the issue with missing organizations.
  /** Organization. */
  readonly organization: OrganizationDto | null;

  /** Status. */
  readonly membershipStatus: UserMembershipStatusDto;

  /** Role. */
  readonly role: UserRoleDto;

  /** Site ID. */
  readonly siteId: number | null;

  /** Site name. */
  readonly siteName: string | null;

  /** User info. */
  readonly user: UserInfoDto;

  /** Creation date in ISO format. */
  readonly createdAt: string;
}
