import React, { useState } from 'react'
import { useResetPassword } from 'api/auth/useResetPassword/useUpdatePassword'
import { Layout } from 'components/Layout'
import { RestorePasswordForm } from 'forms/RestorePassword'
import { IFormData } from 'forms/RestorePassword/data'
import { FormHeading, FormText, Header, StyledForm } from 'forms/SignIn/styled'

export const RestorePassword = () => {
  const [isSent, setIsSent] = useState(false)
  const { resetEmail, isLoading, error } = useResetPassword()

  const handleSubmit = async (values: IFormData) => {
    await resetEmail(values.email)

    setIsSent(true)
  }

  if (isSent) {
    return (
      <Layout grayBg>
        <Header>
          <FormHeading>Check your email</FormHeading>
          <FormText>
            We have sent you a mail with data to restore access. Please follow
            the link in email.
          </FormText>
        </Header>
      </Layout>
    )
  }

  return (
    <Layout grayBg>
      <RestorePasswordForm isLoading={isLoading} handleSubmit={handleSubmit} error={error} />
    </Layout>
  )
}
