import { checkEmailExistence } from 'api/checkEmailExistence';
import { array, boolean, number, object, string } from 'yup';
import { BasePeriod, DaysStructure } from './components/IntakePeriods/types';
import DebouncePromise from 'awesome-debounce-promise';
import { ALL_DAY_DEFAULT_TIME } from 'services/transformers';

const REQUIRED_FIELD_MESSAGE = 'Required field.';
const INVALID_EMAIL_MESSAGE = 'Invalid email.';
const INVALID_URL_MESSAGE = 'Invalid URL.';
const EMAIL_ALREADY_IN_USE_MESSAGE = 'Email already in use.';
const ADDRESS_NOT_SELECT_PROPERLY = 'Please choose address from dropdown.';

// This regexp checks that value starts with +1 and have 11 numbers in general.
const phoneRegexp = /[+]1[0-9]{10}$/;

/**
 * Validates if at least one of intake periods is enabled.
 * @param periods Object with periods to validate.
 * @returns False if all of the periods are disabled, true otherwise.
 */
function validateIntakePeriods(periods: DaysStructure): boolean {
  return !periods.long.every(value => value.enabled === false);
}

/**
 * Validates if both or none times selected for all intake periods.
 * @param periods Object with periods to validate.
 */
function validateIntakePeriodBothTimesSelected(periods: DaysStructure): boolean {
  const checkBothOrNoneFilled = (periods: readonly BasePeriod[]) => {
    return periods.every(value => (
      value.enabled ?
        value.to != null && value.from != null :
        true
      )
    );
  }
  return checkBothOrNoneFilled(periods.long);
}

/**
 * Validates if both selected to "All Time" for all intake periods.
 * @param periods Object with periods to validate.
 */
function validateIntakePeriodBothAllTimeSelected(periods: DaysStructure): boolean {
  const checkBothAllTime = (periods: readonly BasePeriod[]) => {
    return !periods.some(value => value.to !== value.from && (value.to == ALL_DAY_DEFAULT_TIME || value.from == ALL_DAY_DEFAULT_TIME))
  }
  return checkBothAllTime(periods.long);
}

type ValidateAdminEmailExistenceFunction = (email: string | undefined) => Promise<boolean>;

function getAdminEmailExistenceValidation(): ValidateAdminEmailExistenceFunction {
  let adminPreviousEmail = '';
  let isPreviousExist = true;
  return async function validateEmailExistence(email) {
    if (email == null || adminPreviousEmail === email) {
      return isPreviousExist;
    }

    const isExists = await checkEmailExistence(email);
    adminPreviousEmail = email;
    isPreviousExist = isExists;
    return isExists;
  }
}

const validateAdminEmailExistence = getAdminEmailExistenceValidation();

const EMAIL_VALIDATION_DEBOUNCE_SECONDS = 1;
const validateAdminEmailExistenceDebounced = DebouncePromise(
  validateAdminEmailExistence,
  EMAIL_VALIDATION_DEBOUNCE_SECONDS * 1000
);

export const intakePeriodsSchema = array()
  .of(object().shape({
    timePeriod: string()
      .required(REQUIRED_FIELD_MESSAGE),
    from: string(),
    to: string(),
    enabled: boolean()
      .required(REQUIRED_FIELD_MESSAGE),
  })
);

export const nonCommercialSiteAboutValidationSchema = object().shape({
  name: string()
    .required(REQUIRED_FIELD_MESSAGE),
  alias: string()
    .nullable()
    .min(3, 'Alias must be at least 3 characters.'),
  description: string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(80),
}).required();

export const commercialSiteAboutValidationSchema = nonCommercialSiteAboutValidationSchema.concat(object().shape({
  images: array().min(1, 'Please add at least one photo.'),
}).required());

export const siteContactsValidationSchema = object().shape({
  address: string()
    .nullable()
    .test(
      'Validate-address-required',
      REQUIRED_FIELD_MESSAGE,
      (value) => value !== undefined,
    )
    .test(
      'Validate-address-empty',
      ADDRESS_NOT_SELECT_PROPERLY,
      (value) => value !== null,
    ),
  contactPhone: string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'Validate-phone',
      'Phone number is incorrect',
      (value) => phoneRegexp.test(value),
    ),
  contactWeb: string()
    .url(INVALID_URL_MESSAGE),
  contactEmail: string()
    .required(REQUIRED_FIELD_MESSAGE)
    .email(INVALID_EMAIL_MESSAGE),
}).required();

const INTAKE_PERIODS_MIN_MESSAGE = 'Add at least one intake period.';
const INTAKE_PERIODS_BOTH_SELECTED_MESSAGE = 'If intake period is selected, both inputs must be filled.';
const INTAKE_PERIODS_BOTH_ALL_TIME_MESSAGE = '"All Time" should be selected in both inputs.';

export const siteIntakePeriodsValidationSchema = object().shape({
  intakePeriods: object().shape({
    short: intakePeriodsSchema,
    long: intakePeriodsSchema,
  })
  .test(
    'Check-intakes',
    INTAKE_PERIODS_MIN_MESSAGE,
    function test(value) {
      return validateIntakePeriods(value);
    }
  )
  .test(
    'Check-both-selected',
    INTAKE_PERIODS_BOTH_SELECTED_MESSAGE,
    function test(value) {
      return validateIntakePeriodBothTimesSelected(value);
    }
  )
  .test(
    'Check-both-all-time',
    INTAKE_PERIODS_BOTH_ALL_TIME_MESSAGE,
    function test(value) {
      return validateIntakePeriodBothAllTimeSelected(value);
    }
  ),
}).required();

const ROOMS_MIN_MESSAGE = 'Add at least one room.';
const BEDS_MIN_MESSAGE = 'Add at least one bed to the room.';

export const siteAccomodationsValidationSchema = object().shape({
  rooms: array()
    .min(1, ROOMS_MIN_MESSAGE)
    .of(object().shape({
      beds: array().min(1, BEDS_MIN_MESSAGE)
  }))
}).required();

const MIN_LENGTH_OF_STAY_REQUIRED_MESSAGE = 'Length of stay, min days is required.';
const MIN_LENGTH_OF_STAY_MIN_VALUE = 1;
const MIN_LENGTH_OF_STAY_MIN_VALUE_MESSAGE = `Length of stay, min days must be greater than or equal to ${MIN_LENGTH_OF_STAY_MIN_VALUE}.`
const MIN_LENGTH_OF_STAY_INTEGER_MESSAGE = 'Length of stay, min days must be integer.';

const MAX_LENGTH_OF_STAY_REQUIRED_MESSAGE = 'Length of stay, max days is required.';
const MAX_LENGTH_OF_STAY_MIN_VALUE = 2;
const MAX_LENGTH_OF_STAY_MIN_VALUE_MESSAGE = `Length of stay, max days must be greater than or equal to ${MAX_LENGTH_OF_STAY_MIN_VALUE}.`
const MAX_LENGTH_OF_STAY_MAX_VALUE = 365;
const MAX_LENGTH_OF_STAY_MAX_VALUE_MESSAGE = `Length Of Stay Days Max must be less than or equal to ${MAX_LENGTH_OF_STAY_MAX_VALUE}.`
const MAX_LENGTH_OF_STAY_INTEGER_MESSAGE = 'Length of stay, max days must be integer.';
const MAX_LENGTH_OF_STAY_MORE_THAN_MIN_LENGTH_MESSAGE = 'Length of stay, max days must be greater than Length of stay, min days.';

const SERVICES_MIN_MESSAGE = 'Add at least one service.'

export const siteServicesValidationSchema = object().shape({
  lengthOfStayDaysMin: number()
    .nullable(),
  lengthOfStayDaysMax: number()
    .nullable(),
  languageIds: string()
    .required(REQUIRED_FIELD_MESSAGE),
  screeningPeriod: string()
    .required(REQUIRED_FIELD_MESSAGE),
  serviceIds: array()
    .min(1, SERVICES_MIN_MESSAGE)
    .test(
      'validate-services',
      SERVICES_MIN_MESSAGE,
      (values: (string | undefined)[]) => values.some(value => value != null),
  ),
}).required();

export const siteCreationContactsValidationSchema = object().shape({
  adminEmail: string()
    .required(REQUIRED_FIELD_MESSAGE)
    .email(INVALID_EMAIL_MESSAGE),
}).concat(siteContactsValidationSchema);
