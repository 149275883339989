import { SortDirection } from './types/SortingDirection';

interface Params {

  /** Target value. */
  readonly targetValue: string,

  /** Value to compare with target value. */
  readonly compareValue: string,

  /** Sorting direction. */
  readonly direction: SortDirection
}

/** Performs locale compare of strings considering sorting direction. */
export function localeCompareWithDirection({
  targetValue,
  compareValue,
  direction,
}: Params): number {
  const directionMultiplier = direction === 'asc' ? 1 : -1;

  return targetValue.localeCompare(compareValue, undefined, { numeric: true }) * directionMultiplier;
}
