import { StrictOmit } from 'utils/types/strictOmit';
import { IGuestType } from 'api/guests/types';

type BedCreationData = StrictOmit<Bed, 'isOccupied'>;

/** Bed. */
export class Bed {

  /** ID. */
  public readonly id: string | null;

  /** Ordered index of bed. */
  public readonly index: number;

  /** Name. */
  public readonly name: string;

  /** Description */
  public readonly description: string;

  /** Guest. */
  public readonly guest?: IGuestType;

  /** Type. */
  public readonly type: string;

  /** Date of bed creation. */
  public readonly createdAt: Date;

  /** Related image path. */
  public readonly imagePath: string | null;

  public constructor(data: BedCreationData) {
    this.id = data.id;
    this.index = data.index;
    this.description = data.description;
    this.guest = data.guest;
    this.name = data.name,
    this.type = data.type;
    this.createdAt = data.createdAt;
    this.imagePath = data.imagePath;
  }

  /**
   * Generate bed from bed type.
   * @param bedName Bed name.
   * @param bedType Bed type.
   * @param numberOfCopies Number of copies to generate.
   * @param lastBedIndex Last bed index.
   * @param photo Photo path.
   */
  public static generateFromType(
    bedName: string,
    bedType: string,
    numberOfCopies: number,
    lastBedIndex: number,
    photo?: string,
  ): Bed[] {
    const result: Bed[] = [];
    let lastIndex = lastBedIndex;

    for (let i = 0; i < numberOfCopies; i++) {
      result.push(new Bed({
        id: null,
        description: '',
        index: this.generateNewBedIndex(lastIndex),
        name: numberOfCopies > 1 ? `${bedName} #${i + 1}` : bedName,
        guest: void 0,
        type: bedType,
        createdAt: new Date(),
        imagePath: photo ?? null,
      }));

      lastIndex++;
    }

    return result;
  }

  /**
   * Generate bed duplicate.
   * @param bed Bed to duplicate.
   * @param index Index of new bed.
   */
  public static generateDuplicateFrom(bed: Bed, index: number): Bed {
    return new Bed({
      ...bed,
      index,
    })
  }

  /**
   * Generate index for new bed.
   * @param index Index of current/previous bed.
   */
  private static generateNewBedIndex(index: number): number {
    return index + 1;
  }

  /** Whether bed is occupied or not. */
  public get isOccupied(): boolean {
    return this.guest != null;
  }
}
