import { enumToArray } from 'utils/enumToArray';
import { useParams, useHistory } from 'react-router-dom';
import { OrganizationTypeHref, routes } from 'router/data';

interface Params {

  /** Organization type. */
  readonly organizationType?: OrganizationTypeHref;
}

/**
 * Tries to get organizations type from the href params.
 * In case organization type is incorrect or not presented redirects to organizations list.
 */
export const useOrganizationTypeHref = (): OrganizationTypeHref | undefined => {

  const history = useHistory();

  const { organizationType } = useParams<Params>()

  if (organizationType == null) {
    history.push(routes.OrganizationsList());
    return;
  }

  if (enumToArray(OrganizationTypeHref).includes(organizationType)) {
    return organizationType;
  }

  history.push(routes.OrganizationsList());
  return;
}
