import { RoomDto } from './../pages/SiteCreate/components/Rooms/domain/RoomDto';
import faker from 'faker';
import { entityFactory } from 'services/fixtures';
import { Room } from 'pages/SiteCreate/components/Rooms/domain/Room';
import { Organization } from 'models/organization/Organization';
import { Site } from 'models/site/Site';
import { Service } from 'models/service';
import { PaymentMethod } from '../models/paymentMethod/PaymentMethod';
import { InsuranceCompany } from '../models/insuranceCompany/InsuranceCompany';
import { Image } from 'models/image/Image.';

export interface SiteService {
  id: string;
  name: string;
  category: string;
}

export const createOrgService = entityFactory<SiteService>(() => ({
  id: faker.datatype.uuid(),
  name: faker.lorem.word(),
  category: faker.lorem.word(),
}))

export enum EServiceCategory {
  GENERAL = 'General',
  DISABILITY = 'Disability',
  GENDER = 'Gender',
  AGE = 'Age',
  CLIENT = 'Client',
}

/** Intake period type. */
export enum IntakePeriodType {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Weekdays = 'Weekdays',
  Weekends = 'Weekends',
  Holidays = 'Holidays',
}

export interface IntakePeriod {
  timePeriod: IntakePeriodType;
  from: string;
  to: string;
}

export interface IntakePeriodsFormInfo {
  showDaysOfWeek: boolean;
  intakePeriods: {
    short: (IntakePeriod & { enabled: boolean })[];
    long: (IntakePeriod & { enabled: boolean })[];
  }
}

export interface IBed {
  description: string;
}

export interface IAccomodationForm {
  rooms: Room[];
}

export interface IAboutForm {
  name: string;
  alias: string;
  description: string;
  readonly images: readonly Image[];
}

export interface IContactsForm {

  /** Address. */
  readonly address: string;

  /** Phone number. */
  readonly contactPhone: string;

  /** Web address. */
  readonly contactWeb: string;

  /** Email address. */
  readonly contactEmail: string;

  /** Organization id. */
  readonly organizationId: Organization['id'];
}

export interface IServicesForm {
  lengthOfStayDaysMin: string | null;
  lengthOfStayDaysMax: string | null;
  screeningPeriod: string;
  languageIds: string[];
  serviceIds: string[];
}

export interface RoomsUpdate {
  rooms: {
    id: Room['id'];
    name: Room['name'];
    beds: Room['beds'];
  }[];
}

export interface SiteInputType {
  name: string;
  alias: string;
  contactWeb: string;
  contactPhone: string;
  contactEmail: string;
  addressStreet1: string;
  addressStreet2: string;
  addressZipCode: string;
  addressState: string;
  addressCity: string;
  showDaysOfWeek: boolean;
  lengthOfStayDaysMin: number | undefined;
  lengthOfStayDaysMax: number | undefined;
  screeningPeriod: string;
  description: string;
  intakePeriods: IntakePeriod[];
  rooms: RoomDto[];
  languageIds: string[];
  serviceIds: number[];
  organizationId: Organization['id'];
}

export interface Option<TValue> {
  value: TValue;
  label: string;
}

export type EGender = 'Male' | 'Female' | 'Other';

interface GuestPersonalInfo {
  gender: string;
  ageGroup: number;
  firstName: string;
  lastName: string;
}

interface GuestContactInfo {
  phone: string;
  email: string;
  location: string;
}

export type CreateGuestFormInfo = GuestPersonalInfo &
  GuestContactInfo & {

    /** Site id. */
    readonly siteId: Site['id'];

    // TODO(Maxim K.): Add readonly and refactor handleSubmit function in CreateGuestForm component.
    /** Service ids. */
    serviceIds: number[];

    /** Id of the age service. */
    readonly ageServiceId: Service['id'];

    /** Type of client service id. */
    readonly typeOfClientServiceId: Service['id'];

    /** Payment method id. */
    readonly paymentMethod: PaymentMethod | null;

    /** Insurance company id. */
    readonly insuranceCompanyId: InsuranceCompany['id'] | null;
  }

export interface GuestInputType {
  siteId: Site['id'];
  firstName: string;
  lastName: string;
  ageGroup: number;
  gender: string;
  phone: string;
  email: string;
  location: string;
  serviceIds: number[];
  organizationReservationPaymentMethodId: PaymentMethod['id'] | null;
  insuranceCompanyId: InsuranceCompany['id'] | null;
}

export type Services = {
  [key in EServiceCategory]: SiteService[]
};

/** Pagination information and items. */
export interface Pagination<TItem> {

  /** Shows if there is next page. */
  readonly hasNextPage: boolean;

  /** Shows if there is previous page. */
  readonly hasPreviousPage: boolean;

  /** Items. */
  readonly items: readonly TItem[];

  /** Current page index. */
  readonly pageIndex: number;

  /** Total count of the objects. */
  readonly totalCount: number;

  /** Total count of the pages. */
  readonly totalPages: number;
}
