import { TextField } from '@mui/material';
import { Field, useFormik } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { assertNonNullablePropertiesWithReturn } from 'utils/assertNonNull';
import { enumToArray } from 'utils/enumToArray';
import { NullableProperties } from 'utils/types/nullableProperties';
import { object, number, mixed, string } from 'yup';
import { ImagePrefix } from '../../../../../../components/UploadPhoto/ImagePrefix';
import { BedType } from '../Bed/domain/BedType';
import {
  BedCreationConfirmButton,
  BedCreationCounterButton,
  BedCreationCounterContainer,
  BedCreationCounterField,
  BedCreationFormContent,
  BedCreationImageUploader,
  BedCreationNameInput,
  BedCreationTypeAutocomplete,
} from './styled';

const BED_TYPES_LIST = enumToArray(BedType);

function formatBedName(name: string): string {
  return name.trim();
};

interface Props {
  /** Handle form submit. */
  readonly onSubmit: (values: BedCreationValues) => void;
}

/** Values that are required to create new bed. */
export interface BedCreationValues {
  /** Image of the bed. */
  readonly source: string | null;

  /** Bed name. */
  readonly name: string;

  /** Bed type. */
  readonly type: string;

  /** Number of beds. */
  readonly count: number;
}

type BedCreationFormValues = NullableProperties<BedCreationValues, 'type'>;

/** Initial form values. */
const initialFormValues: BedCreationFormValues = {
  source: null,
  type: null,
  count: 0,
  name: '',
};

/** From validation schema. */
const formValidationSchema = object().shape<BedCreationFormValues>({
  name: string().required().matches(/^(?!\s+$)/),
  count: number().min(1).required(),
  type: mixed<string | null>().required(),
  source: mixed<string | null>(),
});

/** Bed creation form. */
export const BedCreationForm: FC<Props> = ({ onSubmit }) => {
  /** Formik instance. */
  const formik = useFormik<BedCreationFormValues>({
    initialValues: initialFormValues,
    validationSchema: formValidationSchema,
    onSubmit: (values, helpers) => {
      const result = assertNonNullablePropertiesWithReturn(values, 'type');
      const formattedResult: BedCreationValues = {
        ...result,
        name: formatBedName(result.name),
      }
      onSubmit(formattedResult);
      helpers.resetForm();
      helpers.validateForm();
    },
  });

  const [isLoadingImage, setIsLoadingImage] = useState(false);

  /** Handle counter increment event. */
  const onCounterIncrementClick = useCallback(() => {
    const currentCounterValue = formik.values.count;
    const numberedValue = Number(currentCounterValue);

    if (Number.isNaN(numberedValue)) {
      return;
    }

    formik.setFieldValue('count', numberedValue + 1);
  }, [formik.values.count]);

  /** Handle counter decrement event. */
  const onCounterDecrement = useCallback(() => {
    const currentCounterValue = formik.values.count;
    const numberedValue = Number(currentCounterValue);

    if (Number.isNaN(numberedValue)) {
      return;
    }

    if (numberedValue <= 0) {
      return;
    }
    formik.setFieldValue('count', numberedValue - 1);
  }, [formik.values.count]);

  /** Get name of the submit button. */
  const getButtonName = useCallback(() => {
    return formik.values.count <= 1 ? 'Add Bed' : 'Add Multiple Beds';
  }, [formik.values.count]);

  useEffect(() => {
    // Need to trigger validation right from the start in order
    // to improve UX.
    formik.validateForm();
  }, []);

  const handleImageLoading = useCallback((isLoading: boolean) => {
    setIsLoadingImage(isLoading);
  }, [setIsLoadingImage]);

  return (
    <BedCreationFormContent>
      <BedCreationImageUploader
        initImageUrl={formik.values.source ?? void 0}
        prefix={ImagePrefix.Bed}
        onChange={(value) => formik.setFieldValue('source', value)}
        size={'small'}
        isDeletionAvailable={true}
        onLoading={handleImageLoading}
      />
      <BedCreationNameInput
        name="name"
        placeholder="Bed Name"
        value={formik.values.name}
        onChange={formik.handleChange}
      />
      <BedCreationTypeAutocomplete
        id="type"
        options={BED_TYPES_LIST}
        onChange={(_, value) => formik.setFieldValue('type', value)}
        value={formik.values.type}
        clearOnBlur
        selectOnFocus
        handleHomeEndKeys
        renderInput={(params) => (
          <Field
            {...params}
            name="type"
            component={TextField}
            placeholder="Select Type"
          />
        )}
      />
      <BedCreationCounterContainer>
        <BedCreationCounterButton
          type="button"
          onClick={onCounterDecrement}
          variant="outlined"
          orientation='left'
          disabled={formik.values.count < 1}
        >
          -
        </BedCreationCounterButton>
        <BedCreationCounterField
          id="count"
          name="count"
          value={formik.values.count}
          onChange={formik.handleChange}
        />
        <BedCreationCounterButton
          type="button"
          onClick={onCounterIncrementClick}
          variant="outlined"
          orientation='right'
        >
          +
        </BedCreationCounterButton>
      </BedCreationCounterContainer>

      <BedCreationConfirmButton
        disabled={!formik.isValid || isLoadingImage}
        type="button"
        variant="contained"
        onClick={() => formik.handleSubmit()}
      >
        {getButtonName()}
      </BedCreationConfirmButton>
    </BedCreationFormContent>
  );
};
