import React, { Dispatch, FC, useCallback, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { RowView } from 'components/Layout/Grid';
import { PhotoPreview } from 'components/UploadPhoto/Single/PhotoPreview';
import {
  SinglePreviewContainer,
  AddPhotoButton,
  ImageValidationErrorContainer,
  ImageUploaderLabelAndIconContainer,
  ImageUploaderLabel,
  ImageUploaderIcon,
} from 'components/UploadPhoto/styled';
import { FirebaseService } from 'services/firebase';
import { Loader } from 'components/Loader/Loader';
import { Size } from 'utils/types/size';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { ImageUploaderButton } from './styled';

type ImageType = 'jpeg' | 'jpg' | 'svg' | 'png';

interface Props {

  /** Prefix. */
  readonly prefix: string;

  /** Handle change event. */
  readonly onChange: (val?: string | null) => void;

  /** Whether photo uploading is disabled or not. */
  readonly isDisabled?: boolean;

  /** Acceptable image formats. */
  readonly acceptableTypeFormats?: ImageType[];

  /** Maximum image size in bytes. */
  readonly bytesMaxImageSize?: number;

  /** On loading callback. */
  readonly onLoading?: (isLoading: boolean) => void;

  /** Initial image URL. */
  readonly initImageUrl?: string;

  /** Handle click event. */
  readonly onClick?: () => void;

  /** Size of component. */
  readonly size: Size;

  /** Whether image deletion available or not. */
  readonly isDeletionAvailable: boolean;

  /** Label to display below icon for empty uploader. */
  readonly label?: string;

  /** Whether the loading is in progress. */
  readonly isLoading?: boolean;

  /** Whether the uploader should be a button. */
  readonly isButton?: boolean;
}

export const SinglePhotoUpload: FC<Props> = ({
  initImageUrl,
  prefix,
  onChange,
  size,
  onClick,
  isDeletionAvailable,
  isDisabled,
  acceptableTypeFormats,
  bytesMaxImageSize,
  onLoading,
  label,
  isLoading: parentIsLoading,
  isButton,
}) => {
  const [images, setImages] = useState<ImageListType>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleOnChange = useCallback(async(imageList: ImageListType) => {

    if (imageList[0].file == null) {
      return;
    };

    onLoading && onLoading(true);
    setIsLoading(true);
    const path = imageList[0] && (await FirebaseService.uploadImage(imageList[0].file));
    path && onChange(path);
    setImages(imageList as never[]);
    setIsLoading(false);
    onLoading && onLoading(false);
  }, [onLoading, setIsLoading, onChange, setImages]);

  const handleDeletePhoto = () => {
    initImageUrl = undefined;
    onChange(null);
  };

  if (isButton) {
    return (
    <ImageUploading
      value={images}
      onChange={handleOnChange}
      dataURLKey="data_url"
      acceptType={acceptableTypeFormats}
      maxFileSize={bytesMaxImageSize}
    >
      {({ onImageUpload }) => (
        <ImageUploaderButton
          type="button"
          variant="outlined"
          onClick={onImageUpload}
          disabled={isLoading || isDisabled}
        >
          {label}
        </ImageUploaderButton>
      )}
    </ImageUploading>
    )
  }

  return (
    <ImageUploading
      value={images}
      onChange={handleOnChange}
      dataURLKey="data_url"
      acceptType={acceptableTypeFormats}
      maxFileSize={bytesMaxImageSize}
    >
      {({ onImageUpload, isDragging, dragProps, errors }) => (
        <>
          <RowView onClick={onClick}>
            {initImageUrl ? (
              <PhotoPreview
                isDeletionAvailable={isDeletionAvailable}
                src={FirebaseService.getImageSrc(initImageUrl)}
                onRemove={handleDeletePhoto}
                size={size}
                isDisabled={isDisabled}
              />
            ) : (
              <SinglePreviewContainer>
                <AddPhotoButton
                  type="button"
                  disabled={isDisabled || isLoading || parentIsLoading}
                  isDragging={isDragging}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  {isLoading || parentIsLoading ?
                    <Loader size={size} /> :
                    (
                      <ImageUploaderLabelAndIconContainer>
                        <ImageUploaderIcon src="/assets/icons/image-uploader-placeholder-icon.svg" alt="" />
                        {label && <ImageUploaderLabel>{label}</ImageUploaderLabel>}
                      </ImageUploaderLabelAndIconContainer>
                    )
                  }
                </AddPhotoButton>
              </SinglePreviewContainer>
            )}
          </RowView>
          {errors?.maxFileSize && bytesMaxImageSize != null && (
            <ImageValidationErrorContainer>
              <ValidationError message={`Maximum file size is ${bytesMaxImageSize / 1000000} Mb.`} />
            </ImageValidationErrorContainer>
          )}
        </>
      )}
    </ImageUploading>
  );
};
