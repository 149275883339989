import { useMutate } from 'hooks/api';
import { OrganizationMapper } from 'mappers/OrganizationMapper';
import { OrganizationCreateValues } from 'models/organization/OrganizationCreateValues';

export const useCreateOrganization = (locationOrigin: string) => {
  const { mutate } = useMutate<undefined, undefined, OrganizationCreateValues>('post', {
    url: '/api/Organizations/nonCommercial',
    requestTransformer: data => JSON.stringify(OrganizationMapper.mapCreateValuesToDto(data, locationOrigin)),
  });

  return { handleCreate: mutate };
};
