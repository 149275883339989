import { Bed } from './Bed';
import { BedDto } from './BedDto';
import { BedType } from './BedType';

export namespace BedMapper {

  /** @inheritdoc */
  export function fromDto(dto: BedDto): Bed {
    return new Bed({
      id: String(dto.id),
      index: dto.index,
      description: dto.description,
      createdAt: new Date(fromDateDto(dto.createdAt)),
      guest: dto.guest,
      type: dto.type === '' ? BedType.SoberLiving : dto.type,
      name: dto.name,
      imagePath: dto.imagePath != null ? dto.imagePath : null,
    });
  }

  /** @inheritdoc */
  export function toDto(data: Bed): BedDto {
    return {
      id: data.id !== null ? Number(data.id) : null,
      index: data.index,
      description: data.description,
      createdAt: toDateDto(data.createdAt.toISOString()),
      guest: data.guest,
      type: data.type,
      imagePath: data.imagePath,
      name: data.name,
    }
  }

  function toDateDto(date: string): string {
    return date.replace('T', ' ');
  }

  function fromDateDto(date: string): string {
    return date.replace(' ', 'T');
  }
}
