export const config = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  state: 'some-random-string',
  nonce: 'another-random-string',
  stripePublicKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY ?? '',

  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,

    authDomain: "rehabsite-a663b.firebaseapp.com",
    messagingSenderId: "720629935839",
    appId: "1:720629935839:web:54522c7fca8b7563ebc36a",
  },
}
