import CSS from 'csstype';
import React, { FC } from 'react';
import { SpaceProps } from 'styled-system';
import { useGuests } from 'api/guests';
import { EGuestStatus, IGuestType } from 'api/guests/types';
import { Header } from 'components/TableComponent/Header';
import { generateGuestHistoryRow } from 'components/TableComponent/RowRenderers/guestHistoryRow';
import { StyledText, TertiaryHeading } from 'components/Typography';
import { SectionIndicator } from 'pages/History/GuestsTable/SectionIndicator';
import { Wrapper } from 'pages/History/GuestsTable/styled';
import { Site } from 'models/site/Site';
import { sortEntities } from 'utils/sortEntities';

export interface IGuestsTableProps {

  /** Site id. */
  readonly siteId: Site['id'];

  /** On guest select callback. */
  readonly onSelect: (guest: IGuestType) => void;
}

export const GuestsTable: FC<IGuestsTableProps & SpaceProps> = ({
  siteId,
  onSelect,
  ...props
}) => {
  const { data: acceptedGuests, error: acceptedGuestsError } = useGuests(
    siteId,
    EGuestStatus.ACCEPTED
  );
  const { data: dischargedGuests, error: dischargedGuestsError } = useGuests(
    siteId,
    EGuestStatus.DISCHARGED
  );
  const { data: rejectedGuests, error: rejectedGuestsError } = useGuests(
    siteId,
    EGuestStatus.REJECTED
  );

  if (acceptedGuestsError || dischargedGuestsError || rejectedGuestsError) {
    return <div>Failed to load</div>;
  }

  if (
    acceptedGuests == null ||
    dischargedGuests == null ||
    rejectedGuests == null
  ) {
    return (
      <StyledText mt="31px" bigText>
        Loading...
      </StyledText>
    );
  }

  const columns = [
    {
      name: '', // checkbox column
      width: '52px',
      sortable: false,
    },
    {
      name: 'Time in',
      width: '140px',
      sortable: true,
    },
    {
      name: 'Time out',
      width: '140px',
      sortable: true,
    },
    {
      name: 'Services needed',
      width: '184px',
      sortable: true,
    },
    {
      name: 'Name',
      width: '1fr',
      sortable: true,
    },
    {
      name: 'Feedback',
      width: '1fr',
      sortable: false,
    },
    {
      name: 'Gender',
      width: '100px',
      sortable: false,
    },
    {
      name: '', // edit buttons
      width: '55px',
      sortable: false,
    },
  ];

  const columnsTemplate: CSS.Property.GridTemplateColumns = `${columns.reduce(
    (acc, col) => `${acc} ${col.width}`,
    ''
  )}`;

  const numberAccepted =
    acceptedGuests.totalCount + dischargedGuests.totalCount;

  return (
    <Wrapper {...props}>
      <TertiaryHeading>
        Accepted: {acceptedGuests.totalCount}
      </TertiaryHeading>

      <Header mt="20px" columns={columns} />

      <SectionIndicator type="active" number={acceptedGuests.totalCount} />
      {acceptedGuests.items.map((guest) =>
        generateGuestHistoryRow(
          guest,
          EGuestStatus.ACCEPTED,
          columnsTemplate,
          onSelect,
        )
      )}

      <SectionIndicator
        type="discharged"
        number={dischargedGuests.totalCount}
      />
      {sortEntities(dischargedGuests.items, 'dischargedAt', 'desc').map((guest) =>
        generateGuestHistoryRow(
          guest,
          EGuestStatus.DISCHARGED,
          columnsTemplate,
        )
      )}

      <SectionIndicator
        type="declined"
        number={rejectedGuests.totalCount}
      />
      {rejectedGuests.items.map((guest) =>
        generateGuestHistoryRow(
          guest,
          EGuestStatus.REJECTED,
          columnsTemplate,
          onSelect,
        )
      )}
    </Wrapper>
  );
};
