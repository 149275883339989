import { Site } from 'models/site/Site'
import { UserRole } from 'models/user/User'
import { SiteUserInvitationValues } from 'models/user/UserInvitationValues'
import { ERoute } from 'router/data'

export enum ROLES {
  STAFF = 'Staff',
  GLOBAL = 'GlobalAdmin',
  ADMIN = 'Admin',
  Intake = 'Intake',
}

// TODO(Maxim K.): Replace siteId with organization id after adding organizations.
export const initialValues = (siteId: Site['id']): SiteUserInvitationValues => ({
  siteId: siteId,
  email: '',
  role: UserRole.Staff,
})

export interface IInviteFormData {
  role: any
  organizationId?: string
  email: string
}
