import styled from '@emotion/styled'
import { theme } from 'styles/theme'

export const AssignedGuest = styled.div<{ vacant?: boolean }>`
  padding: 5px 16px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.lightGray};
  background-color: ${(props) =>
    props.vacant ? theme.colors.lightGreen : 'transparent'};
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.vacant ? 'center' : 'flex-start')};
  height: 106px;
  width: 180px;
  margin-bottom: 10px;

  justify-content: ${(props) => (props.vacant ? 'center' : 'space-between')};
  cursor: ${(props) => (props.vacant ? 'default' : 'pointer')};

  &:hover {
    background: ${(props) =>
      props.vacant ? theme.colors.lightGreen : 'rgba(18, 25, 20, 0.02)'};
  }
`
