import styled from '@emotion/styled'
import React, { FC } from 'react'
import { EGender } from 'services/types'

export interface Props {
  gender: EGender
}

const StyledGenderIcon = styled.img<Props>`
  height: 22px;
  width: 22px;
`

const GENDER_ICONS: { [TKey in EGender]: string } = {
  Male: '/assets/icons/male.svg',
  Female: '/assets/icons/female.svg',
  Other: '/assets/icons/other.svg',
}

export const GenderIcon: FC<Props> = ({ gender }) => (
  <StyledGenderIcon src={GENDER_ICONS[gender]} alt={gender} gender={gender} />
)
