import styled from '@emotion/styled';
import React, { FC } from 'react';
import { space, SpaceProps } from 'styled-system';
import { theme } from 'styles/theme';

const Dot = styled.div<SpaceProps>`
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: ${theme.colors.grayText};
  ${space}
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export interface IEditButtonProps {
  elementId: string | number;
}

export const EditButton: FC<IEditButtonProps> = ({ elementId, ...props }) => (
  <Container {...props}>
    <Dot />
    <Dot ml="4px" />
    <Dot ml="4px" />
  </Container>
);
