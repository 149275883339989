import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import React, { FC, memo } from 'react';
import { Organization } from '../../models/organization/Organization';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';
import { SavedPaymentMethodsTableRow } from './SavedPaymentMethodsTableRow';
import { PaymentMethodsTableContainer, PaymentMethodsTableHeaderCell } from './styled';

interface Props {

  /** Organization id. */
  readonly organizationId: Organization['id'];

  /** Saved payment methods. */
  readonly paymentMethods: readonly SavedPaymentMethod[];
}

const SavedPaymentMethodsTableComponent: FC<Props> = ({ organizationId, paymentMethods }) => {

  const sortedPaymentMethods = [...paymentMethods].sort(method => method.isDefault ? -1 : 0);

  return (
    <PaymentMethodsTableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <PaymentMethodsTableHeaderCell>Card #</PaymentMethodsTableHeaderCell>
            <PaymentMethodsTableHeaderCell textAlign='center'>Expiration Date</PaymentMethodsTableHeaderCell>
            <PaymentMethodsTableHeaderCell textAlign='center'>Subscription</PaymentMethodsTableHeaderCell>
            <PaymentMethodsTableHeaderCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedPaymentMethods.map(method => (
            <SavedPaymentMethodsTableRow
              key={method.id}
              method={method}
              organizationId={organizationId}
            />
          ))}
        </TableBody>
      </Table>
    </PaymentMethodsTableContainer>
  )
}

export const SavedPaymentMethodsTable = memo(SavedPaymentMethodsTableComponent);
