import { UserRole } from '../user/User';

type PasswordChangeLogConstructorData = PasswordChangeLog;

/** Password change log. */
export class PasswordChangeLog {

  /** ID. */
  public readonly id: number;

  /** Email. */
  public readonly email: string;

  /** Role. */
  public readonly role: UserRole;

  /** Date of password change. */
  public readonly passwordChangedAt: Date;

  public constructor(data: PasswordChangeLogConstructorData) {
    this.id = data.id;
    this.email = data.email;
    this.role = data.role;
    this.passwordChangedAt = data.passwordChangedAt;
  }
}
