import { StrictOmit } from 'utils/types/strictOmit';
import { PaymentResult } from 'models/Subscriptions/PaymentResult';
import { PaymentResultDto } from 'dtos/Subscriptions/PaymentResultDto';
import { Pagination } from 'services/types';
import { Organization } from 'models/organization/Organization';
import { useFetch } from '../../hooks/api';
import { PaymentResultMapper } from 'mappers/PaymentResultMapper';
import { getOrderByParamValue } from '../../utils/getOrderByParamValue';
import { SortDirection } from 'utils/types/SortingDirection';

interface PaginatedPaymentResultDtos {

  /** Payments. */
  readonly payments: readonly PaymentResultDto[];

  /** Count of pages. */
  readonly pageCount: number;

  /** Total count of items. */
  readonly totalCount: number
}

type PaginatedPaymentResults = Readonly<StrictOmit<Pagination<PaymentResult>, 'hasNextPage' | 'hasPreviousPage' | 'pageIndex'>>;

const MAP_PAYMENT_RESULT_FIELD_NAME_TO_DTO: Readonly<Record<keyof PaymentResult, keyof PaymentResultDto>> = {
  id: 'id',
  createdAt: 'createdAt',
  amountInDollars: 'amountInDollars',
  purpose: 'purpose',
  state: 'isSuccessfull',
  bedCountForPayment: 'bedCountForPayment',
}

interface Params {

  /** Organization ID. */
  readonly organizationId: Organization['id'];

  /** Size of a single page. */
  readonly pageSize: number;

  /** Number of the page. */
  readonly pageNumber: number;

  /** Field to order by. */
  readonly orderField?: keyof PaymentResult;

  /** Sorting direction. */
  readonly direction?: SortDirection;
}

/** Gets results of previous payments. */
export const usePaymentResults = ({
  organizationId,
  pageNumber,
  pageSize,
  orderField = 'createdAt',
  direction = 'desc',
}: Params) =>
  useFetch<PaginatedPaymentResults, PaginatedPaymentResultDtos>({
    url: `/api/Subscriptions/payment?${new URLSearchParams({
      OrganizationId: organizationId,
      PageSize: pageSize.toString(),
      PageNumber: pageNumber.toString(),
      OrderBy: getOrderByParamValue(MAP_PAYMENT_RESULT_FIELD_NAME_TO_DTO[orderField], direction)
    })}
`,
    mapper: response => ({
      items: response.payments.map(dto => PaymentResultMapper.fromDto(dto)),
      totalCount: response.totalCount,
      totalPages: response.pageCount,
    }),
  });
