import styled from '@emotion/styled';

export const ModalFormWrapper = styled.form`
  width: 100%;
  height: 100%;
  padding: 20px;
`

export const ModalFormHeading = styled.h2`
  font-size: var(--font-size-lg);
  font-weight: 600;
  margin-bottom: 24px;
`;
