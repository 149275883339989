import styled from '@emotion/styled';
import { ModalWindow } from './ModalWindow/ModalWindow';

export const DialogTitleText = styled.h2`
  text-align: left;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
`;

export const DialogActionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 15px;
`;

export const ColumnModal = styled(ModalWindow)`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
