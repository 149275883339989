import React, { } from 'react'
import { useSites } from 'api/sites/useSites'
import { Layout } from 'components/Layout'
import { SitesWrap } from 'pages/SiteList/List/styled';
import { LinkWithoutStyle } from 'components/Typography';
import { routes } from 'router/data';
import { useAuth } from 'hooks/auth/useAuth';
import { Header } from 'components/TopHeader/styled';
import { FormHeading } from 'forms/SignIn/styled';
import { NoData } from 'components/NoData';
import { SiteBlock } from 'components/SiteBlock/SiteBlock';

export const HistoryList = () => {
  const { state: { userData } } = useAuth();
  const { data: sitesData, error } = useSites()

  if (error != null) {
    return <div>failed to load</div>
  }
  if (sitesData == null) {
    return <NoData label='No records available in reservations.' />
  }

  // Only display to admins and staff the allowed sites
  const availableSites = sitesData.items
    .filter(site => userData.roleAdminSiteIds.includes(site.id.toString()) ||
      userData.roleIntakeSiteIds.includes(site.id.toString()) ||
      userData.roleStaffSiteIds.includes(site.id.toString()) ||
      userData.roleGlobal && false
    );

  return (
    <Layout withSideBar>
      <Header>
        <FormHeading>History</FormHeading>
      </Header>
      {availableSites.length > 0 ?

        <SitesWrap>
          {availableSites.map((site) => (
            <LinkWithoutStyle key={site.id} to={routes.HistoryDetails(site.id)}>
              <SiteBlock key={site.id} site={site} />
            </LinkWithoutStyle>
          ))}
        </SitesWrap>
        :
        <NoData label='No records available in history.' />
      }
    </Layout>
  )
}
