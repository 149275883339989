import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { theme } from 'styles/theme'

export const MenuItem = styled.div<{ active?: boolean }>`
  display: block;
  width: fit-content;
  width: 180px;
  padding: 10px 16px;
  background: ${(props) =>
    props.active ? theme.colors.dark : theme.colors.lightGray};
  border-radius: 8px;
  margin-bottom: 8px;
  color: ${(props) => (props.active ? theme.colors.white : theme.colors.dark)};
  text-decoration: none;
  font-size: 14px;
  font-weight: ${(props) => (props.active ? 'bold' : 400)};

  &:hover {
    font-weight: bold;
  }
`

export const MenuItemLink = MenuItem.withComponent(Link)
