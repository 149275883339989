import { SortDirection } from './types/SortingDirection';

/**
 * Gets value for ordering param.
 * @param field Field to order by.
 * @param direction Mode to order.
 */
export function getOrderByParamValue(field: string, direction: SortDirection) {
  return `${field}:${direction}`;
}
