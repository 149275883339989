import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import { IGuestType } from 'api/guests/types';
import { DeclineDialog } from 'components/DeclineReservationDialog/DeclineReservationDialog';
import { useAuth } from 'hooks/auth/useAuth';
import { Site } from 'models/site/Site';
import React, { useState } from 'react';
import { FC } from 'react';
import { ReservationsTableRow } from './ReservationsTableRow';
import { TableHeader } from './styled';

interface ReservationsTableProps {
  /** Guests. */
  readonly guests: readonly IGuestType[];

  /** Site id. */
  readonly siteId: Site['id'];

  /** Whether the site is commercial. */
  readonly isCommercial: Site['isCommercial'];
}

const ReservationsTableComponent: FC<ReservationsTableProps> = ({
  guests,
  siteId,
  isCommercial,
}) => {
  const [selectedGuest, setSelectedGuest] = useState<IGuestType | null>(null);
  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);

  const { roleStaffSiteIds } = useAuth().state.userData;

  /** Whether user has staff role. */
  const isStaff = roleStaffSiteIds.includes(siteId);

  /** Opens dialog window to decline a reservation. */
  const openDeclineDialog = (guest: IGuestType) => {
    setSelectedGuest(guest);
    setIsDeclineDialogOpen(true);
  };

  /** Closes dialog window to decline a reservation. */
  const closeDeclineDialog = () => {
    setIsDeclineDialogOpen(false);
    setSelectedGuest(null);
  };

  return (
    <TableContainer sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeader>Name</TableHeader>
            {isStaff || <TableHeader>Phone</TableHeader>}
            <TableHeader sx={{ minWidth: '7rem' }}>
              Reservation date
            </TableHeader>
            <TableHeader>Services</TableHeader>
            {isCommercial && <TableHeader>Payment</TableHeader>}
          </TableRow>
        </TableHead>
        <TableBody>
          {guests.map((guest) => (
            <ReservationsTableRow
              guest={guest}
              siteId={siteId}
              key={guest.id}
              onDeclineButtonClick={openDeclineDialog}
            />
          ))}
        </TableBody>
      </Table>
      {selectedGuest && (
        <DeclineDialog
          isOpen={isDeclineDialogOpen}
          onClose={closeDeclineDialog}
          guest={selectedGuest}
        />
      )}
    </TableContainer>
  );
};

export const ReservationsTable = ReservationsTableComponent;
