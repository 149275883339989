import { useMutate } from 'hooks/api';
import { Organization } from 'models/organization/Organization';
import { OrganizationUpdateValues } from 'models/organization/OrganizationUpdateValues';

export const useUpdateOrganization = (id: Organization['id']) => {
  const { mutate } = useMutate<undefined, undefined, OrganizationUpdateValues>('put', {
    url: `/api/Organizations/${id}`,
  });

  return { handleUpdate: mutate };
};
