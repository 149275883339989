import { theme } from 'styles/theme';
import styled from '@emotion/styled';

export const BedsDialogContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const BedsDialogTitle = styled.h2`
  text-align: left;
  font-size: var(--font-size-2xlg);
  font-weight: 600;
`;

export const BedsDialogText = styled.p`
  font-size: var(--font-size-s);
  font-weight: 500;
`;

export const BedsDialogWarning = styled.p`
  font-size: var(--font-size-xs);
  font-weight: 600;
  background-color: ${theme.colors.lightRed};
  padding: 8px 16px;
`;

export const BedsDialogLine = styled.hr`
  border: none;
  border-top: 1px solid ${theme.colors.veryLightGray};
`;

export const ItemsTable = styled.table`
  width: 100%;
`;

interface CellProps {

  /** Color of the text. */
  readonly textColor?: 'primary' | 'secondary';

  readonly textAlignment?: 'left' | 'right';
}

const TableCell = styled.th<CellProps>`
  color: ${props => props.textColor === 'primary' ? theme.colors.black : theme.colors.darkGray};
  text-align: ${props => props.textAlignment === 'left' ? 'left' : 'right'};
`

export const ItemsTableHeadingCell = styled(TableCell)`
  font-size: var(--font-size-xs);
  font-weight: 600;
`

export const ItemsTableItemCell = styled(TableCell)`
  font-size: var(--font-size-s);
  font-weight: 500;
`

export const TotalPriceTableCell = styled(TableCell)`
  font-size: var(--font-size-xlg);
  font-weight: 500;
`

export const BedsDialogButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
`
