import { InsuranceCompany } from 'models/insuranceCompany/InsuranceCompany';
import React, { FC, memo } from 'react';
import { InsuranceCompanyCheckboxContainer, InsuranceCompanyLabel } from './styled';

interface Props {

  /** Insurance company. */
  readonly company: InsuranceCompany

  /** Whether the insurance company is selected. */
  readonly isSelected: boolean;

  /** Handle select callback. */
  readonly handleChange: (company: InsuranceCompany) => void;
};

const InsuranceCompanyCheckboxComponent: FC<Props> = ({ company, isSelected, handleChange }) => {

  const handleClick = () => handleChange(company);

  return (
    <InsuranceCompanyCheckboxContainer isSelected={isSelected} onClick={handleClick} >
      <input className='visually-hidden' type="checkbox" value={company.name} id={`insurance-${company.id}`} checked={isSelected} />
      <InsuranceCompanyLabel htmlFor={`$insurance-${company.id}`}>{company.name}</InsuranceCompanyLabel>
    </InsuranceCompanyCheckboxContainer>
  )
};

export const InsuranceCompanyCheckbox = memo(InsuranceCompanyCheckboxComponent);
