import { Room } from 'pages/SiteCreate/components/Rooms/domain/Room';
import { IAboutForm, IContactsForm, IntakePeriodsFormInfo, IServicesForm } from 'services/types';

/** Values required for site editing. */
export type SiteEditingValues = IAboutForm &
  IContactsForm &
  IntakePeriodsFormInfo &
  IServicesForm & {
    readonly id: string;
    readonly rooms: readonly Room[];
  };

/**
 * Gets count of beds from site editing values.
 * @param values Site editing values.
 */
export function getCountOfBedsFromSiteEditValues(values: SiteEditingValues): number {
  return values.rooms.reduce((sum, room) => sum + room.bedsCount, 0);
}
