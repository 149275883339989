import React, { FC } from 'react';
import { GuestMethods, IGuestType } from 'api/guests/types';
import { DaysStayedText } from 'components/DaysStayedText';
import { GenderIcon } from 'components/GenderIcon';
import { AssignedGuest } from 'components/GuestCell/styled';
import { RowView } from 'components/Layout/Grid';
import { MainHeading } from 'components/Typography';

export interface Props {
  guest?: IGuestType;
  onClick?: any;
  maxDays?: number;
}

export const GuestCell: FC<Props> = ({ guest, onClick, maxDays }) => (
  <AssignedGuest vacant={!guest} onClick={guest ? onClick : undefined}>
    {guest ? (
      <>
        <RowView justifyContent="space-between">
          <DaysStayedText guest={guest} maxDays={maxDays} />
          <GenderIcon gender={guest.gender} />
        </RowView>
        <MainHeading small withoutMargin>
          {GuestMethods.getFullName(guest)}
        </MainHeading>
      </>
    ) : (
      <MainHeading small color="green" withoutMargin>
        Vacant
      </MainHeading>
    )}
  </AssignedGuest>
);
