import TableRow from '@mui/material/TableRow';
import { GuestMethods, IGuestType } from 'api/guests/types';
import React, { FC, useCallback, useState } from 'react';
import {
  BedImage,
  CellFlexContainer,
  GuestsTableCell,
  GuestsTableContent,
  GuestsTableContentSmall,
  MenuButtonContainer,
  MenuLink,
  ModalImage,
  ModalImageContainer,
} from './styled';
import Modal from '@mui/material/Modal';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { getDaysDifference } from 'utils/getDaysDifference';
import { FirebaseService } from 'services/firebase';
import { Site } from 'models/site/Site';
import { useAuth } from 'hooks/auth/useAuth';
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';
import { Room } from 'pages/SiteCreate/components/Rooms/domain/Room';
import { routes } from 'router/data';

interface GuestTableRowProps {
  /** Guest on the row. */
  readonly guest: IGuestType;

  /** Room of the guest. */
  readonly room: Room;

  /** Bed of the guest. */
  readonly bed: Bed;

  /** Handler for discharge button. */
  readonly onDischargeButtonClick: (guest: IGuestType) => void;

  /** Site id. */
  readonly siteId: Site['id'];
}

const GuestsTableRowComponent: FC<GuestTableRowProps> = ({ guest, room, onDischargeButtonClick, bed, siteId }) => {
  /** Shows if the image popup is open */
  const [isImageOpen, setIsImageOpen] = useState(false);

  const handleImageOpen = useCallback(() => setIsImageOpen(true), [setIsImageOpen]);
  const handleImageClose = useCallback(() => setIsImageOpen(false), [setIsImageOpen]);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = useCallback(() => setMenuAnchor(null), [setMenuAnchor]);
  const isMenuOpen = menuAnchor !== null;

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, [setIsHovering]);

  const handleMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, [setIsHovering]);

  const { roleStaffSiteIds } = useAuth().state.userData;
  const isUserRoleStaff = roleStaffSiteIds.includes(siteId);

  if (guest.acceptedAt == null) {
    return <></>;
  }
  const acceptedAtDate = new Date(guest.acceptedAt);
  const currentDate = new Date();

  const days = getDaysDifference(currentDate, acceptedAtDate);

  const handleBedImageClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      handleImageOpen();
    },
    [handleImageOpen]
  );

  const handleMenuOpenButtonClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      handleMenuOpen(event);
    },
    [handleMenuOpen]
  );

  return (
    <>
      <TableRow
        component={Link}
        to={routes.GuestDetails(guest.siteId, guest.id)}
        sx={{
          backgroundColor: isHovering || isMenuOpen ? '#F0F0F0' : '#FFFFFF',
          textDecoration: 'none',
          height: 'auto',
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <GuestsTableCell>
          <GuestsTableContent>{GuestMethods.getFullName(guest)}</GuestsTableContent>
          <GuestsTableContentSmall>{guest.gender}</GuestsTableContentSmall>
        </GuestsTableCell>
        <GuestsTableCell>{days === 1 ? `${days} Day` : `${days} Days`}</GuestsTableCell>
        <GuestsTableCell>
          <CellFlexContainer>
            <BedImage src={FirebaseService.getImageSrc(bed.imagePath ?? undefined)} variant="square" onClick={handleBedImageClick} />
            <GuestsTableContent>{`${room.name} / ${bed.name}`}</GuestsTableContent>
            {isUserRoleStaff || (
              <MenuButtonContainer>
                {(isHovering || isMenuOpen) && (
                  <IconButton onClick={handleMenuOpenButtonClick} type="button">
                    <MoreVert />
                  </IconButton>
                )}
              </MenuButtonContainer>
            )}
          </CellFlexContainer>
        </GuestsTableCell>
      </TableRow>
      <Modal open={isImageOpen} onClose={handleImageClose}>
        <ModalImageContainer>
          <ModalImage src={FirebaseService.getImageSrc(bed.imagePath ?? undefined)} />
        </ModalImageContainer>
      </Modal>
      <Menu open={isMenuOpen} onClose={handleMenuClose} anchorEl={menuAnchor}>
        <MenuLink
          to={{
            pathname: `/reservations-request/${guest.id}`,
            state: { guest: guest },
          }}
        >
          <MenuItem onClick={handleMenuClose}>Reassign</MenuItem>
        </MenuLink>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            onDischargeButtonClick(guest);
          }}
          sx={{ color: '#FF0000' }}
        >
          Discharge
        </MenuItem>
      </Menu>
    </>
  );
};

export const GuestsTableRow = GuestsTableRowComponent;
