import { IGuestType } from 'api/guests/types';
import { GuestInfoModal } from 'components/GuestInfoModal';
import { Site } from 'models/site/Site';
import { GuestsTable } from 'pages/History/GuestsTable';
import { SiteTabContentContainer } from 'pages/OrganizationSiteDetails/styled';
import React, { FC, memo, useCallback, useState } from 'react';

interface Props {
  /** Site id. */
  readonly siteId: Site['id'];
}

const SiteHistoryComponent: FC<Props> = ({ siteId }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedGuest, setGuest] = useState<IGuestType | null>(null);

  const handleModalOpen = useCallback(() => setModalOpen(true), []);

  const handleModalClose = useCallback(() => setModalOpen(false), []);

  const onGuestSelect = useCallback((guest: IGuestType) => {
    setGuest(guest);
    handleModalOpen();
  }, []);

  return (
    <>
      <SiteTabContentContainer>
        <GuestsTable siteId={siteId} onSelect={onGuestSelect} />
      </SiteTabContentContainer>
      {selectedGuest && <GuestInfoModal onClose={handleModalClose} isOpen={isModalOpen} guest={selectedGuest} />}
    </>
  );
};

export const SiteHistory = memo(SiteHistoryComponent);
