import { Button } from '@mui/material';
import { useGuest } from 'api/guests';
import { CancelLink } from 'components/CancelLink';
import { GuestInfoHeader } from 'components/GuestInfo/GuestInfo';
import { Layout } from 'components/Layout';
import { MainHeading, SecondaryHeading, TertiaryHeading } from 'components/Typography';
import { LoadingPage } from 'pages/LoadingPage';
import React, { useState } from 'react';
import { FC } from 'react';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { routes } from 'router/data';
import {
  BedAvatar,
  BedInfo,
  DischargeDialogText,
  DischargeDialogTextSmall,
  OccupiedText,
  RoomAvatar,
  RoomInfo,
  RoomInfoContainer,
} from './styled';
import { DischargeDialog } from 'components/DischargeDialog/DischargeDialog';
import { FirebaseService } from 'services/firebase';
import { GuestMethods } from 'api/guests/types';
import { useGuestBed } from 'api/beds/useGuestBed';
import { useAuth } from 'hooks/auth/useAuth';
import { useSiteDetails } from 'api/sites/useSiteDetails';
import { useSiteBackButtonRoute } from 'hooks/routing/useSiteBackButtonRoute';

interface RouteProps {
  /** ID of the guest. */
  readonly guestId: string;

  /** Id of the guest's site. */
  readonly siteId: string;
}

export const GuestDetailsPage: FC<RouteComponentProps<RouteProps>> = ({ match }) => {
  const { guestId, siteId } = match.params;
  const history = useHistory();

  const [isDischargeDialogOpen, setIsDischargeDialogOpen] = useState(false);

  const { data: guest, error: guestError } = useGuest(parseInt(guestId));

  const { data: guestBed, error: guestBedError } = useGuestBed(guest?.assignedBedId ?? null);

  const shouldFetchSiteDetails = guest != null;
  const { data: site, error: siteError } = useSiteDetails(guest?.siteId ?? '', shouldFetchSiteDetails);

  const { roleStaffSiteIds } = useAuth().state.userData;

  /** Whether user has staff role. */
  const isStaff = roleStaffSiteIds.includes(siteId);

  if (guest == null || guestBed == null || site == null) {
    return <LoadingPage />;
  }

  if (guestError != null || guestBedError != null || siteError != null) {
    return <p>Error</p>;
  }

  const guestRoom = guestBed.room;

  if (guestRoom == null || guestBed == null) {
    return <LoadingPage />;
  }

  /** Opens dialog window to discharge a guest. */
  const openDischargeDialog = () => {
    setIsDischargeDialogOpen(true);
  };

  /** Closes dialog window to discharge a guest. */
  const closeDischargeDialog = () => {
    setIsDischargeDialogOpen(false);
  };

  const backButtonRoute = routes.OrganizationSiteDetails(site.organizationId.toString(), site.id);

  return (
    <Layout withSideBar>
      <CancelLink to={backButtonRoute} />
      <MainHeading>Guest Details</MainHeading>
      <GuestInfoHeader guest={guest} />
      <SecondaryHeading>Room & Bed</SecondaryHeading>
      <RoomInfoContainer>
        <TertiaryHeading>{guestRoom?.name ?? 'Custom Room Name'}</TertiaryHeading>
        <RoomInfo>
          <RoomAvatar variant="square" src={FirebaseService.getImageSrc(guestRoom.imagePath ?? undefined)} />
          <BedInfo>
            <BedAvatar variant="square" src={FirebaseService.getImageSrc(guestBed.imagePath ?? undefined)} />
            <div>
              <DischargeDialogText>{guestBed.type ? guestBed.type : ''}</DischargeDialogText>
              <DischargeDialogTextSmall>{`#${guestBed.index}`}</DischargeDialogTextSmall>
            </div>
            <OccupiedText>Occupied</OccupiedText>
            <div>
              <DischargeDialogText>{GuestMethods.getFullName(guest)}</DischargeDialogText>
              <DischargeDialogTextSmall>{guest.gender}</DischargeDialogTextSmall>
            </div>
            {isStaff || (
              <>
                <Button variant="outlined" component={Link} size="small" to={`/reservations-request/${guest.id}`}>
                  Reassign
                </Button>
                <Button color="error" variant="outlined" size="small" onClick={openDischargeDialog}>
                  Discharge
                </Button>
              </>
            )}
          </BedInfo>
        </RoomInfo>
      </RoomInfoContainer>
      <DischargeDialog
        isOpen={isDischargeDialogOpen}
        onClose={closeDischargeDialog}
        guest={guest}
        bedIndex={guestBed.index}
        roomName={guestRoom.name}
        onConfirmationButtonClick={() => history.push(backButtonRoute)}
      />
    </Layout>
  );
};
