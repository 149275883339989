import { useMutate } from 'hooks/api';
import { Site } from 'models/site/Site';

export const useDeleteSite = (id: Site['id']) => {
  const { mutate } = useMutate('delete', {
    url: `/api/Sites/${id}`,
  });

  return { handleDelete: mutate };
};
