import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { User } from 'models/user/User';
import React, { FC, memo, useState } from 'react';
import { UsersTableRow } from './TableRow';
import { UsersTableContainer, UsersTableHeader, UsersTableHeaderCell } from './styled';
import { Organization } from 'models/organization/Organization';
import { NewOrganizationOwnerForm } from './NewOwnerForm';
import { useAuth } from 'hooks/auth/useAuth';
import { Site } from 'models/site/Site';

interface Props {
  /** Users. */
  readonly users: readonly User[];

  /** Organization id. */
  readonly organizationId?: Organization['id'];

  /** Whether the table should only show owners.  */
  readonly isOwnersOnlyTable?: boolean;

  /** Users revalidation function. */
  readonly revalidateUsers: () => Promise<boolean>;

  /** Current site id. */
  readonly siteId?: Site['id'];
}

const UsersTableComponent: FC<Props> = ({ users, organizationId, isOwnersOnlyTable, revalidateUsers, siteId }) => {
  const {
    state: {
      userData: { userId: currentUserId },
    },
  } = useAuth();

  const currentUser = users.find((user) => user.userInfo.id === currentUserId);

  const tableUsers = users.filter((user) => !user.isCurrentUser(currentUserId ?? ''));

  return (
    <UsersTableContainer>
      {isOwnersOnlyTable && <UsersTableHeader>Organization Owners</UsersTableHeader>}
      <TableContainer>
        <Table>
          {isOwnersOnlyTable || (
            <TableHead>
              <TableRow>
                <UsersTableHeaderCell>Email</UsersTableHeaderCell>
                <UsersTableHeaderCell>Role</UsersTableHeaderCell>
                <UsersTableHeaderCell>Status</UsersTableHeaderCell>
                <UsersTableHeaderCell></UsersTableHeaderCell>
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {currentUser && (
              <UsersTableRow
                user={currentUser}
                isCurrentUser
                isOwnerRow={isOwnersOnlyTable}
                revalidateUsers={revalidateUsers}
                siteId={siteId}
              />
            )}
            {tableUsers.map((user) => (
              <UsersTableRow
                key={user.id}
                user={user}
                isOwnerRow={isOwnersOnlyTable}
                revalidateUsers={revalidateUsers}
                siteId={siteId}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isOwnersOnlyTable && organizationId != null && <NewOrganizationOwnerForm organizationId={organizationId} />}
    </UsersTableContainer>
  );
};

export const UsersTable = memo(UsersTableComponent);
