import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'
import { ITableColumn } from 'components/TableComponent'

export const Grid = styled.div<{ columns: ITableColumn[] }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns.map((col) => ` ${col.width}`)};
`

export const SpacedDiv = styled.div<SpaceProps>`
  ${space}
`
