import { screeningPeriodMapper } from './../../mappers/ScreeningPeriodMapper';
import { useFetch } from 'hooks/api';
import { ScreeningPeriod } from 'models/screeningPeriod/ScreeningPeriod';
import { ScreeningPeriodDto } from 'dtos/screeningPeriod/ScreeningPeriodDto';

/** Screening periods get hook. */
export const useScreeningPeriods = () =>
  useFetch<ScreeningPeriod[], ScreeningPeriodDto[]>({
    url: `/api/Sites/screeningPeriods`,
    mapper: data => data.map(dto => screeningPeriodMapper.fromDto(dto)),
  });
