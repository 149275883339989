import React, { memo, FC } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { config } from 'config';
import { AddCardFormContentContainer, AddCardFormTitle } from './styled';
import { AddCardFormContent } from './components/AddCardFormContent/AddCardFormContent';
import { SavedPaymentMethod } from 'models/Subscriptions/SavedPaymentMethod';
import { Site } from 'models/site/Site';

interface Props {

  /** Save handler. */
  readonly onSave: (savedMethodId: SavedPaymentMethod['id']) => void;

  /** Close handler. */
  readonly onClose: () => void;

  /** Organization id. */
  readonly organizationId: Site['organizationId'];
}

const stripePromise = loadStripe(config.stripePublicKey);

const AddCardFormComponent: FC<Props> = ({ onClose, onSave, organizationId }) => {
  return (
    <AddCardFormContentContainer>
      <AddCardFormTitle>Payment method</AddCardFormTitle>
      <Elements stripe={stripePromise}>
        <AddCardFormContent
          onSave={onSave}
          onCancel={onClose}
          organizationId={organizationId}
        />
      </Elements>
    </AddCardFormContentContainer>
  )
};

export const AddCardForm = memo(AddCardFormComponent);