import { useMutate } from 'hooks/api';
import { SiteMapper } from 'mappers/SiteMapper';
import { SiteCreationValues } from 'models/site/SiteCreationValues';
import { ERoute } from 'router/data';
import { Organization } from '../../models/organization/Organization';

export const useCreateSite = (locationOrigin: string, isCommercial: Organization['isCommercial'] = false, redirectUri?: string) => {
  const { mutate } = useMutate<undefined, undefined, SiteCreationValues>('post', {
    url: `/api/Sites/${isCommercial ? 'commercial' : 'nonCommercial'}`,
    redirectUri,
    requestTransformer: (data) => JSON.stringify(SiteMapper.toSiteCreationValuesDto(data, locationOrigin)),
  });

  return { handleCreate: mutate };
};
