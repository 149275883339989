import { Organization } from 'models/organization/Organization';
import React, { FC, memo, useState } from 'react';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';
import { ButtonTableCell, PaymentMethodsTableCell, PaymentMethodsTableRow } from './styled';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { theme } from '../../styles/theme';
import { MoreVert } from '@mui/icons-material';
import { useDeleteSavedPaymentMethod } from '../../api/Subscriptions/useDeleteSavedPaymentMethod';
import { useSavedPaymentMethods } from '../../api/Subscriptions/useSavedPaymentMethods';
import { useMakeSavedPaymentMethodDefault } from '../../api/Subscriptions/useMakeSavedPaymentMethodDefault';

interface Props {

  /** Payment method company on the row. */
  readonly method: SavedPaymentMethod;

  /** Organization ID. */
  readonly organizationId: Organization['id'];
}

const SavedPaymentMethodsTableRowComponent: FC<Props> = ({ method, organizationId }) => {

  const [isHovering, setIsHovering] = useState(false);

  const { revalidate } = useSavedPaymentMethods(Number(organizationId));

  const { makeDefault } = useMakeSavedPaymentMethodDefault();

  const { handleDelete } = useDeleteSavedPaymentMethod(method.id);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const [isLoading, setIsLoading] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const isMenuOpen = menuAnchor != null;

  const handleMenuOpenButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    handleMenuOpen(event);
  };

  const handleDeleteButtonClick = async() => {
    setIsLoading(true);
    await handleDelete();
    await revalidate();
    handleMenuClose();
    setIsLoading(false);
  };

  const handleMakeDefaultButtonClick = async() => {
    setIsLoading(true);
    await makeDefault({
      organizationId,
      savedPaymentMethodId: method.id,
    });
    await revalidate();
    handleMenuClose();
    setIsLoading(false);
  }

  return (
    <>
      <PaymentMethodsTableRow
        isHovering={isHovering}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <PaymentMethodsTableCell>
          {`************${method.lastFourDigits}`}
        </PaymentMethodsTableCell>
        <PaymentMethodsTableCell textAlign='center'>
          {method.formattedExpirationDate}
        </PaymentMethodsTableCell>
        <PaymentMethodsTableCell textAlign='center'>
          {method.isDefault && <CheckIcon color="success" />}
        </PaymentMethodsTableCell>
        <ButtonTableCell>
          {(isHovering || isMenuOpen) && !method.isDefault && (
            <IconButton onClick={handleMenuOpenButtonClick} type="button">
              <MoreVert />
            </IconButton>
          )}
        </ButtonTableCell>
      </PaymentMethodsTableRow>
      <Menu open={isMenuOpen} onClose={handleMenuClose} anchorEl={menuAnchor}>
        <MenuItem
          onClick={handleMakeDefaultButtonClick}
          sx={{ color: theme.colors.blue }}
          disabled={isLoading}
        >
          Make default
        </MenuItem>
        <MenuItem
          onClick={handleDeleteButtonClick}
          sx={{ color: theme.colors.red }}
          disabled={isLoading}
        >
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export const SavedPaymentMethodsTableRow = memo(SavedPaymentMethodsTableRowComponent);
