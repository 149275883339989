import { StrictOmit } from '../../utils/types/strictOmit';
import { SavedPaymentMethod } from './SavedPaymentMethod';

/** Subscription state. */
export enum SubscriptionState {
  ActiveAndPaid = 'ActiveAndPaid',
  ActiveAndNotPaid = 'ActiveAndNotPaid',
  DeletedAndPaid = 'DeletedAndPaid',
  DeletedAndNotPaid = 'DeletedAndNotPaid',
}

type SubscriptionPeriodCreationData = SubscriptionPeriod;

/** Subscription period. */
export class SubscriptionPeriod {

  /** Cost per bed in dollars. */
  public readonly costPerBedInDollars: number;

  /** Duration in months. */
  public readonly durationInMonth: number;

  /** Order. */
  public readonly order: number;

  public constructor(data: SubscriptionPeriodCreationData) {
    this.costPerBedInDollars = data.costPerBedInDollars;
    this.durationInMonth = data.durationInMonth;
    this.order = data.order;
  }
}

type OrganizationSubscriptionCreationDate = StrictOmit<
  OrganizationSubscription,
  'isSubscriptionDeleted' | 'isSubscriptionAllowsSiteEditing' | 'totalInvoice'
>;

/** Organization subscription. */
export class OrganizationSubscription {

  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly subscriptionOptionName: string | null;

  /** Subscription periods. */
  public readonly subscriptionOptionPeriods: readonly SubscriptionPeriod[] | null;

  /** Default card for payments. */
  public readonly defaultSavedCard: SavedPaymentMethod;

  /** Next monthly payment date. */
  public readonly nextMonthlyPaymentDate: Date;

  /** Next monthly payment amount. */
  public readonly nextMonthlyPaymentAmount: number | null;

  /** Total count of paid beds. */
  public readonly totalPaidBedCount: number;

  /** Subscription state. */
  public readonly state: SubscriptionState;

  public constructor(data: OrganizationSubscriptionCreationDate) {
    this.id = data.id;
    this.subscriptionOptionName = data.subscriptionOptionName;
    this.subscriptionOptionPeriods = data.subscriptionOptionPeriods;
    this.defaultSavedCard = data.defaultSavedCard;
    this.nextMonthlyPaymentDate = data.nextMonthlyPaymentDate;
    this.nextMonthlyPaymentAmount = data.nextMonthlyPaymentAmount;
    this.totalPaidBedCount = data.totalPaidBedCount;
    this.state = data.state;
  };

  /** Whether the subscription is deleted. */
  public get isSubscriptionDeleted(): boolean {
    return this.state === SubscriptionState.DeletedAndNotPaid || this.state === SubscriptionState.DeletedAndPaid;
  }

  /** Whether the current subscription allows to edit sites. */
  public get isSubscriptionAllowsSiteEditing(): boolean {
    return this.state === SubscriptionState.ActiveAndPaid;
  }

  public get totalInvoice(): string {
    return this.nextMonthlyPaymentAmount !== null ? `$${this.nextMonthlyPaymentAmount}`: '-';
  }
};
