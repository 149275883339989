import { SavedPaymentMethodCreateValuesDto } from 'dtos/Subscriptions/SavedPaymentMethodCreateValuesDto';
import { SavedPaymentMethodCreateValues } from 'models/Subscriptions/SavedPaymentMethodCreateValues';
import { PaymentMethodBrandDto, SavedPaymentMethodDto } from '../dtos/Subscriptions/SavedPaymentMethodDto';
import { PaymentMethodBrand, SavedPaymentMethod } from '../models/Subscriptions/SavedPaymentMethod';

const MAP_BRAND_DTO_TO_MODEL: Readonly<Record<PaymentMethodBrandDto, PaymentMethodBrand>> = {
  [PaymentMethodBrandDto.Visa]: PaymentMethodBrand.Visa,
  [PaymentMethodBrandDto.Mastercard]: PaymentMethodBrand.Mastercard,
}

/**
 * Maps `mm/yy` formatted date string to Date object.
 * @param date `mm/yy` formatted date.
 */
function mapExpirationDateDto(date: SavedPaymentMethodDto['expirationDate']): SavedPaymentMethod['expirationDate'] {
  const parts = date.split('/');
  if (parts.length != 2) {
    throw new Error(`Incorrect expiration date of payment method: ${date}.`);
  }
  const month = parts[0];
  const year = `20${parts[1]}`;
  return new Date(Number(year), Number(month) - 1, 1);
}

/** Saved payment method mapper. */
export namespace SavedPaymentMethodMapper {

  /** Maps dto to model. */
  export function fromDto(dto: SavedPaymentMethodDto): SavedPaymentMethod {
    return new SavedPaymentMethod({
      id: dto.id,
      brand: MAP_BRAND_DTO_TO_MODEL[dto.brand],
      lastFourDigits: dto.lastFourDigits,
      isDefault: dto.isDefault,
      expirationDate: mapExpirationDateDto(dto.expirationDate),
    })
  }

  /** Maps creation model to dto. */
  export function toCreateValuesDto(model: SavedPaymentMethodCreateValues): SavedPaymentMethodCreateValuesDto {
    return {
      externalSavedPaymentMethodId: model.externalSavedPaymentMethodId,
      organizationId: model.organizationId,
    }
  }
}
