import React, { FC, useCallback, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { IGuestType } from 'api/guests/types'
import { useSiteDetails } from 'api/sites/useSiteDetails'
import { GuestInfoModal } from "components/GuestInfoModal";
import { Layout } from 'components/Layout'
import { BlockView } from 'components/Layout/Grid'
import { MainHeading } from 'components/Typography'
import { LoadingPage } from 'pages/LoadingPage'
import { GuestsTable } from './GuestsTable'
import { CancelLink } from 'components/CancelLink'
import { ERoute } from 'router/data'
import { SiteBlock } from 'components/SiteBlock/SiteBlock';
import { Site } from 'models/site/Site';

interface Props {
  /** Site id. */
  readonly siteId: Site['id'];
}

export const HistoryPage: FC<RouteComponentProps<Props>> = ({
  match,
}) => {

  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedGuest, setGuest] = useState<IGuestType | undefined>()
  const { data: site, error: siteError } = useSiteDetails(
    match.params.siteId
  )

  const show = useCallback(() => {
    setModalOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const onGuestSelect = useCallback((guest: IGuestType) => {
    setGuest(guest)
    show()
  }, [])


  if (siteError) {
    return <div>failed to load</div>
  }
  if (site == null) {
    return <LoadingPage />
  }

  return (
    <Layout withSideBar={true}>
      <CancelLink to={ERoute.HISTORY} />

      <MainHeading>History details</MainHeading>
      <BlockView mt={10} mb={10}>
        <SiteBlock site={site} />
      </BlockView>

      <BlockView>
        <GuestsTable
          siteId={match.params.siteId}
          onSelect={onGuestSelect}
        />
      </BlockView>
      {selectedGuest && <GuestInfoModal
        onClose={onClose}
        isOpen={isModalOpen}
        guest={selectedGuest}
      />}
    </Layout>
  )
}
