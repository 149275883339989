import { useOrganization } from 'api/organizations/useOrganization';
import { ButtonIcon } from 'components/ButtonIcon/styled';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { ErrorMessage } from 'components/FetchErrorMessage/FetchErrorMessage';
import { InsuranceCompaniesTable } from 'components/InsuranceCompaniesTable/InsuranceCompaniesTable';
import { Layout } from 'components/Layout';
import { mapOrganizationTypeHrefToName, OrganizationsNav, OrganizationsNavItem } from 'components/OrganizationsNav/OrganizationsNav';
import { PageHeaderLineContainer } from 'components/PageHeaderLineContainer/PageHeaderLineContainer';
import { StyledButton } from 'components/StyledButton/styled';
import { useOrganizationTypeHref } from 'hooks/routing/useOrganizationTypeFromHref';
import { Organization } from 'models/organization/Organization';
import { LoadingPage } from 'pages/LoadingPage';
import React, { FC, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { routes } from 'router/data';
import { useAuth } from '../../hooks/auth/useAuth';
import { AddInsuranceModalWindowContent } from './components/AddInsuranceModalWindowContent/AddInsuranceModalWindowContent';


interface RouteProps {
  /** ID of the organization. */
  readonly organizationId: Organization['id'];
}

export const OrganizationInsurancesPage: FC<RouteComponentProps<RouteProps>> = ({ match }) => {
  const { organizationId } = match.params;

  const organizationsTypeHref = useOrganizationTypeHref();

  const { data: organization, error: organizationError } = useOrganization(organizationId);

  const { state: { userData: { roleGlobal } } } = useAuth();

  const [isAddInsuranceModalOpen, setIsAddInsuranceModalOpen] = useState(false);

  const handleAddInsuranceModalOpen = () => setIsAddInsuranceModalOpen(true);
  const handleAddInsuranceModelClose = () => setIsAddInsuranceModalOpen(false);

  if (organizationError != null) {
    return (
      <Layout withSideBar>
        <ErrorMessage message={organizationError.toString()} />
      </Layout>
    );
  }

  if (organization == null) {
    return <LoadingPage />;
  };

  if (!organization.isCommercial) {
    return <Redirect to={routes.OrganizationsList(organizationsTypeHref)} />
  };

  const navigationItems: readonly OrganizationsNavItem[] = [
    {
      name: mapOrganizationTypeHrefToName(organizationsTypeHref),
      linkPath: routes.OrganizationsList(organizationsTypeHref),
      isAvailable: () => roleGlobal,
    },
    {
      name: organization.name,
      linkPath: routes.OrganizationDetails(organization.id, organizationsTypeHref),
    },
    {
      name: 'Insurance companies',
    }
  ];

  return (
    <Layout withSideBar>
      <OrganizationsNav items={navigationItems} />
      <PageHeaderLineContainer>
        <h1>Insurance companies</h1>
        <StyledButton onClick={handleAddInsuranceModalOpen} type="button">
          <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
          Add Insurance
        </StyledButton>
      </PageHeaderLineContainer>
      <InsuranceCompaniesTable organization={organization} />
      <ModalWindow
        isOpen={isAddInsuranceModalOpen}
        onClose={handleAddInsuranceModelClose}
      >
        <AddInsuranceModalWindowContent
          organization={organization}
          onClose={handleAddInsuranceModelClose}
        />
      </ModalWindow>
    </Layout>
  );
};
