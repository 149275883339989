import { SubscriptionUpgradeInfoCreationDataDto } from '../dtos/Subscriptions/SubscriptionUpgradeInfoCreationDataDto';
import { SubscriptionUpgradeInfoCreationData } from '../models/Subscriptions/SubscriptionUpgradeInfoCreationData';

/** Subscription upgrade info creation data mapper. */
export namespace SubscriptionUpgradeInfoCreationDataMapper {

  /** Maps model to dto. */
  export function toDto(model: SubscriptionUpgradeInfoCreationData): SubscriptionUpgradeInfoCreationDataDto {
    return {
      siteId: Number(model.siteId),
      newSiteBedCount: model.newSiteBedCount,
    }
  }
}
