type ResubscribeInfoConstructorData = ResubscribeInfo;

/** Resubscribe info. */
export class ResubscribeInfo {

  /** Payment amount. */
  public readonly oneTimePaymentAmountInDollars: number;

  /** Extra paid bed count. */
  public readonly extraPaidBedCount: number;

  public constructor(data: ResubscribeInfoConstructorData) {
    this.oneTimePaymentAmountInDollars = data.oneTimePaymentAmountInDollars;
    this.extraPaidBedCount = data.extraPaidBedCount;
  }
}
