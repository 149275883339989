import React, { FC, memo, useEffect } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { ERoute } from '../../router/data';
import { searchParams } from '../../services/http';

const REDIRECT_DELAY_SECONDS = 5;

const ErrorPageComponent: FC = () => {
  const history = useHistory();

  const { search } = useLocation();

  const source = searchParams('source', search);
  const message = searchParams('message', search);

  useEffect(() => {
    const timeoutId = setTimeout(() => history.push(ERoute.LOGIN), REDIRECT_DELAY_SECONDS * 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  if (source === null || message === null) {
    return <Redirect to={ERoute.LOGIN} />;
  }

  return (
    <Layout grayBg>
      <p>{message}</p>
    </Layout>
  );
};

export const ErrorPage = memo(ErrorPageComponent);
