import { Formik } from 'formik';
import React, { FC, memo } from 'react';
import { InputField } from '../../../components/InputField';
import { Gap, RowView } from '../../../components/Layout/Grid';
import { LogoUpload } from '../../../components/LogoUplaoder/LogoUploader';
import { StyledButton } from '../../../components/StyledButton/styled';
import { InsuranceCompany } from '../../../models/insuranceCompany/InsuranceCompany';
import { StrictOmit } from '../../../utils/types/strictOmit';
import { ModalFormHeading, ModalFormWrapper } from '../../OrganizationsList/components/forms/styled';
import { object, string } from 'yup';
import { CheckboxField } from '../../../components/Checkbox';
import { CheckboxWrap } from '../../SiteCreate/components/IntakePeriods/styled';
import { ImagePrefix } from '../../../components/UploadPhoto/ImagePrefix';

const REQUIRED_FIELD_MESSAGE = 'Required field.';

const validationSchema = object().shape({
  name: string()
    .required(REQUIRED_FIELD_MESSAGE),
  logoPath: string()
    .required('Please add a logo of insurance company.')
    .nullable(),
});

/** Insurance company form values. */
export type InsuranceCompanyFormValues = StrictOmit<InsuranceCompany, 'id'>;

interface Props {

  /** Insurance company to get initial values from. */
  readonly company?: InsuranceCompany;

  /** On done callback. */
  readonly onSubmit: (values: InsuranceCompanyFormValues) => void;

  /** On cancel callback. */
  readonly onCancel: () => void;
};

const InsuranceCompanyFormComponent: FC<Props> = ({ company, onSubmit, onCancel }) => {

  const initialFormValues: InsuranceCompanyFormValues = {
    name: company?.name ?? '',
    isPrimary: company?.isPrimary ?? false,
    logoPath: company?.logoPath ?? '',
  };

  return (
    <Formik initialValues={initialFormValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnMount={true}>
      {({ submitForm, resetForm, isSubmitting, values, setFieldValue, errors, touched }) => (
        <ModalFormWrapper>
          <ModalFormHeading>{company?.name ?? 'Create New Insurance'}</ModalFormHeading>
          <LogoUpload
            prefix={ImagePrefix.InsuranceCompany}
            onChange={(value) => setFieldValue('logoPath', value)}
            initImageUrl={values.logoPath ?? ''}
            errorMessage={touched['logoPath'] ? errors.logoPath : undefined}
            isRequired
          />
          <RowView>
            <InputField name="name" label="Insurance Name" placeholder="Insurance Name" isRequired />
          </RowView>
          <CheckboxWrap>
            <CheckboxField name="isPrimary" label="Top Insurance" checked={values.isPrimary} />
          </CheckboxWrap>
          <RowView alignItems="flex-start" justifyContent="center" style={{ marginTop: '40px' }}>
            <StyledButton
              variant="outlined"
              onClick={() => {
                resetForm();
                onCancel();
              }}
              disabled={isSubmitting}
              width="50%"
            >
              Cancel
            </StyledButton>
            <Gap />
            <StyledButton
              variant="contained"
              onClick={submitForm}
              disabled={isSubmitting}
              width="50%"
            >
              Save
            </StyledButton>
          </RowView>
        </ModalFormWrapper>
      )}
    </Formik>
  );
};

export const InsuranceCompanyForm = memo(InsuranceCompanyFormComponent)
