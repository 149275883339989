import styled from '@emotion/styled'
import { theme } from 'styles/theme'

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  height: 40px;
`

export const RightMenuWrap = styled.div`
  display: flex;
`

export const LogoutLink = styled.span`
  font-size: 14px;
  color: ${theme.colors.dark};
  margin: 10px 16px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`
