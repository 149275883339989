type CreationData = SavedPaymentMethodCreateValues;

/** Saved payment method create values. */
export class SavedPaymentMethodCreateValues {

  /** Payment method ID. */
  public readonly externalSavedPaymentMethodId: string;

  /** Organization ID. */
  public readonly organizationId: number;

  public constructor(data: CreationData) {
    this.externalSavedPaymentMethodId = data.externalSavedPaymentMethodId;
    this.organizationId = data.organizationId;
  }
}
