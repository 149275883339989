import { ResubscribePaymentInfoDto } from '../dtos/Subscriptions/ResubscribePaymentInfoDto';
import { ResubscribePaymentInfo } from '../models/Subscriptions/ResubscribePaymentInfo';

/** Resubscription payment info mapper. */
export namespace ResubscribePaymentInfoMapper {

  /** Maps dto to model. */
  export function fromDto(dto: ResubscribePaymentInfoDto): ResubscribePaymentInfo {
    return new ResubscribePaymentInfo({
      paymentId: dto.paymentId,
      clientSecret: dto.externalPaymentKey,
    })
  }
}
