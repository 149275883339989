type ServiceCreationData = Service;

/** Service category. */
export enum ServiceCategory {
  General = 'General',
  Disability = 'Disability',
  Gender = 'Gender',
  Age = 'Age',
}

/** General service type. */
export enum GeneralServiceType {
  DrugTreatment = 'DRUG_TREATMENT',
  Perinatal = 'PRENATAL',
  WithChildren = 'WITH_CHILDREN',
}

/** Disability service type. */
export enum DisabilityServiceType {
  PhysicalDisability = 'PHYSICAL_DISABILITY',
  Cognitive = 'DEVELOPMENT_DISABILITY',
  MedicalNeeds = 'MEDICALLY_VULNERABLE',
  Blind = 'VISUALY_IMPAIRED',
  Deaf = 'HEARING_IMPAIRED',
  MentalHealth = 'MENTAL_HEALTH',
}

/** Gender service type. */
export enum GenderServiceType {
  Male = 'GENDER_MALE',
  Female = 'GENDER_FEMALE',
  Other = 'GENDER_OTHER',
}

/** Age service type. */
export enum AgeServiceType {
  FirstAgeGroup = 'AGE_UNDER_18',
  SecondAgeGroup = 'AGE_18_26',
  ThirdAgeGroup = 'AGE_OVER_27',
}

/** Service type. */
export type ServiceType = GeneralServiceType | DisabilityServiceType | GenderServiceType | AgeServiceType;

/** Site service. */
export class Service {

  /** Id. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Category. */
  public readonly category: ServiceCategory;

  /** Type. */
  public readonly type: ServiceType;

  public constructor(data: ServiceCreationData) {
    this.id = data.id;
    this.name = data.name;
    this.category = data.category;
    this.type = data.type;
  }
}
