import { IAuthorizeTwoFactorResponseData } from 'api/auth/useTwoFactor/types'
import { useMutate } from 'hooks/api'
import { useAuth } from 'hooks/auth/useAuth'
import { createFormData } from 'services/http'

export const useTwoFactor = () => {
  const {
    actions: { authorize: finishAuthorize, getPreTwoFactorAccessToken },
  } = useAuth()

  const { mutate, isLoading, error } = useMutate<
    IAuthorizeTwoFactorResponseData,
    IAuthorizeTwoFactorResponseData,
    URLSearchParams
  >(
    'post',
    {
      url: '/connect/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Accept': 'application/json',
      },
    },
    (data) => {
      finishAuthorize(data.access_token, data.refresh_token)
    }
  )

  /* eslint-disable camelcase */
  const authorize = (code: string) =>
    mutate(
      createFormData({
        client_id: 'admin',
        response_type: 'token',
        scope: 'openid profile admin offline_access',
        grant_type: 'exchange',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        token: getPreTwoFactorAccessToken()!,
        otp: code,
      })
    )

  return { authorize, isLoading, error }
}
