import { Button, Dialog } from '@mui/material';
import React, { FC } from 'react';
import { Room } from '../../domain/Room';
import {
  RoomDeletionBox,
  RoomDeletionText,
  RoomDeletionTitle,
} from './styled';

interface Props {
  /** Whether modal is opened or not. */
  readonly isOpened: boolean;

  /** Handle close. */
  readonly onClose: () => void;

  /** Room. */
  readonly room: Room;
}

/** Room deletion dialog for case when its impossible to delete the room. */
export const ImpossibleRoomDeletionDialog: FC<Props> = ({
  isOpened,
  onClose,
  room,
}) => {

  return (
    <Dialog open={isOpened} onClose={onClose}>
      <RoomDeletionBox>
        <RoomDeletionTitle>You can't delete the Room</RoomDeletionTitle>
        <RoomDeletionText>
          {`There are occupied beds in the ${room.name}.`}
        </RoomDeletionText>
        <RoomDeletionText>
          Please, discharge beds on Reservation page to remove the room or beds.
        </RoomDeletionText>
        <Button onClick={onClose} variant="contained">
          OK
        </Button>
      </RoomDeletionBox>
    </Dialog>
  );
};
