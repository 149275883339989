import { SiteDto } from 'dtos/site/SiteDto';
import { useFetch } from 'hooks/api';
import { SiteMapper } from 'mappers/SiteMapper';
import { Site } from 'models/site/Site';

export const useSiteDetails = (id: Site['id'], shouldFetch?: boolean) =>
  useFetch<Site, SiteDto>(
    {
      url: `/api/Sites/${id}`,
      mapper: (data) => SiteMapper.fromDto(data),
    },
    shouldFetch
  );
