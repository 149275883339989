import { loadStripe, PaymentIntentResult } from '@stripe/stripe-js';
import { config } from '../../config';

const stripePromise = loadStripe(config.stripePublicKey);

export namespace StripeService {
  export async function confirmCardPayment(
    paymentIntentSecretKey: string,
  ): Promise<PaymentIntentResult> {
    const stripe = await stripePromise;

    if (stripe !== null) {
      return await stripe.confirmCardPayment(paymentIntentSecretKey);
    } else {
      throw new Error('Could not load stripe.');
    }
  }
}
