import React, { FC, useState } from 'react';
import { EGuestStatus, IGuestType } from 'api/guests/types';
import { DaysStayedText } from 'components/DaysStayedText';
import { GenderIcon } from 'components/GenderIcon';
import { BlockView, RowView, Gap } from 'components/Layout/Grid';
import { SmartButton } from 'components/SmartButton';
import { MainHeading } from 'components/Typography';
import {
  StyledInfoText,
  StyledListTitle,
  StyledUl,
  StyledItem,
} from 'pages/Reservation/styled';
import { useIsUserRoleStaff } from 'hooks/user/useIsUserRoleStaff';
import { useUpdateGuestStatus } from 'api/guests';
import { TextField } from '@mui/material';

interface Props {
  selectedGuest?: IGuestType;
  handleUpdateStatus: ReturnType<typeof useUpdateGuestStatus>['handleUpdateStatus'];
  openAssignModal: VoidFunction;
  noBeds: boolean;
  maxDays: number;
}

// TODO: refactor
// eslint-disable-next-line complexity
export const GuestInfo: FC<Props> = ({
  noBeds,
  selectedGuest,
  handleUpdateStatus,
  maxDays,
  openAssignModal,
}) => {

  const [feedback, setFeedback] = useState('');

  const { code, gender, email, phone, location, services, siteId } =
    selectedGuest ?? {};
  const isAssigned = Boolean(selectedGuest?.assignedBedId);

  const decline = selectedGuest ?
    () => handleUpdateStatus({
      guestId: selectedGuest.id,
      status: EGuestStatus.REJECTED,
      feedback,
      bedId: null,
    }) :
    undefined;
  const discharge = selectedGuest ?
    () => handleUpdateStatus({
      guestId: selectedGuest.id,
      status: EGuestStatus.DISCHARGED,
      feedback,
      bedId: null,
    }) :
    undefined;

  const isStaff = useIsUserRoleStaff(String(siteId));

  return (
    <>
      <BlockView mb={20}>
        <RowView justifyContent="space-between">
          <MainHeading small withoutMargin>{`${code}`}</MainHeading>
          {selectedGuest && gender && <GenderIcon gender={gender} />}
        </RowView>
      </BlockView>

      {phone && email && (
        <BlockView mb={24}>
          <StyledInfoText>{phone}</StyledInfoText>
          <StyledInfoText>{email}</StyledInfoText>
        </BlockView>
      )}

      {location && (
        <BlockView mb={24}>
          <StyledInfoText>{location}</StyledInfoText>
        </BlockView>
      )}

      {services && services.length > 0 && (
        <BlockView mb={24}>
          <StyledListTitle>Services needed</StyledListTitle>

          <StyledUl>
            {services.map((service) => (
              <StyledItem key={service.id}>{service.name}</StyledItem>
            ))}
          </StyledUl>
        </BlockView>
      )}

      {selectedGuest?.assignedBedId && (
        <BlockView mb={24}>
          <DaysStayedText guest={selectedGuest} maxDays={maxDays} />
        </BlockView>
      )}

      {
        !selectedGuest?.rejectedAt && (
          <BlockView mb={24}>
            <TextField
              value={feedback}
              onChange={event => setFeedback(event.target.value)}
              multiline
              rows={5}
              placeholder="Feedback"
            />
          </BlockView>
        )
      }

      {!isStaff && selectedGuest && !selectedGuest.rejectedAt && (
        <RowView>
          <SmartButton
            width="100%"
            onClick={isAssigned ? discharge : decline}
            color="red"
          >
            {isAssigned ? 'Discharge' : 'Decline'}
          </SmartButton>
          <Gap />
          {noBeds ? (
            <SmartButton width="100%" disabled>
              No beds
            </SmartButton>
          ) : (
            <SmartButton width="100%" onClick={openAssignModal}>
              {isAssigned ? 'Reassign' : 'Assign'}
            </SmartButton>
          )}
        </RowView>
      )}
    </>
  );
};
