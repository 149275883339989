import styled from '@emotion/styled'
import React, { FC } from 'react'
import { space, SpaceProps } from 'styled-system'
import { MediumText, TertiaryHeading } from 'components/Typography'
import { theme } from 'styles/theme'

const ColorBox = styled.div<
  SpaceProps & { type: 'active' | 'discharged' | 'declined' }
>`
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
  margin-top: 34px;
  margin-bottom: 16px;
  margin-right: 10px;
  ${(props) =>
    props.type === 'active' && `background-color: ${theme.colors.green}`}
  ${(props) =>
    props.type === 'discharged' && `background-color: ${theme.colors.grayText}`}
  ${space}
`

const MediumText500 = styled(MediumText)`
  font-weight: 500;
  color: ${theme.colors.white};
`

export interface ISectionIndicatorProps {
  type: 'active' | 'discharged' | 'declined'
  number: number
}

export const SectionIndicator: FC<ISectionIndicatorProps> = ({
  type,
  number,
  ...props
}) => {
  if (type === 'declined') {
    return (
      <TertiaryHeading mt="40px" mb="20px">
        Declined: {number}
      </TertiaryHeading>
    )
  }

  return (
    <ColorBox type={type} {...props}>
      <MediumText500>
        <b>{number}</b> {type}
      </MediumText500>
    </ColorBox>
  )
}
