import { useResubscribeInfo } from 'api/Subscriptions/useResubscribeInfo';
import { Loader } from 'components/Loader/Loader';
import { Organization } from 'models/organization/Organization';
import { Tariff } from 'models/Tariff/Tariff';
import React, { Dispatch, FC, memo, useEffect } from 'react';

interface Props {

  /** Organization ID. */
  readonly organizationId: Organization['id'];

  /** Tariff id. */
  readonly tariffId: Tariff['id'] | null;

  /** Callback for setting loading status. */
  readonly onLoadingChange: Dispatch<boolean>;
}

const ResubscriptionConfirmationComponent: FC<Props> = ({ organizationId, tariffId, onLoadingChange: setIsLoading }) => {

  const { data } = useResubscribeInfo({
    subscriptionOptionId: tariffId ?? 0,
    organizationId,
  }, tariffId !== null);

  useEffect(() => {
    setIsLoading(data == null);
  }, [data, setIsLoading])

  if (tariffId === null) {
    return <p>You need to choose tariff at the previous step.</p>
  }

  if (data == null) {
    return <Loader size="medium" isPrimary />;
  }

  return (
    <div>
      {`You need to pay $${data.oneTimePaymentAmountInDollars}`}
    </div>
  )
}

export const ResubscriptionConfirmation = memo(ResubscriptionConfirmationComponent);
