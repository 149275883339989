import { EGuestStatus } from 'api/guests/types';
import { GuestUpdateDataDto } from 'dtos/guest/GuestUpdateDto';
import { GuestStatusDto } from 'dtos/guest/GuestStatusDto';
import { GuestUpdateData } from 'models/guest/GuestUpdate';

const GUEST_STATUS_TO_DTO: Readonly<Record<EGuestStatus, GuestStatusDto>> = {
  [EGuestStatus.ACCEPTED]: GuestStatusDto.Accepted,
  [EGuestStatus.DISCHARGED]: GuestStatusDto.Discharged,
  [EGuestStatus.PENDING]: GuestStatusDto.Pending,
  [EGuestStatus.REASSIGNED]: GuestStatusDto.Reassigned,
  [EGuestStatus.REJECTED]: GuestStatusDto.Rejected,
}

/** Guest mapper. */
export namespace GuestMapper {

  /**
   * Maps guest update data to dto.
   * @param model Model.
   */
  export function toUpdateDto(model: GuestUpdateData): GuestUpdateDataDto {
    return {
      id: model.guestId,
      status: GUEST_STATUS_TO_DTO[model.status],
      bedId: model.bedId ?? undefined,
      feedback: model.feedback ?? undefined,
    }
  }
}
