import { apiClient } from '../../services/api/apiClient';

/**
 * Logs failed login attempt.
 * @param username Username to log.
 */
export async function sendFailedLoginLog(username: string): Promise<void> {
  await apiClient.post('/api/EventLogs/loginFailed', {
    login: username,
  })
}
