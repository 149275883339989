import { useMutate } from 'hooks/api';
import { Organization } from '../../models/organization/Organization';

interface RequestData {

  /** Organization id. */
  readonly organizationId: Organization['id'];
}

/** Deletes current subscription for organization. */
export const useDeleteSubscription = () => {
  const { mutate } = useMutate<void, void, RequestData>('delete', {
    url: `/api/Subscriptions`,
  })

  return { handleDelete: mutate }
}
