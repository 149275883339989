import { useCallback } from 'react'
import { useBeds } from 'api/beds'
import { EGuestStatus, IGuestType } from 'api/guests/types'
import { useFetch } from 'hooks/api'
import { apiClient } from 'services/api/apiClient'
import { getAuthHeaders } from 'services/auth/helpers'
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed'
import { Pagination } from 'services/types'
import { Site } from 'models/site/Site'
import { GuestUpdateData } from 'models/guest/GuestUpdate'
import { GuestMapper } from 'mappers/GuestMapper'

export const useGuests = (siteId: Site['id'] | null, guestStatus?: EGuestStatus) =>
// TODO(Maxim K.): Add dto and use it instead of second generic type.
  useFetch<Pagination<IGuestType>, IGuestType>({
    url: `/api/guests?siteId=${siteId}${
      guestStatus != null ? `&Status=${guestStatus}` : ''
    }`,
  }, siteId !== null)

export const useGuest = (guestId: IGuestType['id']) =>
// TODO(Maxim K.): Add dto and use it instead of second generic type.
  useFetch<IGuestType, IGuestType>({
    url: `/api/guests/${guestId}`,
  })

// TODO: use useMutation
export const useUpdateGuestStatus = (siteId: Site['id'] | null, onSuccess: () => void, onFailure?: () => void) => {
  const { data: guestsData, mutate } = useGuests(siteId)
  const { data: bedsData, mutate: mutateBeds } = useBeds(siteId)

  const handleUpdateStatus = useCallback(
    (data: GuestUpdateData) => {
      if (!guestsData) {
        return
      }

      const dataDto = GuestMapper.toUpdateDto(data);

      apiClient({
        url: `/api/guests/${data.guestId}`,
        method: 'patch',
        headers: getAuthHeaders(),
        data: dataDto,
      })
        .then(async () => {
          onSuccess()
          await mutate(guestsData, true)
          await mutateBeds(bedsData, true)
        })
        .catch((err) => {
          if (onFailure != null) {
            onFailure();
          }
        })
    },
    [guestsData]
  )

  return { handleUpdateStatus }
}

/**
 * Update guest.
 * @param guestId Guest ID.
 * @param status Status of the guest.
 * @param bedId Bed ID.
 */
export const useUpdateGuest = (
  guestId: IGuestType['id'],
  status: EGuestStatus,
  bedId?: Bed['id'],
) => {
  return apiClient({
    url: `api/guests/${guestId}`,
    method: 'patch',
    headers: getAuthHeaders(),
    data: {
      id: guestId,
      status,
      bedId,
    }
  })
}
