import React, { FC } from 'react'
import {
  Container,
  Label,
  Text,
  Highlight,
} from 'components/InputPreview/styled'
import { Margin } from 'components/Spacing'

export interface Props {
  email: string
}

export const CheckLinkEmail: FC<Props> = ({ email }) => (
  <Container>
    <Label>Click the link in the email</Label>
    <Margin top={16} />
    <Text>
      An e-mail with your confirmation code has been sent to{' '}
      <Highlight>{email}</Highlight>.
    </Text>
  </Container>
)
