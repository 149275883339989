import axios from 'axios'
import { config } from 'config'
import { handleUnauthorizedError } from 'services/api/interceptors/error'
import { provideAuthToken } from 'services/api/interceptors/token'

export const apiClient = axios.create({
  baseURL: `${config.apiBaseUrl}`,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
  },
})

apiClient.interceptors.response.use((response) => response.data, handleUnauthorizedError)
apiClient.interceptors.request.use(provideAuthToken)
