import React from 'react'
import ReactDOM from 'react-dom'
import 'styles/index.css'
import { Router } from 'react-router-dom'
import { history } from 'browserHistory'
import { AuthProvider } from 'hooks/auth/Context'
import { FlashMessagesProvider } from 'hooks/flash/Context'
import { Routes } from 'router/Routes'
import { EditedSiteDraftContextProvider } from 'pages/SiteEdit/EditedSiteContext/EditedSiteContext'
import { SiteDetailsTabContextProvider } from 'pages/OrganizationSiteDetails/context/SiteDetailsTabContext'

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <FlashMessagesProvider>
        <AuthProvider>
          <EditedSiteDraftContextProvider>
            <SiteDetailsTabContextProvider>
              <Routes />
            </SiteDetailsTabContextProvider>
          </EditedSiteDraftContextProvider>
        </AuthProvider>
      </FlashMessagesProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
