import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const SiteTabHeadingContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`;

export const SiteTabHeadingText = styled.h2`
  font-size: var(--font-size-lg);
  font-weight: 600;
`;

export const SiteTabHeadingCount = styled.span`
  color: ${theme.colors.semiLightGray};
`;

export const SiteTabChildrenContainer = styled.div`
  margin-left: auto;
`;
