const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    grayText: '#757575',
    darkerGray: '#828282',
    middleGray: '#B7B7B7',
    middlePlusGray: '#E5E5E5',
    lightGray: '#F0F0F0',
    darkGray: '#404040',
    green: '#5A802A',
    lightGreen: '#EEF5E6',
    darkGreen: '#44631D',
    dark: '#121914',
    gray: '#D6E0D8',
    red: '#D63A18',
    blue: '#1691DE',
    semiLightBlue: '#54A2FF',
    lightBlue: '#54A2FF1A',
    purple: '#7A33FF',
    lightRed: '#FF00001A',
    grayBorderColor: '#0000001A',
    semiLightGray: '#00000080',
    noContentBackgroundColor: '#F5F5F580',
    noContentColor: '#0000004D',
    veryLightGray: '#e6e6e6',
    lightOverlay: '#FFFFFF80',
    semiLightRed: '#FF0000',
  },
  space: {
    small: '8px',
    medium: '16px',
    large: '32px',
  },
  fontSizes: {
    text: '14px',
    buttonText: '15px',
    smallHeader: '16px',
    largeHeader: '18px',
  },
  borderRadius: {
    small: '2px',
    medium: '6px',
    large: '16px',
  },
  zIndices: {
    modal: 1000,
    overlay: 1,
  },
  sideBarWidth: '160px',
  maxContentWidth: '400px',
}

export { theme }

export type EColors = keyof typeof theme.colors

export const DESC_CHARS_LIMIT = 15
