import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { TableHeader } from 'pages/Reservation/Components/GuestsTable/styled';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { SortableTableHeaderArrow, SortableTableHeaderButton } from './styled';
import { typedMemo } from 'utils/typedMemo';
import { SortDirection } from 'utils/types/SortingDirection';

interface Props<T> {

  /** Click handler. */
  readonly onClick: (columnName: T) => void;

  /** Column name to sort by. */
  readonly columnName: T;

  /** Current sort column name. */
  readonly currentColumnName: T;

  /** Current sort direction. */
  readonly currentDirection: SortDirection;
}

const SortableTableHeaderComponent = <T,>({ children, columnName, currentColumnName, currentDirection, onClick }: PropsWithChildren<Props<T>>) => {
  const isSortingByActive = columnName === currentColumnName;

  const [isHovering, setIsHovering] = useState(false);
  const handleHoverChange = useCallback(() => setIsHovering(state => !state), [setIsHovering]);

  return (
    <TableHeader>
      <SortableTableHeaderButton
        onClick={() => onClick(columnName)}
        type="button"
        onMouseEnter={handleHoverChange}
        onMouseLeave={handleHoverChange}
      >
        {children}
        {
          isSortingByActive && <SortableTableHeaderArrow isActive={true} sortDirection={currentDirection} />
        }
        {
          isHovering && !isSortingByActive && <SortableTableHeaderArrow isActive={false} sortDirection="asc"/>
        }

      </SortableTableHeaderButton>
    </TableHeader>
  )
}

export const SortableTableHeader = typedMemo(SortableTableHeaderComponent);
