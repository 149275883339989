import styled from '@emotion/styled';
import React, { FC, useState } from 'react';
import { theme } from 'styles/theme';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 23px 12px;
`;

const OptionsDropdown = styled.div`
  position: absolute;
  top: 80%;
  right: 0;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray};
  z-index: 100;
  border-radius: 5px;
`;

const OptionsDropdownItem = styled.div`
  padding: 10px 15px;

  &:hover {
    background: ${theme.colors.lightGray};
  }
`;

export interface IDropdownOption {
  name: string;
  code: string;
}

export interface IDropdownListProps {
  selectedOptionId: string;
  options: IDropdownOption[];
  onClick: (id: IDropdownOption) => any;
  preview: (option: any) => JSX.Element;
}

export const DropdownList: FC<IDropdownListProps> = ({
  selectedOptionId,
  options,
  onClick,
  preview,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container
      {...props}
      tabIndex={0}
      onBlur={() => setIsOpen(false)}
      onClick={() => setIsOpen(!isOpen)}
    >
      {preview(selectedOptionId)}
      <OptionsDropdown>
        {isOpen &&
          options.map((option) => (
            <OptionsDropdownItem
              key={option.code}
              onClick={() => onClick(option)}
            >
              {option.name}
            </OptionsDropdownItem>
          ))}
      </OptionsDropdown>
    </Container>
  );
};
