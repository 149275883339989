/** Payment model state. */
export enum PaymentState {
  InProgress = 'InProgress',
  Succeed = 'Succeed',
  Failed = 'Failed',
};

/** Payment model purpose. */
export enum PaymentPurpose {
  SubscriptionCreation = 'SubscriptionCreation',
  SubscriptionMonthlyPayment = 'SubscriptionMonthlyPayment',
  ExtraPaidBedCountChange = 'ExtraPaidBedCountChange',
}

type BasePaymentCreationData = BasePayment;

/** Base payment. */
export class BasePayment {

  /** ID. */
  public readonly id: number;

  /** State of payment. */
  public readonly state: PaymentState;

  /** Purpose of payment. */
  public readonly purpose: PaymentPurpose;

  /** Creation date. */
  public readonly createdAt: Date;

  public constructor(data: BasePaymentCreationData) {
    this.id = data.id;
    this.state = data.state;
    this.purpose = data.purpose;
    this.createdAt = data.createdAt;
  }
}
