import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const OwnersInfo = styled.p`
  font-size: 0.75rem;
  font-weight: 500;
  padding: 10px 60px;
  background-color: ${theme.colors.lightBlue};
  border-radius: 8px;
  text-align: center;
`;

export const OwnersListText = styled.span`
  color: ${theme.colors.semiLightBlue};
  &::before {
    content: url('assets/icons/admin-list-icon.svg');
    position: relative;
    top: 5px;
  }
`;
