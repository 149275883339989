import { object, ref, string } from 'yup'

export const initialValues = {
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
}

export const validationSchema = object().shape({
  oldPassword: string().required('You must enter the old password'),
  password: string()
    .min(8, 'Password should have at least 8 characters')
    .matches(/(?=.*[A-Z])/u, 'Password must have at least one capital letter')
    .matches(/(?=.*[0-9])/u, 'Password must have at least one number')
    .required('New password is a required field'),
  passwordConfirmation: string()
    .oneOf([ref('password'), undefined], 'Passwords must match')
    .required('Confirm new password is a required field'),
})

export type IFormData = typeof initialValues
