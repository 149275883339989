import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { layout, LayoutProps, space, SpaceProps } from 'styled-system'
import { EColors, theme } from 'styles/theme'

export type IThemedButtonProps = LayoutProps & {
  outlined?: boolean
  small?: boolean
  color?: EColors
  disabled?: boolean
  isEdit?: boolean
  isControl?: boolean
  isInDialog?: boolean
  thinText?: boolean
  wrapContent?: boolean
}

export const ThemedButton = styled.button<IThemedButtonProps & SpaceProps>`
  ${layout};
  border: none;
  background: ${(props) =>
    props.disabled
      ? theme.colors.middleGray
      : theme.colors[props.color ?? 'green']};
  border-radius: 8px;
  padding: ${(props) => (props.small ? '8px 12px' : '20px 20px')};
  color: ${theme.colors.lightGray};
  font-size: ${(props) => (props.small ? '12px' : '16px')};
  font-weight: ${(props) => (props.small ? '300' : 'bold')};
  /* width: ${(props) => (props.small ? 'auto' : '50%')}; */
  min-width: 130px;
  max-width: 320px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background: ${(props) =>
      props.disabled
        ? theme.colors.middleGray
        : theme.colors[props.color ?? 'darkGreen']};
  }

  ${(props) =>
    props.outlined &&
    css`
      border: ${props.small ? '1px solid' : '2px solid'};
      border-color: ${theme.colors[props.color ?? 'green']};
      background: transparent;
      color: ${theme.colors[props.color ?? 'green']};

      &:hover {
        background: ${theme.colors[props.color ?? 'green']};
        color: ${theme.colors.white};
      }
    `}

  ${(props) =>
    props.isEdit &&
    `
    min-width: unset;
    max-width: 64px;
    max-height: 32px;
    padding: 9px 7px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    border: 1px solid;
  `}

  ${(props) =>
    props.isControl &&
    `
    max-width: 130px;
    max-height: 48px;
    padding: 14px 0;
  `}

  ${(props) =>
    props.isInDialog &&
    `
    font-weight: 500;
    width: auto;
    min-width: auto;
    max-width: auto;
    padding: 11px 26px;
  `}

    ${(props) =>
    props.wrapContent &&
    `
      display: inline-block;
      width: auto;
      min-width: auto;
      max-width: auto;
    `}

  ${(props) =>
    props.thinText &&
    `
    font-weight: 500;
  `}

  ${space}
`
