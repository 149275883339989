/** Dto service category. */
export enum ServiceCategoryDto {
  General = 'General',
  Disability = 'Disability',
  Gender = 'Gender',
  Age = 'Age',
}

/** Dto general service type. */
export enum GeneralServiceTypeDto {
  DrugTreatment = 'DRUG_TREATMENT',
  Perinatal = 'PRENATAL',
  WithChildren = 'WITH_CHILDREN',
}

/** Dto disability service type. */
export enum DisabilityServiceTypeDto {
  PhysicalDisability = 'PHYSICAL_DISABILITY',
  Cognitive = 'DEVELOPMENT_DISABILITY',
  MedicalNeeds = 'MEDICALLY_VULNERABLE',
  Blind = 'VISUALY_IMPAIRED',
  Deaf = 'HEARING_IMPAIRED',
  MentalHealth = 'MENTAL_HEALTH',
}

/** Dto Gender service type. */
export enum GenderServiceTypeDto {
  Male = 'GENDER_MALE',
  Female = 'GENDER_FEMALE',
  Other = 'GENDER_OTHER',
}

/** Dto age service type. */
export enum AgeServiceTypeDto {
  FirstAgeGroup = 'AGE_UNDER_18',
  SecondAgeGroup = 'AGE_18_26',
  ThirdAgeGroup = 'AGE_OVER_27',
}

/** Dto service type. */
export type ServiceTypeDto = GeneralServiceTypeDto | DisabilityServiceTypeDto | GenderServiceTypeDto | AgeServiceTypeDto;

/** Service dto. */
export interface ServiceDto {

  /** Id. */
  readonly id: string;

  /** Name. */
  readonly name: string;

  /** Category. */
  readonly category: ServiceCategoryDto;

  /** Type. */
  readonly type: ServiceTypeDto;
}
