import { theme } from 'styles/theme';
import styled from '@emotion/styled';

interface ContainerProps {

  /** Whether the checkbox is selected. */
  readonly isSelected: boolean;
};

export const InsuranceCompanyCheckboxContainer = styled.div<ContainerProps>`
  background-color: ${props => props.isSelected ? theme.colors.lightBlue : 'initial'};
  padding: 5px;
  transition: 0.2ms;

  &:hover {
    background-color: ${props => props.isSelected ? theme.colors.lightBlue : theme.colors.lightGray};
  };
`;

export const InsuranceCompanyLabel = styled.label`
  color: ${theme.colors.black};
  font-size: var(--font-size-md);
`
