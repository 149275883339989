import { OrganizationSubscriptionDto } from '../../dtos/Subscriptions/OrganizationSubscriptionDto';
import { useFetch } from '../../hooks/api';
import { OrganizationSubscriptionMapper } from '../../mappers/OrganizationSubscriptionMapper';
import { Organization } from '../../models/organization/Organization';
import { OrganizationSubscription } from '../../models/Subscriptions/OrganizationSubscription';

/** Gets current organization subscription plan. */
export const useOrganizationSubscription = (organizationId: Organization['id'], shouldFetch = true) =>
  useFetch<OrganizationSubscription, OrganizationSubscriptionDto>({
    url: `/api/Subscriptions/?organizationId=${organizationId}`,
    mapper: data => OrganizationSubscriptionMapper.fromDto(data),
  }, shouldFetch);
