import React, { FC, memo, useCallback, useState } from 'react';
import { Loader } from '../../../../components/Loader/Loader';
import { PaginatedLogsTableContainer } from '../PaginatedLogsTableContainer';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { LogsTableHeader } from '../TableCells/TableCells';
import { PaginationContainer } from '../../../../components/PaginationContainer/PaginationContainer';
import { PaginationSelect } from '../../../../components/PaginationSelect/PaginationSelect';
import { FailedLogTableRow } from './FailedLogTableRow';
import { useFailedLoginLogs } from '../../../../api/logs/useLogs';

const PAGE_SIZE = 10;

const FailedLogsComponent: FC = () => {

  const [pageIndex, setPageIndex] = useState<number>(0);

  const handlePageChange = useCallback((newPageIndex: number) => setPageIndex(newPageIndex), [setPageIndex]);

  const { data: paginatedLogs } = useFailedLoginLogs({
    pageNumber: pageIndex + 1,
    pageSize: PAGE_SIZE,
  });

  if (paginatedLogs == null) {
    return <Loader size="medium" isPrimary />
  }

  return (
    <PaginatedLogsTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <LogsTableHeader>Login</LogsTableHeader>
            <LogsTableHeader>Date</LogsTableHeader>
            <LogsTableHeader>Time</LogsTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedLogs.items.map(log => <FailedLogTableRow key={log.id} log={log} />)}
        </TableBody>
      </Table>
      <PaginationContainer>
        <PaginationSelect
          maxPageCount={paginatedLogs.metadata.totalPages}
          currentPageIndex={pageIndex}
          onPageSelect={handlePageChange}
          type="row"
        />
      </PaginationContainer>
    </PaginatedLogsTableContainer>
  )
}

export const FailedLogs = memo(FailedLogsComponent);
