import { BasePayment } from './BasePayment';

type PaymentCreationData = Payment;

/** Payment model. */
export class Payment extends BasePayment {

  /** External message of the payment. */
  public readonly externalMessage: string | null;

  /** Payment time. */
  public readonly paidAt: Date | null;

  public constructor(data: PaymentCreationData) {
    super(data);
    this.externalMessage = data.externalMessage;
    this.paidAt = data.paidAt;
  }
}
