import { OrganizationSubscriptionDto, SubscriptionPeriodDto, SubscriptionStateDto } from '../dtos/Subscriptions/OrganizationSubscriptionDto';
import { OrganizationSubscription, SubscriptionPeriod, SubscriptionState } from '../models/Subscriptions/OrganizationSubscription';
import { SavedPaymentMethodMapper } from './SavedPaymentMethodMapper';

const MAP_STATE_FROM_DTO: Readonly<Record<SubscriptionStateDto, SubscriptionState>> = {
  [SubscriptionStateDto.ActiveAndPaid]: SubscriptionState.ActiveAndPaid,
  [SubscriptionStateDto.ActiveAndNotPaid]: SubscriptionState.ActiveAndNotPaid,
  [SubscriptionStateDto.DeletedAndPaid]: SubscriptionState.DeletedAndPaid,
  [SubscriptionStateDto.DeletedAndNotPaid]: SubscriptionState.DeletedAndNotPaid,
}

/** Subscription mapper. */
export namespace OrganizationSubscriptionMapper {

  /** Maps subscription period from dto to model. */
  function mapSubscriptionPeriodFromDto(dto: SubscriptionPeriodDto): SubscriptionPeriod {
    return new SubscriptionPeriod({
      costPerBedInDollars: dto.costPerBedInDollars,
      durationInMonth: dto.durationInMonth,
      order: dto.order,
    });
  }

  /** Maps dto to model. */
  export function fromDto(dto: OrganizationSubscriptionDto): OrganizationSubscription {
    return new OrganizationSubscription({
      id: dto.id,
      subscriptionOptionName: dto.subscriptionOptionName,
      subscriptionOptionPeriods: dto.subscriptionOptionPeriods && dto.subscriptionOptionPeriods.map(
        periodDto => mapSubscriptionPeriodFromDto(periodDto)
      ),
      defaultSavedCard: SavedPaymentMethodMapper.fromDto(dto.defaultSavedCard),
      nextMonthlyPaymentDate: new Date(dto.nextPeriodPaymentDate),
      nextMonthlyPaymentAmount: dto.nextPeriodPaymentAmount,
      totalPaidBedCount: dto.totalPaidBedCount,
      state: MAP_STATE_FROM_DTO[dto.state],
    });
  }
}
