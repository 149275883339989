import styled from '@emotion/styled'
import { Form } from 'formik'
import { Link } from 'react-router-dom'
import { theme } from 'styles/theme'

export const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
`

export const StyledForm = styled(Form)<{ hasSmallerPadding?: boolean, noCenter?: boolean }>`
  background: ${theme.colors.white};
  border-radius: 8px;
  max-width: 386px;
  height: 610px;
  padding: 80px 50px;
  ${props => !props.noCenter && `margin: 0 auto;`}
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  ${(props) =>
    props.hasSmallerPadding &&
    `
    padding: 60px 50px 40px 50px;
  `}
`

export const FormLink = styled(Link)`
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.blue};
  font-weight: 700;
  text-decoration: none;
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Header = styled.div``

export const Center = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  position: relative;
`

export const FormHeading = styled.span<{ color?: keyof typeof theme.colors }>`
  color: ${theme.colors.dark};
  font-size: 38px;
  line-height: 32px;
  font-weight: 300;
  display: block;
  margin-bottom: 12px;

  ${(props) =>
    props.color &&
    `
    color: ${theme.colors[props.color]};
  `}
`

export const FormText = styled.span<{ color?: keyof typeof theme.colors }>`
  color: ${theme.colors.dark};
  font-size: 14px;
  line-height: 18px;

  ${(props) =>
    props.color &&
    `
    color: ${theme.colors[props.color]};
  `}
`
