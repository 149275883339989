import { FieldArray, useFormikContext } from 'formik';
import React, { FC, useEffect } from 'react';
import { CheckboxField } from 'components/Checkbox';
import { Gap, RowView } from 'components/Layout/Grid';
import { FormSectionWrap } from 'components/Layout/Grid/FormSectionWrap';
import { TimePickerField } from 'components/TimePickerField';
import { MainHeading, SecondaryHeading } from 'components/Typography';
import {
  CheckboxWrap,
  FieldsWrap,
} from 'pages/SiteCreate/components/IntakePeriods/styled';
import { IntakePeriodsFormInfo } from 'services/types';
import { SiteFormBlockProps } from 'pages/SiteEdit';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { DaysStructure } from './types';
import { ALL_DAY_DEFAULT_TIME } from 'services/transformers';

export const DAYS_STRUCTURE: DaysStructure = {
  short: [
    { timePeriod: 'Weekdays', from: '', to: '', enabled: false },
    { timePeriod: 'Weekends', from: '', to: '', enabled: false },
    { timePeriod: 'Holidays', from: '', to: '', enabled: false },
  ],

  long: [
    { timePeriod: 'Monday', from: '', to: '', enabled: false },
    { timePeriod: 'Tuesday', from: '', to: '', enabled: false },
    { timePeriod: 'Wednesday', from: '', to: '', enabled: false },
    { timePeriod: 'Thursday', from: '', to: '', enabled: false },
    { timePeriod: 'Friday', from: '', to: '', enabled: false },
    { timePeriod: 'Weekends', from: '', to: '', enabled: false },
    { timePeriod: 'Holidays', from: '', to: '', enabled: false },
  ],
};

export const IntakePeriodsForm: FC<SiteFormBlockProps> = ({
  withoutMainHeader,
}) => {
  const { values, errors, setFieldValue,  } = useFormikContext<
    IntakePeriodsFormInfo
  >();

  useEffect(() => {
    values.intakePeriods.long.forEach((value, index) => {
      if (value.from === ALL_DAY_DEFAULT_TIME && value.to !== ALL_DAY_DEFAULT_TIME) {
        setFieldValue(`intakePeriods.long[${index}].to`, ALL_DAY_DEFAULT_TIME)
      }
    })
  }, [values])

  return (
    <FormSectionWrap>
      {!withoutMainHeader && (
        <MainHeading>
          Specify a work schedule so that people understand when they can
          contact you
        </MainHeading>
      )}
      <RowView justifyContent="space-between">
        <SecondaryHeading>Intake hours</SecondaryHeading>
      </RowView>

      <FieldArray
        name="intakePeriods"
        render={() => (
          <div>
            {values.intakePeriods.long.map(
              (timeRow, index) => (
                <RowView
                  key={timeRow.timePeriod}
                  justifyContent="space-between"
                  style={{ height: 'auto' }}
                >
                  <CheckboxWrap>
                    <CheckboxField
                      name={`intakePeriods.long[${index}].enabled`}
                      label={timeRow.timePeriod}
                    />
                  </CheckboxWrap>

                  <FieldsWrap>
                    <TimePickerField
                      name={`intakePeriods.long[${index}].from`}
                      defaultValue={'09:00:00'}
                      label={index === 0 ? 'From' : undefined}
                      disabled={!timeRow.enabled}
                      maxValue={
                        values.intakePeriods.long[index].to
                      }
                      withAllDayOption
                    />

                    <Gap />
                    <TimePickerField
                      name={`intakePeriods.long[${index}].to`}
                      label={index === 0 ? 'To' : undefined}
                      disabled={!timeRow.enabled || values.intakePeriods.long[index].from === ALL_DAY_DEFAULT_TIME}
                      defaultValue={'17:00:00'}
                      minValue={
                        values.intakePeriods.long[index].from
                      }
                    />
                  </FieldsWrap>
                </RowView>
              )
            )}
          </div>
        )}
      />
      <ValidationError message={errors.intakePeriods?.toString()} isCentered />
    </FormSectionWrap>
  );
};
