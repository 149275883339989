import { GuestCreationLogDto } from '../dtos/logs/GuestCreationLogDto';
import { GuestCreationLog } from '../models/logs/GuestCreationLog';
import { MAP_ROLE_DTO_TO_MODEL } from './UserMapper';

/** Mapper for guest creation log. */
export namespace GuestCreationLogMapper {

  /** Maps dto to model. */
  export function fromDto(dto: GuestCreationLogDto): GuestCreationLog {
    return new GuestCreationLog({
      id: dto.id,
      firstName: dto.firstName,
      lastName: dto.lastName,
      siteName: dto.siteName,
      createdByEmail: dto.createdByEmail,
      createdByRole: dto.createdByRole && MAP_ROLE_DTO_TO_MODEL[dto.createdByRole],
      guestCreatedAt: new Date(dto.guestCreatedAt),
    })
  }
}
