import React, { FC, useCallback } from 'react'
import { ToggleSwitchField } from 'components/ToggleSwitch'
import { SecondaryHeading } from 'components/Typography'
import { ServicesBlockWrap, ServicesContainer } from 'pages/SiteCreate/components/Services/styled'
import { HorizontalLine } from 'pages/Reservation/Components/CreateGuestForm/styled'
import { Service } from 'models/service'

interface Props {

  /** Services. */
  readonly services: readonly Service[]

  /** Heading text. */
  readonly heading?: string

  /** Whether heading should be underlined. */
  readonly withHeadingLine?: boolean;

  /** Whether services should be positioned in two columns. */
  readonly withTwoColumns?: boolean;

  /** Ids of available services. */
  readonly availableServiceIds?: readonly Service['id'][];
}

export const ServicesBlock: FC<Props> = ({ services, heading, withHeadingLine, withTwoColumns, availableServiceIds }) => {

  const checkIfServiceIsDisabled = useCallback((serviceId: Service['id']) => {
    if (availableServiceIds == null) {
      return false;
    }
    return !availableServiceIds.includes(serviceId);
  }, [availableServiceIds])

  return (
    <ServicesBlockWrap withTwoColumns={withTwoColumns}>
      <SecondaryHeading>{heading}</SecondaryHeading>
      {withHeadingLine && <HorizontalLine />}
      <ServicesContainer withTwoColumns={withTwoColumns}>
        {services.map((service) => (
          <ToggleSwitchField
            key={service.id}
            name={`serviceIds[${service.id}]`}
            value={service.id}
            label={service.name}
            isDisabled={checkIfServiceIsDisabled(service.id)}
          />
        ))}
      </ServicesContainer>
    </ServicesBlockWrap>
  )
}
