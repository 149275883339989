import { useOrganizations } from 'api/organizations/useOrganizations';
import { ButtonIcon } from 'components/ButtonIcon/styled';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { Layout } from 'components/Layout';
import { NoContentBlock } from 'components/NoContentBlock/styled';
import { OrganizationsNavItem, OrganizationsNav, mapOrganizationTypeHrefToName } from 'components/OrganizationsNav/OrganizationsNav';
import { StyledButton } from 'components/StyledButton/styled';
import { useAuth } from 'hooks/auth/useAuth';
import { useOrganizationTypeHref } from 'hooks/routing/useOrganizationTypeFromHref';
import { MAP_HREF_TO_ORGANIZATION_TYPE } from 'mappers/OrganizationMapper';
import { LoadingPage } from 'pages/LoadingPage';
import React, { FC, useMemo, useState } from 'react';
import { CardItemsGridContainer } from '../../components/CardItemsGridContainer/CardItemsGridContainer';
import { PageHeaderLineContainer } from '../../components/PageHeaderLineContainer/PageHeaderLineContainer';
import { OrganizationTypeHref } from '../../router/data';
import { CreateOrganizationForm } from './components/forms/CreateOrganizationForm/CreateOrganizationForm';
import { OrganizationCard } from './components/OrganizationCard/OrganizationCard';
import { McMillenLogo, McMillenThanksContainer } from './styled';


export const OrganizationsListPage: FC = () => {

  const organizationsTypeHref = useOrganizationTypeHref();

  const organizationType = useMemo(() =>
    organizationsTypeHref != null ?
    MAP_HREF_TO_ORGANIZATION_TYPE[organizationsTypeHref] :
    undefined, [organizationsTypeHref]
  );

  const { data: organizations, revalidate: revalidateOrganizations } = useOrganizations(organizationType);

  const [isCreateOrganizationModalOpen, setIsCreateOrganizationModalOpen] = useState(false);

  const { roleGlobal } = useAuth().state.userData;

  const heading = useMemo(() => mapOrganizationTypeHrefToName(organizationsTypeHref), [organizationsTypeHref]);

  const openCreateOrganizationModal = () => setIsCreateOrganizationModalOpen(true);
  const closeCreateOrganizationModal = () => setIsCreateOrganizationModalOpen(false);

  const onOrganizationCreateDone = () => {
    revalidateOrganizations();
    closeCreateOrganizationModal();
  };

  if (organizations == null) {
    return <LoadingPage />;
  };

  const navigationItems: readonly OrganizationsNavItem[] = [
    {
      name: heading,
    },
  ];

  const shouldShowMcMillenThanks = roleGlobal && organizationsTypeHref === OrganizationTypeHref.FreeRehabs;

  const sortedOrganizations = [...organizations].sort(
    (firstOrganization, secondOrganization) =>
      firstOrganization.name.toLowerCase() > secondOrganization.name.toLowerCase() ? 1 : -1
  );

  return (
    <Layout withSideBar>
      <OrganizationsNav items={navigationItems} />
      {shouldShowMcMillenThanks && (
        <McMillenThanksContainer>
          <p>Made possible by McMillen Family Foundation</p>
          <McMillenLogo src="assets/mcmillen-logo.png" alt="" />
        </McMillenThanksContainer>
      )}
      <PageHeaderLineContainer>
        <h1>{heading}</h1>
        {roleGlobal && (
          <StyledButton type="button" onClick={openCreateOrganizationModal}>
            <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
            New Organization
          </StyledButton>
        )}
      </PageHeaderLineContainer>
      {sortedOrganizations.length !== 0 ? (
        <CardItemsGridContainer>
          {sortedOrganizations.map((organization) => (
            <OrganizationCard
              key={organization.id}
              organization={organization}
              revalidateOrganizations={revalidateOrganizations}
            />
          ))}
        </CardItemsGridContainer>
      ) : (
        <NoContentBlock>No Organizations yet</NoContentBlock>
      )}
      <ModalWindow isOpen={isCreateOrganizationModalOpen} isScrollable onClose={closeCreateOrganizationModal}>
        <CreateOrganizationForm onCancel={closeCreateOrganizationModal} onDone={onOrganizationCreateDone} />
      </ModalWindow>
    </Layout>
  );
};
