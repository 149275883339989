import styled from '@emotion/styled';
import { theme } from '../../styles/theme';

export const RoomAndBeds = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ReservationBedsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const VacantBedsCount = styled.p`
  background-color: ${theme.colors.green};
  font-weight: 600;
  font-size: var(--font-size-md);
  padding-inline: 5px;
  color: ${theme.colors.white};
  border-radius: 5px;
`;
