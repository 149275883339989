import { useUpdateOrganization } from 'api/organizations/useUpdateOrganization';
import { InputField } from 'components/InputField';
import { Gap, RowView } from 'components/Layout/Grid';
import { StyledButton } from 'components/StyledButton/styled';
import { Formik } from 'formik';
import { Organization } from 'models/organization/Organization';
import { OrganizationUpdateValues } from 'models/organization/OrganizationUpdateValues';
import React, { FC, memo } from 'react';
import { object, string } from 'yup';
import { LogoUpload } from '../../../../../components/LogoUplaoder/LogoUploader';
import { ImagePrefix } from '../../../../../components/UploadPhoto/ImagePrefix';
import { ModalFormHeading, ModalFormWrapper } from '../styled';
import { OwnersInfo, OwnersListText } from './styled';

const validationSchema = object().shape({
  name: string()
    .required('Organization name is a required field.'),
  logoPath: string()
    .required('Please add a logo to organization.')
    .nullable(),
});

interface Props {
  /** Organization to get initial values from. */
  readonly organization: Organization;

  /** On done callback. */
  readonly onDone: () => void;

  /** On cancel callback. */
  readonly onCancel: () => void;
}

const EditOrganizationFormComponent: FC<Props> = ({ organization, onDone, onCancel }) => {
  const { handleUpdate } = useUpdateOrganization(organization.id);

  const initialValues: OrganizationUpdateValues = {
    id: organization.id,
    name: organization.name,
    logoPath: organization.logoPath,
    insuranceCompanyIds: organization.insuranceCompanies.map(company => company.id),
  };

  const handleSubmit = async (values: OrganizationUpdateValues) => {
    await handleUpdate(values);
    onDone();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ submitForm, resetForm, isSubmitting, values, setFieldValue, errors }) => (
        <ModalFormWrapper>
          <ModalFormHeading>{organization.name}</ModalFormHeading>
          <LogoUpload
            prefix={ImagePrefix.Organization}
            onChange={(value) => setFieldValue('logoPath', value)}
            initImageUrl={values.logoPath}
            errorMessage={errors.logoPath}
            isRequired
          />
          <RowView>
            <InputField name="name" label="Organization Name" placeholder="Organization Name" isRequired />
          </RowView>
          <OwnersInfo>
            To go to the list of owners of the organization,
            <br /> click the <OwnersListText>Owners List</OwnersListText> button on the organization page.
          </OwnersInfo>
          <RowView alignItems="flex-start" justifyContent="center" style={{ marginTop: '40px' }}>
            <StyledButton
              variant="outlined"
              onClick={() => {
                resetForm();
                onCancel();
              }}
              disabled={isSubmitting}
              width="50%"
            >
              Cancel
            </StyledButton>
            <Gap />
            <StyledButton variant="contained" onClick={submitForm} disabled={isSubmitting} width="50%">
              Save
            </StyledButton>
          </RowView>
        </ModalFormWrapper>
      )}
    </Formik>
  );
};

export const EditOrganizationForm = memo(EditOrganizationFormComponent);
