import React, { useEffect } from 'react';
import { useEmailLogin } from 'api/auth/useEmailLogin/useEmailLogin';
import { Layout } from 'components/Layout';
import { SignInForm } from 'forms/SignIn';
import { IFormData } from 'forms/SignIn/data';
import { useAuth } from 'hooks/auth/useAuth';

export const SignIn = () => {
  const { signIn, isLoading, error } = useEmailLogin();
  const {
    state: { isSignedIn },
    actions: { chooseNextStep },
  } = useAuth();

  useEffect(() => {
    if (isSignedIn) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      chooseNextStep();
    }
  }, [isSignedIn]);

  const handleSubmit = (values: IFormData) => signIn(values);

  return (
    <Layout grayBg>
      <SignInForm
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        errorText={error ? 'Incorrect login or password' : undefined}
      />
    </Layout>
  );
};
