import { Field } from 'formik';
import React, { FC } from 'react';
import {
  StyledSwitch,
  StyledSlider,
  SwitchLabel,
  SwitchWrap,
} from 'components/ToggleSwitch/styled';

interface SwitchProps {

  /** Label. */
  readonly label: string;

  /** Whether the switch is checked. */
  readonly isChecked: boolean;

  /** On click callback. */
  readonly onClick: () => void;

  /** Whether the field is disabled. */
  readonly isDisabled?: boolean;
}

export const ToggleSwitch: FC<SwitchProps> = ({ label, isChecked, onClick, isDisabled }) => (
  <SwitchWrap onClick={onClick}>
    <StyledSwitch>
      <StyledSlider isChecked={isChecked} isDisabled={isDisabled} />
    </StyledSwitch>
    <SwitchLabel isDisabled={isDisabled}>{label}</SwitchLabel>
  </SwitchWrap>
);

interface SwitchFieldProps {

  /** Name. */
  readonly name: string;

  /** Label. */
  readonly label: string;

  /** Value. */
  readonly value?: string;

  /** Whether the switch is disabled. */
  readonly isDisabled?: boolean;
}

// TODO: refactor
// eslint-disable-next-line react/no-multi-comp
export const ToggleSwitchField: FC<SwitchFieldProps> = ({ name, label, value, isDisabled }) => (
  <Field name={name} type="checkbox">
    {({ field, form }: any) => (
      <ToggleSwitch
        label={label}
        isChecked={field.value}
        onClick={isDisabled ? () => {} : () => form.setFieldValue(name, field.value ? undefined : value)}
        isDisabled={isDisabled}
      />
    )}
  </Field>
);
