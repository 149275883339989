import { styled } from '@mui/material';
import { GuestFeedbackField } from 'components/GuestFeedbackField/GuestFeedbackField';

export const DischargeDialogInfo = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  rowGap: '1.5rem',
});

export const DischargeDialogRoomInfo = styled('div')({
  fontWeight: 500,
  gridColumn: '1 / 3',
  fontSize: '0.875rem',
});

export const DischargeDialogRoomInfoItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const RoomInfoItemTitle = styled('p')({
  fontWeight: 500,
  fontSize: '0.75rem',
  color: '#00000080',
  marginBottom: '0.2rem',
});

export const RoomInfoItemText = styled('p')({
  fontSize: '1rem',
  fontWeight: 500,
});

export const DischargeFeedbackField = styled(GuestFeedbackField)`
  grid-column: 1/3;
`;
