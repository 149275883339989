import { FieldLabelRequiredMark } from 'components/FieldLabelRequiredMark/FieldLabelRequiredMark';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { Field, useFormikContext } from 'formik';
import React, { ChangeEventHandler, useEffect, useRef } from 'react';
import { FC, memo } from 'react';
import {
  Description,
  InputWrap,
  StyledInput,
  StyledLabel,
} from '../InputField/styled';

type LocationType = '(cities)' | 'address';

export interface Props {
  /** Name. */
  readonly name: string;

  /** Type of location for autocomplete. */
  readonly locationType: LocationType;

  /** Handles change of place. */
  readonly onChange?: (place: google.maps.places.PlaceResult) => void;

  /** Value. */
  readonly value?: string;

  /** Label. */
  readonly label?: string;

  /** Placeholder */
  readonly placeholder?: string;

  /** Whether input disabled. */
  readonly disabled?: boolean;

  /** Description. */
  readonly description?: string;

  /** Whether right side should be cut. */
  readonly isCutRight?: boolean;

  /** Handle blur event. */
  readonly onBlur?: () => void;

  /** Whether fields should be autofocused or not. */
  readonly shouldAutoFocus?: boolean;

  /** Indicates if required mark should be added to the label. */
  readonly isRequired?: boolean;

  /** If true allows address in free form, otherwise require to select address from dropdown menu. */
  readonly allowInvalidAddress?: boolean;
}

const LocationInputComponent: FC<Props> = ({
  name,
  label,
  placeholder,
  disabled,
  description,
  isCutRight,
  onChange,
  onBlur,
  shouldAutoFocus,
  isRequired,
  locationType,
  value,
  allowInvalidAddress,
}) => {
  const { setFieldValue } = useFormikContext();

  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (inputRef.current === null) {
      return;
    }

    const autocomplete = new google.maps.places.Autocomplete(inputRef.current, {
      types: [locationType],
      fields: ['formatted_address'],
      componentRestrictions: { country: 'US' },
    });

    const listener = autocomplete.addListener('place_changed', () => {
      onChange?.(autocomplete.getPlace())
      setFieldValue(name, autocomplete.getPlace().formatted_address)
    });

    return () => listener.remove();
  }, [inputRef.current]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (allowInvalidAddress) {
      setFieldValue(name, e.target.value)
    } else {
      setFieldValue(name, null)
    }
  }

  return (
    <InputWrap>
      <Field name={name}>
        {({ form: { touched, errors } }: any) => (
          <>
            {label && (
              <StyledLabel
                htmlFor={name}
                hasError={Boolean(touched[name] && errors[name])}
              >
                {label}
                {isRequired && <FieldLabelRequiredMark />}
              </StyledLabel>
            )}
            <StyledInput
              id={name}
              placeholder={placeholder}
              disabled={disabled}
              hasError={Boolean(touched[name] && errors[name])}
              onChange={handleChange}
              type="text"
              isCutRight={isCutRight}
              onBlur={onBlur}
              autoFocus={shouldAutoFocus}
              ref={inputRef}
            />
            {description && <Description>{description}</Description>}
            <ValidationError message={touched[name] && errors[name]} />
          </>
        )}
      </Field>
    </InputWrap>
  );
};

export const LocationInput = memo(LocationInputComponent);
