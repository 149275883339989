import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth } from 'hooks/auth/useAuth'
import { ERoute, routes } from 'router/data'

export const Home = () => {
  const {
    state: { isAuthorized, userData },
  } = useAuth()

  if (!isAuthorized) {
    return <Redirect to={ERoute.LOGIN} />
  }

  if (userData.roleGlobal) {
    return <Redirect to={ERoute.FREE_REHABS_LIST} />
  }

  return <Redirect to={routes.OrganizationsList()} />
}
