import styled from '@emotion/styled';
import { TextField } from '@mui/material';
import { theme } from 'styles/theme';

export const BedCardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 35px minmax(80px, 6fr) 2fr 4fr 80px;
  grid-gap: 0 20px;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.lightGray};
  padding-block: 10px;
  font-weight: 500;
`;

export const BedCardPhotoContainer = styled.div`
  height: 35px;
`;

export const BedNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px 0;
  font-size: 0.875rem;
  white-space: pre-wrap;
`;

export const BedTypeText = styled.p`
  font-size: 0.75rem;
`;

export interface BedStatusProps {

  /** Whether bed is occupied or not. */
  readonly isOccupied: boolean
};

export const BedStatus = styled.div<BedStatusProps>`
  color: ${({ isOccupied }) => isOccupied ? theme.colors.semiLightRed : theme.colors.blue};
  background-color: ${({ isOccupied }) => isOccupied ? theme.colors.lightRed : theme.colors.lightBlue};
  border-radius: 15px;
  text-align: center;
  padding-block: 5px;
  font-size: ${theme.fontSizes.text};
`;

export const BedCardDialogText = styled.p`
  margin-bottom: 20px;
`;

export const BedEditControls = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const BedEditSlot = styled.div`
  display: grid;
  justify-items: end;

  @media print {
    display: none;
  }
`;

export const BedGuestGender = styled.p`
  font-size: 0.75rem;
`;

export const BedGuestName = styled.p`
  font-size: 0.875rem;
  word-break: break-word;
`;

export const BedEditContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
`;

export const BedEditTextField = styled(TextField)`
  --field-height: 40px;

  height: var(--field-height);

  & .MuiTextField-root {
    height: var(--field-height);
  }

  & .MuiInputBase-root {
    height: var(--field-height);
    padding: 0;
  }

  & .MuiInputBase-input {
    height: 10px;
  }
`;
