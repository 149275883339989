import { useSavedPaymentMethods } from 'api/Subscriptions/useSavedPaymentMethods';
import { Site } from 'models/site/Site';
import { SavedPaymentMethod } from 'models/Subscriptions/SavedPaymentMethod';
import { SubscriptionUpgradeInfo } from 'models/Subscriptions/SubscriptionUpgradeInfo';
import React, { FC, memo, useEffect } from 'react';
import { BedsDialogText, BedsDialogWarning } from './styled';

interface Props {

  /** Site. */
  readonly site: Site;

  /** Subscription upgrade info. */
  readonly subscriptionUpgradeInfo: SubscriptionUpgradeInfo;

  /** New count of beds. */
  readonly newCountOfBeds: number;

  /** Handle payment method change. */
  readonly handlePaymentMethodSelect: (selectedPaymentMethodId: SavedPaymentMethod['id']) => void;
}

const RemovedBedsDialogContentComponent: FC<Props> = ({ subscriptionUpgradeInfo, newCountOfBeds, site, handlePaymentMethodSelect }) => {

  // Multiplying by -1 since backend negative value in case of removed beds.
  const countOfRemovedBeds = subscriptionUpgradeInfo.extraPaidBedCountDifference * -1;

  const { data: savedPaymentMethods } = useSavedPaymentMethods(site.organizationId);

  useEffect(() => {
    if (savedPaymentMethods != null) {
      handlePaymentMethodSelect(savedPaymentMethods[0].id);
    }
  }, [savedPaymentMethods])

  return (
    <>
      <BedsDialogText>
        You have removed {countOfRemovedBeds} beds.
        Now you have {newCountOfBeds} beds.
        <br />
        {`The payment will be $${subscriptionUpgradeInfo.changedSubscriptionCost} from
         ${subscriptionUpgradeInfo.formattedNextMonthlyPaymentDate}`}
      </BedsDialogText>
      <BedsDialogWarning>The money will not return for removed beds!</BedsDialogWarning>
    </>
  )
}

export const RemovedBedsDialogContent = memo(RemovedBedsDialogContentComponent);
