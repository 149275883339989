import { DateTime } from 'luxon'
import { forEachObjIndexed, groupBy } from 'ramda'
import { DAYS_STRUCTURE } from 'pages/SiteCreate/components/IntakePeriods'
import { CreateGuestFormInfo, GuestInputType, SiteService } from 'services/types'
import { PaymentMethodCategory } from '../models/paymentMethod/PaymentMethod';

// All day means that start and end time are equal
export const ALL_DAY_DEFAULT_TIME = '00:01:00';

/**
 * Round time string.
 * @param time Time string in `XX:XX:XX` format.
 * @param roundTo To witch number to round.
 */
 const roundMinutes = (time: string, roundTo = 30): string => {
  const dateTime = DateTime.fromISO(time);

  if (!dateTime.isValid || time === ALL_DAY_DEFAULT_TIME) {
    return time;
  }

  const roundedMinute = Math.round(dateTime.minute / roundTo) * roundTo;

  return dateTime
    .set({ minute: roundedMinute })
    .toISOTime({
      includeOffset: false,
      suppressMilliseconds: true,
    });
}

export const transformIntakePeriods = (intakePeriods: any[]) => {
  // const initIntakeHours = DAYS_STRUCTURE[showWeekDays ? 'short' : 'long']
  const res = JSON.parse(JSON.stringify(DAYS_STRUCTURE));

  forEachObjIndexed((value, key) => {
    res[key] = value.map((item) => {
      const period = intakePeriods.find(
        (period) => period.timePeriod === item.timePeriod
      )

      const newPeriod = period != null ?
        {
          ...period,
          to: roundMinutes(period.to),
          from: roundMinutes(period.from),
        } :
        item

      return {
        ...newPeriod,
        enabled: Boolean(period),
      }
    })
  }, DAYS_STRUCTURE)

  return res
}

export const servicesTransformer = {
  from: (data: string) =>
    groupBy<SiteService>((item) => item.category)(JSON.parse(data)),
  to: (data: any) => data,
}

export const errorsTransform = (errors: any) => {
  const newErrors: any = {}

  forEachObjIndexed((value, key) => {
    const ff = key as string
    newErrors[ff.split('.')[0]] = value
  }, errors)

  return newErrors
}

const parseDate = (dateString: string | null | undefined) =>
  dateString ? DateTime.fromSQL(dateString) : undefined

export const guestTransformer = {
  to: (data: CreateGuestFormInfo): string => {
    const transformedData: GuestInputType = {
      ageGroup: data.ageGroup,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      phone: data.phone,
      email: data.email,
      location: data.location,
      siteId: data.siteId,
      serviceIds: [
        ...data.serviceIds,
        ...(data.typeOfClientServiceId !== '' ? [Number(data.typeOfClientServiceId)] : []),
      ],
      organizationReservationPaymentMethodId: data.paymentMethod?.id ?? null,
      insuranceCompanyId: data.paymentMethod?.category !== PaymentMethodCategory.Insurance ? null : data.insuranceCompanyId,
    }

    return JSON.stringify(transformedData)
  },
  from: (data: string) => {
    const response = JSON.parse(data)
    return {
      ...response,
      createdAt: parseDate(response.createdAt),
      acceptedAt: parseDate(response.acceptedAt),
      rejectedAt: parseDate(response.rejectedAt),
      reassignedAt: parseDate(response.reassignedAt),
      dischargedAt: parseDate(response.dischargedAt),
    }
  },
}
