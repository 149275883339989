import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

export const OrganizationContainer = styled(Link)`
  display: grid;
  grid-template-columns: 36px 1fr 36px;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 12px;
  padding: 20px;
  border: 1px solid ${theme.colors.gray};
  border-radius: 8px;
  text-decoration: none;
  color: ${theme.colors.black};
  transition: background-color 200ms linear;

  &:hover {
    background-color: ${theme.colors.lightGray};
  }
`;

export const OrganizationLogo = styled.img`
  grid-column: 1;
  grid-row: 1/3;
  width: 36px;
  height: 36px;
  border-radius: 4px;
`;

export const OrganizationName = styled.h3`
  grid-column: 2;
  grid-row: 1;
  font-size: var(--font-size-s);
  font-weight: 600;
`;

export const OrganizationCountOfSites = styled.p`
  font-size: var(--font-size-xs);
  font-weight: 500;
  grid-column: 2;
  grid-row: 2;
`;

export const OrganizationMenuButton = styled.div`
  grid-column: 3;
  grid-row: 1/3;
  width: 36px;
  height: 36px;
`

export const OrganizationMenuItem = styled(MenuItem)`
  font-size: var(--font-size-xs);
  font-weight: 600;
  min-width: 100px;
`
