import React, { FC, memo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Layout } from '../../components/Layout';
import { PageHeaderLineContainer } from '../../components/PageHeaderLineContainer/PageHeaderLineContainer';
import { TertiaryHeading } from '../../components/Typography';
import { Organization } from '../../models/organization/Organization';
import { UnderlinedHeadingLine } from '../OrganizationSiteDetails/components/SiteInformation/components/UnderlinedHeading/styled';
import { BillingTable } from './components/BillingTable/BillingTable';
import { PaymentDetailsInfoTable } from './components/PaymentDetailsInfoTable/PaymentDetailsInfoTable';
import { PaymentDetailsPageContainer } from './styled';

interface OrganizationDetailsRouteProps {

  /** ID of the organization. */
  readonly organizationId: Organization['id'];
}

const PaymentDetailsPageComponent: FC<RouteComponentProps<OrganizationDetailsRouteProps>> = ({ match }) => {

  const { organizationId } = match.params;

  return (
    <Layout withSideBar>
      <PageHeaderLineContainer>
        <h1>Payment details</h1>
      </PageHeaderLineContainer>
      <UnderlinedHeadingLine />
      <PaymentDetailsPageContainer>
        <PaymentDetailsInfoTable organizationId={organizationId} />
        <TertiaryHeading>Billing</TertiaryHeading>
        <BillingTable organizationId={organizationId} />
      </PaymentDetailsPageContainer>
    </Layout>
  )
};

export const PaymentDetailsPage = memo(PaymentDetailsPageComponent);
