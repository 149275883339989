import { useMutate } from 'hooks/api';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';

/**
 * Deletes payment method.
 * @param id ID of payment method.
 * */
export const useDeleteSavedPaymentMethod = (id: SavedPaymentMethod['id']) => {
  const { mutate } = useMutate('delete', {
    url: `/api/Subscriptions/savedPaymentMethods/${id}`,
  });

  return { handleDelete: mutate };
};
