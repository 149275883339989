import { StripeCardElementOptions } from '@stripe/stripe-js';

/**
 * Get style from css variable.
 * @param variable CSS variable.
 */
function getVar(variable: string): string {
  if (document.firstElementChild == null) {
    return '';
  }
  return getComputedStyle(document.firstElementChild).getPropertyValue(variable);
}

export const cardElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      fontSize: getVar('--font-size-md'),
      lineHeight: '1.5rem',
    },
  }
};