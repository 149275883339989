import { useFetch } from 'hooks/api';
import { PaginationMapper } from 'mappers/PaginationMapper';
import { MAP_ROLE_TO_DTO, OrganizationUserMapper } from 'mappers/UserMapper';
import { Organization } from 'models/organization/Organization';
import { Pagination } from 'services/types';
import { User, UserRole } from 'models/user/User';
import { UserDto } from 'dtos/userDto/UserDto';

export const useOrganizationUsers = (organizationId: Organization['id'], role?: UserRole, shouldFetch?: boolean) => {
  return useFetch<Pagination<User>, Pagination<UserDto>>(
    {
      url: `/api/AccessUsers/organizationScopeUsers?organizationId=${organizationId}${
        role != null ? `&Role=${MAP_ROLE_TO_DTO[role]}` : ''
      }`,
      mapper: data => PaginationMapper.fromDto(data, OrganizationUserMapper.fromDto),
    },
    shouldFetch
  );
};
