import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu } from '@mui/material';
import { useDeleteOrganization } from 'api/organizations/useDeleteOrganization';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { useOrganizationTypeHref } from 'hooks/routing/useOrganizationTypeFromHref';
import { Organization } from 'models/organization/Organization';
import React, { FC, memo, useMemo, useState } from 'react';
import { ERoute, routes } from 'router/data';
import { FirebaseService } from 'services/firebase';
import { theme } from 'styles/theme';
import { EditOrganizationForm } from '../forms/EditOrganizationForm/EditOrganizationForm';
import {
  OrganizationCountOfSites,
  OrganizationContainer,
  OrganizationLogo,
  OrganizationMenuButton,
  OrganizationMenuItem,
  OrganizationName,
} from './styled';
import { useAuth } from 'hooks/auth/useAuth';
import { useIsUserPrivileged } from 'hooks/auth/useIsUserPrivileged';

interface Props {
  /** Organization. */
  readonly organization: Organization;

  /** Revalidate organizations callback. */
  readonly revalidateOrganizations: () => Promise<boolean>;
}

const OrganizationCardComponent: FC<Props> = ({ organization, revalidateOrganizations }) => {

  const organizationsTypeHref = useOrganizationTypeHref();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const isMenuOpen = Boolean(menuAnchor);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setIsDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const { handleDelete } = useDeleteOrganization(organization.id);

  const handleDeleteButtonClick = () => {
    handleMenuClose();
    handleDeleteDialogOpen();
  };

  const handleOrganizationDelete = async () => {
    await handleDelete();
    revalidateOrganizations();
    handleDeleteDialogClose();
  };

  const handleEditButtonClick = () => {
    handleMenuClose();
    handleEditModalOpen();
  };

  const onOrganizationEditDone = () => {
    revalidateOrganizations();
    handleEditModalClose();
  };

  const handleMenuOpenButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    handleMenuOpen(event);
  }

  const isUserPrivileged = useIsUserPrivileged(organization.id);

  const countOfSites =
    organization.siteCount === 0
      ? 'No Sites Yet'
      : organization.siteCount === 1
      ? `${organization.siteCount} Site`
      : `${organization.siteCount} Sites`;

  return (
    <>
      <OrganizationContainer
        to={routes.OrganizationDetails(organization.id, organizationsTypeHref)}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <OrganizationLogo src={FirebaseService.getImageSrc(organization.logoPath)} />
        <OrganizationName>{organization.name}</OrganizationName>
        <OrganizationCountOfSites>{countOfSites}</OrganizationCountOfSites>
        <OrganizationMenuButton>
          {((isHovering || isMenuOpen) && isUserPrivileged) && (
            <IconButton
              onClick={handleMenuOpenButtonClick}
              type="button"
            >
              <MoreVert />
            </IconButton>
          )}
        </OrganizationMenuButton>
      </OrganizationContainer>
      {
        isUserPrivileged && (
          <>
            <Menu open={isMenuOpen} onClose={handleMenuClose} anchorEl={menuAnchor}>
              <OrganizationMenuItem onClick={handleEditButtonClick} sx={{ color: theme.colors.blue }}>
                Edit
              </OrganizationMenuItem>
              <OrganizationMenuItem onClick={handleDeleteButtonClick} sx={{ color: theme.colors.red }}>
                Delete
              </OrganizationMenuItem>
            </Menu>
            <ConfirmDialog
              isOpened={isDeleteDialogOpen}
              onConfirmationButtonClick={handleOrganizationDelete}
              onClose={handleDeleteDialogClose}
              title={`Are you sure you want to Delete ${organization.name}`}
              confirmButtonText="Delete Organization"
              confirmButtonColor="error"
              cancelButtonText="Cancel"
            >
              All Sites of {organization.name} organization will be deleted too. You will not be able to undo this action.
            </ConfirmDialog>
            <ModalWindow isOpen={isEditModalOpen} isScrollable onClose={handleEditModalClose}>
              <EditOrganizationForm
                organization={organization}
                onCancel={handleEditModalClose}
                onDone={onOrganizationEditDone}
              />
            </ModalWindow>
          </>
        )
      }
    </>
  );
};

export const OrganizationCard = memo(OrganizationCardComponent);
