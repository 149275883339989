import styled from '@emotion/styled'
import React, { FC } from 'react'
import { MediumText } from 'components/Typography'

const Container = styled.div`
  display: flex;
  padding-inline: 10px;
  align-items: center;
  word-break: break-word;
`;

const MediumText500 = styled(MediumText)`
  font-weight: 500;
`;

export interface IRowCellProps {
  text: string
  strong?: boolean
}

export const RowCell: FC<IRowCellProps> = ({ text, strong }) => (
  <Container>
    {strong ? (
      <MediumText>{text}</MediumText>
    ) : (
      <MediumText500>{text}</MediumText500>
    )}
  </Container>
)
