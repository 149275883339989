import { AxiosRequestConfig } from 'axios'
import { authStorage } from 'services/auth/storage'

export const provideAuthToken = (
  requestConfig: AxiosRequestConfig
): AxiosRequestConfig => {
  requestConfig.headers.Authorization = `Bearer ${
    authStorage.getAccessToken() ?? authStorage.getPreTwoFactorAccessToken()
  }`

  return requestConfig
}
