import { CameraAltRounded } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import React, { FC } from 'react';
import { FirebaseService } from 'services/firebase';
import { CommonDialogProps } from '../CommonDialogProps';
import { PreviewedImage } from './styled';

interface Props extends CommonDialogProps {
  /** Image source. */
  readonly source?: string;
}

/** Image preview dialog. */
export const ImagePreviewDialog: FC<Props> = ({
  source,
  isOpened,
  onClose,
}) => {
  return (
    <Dialog open={isOpened} onClose={onClose}>
      {source != null && source !== '' ? (
        <PreviewedImage src={FirebaseService.getImageSrc(source)} alt="" />
      ) : (
        <CameraAltRounded />
      )}
    </Dialog>
  );
};
