import styled from '@emotion/styled';

export const AddCardFormContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AddCardFormTitle = styled.h2`
  text-align: left;
  font-size: var(--font-size-2xlg);
  font-weight: 600;
`;
