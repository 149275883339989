import { ValidationError } from 'components/ValidationError/ValidationError';
import { RowView } from 'components/Layout/Grid';
import { MainHeading } from 'components/Typography';
import { FieldArray, FieldArrayRenderProps, FormikErrors, useFormikContext } from 'formik';
import { SiteFormBlockProps } from 'pages/SiteEdit';
import React, { FC, useEffect, useRef, useState } from 'react';
import { RoomCard } from './components/RoomCard/RoomCard';
import { Room } from './domain/Room';
import { RoomsActionButtons, RoomsListContainer, RoomsPlaceholder, RoomsListButton, PrintHeaderContainer } from './styled';
import { SiteEditingValues } from 'models/site/SiteEditingValues';
import { ButtonIcon } from 'components/ButtonIcon/styled';
import { SiteTabHeading } from 'pages/OrganizationSiteDetails/components/SiteTabHeading/SiteTabHeading';
import { sortEntities } from 'utils/sortEntities';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { Site } from 'models/site/Site';
import { OrganizationSiteHeader } from 'components/OrganizationSiteHeader/OrganizationSiteHeader';

/**
 * Gets beds error from the room.
 * @param errors Formik errors.
 * @param values Formik values.
 * @param currentRoom Room to get error for.
 * @returns Error message of undefined if there is no error.
 */
function getRoomError(errors: FormikErrors<SiteEditingValues>, values: SiteEditingValues, currentRoom: Room): string | undefined {
  if (errors.rooms == null) {
    return undefined;
  }

  const index = values.rooms.findIndex(room => room.id === currentRoom.id);

  if (Array.isArray(errors.rooms) && errors.rooms[index]) {
    const err = errors.rooms[index];
    if (typeof err === 'string') {
      return undefined;
    }
    return err.beds?.toString();
  }
  return undefined;
}

interface Props extends SiteFormBlockProps {

  /** Callback triggers on count of beds change. */
  readonly onCountOfBedsChange?: (values: SiteEditingValues) => void;

  /** Whether the rooms or beds can be edited. */
  readonly isEditable?: boolean;

  /** Whether the assign buttons are hidden */
  readonly areAssignButtonsHidden?: boolean;

  /** Site. */
  readonly site?: Site;

  /** Whether its possible to print rooms report. */
  readonly isPrintable?: boolean;
};

/** Rooms list form. */
export const RoomsList: FC<Props> = ({
  withoutMainHeader,
  onCountOfBedsChange,
  isEditable = true,
  areAssignButtonsHidden = false,
  site,
  isPrintable = false,
}) => {

  const roomsListRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => roomsListRef.current,
    documentTitle: site?.name ?? 'Rooms report',

    // Hiding page header for printing.
    pageStyle: `
      @page {
        margin-top: 0;
      }

      @media print {
        body {
          -webkit-print-color-adjust: exact;
          color-adjust: exact;
        }
      }
    `,
  });

  const [isEditingRoom, setIsEditingRoom] = useState(false);

  /** Form values. */
  const { values, errors, setFieldTouched, setFieldValue } = useFormikContext<SiteEditingValues>();

  /** Whether any rooms are presented or not. */
  const hasRooms = values.rooms.length > 0;

  /** Number of rooms in service. */
  const numberOfRooms = values.rooms.length;

  const [previousNumberOfRooms, setPreviousNumberOfRooms] = useState(values.rooms.length);

  /** Rooms placeholder. */
  const roomsPlaceholder = <RoomsPlaceholder>No Rooms</RoomsPlaceholder>;

  /** Get name for new room. */
  const getNameForNewRoom = () => {
    const roomNumber = values.rooms.length + 1;
    return `House #${roomNumber}`;
  };

  useEffect(() => {
    onCountOfBedsChange != null && onCountOfBedsChange(values);
  }, [values, onCountOfBedsChange]);

  useEffect(() => {
    if (previousNumberOfRooms < numberOfRooms) {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    }
    setPreviousNumberOfRooms(numberOfRooms);
  }, [numberOfRooms]);

  const handleNewHouseButtonClick = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push(Room.generateEmpty(getNameForNewRoom()));
    setFieldTouched('rooms', true);
  }

  useEffect(() => {
    if (!isEditingRoom) {
      setFieldValue('rooms', sortEntities(values.rooms, 'name'));
    }
  }, [isEditingRoom]);

  return (
    <RoomsListContainer ref={roomsListRef}>
      {!withoutMainHeader && <MainHeading>Okay, now add rooms to comfortably distribute your guests</MainHeading>}
      {site != null && isPrintable && <PrintHeaderContainer><OrganizationSiteHeader site={site} /></PrintHeaderContainer>}
      <FieldArray
        name="rooms"
        render={(arrayHelpers) => (
          <>
            <RowView marginBottom="40px" justifyContent="space-between" alignItems="center">
              <SiteTabHeading headingText="Houses" count={numberOfRooms} />
              <RoomsActionButtons>
                {isPrintable && <RoomsListButton onClick={handlePrint} type="button">
                  <PrintIcon sx={{ marginRight: '5px' }} />
                  Print Report
                </RoomsListButton>}
                {isEditable && <RoomsListButton
                  onClick={() => handleNewHouseButtonClick(arrayHelpers)}
                  type="button"
                >
                  <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
                  New House
                </RoomsListButton>}
              </RoomsActionButtons>
            </RowView>
            {!hasRooms
              ? roomsPlaceholder
              : values.rooms.map((room, index) => {
                  const roomError = getRoomError(errors, values, room);
                  return (
                    <RoomCard
                      key={`${room}0${index}-${room.id}`}
                      room={room}
                      isEditModeAvailable={isEditable}
                      onRoomDeletion={() => arrayHelpers.remove(index)}
                      errorMessage={roomError}
                      areAssignButtonsHidden={areAssignButtonsHidden}
                      onEditingModeChange={setIsEditingRoom}
                    />
                  );
                })}
            <RowView justifyContent="center">
              {isEditable && <RoomsListButton
                onClick={() => handleNewHouseButtonClick(arrayHelpers)}
                type="button"
              >
                <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
                New House
              </RoomsListButton>}
            </RowView>
            {!Array.isArray(errors.rooms) && <ValidationError message={errors.rooms?.toString()} isCentered />}
          </>
        )}
      />
    </RoomsListContainer>
  );
};
