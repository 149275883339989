import { AxiosError } from 'axios'
import { history } from 'browserHistory'
import { authStorage } from 'services/auth/storage'

export const handleUnauthorizedError = (error: AxiosError) => {
  if (error.response?.status === 401) {
    authStorage.clearLoginData()
    history.push('/login')
    return Promise.reject();
  } else {
    throw error
  }
}
