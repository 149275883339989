import { TableHeader } from './../../pages/Reservation/Components/GuestsTable/styled';
import styled from '@emotion/styled';
import { TableCell, TableContainer, TableRow } from '@mui/material';
import { theme } from 'styles/theme';
import * as CSS from 'csstype';

export const PaymentMethodsTableContainer = styled(TableContainer)`
  overflow-y: auto;
  max-height: 70%;
  width: 100%;
`;

interface RowProps {

  /** Whether is hovering the row. */
  readonly isHovering: boolean;
};

export const PaymentMethodsTableRow = styled(TableRow)<RowProps>`
  background-color: ${props => props.isHovering ? theme.colors.lightGray : theme.colors.white};
  word-break: break-word;
`;

interface CellProps {

  readonly textAlign?: CSS.Property.TextAlign;
}

export const PaymentMethodsTableHeaderCell = styled(TableHeader)<CellProps>`
  text-align: ${props => props.textAlign};
`

export const PaymentMethodsTableCell = styled(TableCell)<CellProps>`
  color: ${theme.colors.black};
  font-weight: 500;
  padding: 10px 0 10px;
  height: 64px;
  text-align: ${props => props.textAlign};
`;

export const ButtonTableCell = styled(TableCell)`
  padding: 0;
  height: 64px;
  width: 50px;
`
