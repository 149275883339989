import { object, string } from 'yup'

export const initialValues = {
  code: '',
}

export const validationSchema = object().shape({
  code: string().required('Please enter the confirmation code'),
})

export type IFormData = typeof initialValues
