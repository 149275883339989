import styled from '@emotion/styled';

export const TariffCheckboxTitle = styled.h3`
  font-size: var(--font-size-lg);
  font-weight: 500;
`;

export const TariffCheckboxSubtitle = styled.p`
  font-size: var(--font-size-md);
  font-weight: 500;
`;

export const TariffCheckboxDetailsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  gap: 0 8px;
  flex-wrap: wrap;
  font-size: var(--font-size-md);
`;
