import {useContext, useEffect} from 'react'
import { AuthContext } from 'hooks/auth/Context'

export const useAuth = (shouldRefetchUser = false) => {
  const auth = useContext(AuthContext)

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    if (shouldRefetchUser) {
      auth.actions.refetchUserInfo();
    }
  }, [])

  return auth
}
