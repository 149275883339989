import styled from '@emotion/styled'

export const CheckboxWrap = styled.div`
  width: 150px;
`

export const FieldsWrap = styled.div`
  display: flex;
  flex: 1;
`
