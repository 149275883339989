import { Formik } from 'formik';
import React, { FC } from 'react';
import { CancelLink } from 'components/CancelLink';
import { ErrorTooltip } from 'components/ErrorTooltip';
import { InputField } from 'components/InputField';
import { ThemedButton } from 'components/ThemedButton';
import { initialValues, validationSchema } from 'forms/CreatePassword/data';
import { Props } from 'forms/CreatePassword/types';
import {
  Footer,
  FormHeading,
  StyledForm,
  FormText,
  Center,
  Header,
  Content,
} from 'forms/SignIn/styled';
import { ERoute } from 'router/data';

export const CreatePasswordForm: FC<Props> = ({
  handleSubmit,
  isLoading,
  error,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
  >
    {({ submitForm }) => (
      <StyledForm>
        <Header>
          <CancelLink to={ERoute.LOGIN} label="Back" />
          <FormHeading>Create a new password</FormHeading>
          <FormText>
            Please create a new password to access your account.
          </FormText>
        </Header>

        <Center>
          <Content>
            <InputField
              name="password"
              label="Your new password"
              type="password"
              placeholder="••••••"
            />
            {error && <ErrorTooltip isOpen text={error} isRight centered />}
            <InputField
              name="passwordConfirmation"
              label="Confirm new password"
              type="password"
              placeholder="••••••"
            />
          </Content>
        </Center>

        <Footer>
          <ThemedButton
            disabled={isLoading}
            type="submit"
            width="100%"
            onClick={submitForm}
          >
            Save
          </ThemedButton>
        </Footer>
      </StyledForm>
    )}
  </Formik>
);
