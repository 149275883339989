import { DateTime } from 'luxon'
import React, { FC } from 'react'
import { UniversalSelectFields } from 'components/UniversalSelectFields'
import { ALL_DAY_DEFAULT_TIME } from 'services/transformers';

interface Props {

  /** Name. */
  readonly name: string;

  /** Label. */
  readonly label?: string;

  /** Placeholder. */
  readonly placeholder?: string;

  /** Whether the field is disabled. */
  readonly disabled?: boolean;

  /** Max value. */
  readonly maxValue?: string;

  /** Min value. */
  readonly minValue?: string;

  /** Default value. */
  readonly defaultValue?: string;

  /** Whether the all day option should be presented. */
  readonly withAllDayOption?: boolean;
}

const prepareOptions = (min?: string, max?: string, withAllDay?: boolean) => {
  const options: any[] = []

  if (withAllDay) {
    const timeObj = DateTime.fromObject({ hour: 0, minute: 1 })
    let value = timeObj.toFormat('HH:mm:ss')
    let label = 'All Day'
    options.push({ value, label })
  }

  const minObj = min != null ? DateTime.fromISO(min) : undefined
  const maxObj = max != null && max !== ALL_DAY_DEFAULT_TIME ? DateTime.fromISO(max).plus({
    days: (DateTime.fromISO(max).hour === 0 && DateTime.fromISO(max).minute === 0) ? 1 : 0,
  }) : undefined

  for (let i = 0; i < 49; i += 1) {
    let label
    let timeObj

    if (i % 2 === 0) {
      // EVEN - o'clock
      timeObj = DateTime.fromObject({ hour: i / 2, minute: 0 })
      label = timeObj.toFormat('h a').toLowerCase()
    } else {
      // ODD - half hour
      timeObj = DateTime.fromObject({ hour: Math.floor(i / 2), minute: 30 })
      label = timeObj.toFormat('h:mm a').toLowerCase()
    }

    if (minObj && minObj >= timeObj) {
      continue
    }

    if (maxObj && maxObj <= timeObj) {
      break
    }

    const value = timeObj.toFormat('HH:mm:ss')
    options.push({ value, label })

    // const timeObj = DateTime.fromObject({ hour: i, minute: 0 })
    // let label = timeObj.toFormat('h a').toLowerCase()
    // let value = timeObj.toFormat('HH:mm:ss')
    // options.push({ value, label })

    // const nextTimeObj = timeObj.plus({ minutes: 30 })

    // if (minObj && minObj > nextTimeObj) {
    //   continue
    // }

    // if (maxObj && maxObj <= nextTimeObj) {
    //   break
    // }

    // label = nextTimeObj.toFormat('h:mm a').toLowerCase()
    // value = nextTimeObj.toFormat('HH:mm:ss')
    // options.push({ value, label })
  }

  return options
}

const getDefault = (defaultValue: string, options: any[]) => {
  if (defaultValue && options.length) {
    return options.findIndex(x => x.value == defaultValue)
  }
  return undefined;

}

export const TimePickerField: FC<Props> = ({
  minValue,
  maxValue,
  defaultValue,
  withAllDayOption,
  ...props
}) => {
  const options = prepareOptions(minValue, maxValue, withAllDayOption);
  const defaultVal = defaultValue ? getDefault(defaultValue, options) : undefined;


  return <UniversalSelectFields {...props} defaultIndex={defaultVal} options={options} />
}
