import React, { FC } from 'react';
import { IGuestType } from 'api/guests/types';
import { Badge } from 'components/Badge';
import {
  BedBlockView,
  BedPhoto,
  ContentWrap,
  PhotoPlaceholder,
} from 'components/BedsBlock/styled';
import { GuestCell } from 'components/GuestCell';
import { Gap, RowView } from 'components/Layout/Grid';
import { MainHeading, SecondaryHeading } from 'components/Typography';
import { FirebaseService } from 'services/firebase';
import { assertNonNull } from 'utils/assertNonNull';
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';

export interface Props {
  beds: Bed[];
  onBedSelect?: (id: Bed['id']) => void;
  withoutGuestCell?: boolean;
  onGuestSelect?: (guestType: IGuestType) => void;
  maxDays?: number;
}

export const BedsBlock: FC<Props> = ({
  beds,
  withoutGuestCell,
  onBedSelect,
  onGuestSelect,
  maxDays,
}) => {
  const selectVacantBeds = (beds: Bed[]) => beds.filter((bed) => !bed.guest);

  return (
    <ContentWrap>
      <RowView mb={20}>
        <SecondaryHeading withoutMargin>Beds</SecondaryHeading>
        <Gap size="sm" />
        <Badge
          small
          title={`${selectVacantBeds(beds).length} vacant`}
          color="green"
        />
      </RowView>

      <RowView flexWrap="wrap" alignItems="flex-start">
        {beds.map((bed, index) => (
          <BedBlockView
            key={bed.id}
            last={index === beds.length + 1}
            clickable={Boolean(onBedSelect)}
            onClick={() => {
              assertNonNull(bed.id);
              onBedSelect?.(bed?.id);
            }}
          >
            <MainHeading withoutMargin small>{`#${bed.index}`}</MainHeading>

            {bed.imagePath ? (
              <BedPhoto src={FirebaseService.getImageSrc(bed.imagePath)} />
            ) : (
              <PhotoPlaceholder>
                <img src="/assets/icons/bed.svg" />
              </PhotoPlaceholder>
            )}

            {!withoutGuestCell && (
              <GuestCell
                maxDays={maxDays}
                guest={bed.guest}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                onClick={() => onGuestSelect?.(bed.guest!)}
              />
            )}
          </BedBlockView>
        ))}
      </RowView>
    </ContentWrap>
  );
};
