import { Reducer, useCallback, useReducer } from 'react';
import { SortDirection } from './types/SortingDirection';

/** Sort params. */
export interface SortParams<T extends string> {

  /** Sort column name. */
  readonly columnName: T;

  /** Sort direction. */
  readonly direction: SortDirection;
}

/** Sort state action. */
interface SortAction<T extends string> {

  /** Type. */
  readonly type: 'FieldChange' | 'DirectionChange';

  /** Payload. */
  readonly payload: T;
}

/**
 * Guests table sort params reducer.
 * @param state State.
 * @param action Action.
 */
function sortParamsReducer<T extends string>(state: SortParams<T>, action: SortAction<T>): SortParams<T> {
  const { type, payload } = action;
  switch (type) {
    case 'FieldChange':
      return {
        columnName: payload,
        direction: 'asc',
      };
    case 'DirectionChange':
      return {
        ...state,
        direction: state.direction === 'asc' ? 'desc' : 'asc',
      };
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
}

interface SortParamsReturn<T extends string> {

  /** Sort params. */
  readonly sortParams: SortParams<T>;

  /** Handles sort change. */
  readonly handleSortChange: (columnName: T) => void;
}

/**
 * Get sort params for table.
 * @param initialSortParams Initial sort params.
 */
export const useTableSortParams = <T extends string>(initialSortParams: SortParams<T>): SortParamsReturn<T> => {
  const [sortParams, dispatch] = useReducer<Reducer<SortParams<T>, SortAction<T>>>(sortParamsReducer, initialSortParams);

  const handleSortChange = useCallback((columnName: T) => {
    dispatch({
      type: columnName === sortParams.columnName ? 'DirectionChange' : 'FieldChange',
      payload: columnName,
    })
  }, [sortParams, dispatch]);

  return {
    sortParams,
    handleSortChange
  }
}
