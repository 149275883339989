import { Button, ButtonTypeMap, ExtendButtonBase, styled } from '@mui/material';
import { theme } from 'styles/theme';

interface Props {
  /** Width of the button. */
  readonly width?: string;

  /** Height of the button. */
  readonly height?: string;
}

/** Styled button type is needed in order not to lose some properties of mui button. */
export type StyledButtonType = ExtendButtonBase<ButtonTypeMap<Props, 'button'>>;

export const StyledButton = styled(Button)<Props>`
  font-family: 'Inter';
  text-transform: none;
  border: 1px solid ${theme.colors.grayBorderColor};
  border-radius: 8px;
  font-size: 0.75rem;
  font-weight: 600;
  width: ${(props) => (props.width != null ? props.width : undefined)};
  height: ${(props) => (props.height != null ? props.height : undefined)};
  padding-block: 0.625rem;
` as StyledButtonType;
