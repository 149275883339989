import React, { useEffect, useState } from 'react';
import { CancelLink } from 'components/CancelLink';
import { ChangeUserEmail } from 'components/ChangeUserEmail';
import { ChangeUserPassword } from 'components/ChangeUserPassword';
import { ChangeUserSettingsPhone } from 'components/ChangeUserSettingsPhone';
import { Layout } from 'components/Layout';
import { OuterWrap } from 'components/Layout/styled';
import { Margin } from 'components/Spacing';
import { ThemedButton } from 'components/ThemedButton';
import { MainHeading, TertiaryHeading } from 'components/Typography';
import { FormHeading, Header } from 'forms/SignIn/styled';
import { useAuth } from 'hooks/auth/useAuth';
import {
  FormContentWrap,
  FormWrap,
  SideBarWrap,
} from 'pages/SiteCreate/styled';
import { DeleteUserModal } from 'pages/UserSettings/DeleteUserModal';
import { ERoute } from 'router/data';
import { CurrentSubscriptionsBlock } from '../../components/CurrentSubscriptionBlock/CurrentSubscriptionBlock';
import { UnderlinedHeading } from 'pages/OrganizationSiteDetails/components/SiteInformation/components/UnderlinedHeading/UnderlinedHeading';
import { UserContactInformation, UserSettingsContainer } from './styled';
import { StyledButton } from 'components/StyledButton/styled';

export const UserSettingsPage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    actions: { refetchUserInfo },
  } = useAuth();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    refetchUserInfo();
  }, []);

  return (
    <OuterWrap>
      <Layout>
        <FormWrap>
          <SideBarWrap>
            <CancelLink to={ERoute.HOMEPAGE} label="Back" />
          </SideBarWrap>
          <UserSettingsContainer>
            <Header>
              <MainHeading withoutMargin>User settings</MainHeading>
            </Header>
            <div>
              <UnderlinedHeading text="Contact Information" />
              <UserContactInformation>
                <ChangeUserEmail />
                <ChangeUserSettingsPhone />
              </UserContactInformation>
            </div>
            <CurrentSubscriptionsBlock/>
            <ChangeUserPassword />
            <div>
              <UnderlinedHeading text="Profile" />
              <StyledButton
                type="button"
                color="error"
                variant="outlined"
                onClick={() => setModalOpen(true)}
              >
                Delete profile
              </StyledButton>
            </div>
          </UserSettingsContainer>
        </FormWrap>
      </Layout>

      <DeleteUserModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
      />
    </OuterWrap>
  );
};
