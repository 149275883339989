import React, { FC, memo } from 'react'
import { ErrorMessageText } from './styled';

interface Props {

  /** Message. */
  readonly message: string;
}

const ErrorMessageComponent: FC<Props> = ({ message }) => {

  return (
    <ErrorMessageText>{message}</ErrorMessageText>
  )
}

export const ErrorMessage = memo(ErrorMessageComponent);
