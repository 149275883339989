import styled from '@emotion/styled';

export const McMillenThanksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
`;

export const McMillenLogo = styled.img`
  max-width: 150px;
`;
