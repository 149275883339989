import { IUserData } from 'hooks/auth/types';
import { Organization } from 'models/organization/Organization';
import { StrictOmit } from 'utils/types/strictOmit';

interface UserInfo {

  /** Id. */
  readonly id: string;

  /** Email. */
  readonly email: string;
}

/** User membership status. */
export enum UserMembershipStatus {
  PendingInvitation = 'Invited',
  Active = 'Active',
  Disabled = 'Disabled',
}

/** User role. */
export enum UserRole {
  Admin = 'Admin',
  Staff = 'Staff',
  Intake = 'Intake',
  GlobalAdmin = 'GlobalAdmin',
  Owner = 'Owner',
}

type UserCreationData = StrictOmit<
  User,
  'isGlobalAdmin' | 'isCurrentUser' | 'isAdmin' | 'isOwner'
>;

/** User. */
export class User {
  /** Id. */
  public readonly id: number;

  // TODO (Maxim K.): Remove null when backend fix the issue with missing organizations.
  /** Organization. */
  public readonly organization: Organization | null;

  /** Status. */
  public readonly membershipStatus: UserMembershipStatus;

  /** Role. */
  public readonly role: UserRole;

  /** User info. */
  public readonly userInfo: UserInfo;

  /** Creation date. */
  public readonly createdAt: Date;

  /** Site ID. */
  public readonly siteId: number | null;

  /** Site name. */
  public readonly siteName: string | null;

  public constructor(data: UserCreationData) {
    this.id = data.id;
    this.organization = data.organization;
    this.membershipStatus = data.membershipStatus;
    this.role = data.role;
    this.userInfo = data.userInfo;
    this.createdAt = data.createdAt;
    this.siteId = data.siteId;
    this.siteName = data.siteName;
  }

  /** Whether the user is global admin. */
  public isGlobalAdmin(): boolean {
    return this.role === UserRole.GlobalAdmin;
  }

  /** Whether the user is admin. */
  public isAdmin(): boolean {
    return this.role === UserRole.Admin;
  }

  /** Whether the user is owner of organization. */
  public isOwner(): boolean {
    return this.role === UserRole.Owner;
  }

  /** Whether the user is currently active user. */
  public isCurrentUser(currentUserId: IUserData['userId']): boolean {
    return this.userInfo.id === currentUserId;
  }
}
