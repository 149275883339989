import { Method } from 'axios'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'
import { IConfig, IFetchOptions, IMutateOptions } from 'hooks/api/types'
import { apiClient } from 'services/api/apiClient'
import { getAuthHeaders } from 'services/auth/helpers'

const createFetcher = <TResponseData, TResponseDataDto, TRequestData>(
  options: IConfig<TResponseData, TResponseDataDto, TRequestData>
) => () =>
  apiClient
    .request({
      url: options.url,
      data: options.data,
      method: options.method,
      transformResponse: options.responseTransformer,
      transformRequest: options.requestTransformer,
      headers: {
        ...options.headers,
        ...getAuthHeaders(),
      },
    })
    .then((response: any) => { // Using any since we are using interceptor to only get data from AxiosResponse.

      const mappedData = options.mapper != null ? options.mapper(response) : response as TResponseData;

      if (options.onSuccess) {
        options.onSuccess(mappedData)
      }

      if (options.onFinish) {
        options.onFinish()
      }

      return mappedData;
    })
    .catch((error) => {
      // the new API returns errors in different format
      const errorData = error?.response?.data;
      const errorMessage = errorData?.message ?? errorData?.error;
      if (options.onError && errorMessage != null) {
        options.onError(errorMessage);
      }

      if (options.onFinish) {
        options.onFinish()
      }

      if (errorMessage != null) {
        throw new Error(errorMessage);
      }

      throw new Error(error.message);
    })

export const useFetch = <TResponseData, TResponseDataDto, TData = undefined>(
  options: IFetchOptions<TResponseData, TResponseDataDto, TData>,
  shouldFetch = true,
) =>
  useSWR<TResponseData>(
    shouldFetch ? options.url : null,
    createFetcher({ ...options, method: options.method ?? 'GET' })
  )

export const useMutate = <TResponseData, TResponseDataDto, TRequestData>(
  method: Method,
  options: IMutateOptions<TResponseData, TResponseDataDto, TRequestData>,
  onSuccess?: (response: TResponseData, request?: TRequestData) => void
) => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()
  const history = useHistory()

  const callback = useCallback(
    (data?: TRequestData, handleError?: (error: any) => void) => {
      setIsLoading(true)
      setError(undefined);
      return createFetcher<TResponseData, TResponseDataDto, TRequestData>({
        ...options,
        method,
        data,
        onSuccess: (response) => {
          if (options.redirectUri) {
            history.push(options.redirectUri)
          }

          if (onSuccess) {
            onSuccess(response, data)
          }
        },
        onFinish: () => {
          setIsLoading(false)

          if (options.onFinish) {
            options.onFinish()
          }
        },
        onError: (error) => {
          setError(error)
          setIsLoading(false)

          // TODO: remove because error should be catched outside
          if (handleError) {
            handleError(error)
          }

          throw error
        },
      })()
    },
    [options.url]
  )

  return { mutate: callback, isLoading, error }
}
