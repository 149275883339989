import { useMutate } from 'hooks/api';
import { InsuranceCompany } from '../../models/insuranceCompany/InsuranceCompany';

/**
 * Insurance company delete hook.
 * @param id Id of the company.
 */
export const useDeleteInsuranceCompany = (id: InsuranceCompany['id']) => {
  const { mutate } = useMutate('delete', {
    url: `/api/InsuranceCompanies/${id}`,
  })

  return { handleDelete: mutate }
}
