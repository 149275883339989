import styled from '@emotion/styled'
import { flexbox, FlexboxProps, space, SpaceProps } from 'styled-system'

export const RowView = styled.div<FlexboxProps & SpaceProps>`
  ${space};
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  ${flexbox};
`

export const BlockView = styled.div<FlexboxProps & SpaceProps>`
  ${flexbox}
  ${space}
  display: flex;
  flex-direction: column;
`

interface IGapProps {
  size?: 'sm' | 'md' | 'lg'
  direction?: 'horizontal' | 'vertical'
}

const GAP_SIZES = {
  sm: 10,
  md: 20,
  lg: 30,
}

export const Gap = styled.div<IGapProps>`
  width: ${({ direction = 'horizontal', size = 'md' }) =>
    direction === 'horizontal' ? GAP_SIZES[size] : 0}px;
  height: ${({ direction = 'horizontal', size = 'md' }) =>
    direction === 'horizontal' ? 0 : GAP_SIZES[size]}px;
`
