import styled from '@emotion/styled';
import { Select } from '@mui/material';
import { theme } from '../../styles/theme';

export const PaginationSelectContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--font-size-xs);
  color: ${theme.colors.semiLightGray};
`;

export const PaginationSelectInput = styled(Select)`
  font-family: inherit;
  font-size: var(--font-size-xs);
  font-weight: 600;
  max-height: 36px;
  color: ${theme.colors.semiLightBlue};
  border: 1px solid ${theme.colors.veryLightGray};
  border-radius: 8px;

  & > .MuiSelect-icon {
    color: ${theme.colors.semiLightBlue};
  }

  & > fieldset {
    border: none;
  }
` as unknown as typeof Select;
