import React, { FC } from 'react'
import { SpaceProps } from 'styled-system'
import { ITableColumn } from 'components/TableComponent'
import { SortButtons } from 'components/TableComponent/Header/SortButtons'
import {
  Container,
  HeaderCell,
  Text,
} from 'components/TableComponent/Header/styled'

export interface IHeaderProps {
  columns: ITableColumn[]
}

export const Header: FC<IHeaderProps & SpaceProps> = ({
  columns,
  ...props
}) => (
  <Container columns={columns} {...props}>
    {columns.map((column) => (
      <HeaderCell className='table-header' key={column.name}>
        <Text>{column.name}</Text>
        {/*{column.sortable && (*/}
        {/*  <SortButtons*/}
        {/*    ml="17px"*/}
        {/*    onAscendantSort={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function*/}
        {/*    onDescendantSort={() => {}} // eslint-disable-line @typescript-eslint/no-empty-function*/}
        {/*  />*/}
        {/*)}*/}
      </HeaderCell>
    ))}
  </Container>
)
