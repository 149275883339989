import React, { FC } from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps, useParams } from 'react-router-dom';
import { useAuth } from 'hooks/auth/useAuth';
import { ERoute } from 'router/data';

interface Props {

  /** Page component of the route. */
  readonly component: RouteProps['component'];

  /** Path of the route. */
  readonly path: ERoute;
}

interface RouteParams {
  // Should be optional because it is not presented on every route.
  /** Organization id. */
  readonly organizationId?: string;
}

export const RoleOwnerAndGlobalRoute: FC<Props> = ({ component, path }) => {
  const { organizationId } = useParams<RouteParams>();
  const {
    userData,
    isAuthorized,
  } = useAuth().state;

  if (!isAuthorized) {
    return <Redirect to={ERoute.HOMEPAGE} />;
  }

  if (!userData.roleGlobal && userData.roleOwnerOrganizationIds.length === 0) {
    return <Redirect to={ERoute.HOMEPAGE} />;
  }

  if (organizationId != null && !userData.roleOwnerOrganizationIds.includes(organizationId)) {
    return <Redirect to={ERoute.HOMEPAGE} />;
  }

  return <Route path={path} component={component} />;
};
