import styled from '@emotion/styled';
import { theme } from '../../styles/theme';

export const ResubscriptionFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ResubscriptionFormButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 10px;
  gap: 20px;
`;

export const ResubscriptionFormErrorMessage = styled.p`
  font-size: var(--font-size-md);
  color: ${theme.colors.red};
`;
