import React, { FC, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { RowView } from 'components/Layout/Grid';
import { AddPhotoButton, MultiPreviewContainer } from 'components/UploadPhoto/styled';
import { FirebaseService } from 'services/firebase';
import { Loader } from 'components/Loader/Loader';
import { Image } from 'models/image/Image.';
import { sortEntities } from 'utils/sortEntities';
import { PhotoPreview } from './PhotoPreview';

interface Props {

  /** Init image. */
  readonly initialImages: readonly Image[];

  /** Image path prefix. */
  readonly prefix: string;

  /** On change callback. */
  readonly onChange: (val: readonly Image[]) => void;
}

export const MultiPhotoUpload: FC<Props> = ({ initialImages, prefix, onChange }) => {

  const [images, setImages] = useState<ImageListType>([]);
  const [isLoading, setIsLoading] = useState(false);

  const maxNumber = 5;
  const handleOnChange = async(imageList: ImageListType, index: number[] | undefined) => {
    if (!index) {
      return;
    }
    setIsLoading(true);
    imageList = imageList.slice(index[0]);
    // data for submit
    const paths = await FirebaseService.uploadImages(
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      imageList.map((image) => image.file!),
    );

    const maxImageIndex = Image.getFirstOrLastImage(initialImages, 'last')?.index ?? 0;

    const newImages = paths.map((path, index) => new Image({
      imagePath: path,
      index: maxImageIndex + index + 1,
    }))

    paths && onChange([...initialImages, ...newImages]);
    setImages(imageList as never[]);
    setIsLoading(false);
  };

  return (
    <ImageUploading multiple value={images} onChange={handleOnChange} maxNumber={maxNumber} dataURLKey="data_url">
      {({ onImageUpload, onImageRemove }) => (
        <RowView marginY={15} flexWrap="wrap">
          {sortEntities(initialImages, 'index').map(image => (
            <PhotoPreview
              initialImages={initialImages}
              url={image.imagePath}
              index={image.index}
              onChange={onChange}
              onImageRemove={onImageRemove}
              key={image.imagePath}
            />
          ))}
          <MultiPreviewContainer>
            <AddPhotoButton type="button" onClick={onImageUpload} disabled={isLoading}>
              {isLoading ? <Loader size="medium" /> : 'Add Photo'}
            </AddPhotoButton>
          </MultiPreviewContainer>
        </RowView>
      )}
    </ImageUploading>
  );
};
