import styled from '@emotion/styled'

export const InputWrap = styled.div`
  position: relative;
  width: 300px;
`

export const ControlsContainer = styled.div`
  display: flex;
  width: 300px;
  position: relative;
`
