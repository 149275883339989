import CSS from 'csstype'
import React from 'react'
import { EGuestStatus, IGuestType } from 'api/guests/types'
import { GenderCell } from 'components/TableComponent/RowRenderers/CommonComponents/GenderCell'
import { RowCell } from 'components/TableComponent/RowRenderers/CommonComponents/RowCell'
import { Grid } from 'components/TableComponent/RowRenderers/guestHistoryRow/styled'
import { formatDateToMDY } from '../../../../utils/formatDateToMDY'

export const generateGuestHistoryRow = (
  guest: IGuestType,
  guestStatus: EGuestStatus,
  columnsTemplate?: CSS.Property.GridTemplateColumns,
  onSelectGuest?: (guest: IGuestType) => void,
) => {
  const timeIn = guest.acceptedAt  != null ? formatDateToMDY(new Date(guest.acceptedAt)) : '-';
  const timeOut = guest.dischargedAt  != null ? formatDateToMDY(new Date(guest.dischargedAt)) : '-';

  const isRowClickable = onSelectGuest != null;

  return (
    <Grid
      columnsTemplate={columnsTemplate ?? '1fr'}
      onClick={ isRowClickable ? () => onSelectGuest(guest) : undefined }
      isClickable={isRowClickable}
    >
      <div /> {/* checkbox placeholder */}
      <RowCell text={timeIn} />
      <RowCell text={timeOut} />
      <RowCell
        strong
        text={guest.services.reduce(
          (acc, service, idx) => `${acc}${idx > 0 ? ', ' : ''}${service.name}`,
          ''
        )}
      />
      <RowCell strong text={`${guest.firstName} ${guest.lastName} ${guest.code}`} />
      <RowCell strong text={guest.feedback ?? ''} />
      <GenderCell gender={guest.gender} />
    </Grid>
  )
}
