import styled from '@emotion/styled'
import { theme } from 'styles/theme'

export const OuterWrap = styled.div<{ grayBg?: boolean }>`
  background: ${(props) =>
    props.grayBg ? theme.colors.lightGray : theme.colors.white};
`;

export const LayoutWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  min-height: 100vh;
  margin: auto;
  max-width: 1300px;

  justify-content: space-between;
`;

export const TwoColumnsWrap = styled.div`
  display: flex;
  width: 100%;
  margin: 40px 0;
  gap: 20px;
`;

export const ContentWrap = styled.div`
  flex-grow: 1;
`;

export const SideBarWrap = styled.div``;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  height: 120px;
  padding: 10px 0;
  align-items: start;
`;

export const TermsAndConditionsLink = styled.a`
  text-decoration: none;
  color: ${theme.colors.blue};
  font-size: 16px;

  &:hover {
    text-decoration: underline;
  }
`;

export const PhoneLink = styled.a`
  text-decoration: none;
  color: ${theme.colors.dark};
  padding: 5px 0;
`;
