import styled from '@emotion/styled';
import { StyledButton, StyledButtonType } from 'components/StyledButton/styled';

export const SiteInformationSection = styled.section`
  margin-top: 32px;
  word-break: break-word;
`;

export const SiteBasicInformationBlock = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 32px;
`;

export const SiteTwoColumnsBlock = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
`;

export const SiteOneColumnBlock = styled(SiteTwoColumnsBlock)`
  grid-template-columns: 1fr;
`;

export const SiteImage = styled.img`
  max-width: 100%;
  min-width: 50%;
  border-radius: var(--border-radius);
`;

export const LengthOfStayRow = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 50%;
`;

export const EditSiteButton = styled(StyledButton)`
  margin-top: 30px;
  min-width: 150px;
  min-height: 50px;
` as StyledButtonType;
