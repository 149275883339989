import React, { FC, memo } from 'react';
import { SiteInformationItemContainer, SiteInformationItemContent, SiteInformationItemContentLink, SiteInformationItemHeading } from './styled';

/** Props for the SiteInformationItem component. */
export interface SiteInformationItemProps {

  /** Heading text. */
  readonly heading: string;

  /** Content text. */
  readonly text: string;

  /** Whether item should take all available columns in a grid container. */
  readonly shouldTakeAllColumns?: boolean;

  /** Whether the item should be a link */
  readonly isLink?: boolean;
}

const SiteInformationItemComponent: FC<SiteInformationItemProps> = ({ heading, text, shouldTakeAllColumns, isLink }) => {
  return (
    <SiteInformationItemContainer shouldTakeAllColumns={shouldTakeAllColumns}>
      <SiteInformationItemHeading>{heading}</SiteInformationItemHeading>
      {isLink ?
        <SiteInformationItemContentLink href={text}>{text}</SiteInformationItemContentLink> :
        <SiteInformationItemContent>{text}</SiteInformationItemContent>
      }
    </SiteInformationItemContainer>
  );
};

export const SiteInformationItem = memo(SiteInformationItemComponent);
