import React, { FC } from 'react';
import { EGuestStatus, IGuestType } from 'api/guests/types';
import { BedsBlock } from 'components/BedsBlock';
import { CancelLink } from 'components/CancelLink';
import { GuestCell } from 'components/GuestCell';
import { BlockView } from 'components/Layout/Grid';
import { MainHeading } from 'components/Typography';
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';
import { useUpdateGuestStatus } from 'api/guests';

interface Props {
  vacantBeds: Bed[];
  selectedGuest: IGuestType;
  goBack: VoidFunction;
  handleUpdateStatus: ReturnType<typeof useUpdateGuestStatus>['handleUpdateStatus'];
}

export const AssignBed: FC<Props> = ({
  vacantBeds,
  selectedGuest,
  handleUpdateStatus,
  goBack,
}) => {
  const isAssigned = Boolean(selectedGuest?.assignedBedId);
  const assign = (id: Bed['id']) =>
    handleUpdateStatus({
      guestId: selectedGuest.id,
      status: EGuestStatus.ACCEPTED,
      bedId: Number(id),
      feedback: null,
    });
  const reassign = (id: Bed['id']) =>
    handleUpdateStatus({
      guestId:selectedGuest.id,
      status: EGuestStatus.REASSIGNED,
      bedId: Number(id),
      feedback: null,
    });

  return (
    <>
      <CancelLink onClick={goBack} label="Back" />

      <BlockView marginY={30}>
        <MainHeading withoutMargin>Assign guest</MainHeading>
      </BlockView>

      <div style={{ overflow: 'scroll', flex: 1 }}>
        <BlockView marginY={35}>
          <GuestCell guest={selectedGuest} />
        </BlockView>

        <BlockView marginY={35}>
          <BedsBlock
            beds={vacantBeds}
            withoutGuestCell
            onBedSelect={isAssigned ? reassign : assign}
          />
        </BlockView>
      </div>
    </>
  );
};
