import { object, string } from 'yup'

export const initialValues = {
  phone: '',
}

export const validationSchema = object().shape({
  phone: string().required('Please new phone number'),
})

export type IFormData = typeof initialValues
