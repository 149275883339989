import React, { FC, useEffect } from 'react';
import {
  Backdrop,
  CloseButton,
  ModalContent,
  ModalContentWrap,
  StyledModalWindow,
} from 'components/Modal/styled';

interface Props {
  isOpen: boolean;
  onClose?: VoidFunction;
  large?: boolean;
  medium?: boolean;
}

export const Modal: FC<Props> = ({
  isOpen,
  onClose,
  large,
  children,
  medium,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return (
    <>
      <ModalContentWrap>
        <StyledModalWindow isOpen={isOpen} large={large} medium={medium}>
          <ModalContent>{children}</ModalContent>
          <CloseButton onClick={onClose}>
            <img src="/assets/icons/close.svg" alt="Close" />
          </CloseButton>
        </StyledModalWindow>
      </ModalContentWrap>

      <Backdrop isOpen={isOpen} onClick={onClose} />
    </>
  );
};
