import { useFormikContext } from 'formik';
import React, { memo, ReactElement, useEffect, useRef } from 'react';

interface Props<T> {

  /** On unmount effect callback. */
  readonly onUnmount: (values: T) => void;
}

const FormikUnmountEffectComponent = <T,>({ onUnmount }: Props<T>): ReactElement => {
  const { values } = useFormikContext<T>();

  const valuesRef = useRef(values);

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  useEffect(() => {
    return () => {
      onUnmount(valuesRef.current);
    };
  }, []);

  return <></>;
};

export const FormikUnmountEffect = memo(FormikUnmountEffectComponent) as typeof FormikUnmountEffectComponent;
