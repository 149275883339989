const EARLY_TARIFF_NAME = 'Early bird';

/** Tariff. */
export class Tariff {

  /** Id. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Tariff price. */
  public readonly price: number;

  /** Price per bed in dollars. */
  public readonly pricePerBed: number;

  /** Duration in months. */
  public readonly duration: number;

  /** Minimum bed count. */
  public readonly minBedCount: number;

  public constructor(data: TariffCreationData) {
    this.id = data.id;
    this.name = data.name;
    this.price = data.price;
    this.pricePerBed = data.pricePerBed;
    this.duration = data.duration;
    this.minBedCount = data.minBedCount;
  }

  /** Whether tariff is early. */
  public get isEarly() {
    return this.name === EARLY_TARIFF_NAME;
  }
}

type TariffCreationData = Pick<Tariff,
  | 'id'
  | 'name'
  | 'price'
  | 'pricePerBed'
  | 'duration'
  | 'minBedCount'
>;
