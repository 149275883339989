import { useInviteOrganizationUser } from 'api/users/useInviteOrganizationUser';
import { InputField } from 'components/InputField';
import { Formik, FormikErrors, useFormikContext } from 'formik';
import { OrganizationUserInvitationValues } from 'models/user/UserInvitationValues';
import { Organization } from 'models/organization/Organization';
import { UserRole } from 'models/user/User';
import React, { FC, memo } from 'react';
import { object, string } from 'yup';
import { NewAdminForm, NewAdminSubmitButton } from './styled';
import { isResponseError } from 'utils/isResponseError';
import { useOrganizationUsers } from 'api/users/useOrganizationUsers';

interface Props {
  /** Organization Id. */
  readonly organizationId: Organization['id'];
}

const validationSchema = object().shape({
  email: string().required('Email is a required field.').email('Invalid email.'),
});

const NewOrganizationOwnerFormComponent: FC<Props> = ({ organizationId }) => {
  const initialValues: OrganizationUserInvitationValues = {
    email: '',
    organizationId,
    role: UserRole.Owner,
  };

  const { handleCreate } = useInviteOrganizationUser(location.origin);

  const { revalidate: revalidateUsers } = useOrganizationUsers(organizationId);

  const handleSubmit = async (
    values: OrganizationUserInvitationValues,
    formikHelpers: { setErrors: (errors: FormikErrors<OrganizationUserInvitationValues>) => void }
  ) => {
    try {
      await handleCreate(values);
      await revalidateUsers();
    } catch (error: unknown) {
      if (typeof error === 'string') {
        formikHelpers.setErrors({
          email: error,
        });
      } else {
        formikHelpers.setErrors({
          email: 'Unexpected error happened. Please try again.',
        });
      }
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ submitForm, isSubmitting }) => (
        <NewAdminForm>
          <InputField name="email" placeholder="Enter email address" />
          <NewAdminSubmitButton variant="outlined" disabled={isSubmitting} onClick={submitForm}>
            Send Invite
          </NewAdminSubmitButton>
        </NewAdminForm>
      )}
    </Formik>
  );
};

export const NewOrganizationOwnerForm = memo(NewOrganizationOwnerFormComponent);
