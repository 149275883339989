import React, { FC, memo, useState } from 'react';
import { Organization } from 'models/organization/Organization';
import { OrganizationSubscriptionContainer, OrganizationSubscriptionInfo } from './styled';
import { useOrganizationSubscription } from 'api/subscription/useOrganizationSubscription';
import { useDeleteSubscription } from 'api/subscription/useDeleteSubscription';
import { useOrganization } from 'api/organizations/useOrganization';
import { Loader } from 'components/Loader/Loader';
import { StyledButton } from 'components/StyledButton/styled';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { ResubscriptionForm } from 'components/ResubscriptionForm/ResubscriptionForm';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';

interface Props {

  /** Organization ID. */
  readonly organizationId: Organization['id'];
};

const OrganizationSubscriptionComponent: FC<Props> = ({ organizationId }) => {

  const [isResubscribeDialogOpen, setIsResubscribeDialogOpen] = useState<boolean>(false);

  const handleResubscribeDialogOpen = () => setIsResubscribeDialogOpen(true);
  const handleResubscribeDialogClose = () => setIsResubscribeDialogOpen(false);

  const [isUnsubscribing, setIsUnsubscribing] = useState<boolean>(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false);

  const handleConfirmDialogOpen = () => setIsConfirmDialogOpen(true);
  const handleConfirmDialogClose = () => setIsConfirmDialogOpen(false);

  const { data: organization } = useOrganization(organizationId);

  const shouldFetchSubscription = organization?.isCommercial || false;

  const { data: subscription, revalidate: revalidateSubscription, error: subscriptionError } = useOrganizationSubscription(organizationId, shouldFetchSubscription);

  const { handleDelete } = useDeleteSubscription();

  if (organization == null || organization != null && !organization.isCommercial) {
    return null;
  }

  if (subscriptionError != null) {
    return (
      <OrganizationSubscriptionContainer>
        <h3>{organization.name}</h3>
        <p>{subscriptionError.message}</p>
      </OrganizationSubscriptionContainer>
    )
  }

  if (subscription === undefined) {
    return (
      <OrganizationSubscriptionInfo>
        <Loader size="medium" isPrimary />
      </OrganizationSubscriptionInfo>
    );
  }

  const handleConfirmButtonClick = async() => {
    try {
      setIsUnsubscribing(true);
      await handleDelete({
        organizationId,
      });
      await revalidateSubscription();
      handleConfirmDialogClose();
    } finally {
      setIsUnsubscribing(false);
    }
  }

  const currentSubscriptionDetails = `${subscription.subscriptionOptionName} - $${
    subscription.subscriptionOptionPeriods && subscription.subscriptionOptionPeriods[0].costPerBedInDollars
  }/bed/mo.`

  return (

    <OrganizationSubscriptionContainer>
      <h3>{organization.name}</h3>
      <OrganizationSubscriptionInfo>
      {subscription.isSubscriptionDeleted ? (
        <>
          <p>There is no active subscription.</p>
          <StyledButton variant="outlined" onClick={handleResubscribeDialogOpen}>
            Resubscribe
          </StyledButton>
          <ModalWindow isOpen={isResubscribeDialogOpen} onClose={handleResubscribeDialogClose} isScrollable>
            <ResubscriptionForm onClose={handleResubscribeDialogClose} organizationId={organizationId} />
          </ModalWindow>
        </>
      ) : (
        <>
          <p>{currentSubscriptionDetails}</p>
          <StyledButton color="error" variant="outlined" onClick={handleConfirmDialogOpen}>
            Unsubscribe
          </StyledButton>
          <ConfirmDialog
            isOpened={isConfirmDialogOpen}
            onClose={handleConfirmDialogClose}
            onConfirmationButtonClick={handleConfirmButtonClick}
            title={`Are you sure you want to cancel current subscription for ${organization.name}?`}
            confirmButtonColor="warning"
            isConfirmButtonDisabled={isUnsubscribing}
            shouldDisplaySpinner={isUnsubscribing}
          />
        </>
      )}
    </OrganizationSubscriptionInfo>
    </OrganizationSubscriptionContainer>
  );
};

export const OrganizationSubscription = memo(OrganizationSubscriptionComponent);
