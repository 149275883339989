import { ImageDto } from 'dtos/image/ImageDto';
import { Image } from 'models/image/Image.';

class ImageMapper {

  /** Maps dto to model. */
  public fromDto(dto: ImageDto): Image {
    return new Image({
      imagePath: dto.path,
      index: dto.index,
    })
  }

  /** Maps model to dto. */
  public toDto(model: Image): ImageDto {
    return {
      path: model.imagePath,
      index: model.index,
    }
  }
}

export const imageMapper = new ImageMapper();
