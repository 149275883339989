import styled from '@emotion/styled';
import { theme } from 'styles/theme';
import { StrictOmit } from 'utils/types/strictOmit';
import { SiteInformationItemProps } from './SiteInformationItem';

type ContainerProps = StrictOmit<SiteInformationItemProps, 'heading' | 'text'>;

export const SiteInformationItemContainer = styled.div<ContainerProps>`
  grid-column: ${(props) => (props.shouldTakeAllColumns ? '1/-1' : '')};
`;

export const SiteInformationItemHeading = styled.h3`
  font-size: var(--font-size-xs);
  font-weight: 500;
  color: ${theme.colors.semiLightGray};
  margin-bottom: 8px;
`;

export const SiteInformationItemContent = styled.p`
  font-size: var(--font-size-md);
  font-weight: 500;
  white-space: pre-line;
`;

export const SiteInformationItemContentLink = styled.a`
  font-size: var(--font-size-md);
  color: ${theme.colors.blue};
  font-weight: 500;
  text-decoration: none;
  white-space: pre-line;
`;
