import styled from '@emotion/styled';

export const OrganizationSubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const OrganizationSubscriptionInfo = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
