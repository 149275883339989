import styled from '@emotion/styled';

export const SiteHeader = styled.header`
  display: flex;
  align-items: center;
  margin-block: 40px;
  word-break: break-word;
  gap: 15px;
`;

export const SiteHeaderName = styled.h1`
  font-size: var(--font-site-xlg);
  font-weight: 600;
`;

export const SiteHeaderLogo = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 4px;
`;
