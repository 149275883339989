import React, { FC, useCallback, useState } from 'react'
import { BadgeWrap } from 'components/Badge/styled'
import { ErrorTooltip } from 'components/ErrorTooltip'
import { EColors } from 'styles/theme'

export interface Props {
  title: string
  tooltip?: string
  small?: boolean
  color?: EColors
}

export const Badge: FC<Props> = ({ title, tooltip, ...props }) => {
  const [shouldShowTooltip, setShowTooltip] = useState(false)

  const show = useCallback(() => setShowTooltip(true), [])
  const hide = useCallback(() => setShowTooltip(false), [])

  return (
    <BadgeWrap {...props}>
      <p onMouseEnter={show} onMouseLeave={hide}>
        {title}
      </p>
      {tooltip && (
        <ErrorTooltip text={tooltip} isOpen={shouldShowTooltip} isRight isTop />
      )}
    </BadgeWrap>
  )
}
