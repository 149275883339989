import { TariffDto } from '../../dtos/Tariff/TariffDto';
import { useFetch } from '../../hooks/api';
import { TariffMapper } from '../../mappers/TariffMapper';
import { Tariff } from '../../models/Tariff/Tariff';

/** Fetches subscription tariffs. */
export const useSubscriptionTariffs = () =>
  useFetch<readonly Tariff[], readonly TariffDto[]>({
    url: `/api/Subscriptions/options`,
    mapper: data => data.map(dto => TariffMapper.fromDto(dto)),
  });
