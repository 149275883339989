import styled from '@emotion/styled'

export const MessagesContainer = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9;
  bottom: 32px;
`

export const Container = styled.div`
  background: #121914;
  box-shadow: 0px 2px 8px rgba(215, 216, 217, 0.44),
    0px 4px 32px rgba(238, 239, 240, 0.27);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-width: 588px;
`

export const Label = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #ffffff;
`

export const CloseButton = styled.button`
  background-image: url('/assets/icons/close.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  width: 15px;
  height: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`
