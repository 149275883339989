import { FieldArray, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useSiteServices } from 'api/sites/useSiteServices';
import { InputField } from 'components/InputField';
import { LangugePicker } from 'components/LanguagePicker';
import { Gap, RowView } from 'components/Layout/Grid';
import { FormSectionWrap } from 'components/Layout/Grid/FormSectionWrap';
import { ScreeningPeriodPicker } from 'components/ScreeningPeriodPicker';
import { MainHeading, SecondaryHeading } from 'components/Typography';
import { ServicesBlock } from 'pages/SiteCreate/components/Services/ServicesBlock';
import { EServiceCategory, IServicesForm } from 'services/types';
import { SiteFormBlockProps } from 'pages/SiteEdit';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { useScreeningPeriods } from 'api/screeningPeriod/useScreeningPeriods';
import { Site } from '../../../../models/site/Site';
import { Organization } from '../../../../models/organization/Organization';

interface ServicesFormProps {

  /** Whether the services should be commercial. */
  readonly isCommercial: Site['isCommercial'] | Organization['isCommercial'];
}

type Props = ServicesFormProps & SiteFormBlockProps;

export const ServicesForm: FC<Props> = ({ withoutMainHeader, isCommercial }) => {
  const { data: services, error } = useSiteServices(isCommercial);
  const { data: screeningPeriods, error: screeningPeriodsError } = useScreeningPeriods();

  const { values, errors } = useFormikContext<IServicesForm>();

  if (error != null || screeningPeriodsError != null) {
    return <div>failed to load</div>;
  }
  if (services == null || screeningPeriods == null) {
    return <div>loading...</div>;
  }

  return (
    <FormSectionWrap>
      {!withoutMainHeader && (
        <MainHeading>
          One last thing: select the services you can provide
        </MainHeading>
      )}
      <SecondaryHeading>Services</SecondaryHeading>

      <FieldArray
        name="serviceIds"
        render={() => (
          <>
            <ServicesBlock
              services={services[EServiceCategory.GENERAL]}
              heading="General"
            />
            <ServicesBlock
              services={services[EServiceCategory.DISABILITY]}
              heading="Disabilities"
            />
            {isCommercial && <ServicesBlock
              services={services[EServiceCategory.CLIENT]}
              heading="Type of client"
            />}
            <ServicesBlock
              services={services[EServiceCategory.GENDER]}
              heading="Allowed genders"
            />
            <ServicesBlock
              services={services[EServiceCategory.AGE]}
              heading="Allowed age ranges"
            />
          </>
        )}
      />
      <ValidationError message={errors.serviceIds?.toString()} isCentered />

      <SecondaryHeading>Other parameters</SecondaryHeading>
      <RowView>
        <LangugePicker name="languageIds" label="Languages" />
        <Gap />
        <ScreeningPeriodPicker
          name="screeningPeriod"
          label="Max screening period"
          screeningPeriods={screeningPeriods}
        />
      </RowView>
    </FormSectionWrap>
  );
};
