import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { theme } from 'styles/theme';

export const AddPhotoButton = styled.button<{ isDragging?: Boolean }>`
  border: none;
  background-color: ${theme.colors.lightGray};
  color: ${(props) => theme.colors[props.isDragging ? 'blue' : 'grayText']};
  font-size: 12px;
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: ${(props) => (props.isDragging ? `2px dashed ${theme.colors.blue}` : 'none')};
  transition: 200ms ease-in;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background-color: ${theme.colors.middlePlusGray};
  }
`;

export const MultiPreviewContainer = styled.div`
  width: 80px;
  height: 72px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const SinglePreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const StyledImagePreview = styled.img<{ isActive?: boolean }>`
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: ${(props) => (props.isActive ? 'pointer' : 'default')};
`;

export const OverlayButton = styled.button<{ active?: boolean }>`
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  inset: 0;
  cursor: pointer;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border: none;

  &:hover {
    opacity: 1;
  }
`;

export const ImageValidationErrorContainer = styled.div`
  grid-column: 1 / -1;
  width: 100%;
`;

export const ImageUploaderLabelAndIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  font-size: var(--font-size-xs);
  color: ${theme.colors.semiLightBlue};
  align-items: center;
  gap: 5px;
`;

export const ImageUploaderIcon = styled.img`
  width: 20px;
  height: 20px;
`;

export const ImageUploaderLabel = styled.label`
  cursor: inherit;
`;
