import { DateTime, Interval } from 'luxon'
import React, { FC } from 'react'
import { IGuestType } from 'api/guests/types'
import { StyledDaysText } from 'components/DaysStayedText/styled'

export interface Props {
  guest?: IGuestType
  maxDays?: number
}

const formatDaysStayed = (guest: IGuestType) => {
  const days = Interval.fromDateTimes(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    DateTime.fromSQL(guest.acceptedAt!),
    DateTime.local()
  ).length('days')

  return days
}

const formatTimeAgo = (from: string) => DateTime.fromSQL(from).toRelative()

const formatDate = (date: string) =>
  DateTime.fromSQL(date).toFormat('LLL. dd, y')

export const DaysStayedText: FC<Props> = ({ guest, maxDays }) => {
  const isAssigned = Boolean(guest?.assignedBedId)
  const wasDischargedDate = guest?.dischargedAt
  const wasRejectedDate = guest?.rejectedAt
  const days = guest ? formatDaysStayed(guest).toFixed() : 0
  let text = ''

  if (guest) {
    if (isAssigned) {
      text = `${days} days stayed`
    } else if (wasDischargedDate) {
      text = formatDate(wasDischargedDate)
    } else if (wasRejectedDate) {
      text = formatDate(wasRejectedDate)
    } else {
      text = formatTimeAgo(guest?.createdAt) ?? 'error'
    }
  }

  return <StyledDaysText danger={days > (maxDays ?? 0)}>{text}</StyledDaysText>
}
