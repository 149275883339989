import styled from '@emotion/styled'
import CSS from 'csstype'
import { theme } from 'styles/theme'

export const Grid = styled.div<{
  columnsTemplate: CSS.Property.GridTemplateColumns
}>`
  display: grid;
  grid-template-columns: ${(props) => props.columnsTemplate};
  cursor: pointer;
  border-radius: 12px;

  &:hover {
    background-color: ${theme.colors.lightGreen};
    cursor: pointer;
  }
`
