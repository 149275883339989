import styled from '@emotion/styled'

export const Divider = styled.span`
  display: flex;
  flex-direction: row;
  color: #828282;
  margin: 24px 0 17px 0;
  font-size: 14px;
  line-height: 18px;

  &::after,
  &::before {
    content: '';
    flex: 1 1;
    border-bottom: 0.1rem solid #cccccc;
    margin: auto;
  }

  &::before {
    margin-right: 0.8rem;
  }

  &::after {
    margin-left: 0.8rem;
  }
`
