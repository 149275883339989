import React, { FC, memo, useCallback, useState } from 'react';
import { Bed } from '../../../../pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';
import { EGuestStatus, GuestMethods, IGuestType } from '../../../../api/guests/types';
import { OccupiedBedContainer, OccupiedBedInfo, VacantBedButton, VacantBedContainer } from './styled';
import { Snackbar } from '@mui/material';
import { Site } from '../../../../models/site/Site';
import { useSiteBackButtonRoute } from '../../../../hooks/routing/useSiteBackButtonRoute';
import { useUpdateGuestStatus } from '../../../../api/guests';
import { useHistory } from 'react-router-dom';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';
import { useSiteDetails } from '../../../../api/sites/useSiteDetails';

interface Props {

  /** Bed. */
  readonly bed: Bed;

  /** Site. */
  readonly site: Site;

  /** Guest. */
  readonly guest?: IGuestType;
}

const ReservationBedRowComponent: FC<Props> = ({ bed, site, guest }) => {

  const { revalidate: revalidateSite } = useSiteDetails(site.id);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  const handleConfirmDialogOpen = useCallback(() => setIsConfirmDialogOpen(true), [setIsConfirmDialogOpen]);
  const handleConfirmDialogClose = useCallback(() => setIsConfirmDialogOpen(false), [setIsConfirmDialogOpen]);

  const [hasNotification, setHasNotification] = useState<boolean>(false);

  const handleNotificationClose = useCallback(() => setHasNotification(false), [setHasNotification]);

  const assignRedirectRoute = useSiteBackButtonRoute(site.organizationId.toString(), site.id);

  const history = useHistory();

  const handleUpdateStatusSuccess = useCallback(async() => {
    await revalidateSite();
    history.push(assignRedirectRoute);
  }, [revalidateSite])

  const { handleUpdateStatus } = useUpdateGuestStatus(
    guest?.siteId ?? null,
    handleUpdateStatusSuccess,
    () => setHasNotification(true),
  );

  const handleAssignButtonClick = useCallback(async() => {
    if (guest == null) {
      return;
    }

    handleUpdateStatus({
      guestId: guest.id,
      status: guest.acceptedAt ? EGuestStatus.REASSIGNED : EGuestStatus.ACCEPTED,
      bedId: Number(bed.id),
      feedback: null,
    });
  }, [handleUpdateStatus, guest])

  const isOccupied = bed.guest != null;

  const bedInfo = `${bed.name} - ${bed.type}`;

  return (
    isOccupied ?
      (
        <OccupiedBedContainer>
          {GuestMethods.getShortenedName(bed.guest)}
          <OccupiedBedInfo>
            {bedInfo}
          </OccupiedBedInfo>
        </OccupiedBedContainer>
      ) :
      (
        <>
          {
            guest != null ?
            (
              <>
                <VacantBedButton type="button" onClick={handleConfirmDialogOpen}>
                  {bedInfo}
                </VacantBedButton>
                <ConfirmDialog
                  isOpened={isConfirmDialogOpen}
                  onClose={handleConfirmDialogClose}
                  onConfirmationButtonClick={handleAssignButtonClick}
                >
                  Are you sure you want to assign {GuestMethods.getShortenedName(guest)} to this bed?
                </ConfirmDialog>
              </>
            ) :
            (
              <VacantBedContainer>
                Vacant
              </VacantBedContainer>
            )
          }
          <Snackbar
            open={hasNotification}
            autoHideDuration={5000}
            onClose={handleNotificationClose}
            message="An error occurred while assigning the bed."
          />
        </>
      )
  )
};

export const ReservationBedRow = memo(ReservationBedRowComponent);
