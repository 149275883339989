import { useMutate } from '../../hooks/api';
import { Organization } from '../../models/organization/Organization';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';

interface DefaultPaymentMethodInfo {

  /** Organization ID. */
  readonly organizationId: Organization['id'],

  /** Saved payment method id. */
  readonly savedPaymentMethodId: SavedPaymentMethod['id']
};

/** Makes saved payment method default for organization. */
export const useMakeSavedPaymentMethodDefault = () => {
  const { mutate } = useMutate<undefined, undefined, DefaultPaymentMethodInfo>('put', {
    url: `/api/Subscriptions/savedPaymentMethods/default`,
  });

  return { makeDefault: mutate };
};
