import React, { useContext, useState } from 'react';
import { useUpdatePassword } from 'api/auth/useUpdatePassword/useUpdatePassword';
import { Container } from 'components/ChangeUserSettingsPhone/styled';
import { SettingsButton } from 'components/SettingsButton';
import { ChangePasswordForm } from 'forms/ChangePassword';
import { IFormData } from 'forms/ChangePassword/data';
import { FlashMessagesContext } from 'hooks/flash/Context';
import { UnderlinedHeading } from 'pages/OrganizationSiteDetails/components/SiteInformation/components/UnderlinedHeading/UnderlinedHeading';
import { StyledButton } from 'components/StyledButton/styled';

export const ChangeUserPassword = () => {
  const {
    actions: { showInfo },
  } = useContext(FlashMessagesContext);
  const [step, setStep] = useState(1);
  const { updatePassword, isLoading, error } = useUpdatePassword();

  const goNextStep = () => {
    setStep((step) => step + 1);
  };

  const goStepBack = () => {
    setStep((step) => step - 1);
  };

  const handleStartEdit = () => {
    goNextStep();
  };

  const handleChangePassword = async (data: IFormData) => {
    await updatePassword({
      newPassword: data.password,
      oldPassword: data.oldPassword,
    });
    showInfo('Password has been changed');
    setStep(1);
  };

  return (
    <Container>
      <UnderlinedHeading text="Password" />
      {step === 1 && (
        <StyledButton variant="outlined" onClick={handleStartEdit}>Change password</StyledButton>
      )}
      {step === 2 && (
        <ChangePasswordForm
          handleCancel={goStepBack}
          handleSubmit={handleChangePassword}
          isLoading={isLoading}
          error={error}
        />
      )}
    </Container>
  );
};
