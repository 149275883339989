import styled from '@emotion/styled';
import { Box } from '@mui/system';
import { theme } from 'styles/theme';
import { PlaceholderElement } from '../../styled';
import { RowView } from 'components/Layout/Grid';

interface Props {
  readonly errorMessage?: string;
}

export const RoomWrap = styled.div<Props>`
  border: 1px solid ${props => props.errorMessage == null ? theme.colors.gray : theme.colors.red};
  padding: 20px;
  border-radius: var(--border-radius);
  margin-bottom: 20px;
  width: 100%;
`;

export const RoomHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
`;

export const RoomBedsTabsContainer = styled(Box)`
  border-bottom: 1px solid ${theme.colors.grayBorderColor};
  display: flex;
  justify-content: space-between;
`;

export const RoomContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
`;

export const RoomBedsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
`;

export const RoomHeaderMenu = styled.div`
  grid-column: 1/3;
  justify-self: end;

  @media print {
    display: none;
  }
`;

export const RoomCreationFormContainer = styled(RowView)`
  @media print {
    display: none;
  }
`;

interface CollupseButtonProps {

  /** Whether the content is collapsed. */
  readonly isCollapsed: boolean;
}

export const RoomHeaderCollapseButton = styled.button<CollupseButtonProps>`
  justify-self: end;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: none;
  width: 40px;
  height: 40px;
  background-image: url("/assets/icons/arrow_down.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: ${props => !props.isCollapsed ? 'rotateX(180deg)' : ''};
  transition: transform 0.5s;
  transition: background-color 0.15s;

  &:hover {
    background-color: ${theme.colors.lightGray};
  }
`;

export const RoomBeds = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

export const BedsPlaceholder = styled(PlaceholderElement)`
  --placeholder-height: 100px;
  margin-left: 20px;
`;

export const RoomPhoto = styled.img`
  width: var(--room-photo-height);
  height: var(--room-photo-height);
  object-fit: cover;
`;

export const RoomPhotoContainer = styled.div`
  margin-block: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const RoomPhotoUploader = styled.div`
  height: 150px;
`;

export const EditPencelIcon = styled.img`
  vertical-align: baseline;
  margin-left: 5px;

  @media print {
    display: none;
  }
`;
