import { Formik } from 'formik';
import React, { FC } from 'react';
import { Divider } from 'components/Divider';
import { ErrorTooltip } from 'components/ErrorTooltip';
import { InputField } from 'components/InputField';
import { BlockView } from 'components/Layout/Grid';
import { SocialButton } from 'components/SocialButton';
import { ThemedButton } from 'components/ThemedButton';
import { initialValues } from 'forms/SignIn/data';
import {
  Footer,
  FormHeading,
  FormLink,
  StyledForm,
  FormText,
  Center,
  Header,
  Content,
  ButtonContainer,
} from 'forms/SignIn/styled';
import { Props } from 'forms/SignIn/types';
import { ERoute } from 'router/data';

export const SignInForm: FC<Props> = ({
  handleSubmit,
  isLoading,
  errorText,
}) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {() => (
      <StyledForm hasSmallerPadding>
        <Header>
          <FormHeading>RehabSite</FormHeading>
          <FormText>Helping you to help people</FormText>
        </Header>

        <Center>
          <Content>
            <SocialButton type="google" />
            <BlockView mt={16}>
              <SocialButton type="microsoft" />
            </BlockView>
            <Divider>Or</Divider>
            <InputField
              name="username"
              label="Username"
              placeholder="Insert your name"
            />
            <InputField
              name="password"
              label="Password"
              type="password"
              placeholder="••••••"
            />
            <FormLink to={ERoute.RESTORE_PASSWORD}>Forgot password?</FormLink>
          </Content>
        </Center>

        <Footer>
          <ButtonContainer>
            <ThemedButton
              disabled={isLoading}
              type="submit"
              width="100%"
            >
              Sign in
            </ThemedButton>
            {errorText && (
              <ErrorTooltip isOpen text={errorText} isRight centered />
            )}
          </ButtonContainer>
        </Footer>
      </StyledForm>
    )}
  </Formik>
);
