export const createFormData = (data: Record<string, string>) =>
  new URLSearchParams(data)

export const encodeQueryParams = (data: Object) =>
  Object.entries(data)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&')

export const searchParams = (key: string, searchQuery: string) => {
  const query = new URLSearchParams(searchQuery)
  return query.get(key)
}
