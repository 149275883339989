/** Payment dto purpose. */
export enum PaymentPurposeDto {
  SubscriptionCreation = 'SubscriptionCreation',
  SubscriptionMonthlyPayment = 'SubscriptionPeriodPayment',
  ExtraPaidBedCountChange = 'ExtraPaidBedCountChange',
}

/** Base payment dto. */
export interface BasePaymentDto {

  /** ID. */
  readonly id: number;

  /** Purpose of payment. */
  readonly purpose: PaymentPurposeDto;

  /** Creation date. */
  readonly createdAt: string;
}
