/* eslint-disable @typescript-eslint/no-misused-promises */
import { IUpdatePasswordData } from 'api/auth/useUpdatePassword/types'
import { useMutate } from 'hooks/api'

export const useUpdatePassword = () => {
  const { mutate, isLoading, error } = useMutate<unknown, unknown, IUpdatePasswordData>(
    'put',
    {
      url: '/api/Users/password',
      headers: {
        Accept: 'application/json',
      },
    }
  )

  const updatePassword = (data: IUpdatePasswordData) => mutate(data)

  return { updatePassword, isLoading, error }
}
