import { filter } from 'ramda'
import React, { FunctionComponent, useState, createContext } from 'react'
import { IFlashMessage, Props, IInitialValues } from 'hooks/flash/types'
import { generateId } from 'services/generateId'

export const FlashMessagesContext = createContext<IInitialValues>(
  undefined as never
)

export const FlashMessagesProvider: FunctionComponent<Props> = ({
  children,
}) => {
  const [messages, setMessages] = useState<IFlashMessage[]>([])

  const hideMessage = (id: string) =>
    setMessages((currentMessages) =>
      filter((item) => item.id !== id, currentMessages)
    )

  const showMessage = (message: IFlashMessage) => {
    setMessages((currentMessages) => [...currentMessages, message])
    setTimeout(() => {
      hideMessage(message.id)
    }, 5000)
  }

  const showInfo = (message: string) =>
    showMessage({ id: generateId(10), message, type: 'info' })

  return (
    <FlashMessagesContext.Provider
      value={{
        state: {
          messages,
        },
        actions: {
          hideMessage,
          showMessage,
          showInfo,
        },
      }}
    >
      {children}
    </FlashMessagesContext.Provider>
  )
}
