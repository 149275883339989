import React, { FC, FormEventHandler, memo, useState } from 'react';
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StyledButton } from 'components/StyledButton/styled';
import { useUpdateSavedPaymentMethods } from 'api/subscription/useUpdateSavedPaymentMethods';
import { SavedPaymentMethodCreateValues } from 'models/Subscriptions/SavedPaymentMethodCreateValues';

import { AddCardForm, Buttons, Card, CardAgreementInfo, CardInfo, Field, InputWrap, LabelText } from './styled';
import { cardElementOptions } from './stripeCardElementOptions';
import { SavedPaymentMethod } from 'models/Subscriptions/SavedPaymentMethod';
import { Loader } from 'components/Loader/Loader';
import { Site } from 'models/site/Site';
import { LoaderWrapper } from '../../../Loader/styled';
import { ErrorMessage } from '../../../FetchErrorMessage/FetchErrorMessage';

interface Props {

  /** Save handler. */
  readonly onSave: (savedMethodId: SavedPaymentMethod['id']) => void;

  /** Cancel handler. */
  readonly onCancel: () => void;

  /** Organization id. */
  readonly organizationId: Site['organizationId'];
}

const AddCardFormContentComponent: FC<Props> = ({ onSave, onCancel, organizationId }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const { updateSavedPaymentMethods, errorMessage } = useUpdateSavedPaymentMethods()

  /**
   * Handles payment.
   * @param event Form event.
   */
  const handleSave: FormEventHandler<HTMLFormElement> = async(event) => {
    event.preventDefault();

    if (stripe == null || elements == null) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (cardElement == null) {
      return;
    }

    setIsLoading(true);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (result.paymentMethod != null) {
      try {
        const savedMethodId = await updateSavedPaymentMethods(new SavedPaymentMethodCreateValues({
          externalSavedPaymentMethodId: result.paymentMethod.id,
          organizationId,
        }))
        onSave(savedMethodId);
      } finally {
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  return (
    <AddCardForm onSubmit={handleSave}>
      {isLoading && (
        <LoaderWrapper>
          <Loader
            size='large'
            isPrimary
          />
        </LoaderWrapper>
      )}
      <Card>
        <Field>
          <LabelText>Card number</LabelText>
          <InputWrap>
            <CardNumberElement  options={{showIcon: true, ...cardElementOptions}} />
          </InputWrap>
        </Field>
        <CardInfo>
          <Field>
            <LabelText>Expiration</LabelText>
            <InputWrap>
              <CardExpiryElement options={cardElementOptions} />
            </InputWrap>
          </Field>
          <Field>
            <LabelText>CVC</LabelText>
            <InputWrap>
              <CardCvcElement options={cardElementOptions} />
            </InputWrap>
          </Field>
        </CardInfo>
        <CardAgreementInfo>
          By providing your payment information and confirming this payment, you authorise (A) Demo Test Inc. and Stripe, our payment service provider and/or PPRO.
        </CardAgreementInfo>
      </Card>
      {errorMessage != null && <ErrorMessage message={errorMessage} />}
      <Buttons>
        <StyledButton type='button' variant='outlined' onClick={onCancel}>Cancel</StyledButton>
        <StyledButton type='submit' variant='contained' disabled={isLoading}>Save</StyledButton>
      </Buttons>
    </AddCardForm>
  );
};

export const AddCardFormContent = memo( AddCardFormContentComponent);
