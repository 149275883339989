import { Formik } from 'formik';
import React, { FC } from 'react';
import { CancelLink } from 'components/CancelLink';
import { ErrorTooltip } from 'components/ErrorTooltip';
import { InputField } from 'components/InputField';
import { ThemedButton } from 'components/ThemedButton';
import { initialValues } from 'forms/RestorePassword/data';
import { Props } from 'forms/RestorePassword/types';
import {
  Footer,
  FormHeading,
  StyledForm,
  Header,
  Content,
  Center,
} from 'forms/SignIn/styled';
import { ERoute } from 'router/data';

export const RestorePasswordForm: FC<Props> = ({
  handleSubmit,
  isLoading,
  error,
}) => (
  <Formik initialValues={initialValues} onSubmit={handleSubmit}>
    {({ submitForm }) => (
      <StyledForm>
        <Header>
          <CancelLink to={ERoute.LOGIN} label="Back" />
          <FormHeading>Restore password</FormHeading>
        </Header>

        <Center>
          <Content>
            <InputField
              name="email"
              label="Please insert your email"
              placeholder="Enter your Email Address here..."
            />
            {error && <ErrorTooltip isOpen text={error} isRight centered />}
          </Content>
        </Center>

        <Footer>
          <ThemedButton
            disabled={isLoading}
            type="submit"
            width="100%"
            onClick={submitForm}
          >
            Continue
          </ThemedButton>
        </Footer>
      </StyledForm>
    )}
  </Formik>
);
