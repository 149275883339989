import { Site } from 'models/site/Site';
import React, { createContext, FC, memo, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

interface SiteDetailsContext {

  /** Active tab index. */
  readonly activeTabIndex: number;

  /** Id of the last visited site. */
  readonly lastVisitedSiteId: Site['id'] | null;
}

type DispatchSiteDetailsTabContext = ((newContext: SiteDetailsContext) => void) | undefined;

const SiteDetailsTabContext = createContext<SiteDetailsContext>({
  activeTabIndex: 0,
  lastVisitedSiteId: null,
});
const SetSiteDetailsTabContext = createContext<DispatchSiteDetailsTabContext | undefined>(undefined);

const SiteDetailsTabContextProviderComponent: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [siteDetailsTabContext, setSiteDetailsTabContext] = useState<SiteDetailsContext>({
    activeTabIndex: 0,
    lastVisitedSiteId: null,
  });

  return (
    <SiteDetailsTabContext.Provider value={siteDetailsTabContext}>
      <SetSiteDetailsTabContext.Provider value={setSiteDetailsTabContext}>
        {children}
      </SetSiteDetailsTabContext.Provider>
    </SiteDetailsTabContext.Provider>
  );
};

export const SiteDetailsTabContextProvider = memo(SiteDetailsTabContextProviderComponent);

type DispatchSiteDetailsTabIndex = (newIndex: number) => void;

export const useSiteDetailsTabContext = (siteId: Site['id']): [number, DispatchSiteDetailsTabIndex] => {
  const { activeTabIndex, lastVisitedSiteId } = useContext(SiteDetailsTabContext);
  const setSiteDetailsTabContext = useContext(SetSiteDetailsTabContext);

  if (setSiteDetailsTabContext == null) {
    throw new Error('You must use useSiteDetailsTabContext hook inside of SiteDetailsTabContextProvider.');
  }

  const handleSiteDetailsTabChange = useCallback((newTabIndex: number) => {
    setSiteDetailsTabContext({
      activeTabIndex: newTabIndex,
      lastVisitedSiteId,
    });
  }, [setSiteDetailsTabContext, lastVisitedSiteId]);

  useEffect(() => {
    if (lastVisitedSiteId !== siteId) {
      setSiteDetailsTabContext({
        activeTabIndex: 0,
        lastVisitedSiteId: siteId,
      });
    };
  }, [lastVisitedSiteId, setSiteDetailsTabContext])

  return [activeTabIndex, handleSiteDetailsTabChange];
};
