import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

export const OrganizationsNavWrapper = styled.nav`
  display: flex;
  align-items: center;
`;

export const ArrowImage = styled.img`
  margin-inline: 15px;
  width: 0.25rem;
`;

export const OrganizationsNavLink = styled(Link)`
  font-size: var(--font-size-xs);
  font-weight: 600;
  text-decoration: none;
  color: ${theme.colors.semiLightBlue};
`;

export const OrganizationNavText = styled.p`
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: ${theme.colors.semiLightGray};
`;
