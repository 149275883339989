import React, { FC, useMemo } from 'react'
import { UniversalSelectFields } from 'components/UniversalSelectFields'
import { ScreeningPeriod } from 'models/screeningPeriod/ScreeningPeriod'
import { Option } from 'services/types'

interface Props {

  /** Name. */
  readonly name: string

  /** Label. */
  readonly label?: string

  /** Screening period options. */
  readonly screeningPeriods: readonly ScreeningPeriod[];
}

export const ScreeningPeriodPicker: FC<Props> = ({ name, label, screeningPeriods }) => {

  const options = useMemo(() => screeningPeriods.map<Option<string>>(period => ({
    label: `${period.totalHours} h`,
    value: period.value,
  })), [screeningPeriods]);

  return (
    <UniversalSelectFields name={name} label={label} options={options} />
  )
}
