import { useMutate } from 'hooks/api';
import { SiteMapper } from 'mappers/SiteMapper';
import { Site } from 'models/site/Site';
import { SiteEditingValues } from 'models/site/SiteEditingValues';
import { SiteDto } from '../../dtos/site/SiteDto';

export const useUpdateSite = (id: Site['id'], isCommercial: Site['isCommercial'], redirectUrl?: string) => {
  const { mutate, error } = useMutate<Site, SiteDto, SiteEditingValues>('put', {
    url: `/api/Sites/${isCommercial ? 'commercial' : 'nonCommercial'}/${id}`,
    redirectUri: redirectUrl,
    requestTransformer: (data) => JSON.stringify(SiteMapper.toSiteEditionValuesDto(data)),
    mapper: SiteMapper.fromDto,
  });

  return { handleUpdate: mutate, error };
};
