import React, { FC, memo, useCallback, useState } from 'react';
import { ReservationsTable } from 'pages/Reservation/Components/ReservationsTable/ReservationsTable';
import { Site } from 'models/site/Site';
import { IGuestType } from 'api/guests/types';
import { SiteTabHeading } from '../SiteTabHeading/SiteTabHeading';
import { SiteTabContentContainer } from 'pages/OrganizationSiteDetails/styled';
import { ButtonIcon } from 'components/ButtonIcon/styled';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { StyledButton } from 'components/StyledButton/styled';
import { CreateGuestForm } from 'pages/Reservation/Components/CreateGuestForm/CreateGuestForm';
import { useGuests } from 'api/guests';
import { useSiteDetails } from 'api/sites/useSiteDetails';
import { Loader } from 'components/Loader/Loader';
import { AlertDialog } from 'components/Dialogs/AlertDialog/AlertDialog';
import { NoVacantBedsMessage } from './styled';
import { useAuth } from 'hooks/auth/useAuth';
import { isUserSiteStaff } from 'hooks/auth/useIsUserSiteStaff';

interface Props {
  /** Site id. */
  readonly siteId: Site['id'];

  /** Guests. */
  readonly guests: readonly IGuestType[];

  /** Whether it is possible to add new guest. */
  readonly isAddingGuestsPossible: boolean;
}

const SiteReservationsComponent: FC<Props> = ({ siteId, guests, isAddingGuestsPossible }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [isNoVacantBedsDialogOpen, setIsNoVacantBedsDialogOpen] = useState(false);

  const { data: siteData, error: siteError } = useSiteDetails(siteId);
  const { revalidate: revalidateGuests } = useGuests(siteId);

  const handleCreateGuestButtonClick = useCallback(() => {
    isAddingGuestsPossible ? setModalOpen(true) : setIsNoVacantBedsDialogOpen(true);
  }, [setModalOpen, isAddingGuestsPossible]);

  const handleCreateGuestModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleCreateGuestModalDone = useCallback(() => {
    setModalOpen(false);
    revalidateGuests();
  }, [setModalOpen, revalidateGuests]);

  const handleNoVacantBedsModalClose = useCallback(() => setIsNoVacantBedsDialogOpen(false), [setIsNoVacantBedsDialogOpen]);

  const isUserStaff = isUserSiteStaff(siteId);

  if (siteData == null) {
    return <Loader size="medium" />;
  }

  return (
    <>
      <SiteTabContentContainer>
        <SiteTabHeading headingText="Reservations" count={guests.length}>
          {
            isUserStaff || (
              <StyledButton type="button" onClick={handleCreateGuestButtonClick}>
                <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
                New Guest
              </StyledButton>
            )
          }
        </SiteTabHeading>
        <ReservationsTable
          siteId={siteId}
          isCommercial={siteData.isCommercial}
          guests={guests}
        />
      </SiteTabContentContainer>
      <ModalWindow isScrollable isOpen={isModalOpen} onClose={handleCreateGuestModalClose}>
        <CreateGuestForm
          onCancel={handleCreateGuestModalClose}
          onDone={handleCreateGuestModalDone}
          site={siteData}
        />
      </ModalWindow>
      <AlertDialog
        isOpened={isNoVacantBedsDialogOpen}
        onClose={handleNoVacantBedsModalClose}
      >
        <NoVacantBedsMessage>You cannot add a new guest because there are no vacant beds.</NoVacantBedsMessage>
      </AlertDialog>
    </>
  );
};

export const SiteReservations = memo(SiteReservationsComponent);
