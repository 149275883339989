import { useUpdateGuestStatus } from 'api/guests';
import { EGuestStatus, IGuestType } from 'api/guests/types';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';
import React, { useCallback, useState } from 'react';
import { FC } from 'react';
import { formatDateToMDY } from 'utils/formatDateToMDY';
import {
  DeclineDialogInfo,
  DeclineDialogInfoItem,
  DeclineFeedbackField,
  InfoItemText,
  InfoItemTitle,
} from './styled';

interface DeclineDialogProps {
  /** Whether dialog is opened or not. */
  readonly isOpen: boolean;

  /** Handle dialog close event. */
  readonly onClose: () => void;

  /** Guest whos gonna be declined. */
  readonly guest: IGuestType;

  /** Handle click on confirmation button click. */
  readonly onConfirmationButtonClick?: () => void;
}

const DeclineDialogComponent: FC<DeclineDialogProps> = ({
  isOpen,
  onClose,
  guest,
  onConfirmationButtonClick,
}) => {

  const [feedback, setFeedback] = useState('');

  const { handleUpdateStatus } = useUpdateGuestStatus(
    guest.siteId,
    onConfirmationButtonClick ? onConfirmationButtonClick : () => {},
  );

  const handleConfirmButtonClick = useCallback(() => {
    handleUpdateStatus({
      guestId: guest.id,
      status: EGuestStatus.REJECTED,
      feedback,
      bedId: null,
    });
    onClose();
  }, [guest, feedback]);

  return (
    <ConfirmDialog
      isOpened={isOpen}
      onClose={onClose}
      title="Are you sure to Decline reservation?"
      cancelButtonText="Don't Decline Reservation"
      onConfirmationButtonClick={handleConfirmButtonClick}
      confirmButtonText="Decline Reservation"
      confirmButtonColor="error"
    >
      <DeclineDialogInfo>
        <DeclineDialogInfoItem>
          <InfoItemTitle>Guest</InfoItemTitle>
          <InfoItemText>{guest.code}</InfoItemText>
        </DeclineDialogInfoItem>
        <DeclineDialogInfoItem>
          <InfoItemTitle>Reservation Date</InfoItemTitle>
          <InfoItemText>
            {guest.createdAt != null
              ? formatDateToMDY(new Date(guest.createdAt))
              : ''}
          </InfoItemText>
        </DeclineDialogInfoItem>
        <DeclineFeedbackField
          value={feedback}
          onChange={setFeedback}
        />
      </DeclineDialogInfo>
    </ConfirmDialog>
  );
};

export const DeclineDialog = DeclineDialogComponent;
