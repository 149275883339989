import styled from '@emotion/styled'

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto max-content;
  grid-template-rows: repeat(2, 1fr);
`;

export const Label = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-xs);
  line-height: 18px;
  color: #757575;
  display: inline-block;
  grid-row: 1/2;
  grid-column: 1/2;
`;

export const Text = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: var(--font-size-md);
  line-height: 18px;
  color: #000000;
  display: inline-block;
  grid-row: 2/3;
  grid-column: 1/2;
`;

export const Highlight = styled.span`
  color: #44631d;
`;

export const InputPreviewButtonContainer = styled.div`
  grid-row: 1/3;
  grid-column: 2/3;
`;
