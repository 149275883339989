import { Tabs } from '@mui/material';
import { useOrganization } from 'api/organizations/useOrganization';
import { useSiteDetails } from 'api/sites/useSiteDetails';
import { CounterTab, StyledTab } from 'components/Tabs/Tabs';
import { Layout } from 'components/Layout';
import { mapOrganizationTypeHrefToName, OrganizationsNav, OrganizationsNavItem } from 'components/OrganizationsNav/OrganizationsNav';
import { LoadingPage } from 'pages/LoadingPage';
import React, { FC, SyntheticEvent, useCallback, useReducer, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { routes } from 'router/data';
import { RoomsWarningText } from './styled';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { SiteInformation } from './components/SiteInformation/SiteInformation';
import { useGuests } from 'api/guests';
import { GuestMethods } from 'api/guests/types';
import { SiteReservations } from './components/SiteReservations/SiteReservations';
import { SiteGuests } from './components/SiteGuests/SiteGuests';
import { SiteRooms } from './components/SiteRooms/SiteRooms';
import { SiteUsers } from './components/SiteUsers/SiteUsers';
import { SiteHistory } from './components/SiteHistory/SiteHistory';
import { useSiteUsers } from 'api/users/useSiteUsers';
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';
import { useSiteDetailsTabContext } from './context/SiteDetailsTabContext';
import { useOrganizationTypeHref } from '../../hooks/routing/useOrganizationTypeFromHref';
import { ErrorMessage } from 'components/FetchErrorMessage/FetchErrorMessage';
import { useAuth } from '../../hooks/auth/useAuth';
import { NavigationConfirmModal } from '../../components/NavigationConfirmModal/NavigationConfirmModal';
import { ConfirmDialog } from '../../components/Dialogs/ConfirmDialog/ConfirmDialog';
import { useIsUserPrivileged } from 'hooks/auth/useIsUserPrivileged';
import { OrganizationSiteHeader } from 'components/OrganizationSiteHeader/OrganizationSiteHeader';

interface ConfirmationState {

  /** Whether need to confirm tab change. */
  readonly shouldShowTabChangeConfirm: boolean;

  /** Whether need to confirm navigation. */
  readonly shouldShowNavigationConfirm: boolean;
}

enum ConfirmationActionType {
  ChangeNavigation = 'ChangeNavigation',
  ChangeTab = 'ChangeTab',
  ChangeBoth = 'ChangeBoth',
}

interface Action {

  /** Action type. */
  readonly type: ConfirmationActionType,

  /** Payload. */
  readonly payload: boolean;
}

function confirmationReducer(state: ConfirmationState, { type, payload }: Action): ConfirmationState {
  switch (type) {
    case ConfirmationActionType.ChangeNavigation:
      return {
        ...state,
        shouldShowNavigationConfirm: payload,
      };
    case ConfirmationActionType.ChangeTab:
      return {
        ...state,
        shouldShowTabChangeConfirm: payload,
      };
    case ConfirmationActionType.ChangeBoth:
      return {
        shouldShowNavigationConfirm: payload,
        shouldShowTabChangeConfirm: payload,
      };
    default:
      return state;
  }
}

const INITIAL_CONFIRMATION_STATE: ConfirmationState = {
  shouldShowTabChangeConfirm: false,
  shouldShowNavigationConfirm: false,
}

const ROOMS_WARNING_TITLE = 'Leave page?';
const ROOMS_WARNING_TEXT = 'Changes that you made wont be saved.';
const ROOMS_WARNING_CONFIRM_TEXT = 'Leave';

interface RouteProps {

  /** Organization id. */
  readonly organizationId: string;

  /** Site id. */
  readonly siteId: string;
}

export const OrganizationSiteDetailsPage: FC<RouteComponentProps<RouteProps>> = ({ match }) => {

  const [confirmationState, dispatch] = useReducer(confirmationReducer, INITIAL_CONFIRMATION_STATE);

  const [haveRoomsChanged, setHaveRoomsChanged] = useState(false);
  const handleRoomsChange = useCallback((roomsChanged: boolean) => {
    setHaveRoomsChanged(roomsChanged);
    dispatch({
      type: ConfirmationActionType.ChangeNavigation,
      payload: roomsChanged,
    });
  }, [setHaveRoomsChanged, dispatch]);

  const { organizationId, siteId } = match.params;

  const organizationsTypeHref = useOrganizationTypeHref();

  const isUserPrivileged = useIsUserPrivileged(organizationId);

  const { data: organization } = useOrganization(organizationId);
  const { data: site, error: siteError } = useSiteDetails(siteId);
  const { data: guestsData } = useGuests(siteId);
  const { data: usersData, revalidate: revalidateUsers } = useSiteUsers(siteId, undefined, isUserPrivileged);

  const [activeTabIndex, setActiveTabIndex] = useSiteDetailsTabContext(siteId);

  const [newTabIndex, setNewTabIndex] = useState<number | null>(null);

  const handleTabChange = useCallback((event: SyntheticEvent, newActiveTabIndex: number) => {
    if (haveRoomsChanged) {
      dispatch({
        type: ConfirmationActionType.ChangeTab,
        payload: true,
      })
      setNewTabIndex(newActiveTabIndex);
    } else {
      setActiveTabIndex(newActiveTabIndex);
    }
  }, [haveRoomsChanged, setNewTabIndex, setActiveTabIndex, dispatch]);

  const handleTabChangeConfirm = useCallback(() => {
    if (newTabIndex != null) {
      setActiveTabIndex(newTabIndex);
      setHaveRoomsChanged(false);
      dispatch({
        type: ConfirmationActionType.ChangeBoth,
        payload: false,
      })
    }
  }, [newTabIndex, setActiveTabIndex, setHaveRoomsChanged, dispatch]);

  const handleConfirmModalClose = useCallback(() => dispatch({
    type: ConfirmationActionType.ChangeTab,
    payload: false,
  }), [dispatch]);

  if (siteError != null) {
    return (
      <Layout withSideBar>
        <ErrorMessage message={siteError.toString()} />
      </Layout>
    );
  }

  if (
    organization == null ||
    site == null ||
    guestsData == null ||
    isUserPrivileged && usersData == null
  ) {
    return <LoadingPage />;
  }

  const rooms = site.rooms;
  const beds = rooms.reduce<readonly Bed[]>((beds, room) => [...beds, ...room.beds], []);
  const guests = guestsData.items.filter(guest => GuestMethods.isAssignedGuest(guest));
  const reservationGuests = guestsData.items.filter(guest => GuestMethods.isReservationGuest(guest));
  const users = usersData?.items;

  if (organizationId !== site.organizationId.toString()) {
    return <Redirect to={routes.OrganizationDetails(organizationId)} />;
  }

  const navigationItems: readonly OrganizationsNavItem[] = [
    {
      name: mapOrganizationTypeHrefToName(organizationsTypeHref),
      linkPath: routes.OrganizationsList(organizationsTypeHref),
    },
    {
      name: organization.name,
      linkPath: routes.OrganizationDetails(organization.id, organizationsTypeHref),
    },
    {
      name: site.name,
    },
  ];

  const isAddingGuestsPossible = (site.countOfVacantBeds - reservationGuests.length) > 0;

  return (
    <Layout withSideBar>
      <NavigationConfirmModal
        needToConfirm={confirmationState.shouldShowNavigationConfirm}
        title={ROOMS_WARNING_TITLE}
        confirmButtonVariant='contained'
        confirmButtonText={ROOMS_WARNING_CONFIRM_TEXT}
      >
        <RoomsWarningText>{ROOMS_WARNING_TEXT}</RoomsWarningText>
      </NavigationConfirmModal>
      <ConfirmDialog
        isOpened={confirmationState.shouldShowTabChangeConfirm}
        onConfirmationButtonClick={handleTabChangeConfirm}
        title={ROOMS_WARNING_TITLE}
        onClose={handleConfirmModalClose}
        confirmButtonText={ROOMS_WARNING_CONFIRM_TEXT}
        confirmButtonVariant='contained'
      >
        <RoomsWarningText>{ROOMS_WARNING_TEXT}</RoomsWarningText>
      </ConfirmDialog>
      <OrganizationsNav items={navigationItems} />
      <OrganizationSiteHeader site={site} />
      <Tabs value={activeTabIndex} onChange={handleTabChange}>
        <StyledTab label="Information" />
        <CounterTab label="Houses" count={rooms.length} />
        <CounterTab label="Reservations" count={reservationGuests.length} />
        <CounterTab label="Guests" count={guests.length} />
        <StyledTab label="History" />
        {isUserPrivileged && <CounterTab label="Users" count={users?.length ?? 0} />}
      </Tabs>

      <TabPanel index={0} value={activeTabIndex}>
        <SiteInformation site={site} />
      </TabPanel>
      <TabPanel index={1} value={activeTabIndex}>
        <SiteRooms site={site} handleRoomsChange={handleRoomsChange} />
      </TabPanel>
      <TabPanel index={2} value={activeTabIndex}>
        <SiteReservations siteId={site.id} guests={reservationGuests} isAddingGuestsPossible={isAddingGuestsPossible} />
      </TabPanel>
      <TabPanel index={3} value={activeTabIndex}>
        <SiteGuests guests={guests} beds={beds} rooms={rooms} siteId={site.id} />
      </TabPanel>
      <TabPanel index={4} value={activeTabIndex}>
        <SiteHistory siteId={site.id} />
      </TabPanel>
      {
        isUserPrivileged && (
          <TabPanel index={5} value={activeTabIndex}>
            <SiteUsers users={users ?? []} site={site} revalidateUsers={revalidateUsers} />
          </TabPanel>
        )
      }
    </Layout>
  );
};
