import { SubscriptionUpgradeInfo } from './../models/Subscriptions/SubscriptionUpgradeInfo';
import { SubscriptionUpgradeInfoDto } from './../dtos/Subscriptions/SubscriptionUpgradeInfoDto';

/** Subscription upgrade info mapper. */
export namespace SubscriptionUpgradeInfoMapper {

  /** Maps dto to model. */
  export function fromDto(dto: SubscriptionUpgradeInfoDto): SubscriptionUpgradeInfo {

    return new SubscriptionUpgradeInfo({
      extraPaidBedCountDifference: dto.extraPaidBedCountDifference,
      oneTimePaymentAmount: dto.oneTimePaymentAmount,
      changedSubscriptionCost: dto.changedSubscriptionCost,
      nextMonthlyPaymentDate: dto.nextPeriodPaymentDate !== null ? new Date(dto.nextPeriodPaymentDate) : null,
    })
  }
}
