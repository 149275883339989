import styled from '@emotion/styled';
import { theme } from '../../styles/theme';

interface Props {

  /** Whether the checkbox is checked. */
  readonly isChecked: boolean;

  /** Whether content of the checkbox should be positioned in column. */
  readonly isColumn?: boolean;
}

export const CardCheckboxLabel = styled.label<Props>`
  padding: 16px;
  cursor: pointer;
  border: 2px solid ${theme.colors.gray};
  border-radius: 8px;
  transition: border 200ms;
  flex-direction: ${props => props.isColumn ? 'column' : 'initial'};
  border-color: ${props => props.isChecked ? theme.colors.blue : ''};
`;
