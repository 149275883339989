import styled from '@emotion/styled'

export const Container = styled.button<{ disabled?: boolean }>`
  background: #f2f2f2;
  border-radius: 6px;
  padding: 11px 16px;
  border: none;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #000000;
  width: 100%;
  display: flex;
  max-width: 284px;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.7;
  `}
`

export const SocialIcon = styled.div<{ image: 'google' | 'microsoft' }>`
  background-image: url('/assets/icons/${(props) => props.image}.svg');
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 20px;
`
