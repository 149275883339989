import React, { FC } from 'react';
import { Container, InputPreviewButtonContainer, Label, Text } from 'components/InputPreview/styled';
import { Margin } from 'components/Spacing';
import { ThemedButton } from 'components/ThemedButton';
import { StyledButton } from 'components/StyledButton/styled';

export interface Props {
  label: string;
  text: string;
  onEdit: VoidFunction;
}

export const InputPreview: FC<Props> = ({ label, text, onEdit }) => (
  <Container>
    <Label>{label}</Label>
    <Text>{text}</Text>
    <InputPreviewButtonContainer>
      <StyledButton variant="outlined" onClick={onEdit}>
        Edit
      </StyledButton>
    </InputPreviewButtonContainer>
  </Container>
);
