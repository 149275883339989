import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const UnderlinedHeadingText = styled.h2`
  font-size: var(--font-size-lg);
  font-weight: 600;
  margin-bottom: 16px;
`;

export const UnderlinedHeadingLine = styled.hr`
  border: 1px solid ${theme.colors.grayBorderColor};
  border-bottom: none;
  margin-bottom: 20px;
`;
