import styled from '@emotion/styled';
import { StyledButton, StyledButtonType } from 'components/StyledButton/styled';

export const OrganizationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 40px 24px;
  word-break: break-word;
`;

export const OrganizationHeaderNameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const OrganizationHeaderName = styled.h1`
  font-size: var(--font-size-2xlg);
  font-weight: 600;
`;

export const OrganizationHeaderLogo = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 4px;
  margin-right: 16px;
`;

export const OrganizationHeaderButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  min-width: 220px;
`;

export const OrganizationSitesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  margin-bottom: 16px;
`;

export const OrganizationNewSiteButtonContainer = styled.div`
  text-align: center;
`;
