import {
  Autocomplete,
  Button,
  MenuItem,
  Snackbar,
} from '@mui/material';
import { useUpdateGuestStatus } from 'api/guests';
import { EGuestStatus, GuestMethods, IGuestType } from 'api/guests/types';
import { useSiteDetails } from 'api/sites/useSiteDetails';
import { BaseMenu } from 'components/BaseMenu/BaseMenu';
import { AlertDialog } from 'components/Dialogs/AlertDialog/AlertDialog';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';
import { ImagePreviewDialog } from 'components/Dialogs/ImagePreviewDialog/ImagePreviewDialog';
import { StyledButton } from 'components/StyledButton/styled';
import { SinglePhotoUpload } from 'components/UploadPhoto/Single';
import { Field, useFormikContext } from 'formik';
import { useSiteBackButtonRoute } from 'hooks/routing/useSiteBackButtonRoute';
import { SiteEditingValues } from 'models/site/SiteEditingValues';
import React, { ChangeEvent, ChangeEventHandler, FC, SyntheticEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { theme } from 'styles/theme';
import { enumToArray } from 'utils/enumToArray';
import { ImagePrefix } from '../../../../../../components/UploadPhoto/ImagePrefix';
import { Bed } from './domain/Bed';
import { BedType } from './domain/BedType';
import {
  BedCardContainer,
  BedCardDialogText,
  BedCardPhotoContainer,
  BedEditContainer,
  BedEditControls,
  BedEditSlot,
  BedGuestGender,
  BedGuestName,
  BedNameContainer,
  BedTypeText,
  BedStatus,
  BedEditTextField,
} from './styled';

interface Props {
  /** Bed. */
  readonly bed: Bed;

  /** Handle bed photo change event. */
  readonly onBedPhotoChange: (bedPhoto?: string | null) => void;

  /** Whether edit mode is available or not. */
  readonly isEditModeAvailable: boolean;

  /** Control name of the bed. */
  readonly bedControlName: string;

  /** Handle the bed deletion event. */
  readonly onBedDeletion?: () => void;

  /** Guest. */
  readonly guest?: IGuestType;

  /** Whether the assign button is hidden */
  readonly isAssignButtonHidden?: boolean;
}

const BED_TYPES_LIST = enumToArray(BedType);

/** Bed card component. */
export const BedCard: FC<Props> = ({
  bed,
  onBedPhotoChange,
  isEditModeAvailable,
  onBedDeletion,
  bedControlName,
  guest,
  isAssignButtonHidden = false,
}) => {
  /** Form values. */
  const { values, setFieldValue, setFieldTouched } = useFormikContext<SiteEditingValues>();

  /** Name of current room. */
  const roomName =
    values.rooms.find((room) => room.beds.includes(bed))?.name ?? '';

  /** Whether bed deletion alert dialog is opened or not. */
  const [isDeletionAlertOpened, setIsDeletionAlertOpened] = useState<boolean>(
    false
  );

  /** Whether bed deletion confirm dialog is opened or not. */
  const [isDeletionConfirmOpened, setIsDeletionConfirmOpened] = useState<
    boolean
  >(false);

  /** Whether bed edition alert dialog is opened or not. */
  const [isEditAlertOpened, setIsEditAlertOpened] = useState<boolean>(false);

  /** Whether dialog with image preview is opened or not. */
  const [isImagePreviewDialogOpened, setIsImageDialogOpened] = useState<
    boolean
  >(false);

  /** Whether bed card is being edited. */
  const [isEdited, setIsEdited] = useState<boolean>(false);

  /** Whether notification is presented or not. */
  const [hasNotification, setHasNotification] = useState<boolean>(false);

  /** Handle bed deletion alert close event. */
  const onBedDeletionAlertClose = useCallback(
    () => setIsDeletionAlertOpened(false),
    []
  );

  /** Handle bed deletion confirm close event. */
  const onBedDeletionConfirmClose = useCallback(
    () => setIsDeletionConfirmOpened(false),
    []
  );

  /** Handle bed edition alert close event. */
  const onBedEditAlertClose = useCallback(
    () => setIsEditAlertOpened(false),
    []
  );

  /** Handle close event of image preview dialog. */
  const inImagePreviewClose = useCallback(
    () => setIsImageDialogOpened(false),
    []
  );

  const shouldFetchSiteDetails = guest != null;
  const { data: site } = useSiteDetails(guest?.siteId || '', shouldFetchSiteDetails);

  const assignRedirectRoute = useSiteBackButtonRoute(site?.organizationId.toString() ?? '', site?.id ?? '');

  const { handleUpdateStatus } = useUpdateGuestStatus(
    guest != null ? guest.siteId : null,
    guest != null
      ? () => history.push(assignRedirectRoute)
      : () => {},
    () => setHasNotification(true)
  );

  const history = useHistory();

  const [editableBedName, setEditableBedName] = useState(bed.name);

  /** Handle click on save button in bed edit. */
  const onBedEditSaveButtonClick = useCallback(() => {
    setIsEdited(false);
    setFieldValue(`${bedControlName}.name`, editableBedName);
    setFieldTouched('rooms', true);
  }, [bedControlName, editableBedName, setFieldValue, setFieldTouched]);

  /** Handle confirmation for bed deletion. */
  const onBedDeletionConfirm = useCallback(() => {
    if (onBedDeletion != null) {
      onBedDeletion();
      setFieldTouched('rooms', true);
    }
    setIsDeletionConfirmOpened(false);
  }, []);

  /** Handle click on bed remove button in menu. */
  const onBedRemoveButtonClick = useCallback((onMenuClose: () => void) => {
    onMenuClose();
    if (bed.isOccupied) {
      setIsDeletionAlertOpened(true);
    } else {
      setIsDeletionConfirmOpened(true);
    }
  }, []);

  /** Handle click on bed edit button. */
  const onBedEditButtonClick = useCallback((onMenuClose: () => void) => {
    onMenuClose();
    if (bed.isOccupied) {
      setIsEditAlertOpened(true);
    } else {
      setEditableBedName(bed.name);
      setIsEdited(true);
    }
  }, [setEditableBedName, setIsEditAlertOpened, setIsEdited, bed]);

  /** Handle close event for notifications. */
  const onNotificationClose = useCallback(() => setHasNotification(false), []);

  /** Handle bed assignment. */
  const onBedAssignClick = useCallback(() => {
    if (guest != null && bed.id != null) {
      handleUpdateStatus({
        guestId: guest.id,
        status: guest.acceptedAt ? EGuestStatus.REASSIGNED : EGuestStatus.ACCEPTED,
        bedId: Number(bed.id),
        feedback: null,
      });
    } else {
      setHasNotification(true);
    }
  }, [guest, bed]);

  const bedMenu = (
    <BaseMenu>
      {({ onMenuClose }) => (
        <div>
          <MenuItem
            onClick={() => onBedEditButtonClick(onMenuClose)}
            sx={{ color: theme.colors.blue }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => onBedRemoveButtonClick(onMenuClose)}
            sx={{ color: theme.colors.red }}
          >
            Remove
          </MenuItem>
        </div>
      )}
    </BaseMenu>
  );

  const bedEditControls = (
    <BedEditControls>
      <Button onClick={onBedEditSaveButtonClick} variant="contained">
        Save
      </Button>
    </BedEditControls>
  );

  /** Slot that displayed for edit mode. */
  const editModeSlot = isEdited ? bedEditControls : bedMenu;

  const userAssignSlot = (
    <>
      {!bed.isOccupied && !isAssignButtonHidden && (
        <StyledButton
          type="button"
          variant="outlined"
          onClick={onBedAssignClick}
        >
          Assign
        </StyledButton>
      )}
    </>
  );

  const handlePhotoUploadClick = useCallback(
    () => isEdited || bed.imagePath == null ?
      undefined :
      setIsImageDialogOpened(true),
    [isEdited, bed.imagePath, setIsImageDialogOpened],
  );

  const handleBedTypeChange = useCallback((_: SyntheticEvent<Element, Event>, value: string | null) => {
    if (value !== null) {
      setFieldValue(`${bedControlName}.type`, value)
    }
  }, [setFieldValue, bedControlName]);

  const handleBedNameChange: ChangeEventHandler<HTMLInputElement> = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEditableBedName(event.target.value);
  }, [setFieldValue, bedControlName]);

  return (
    <BedCardContainer>
      <BedCardPhotoContainer>
        <SinglePhotoUpload
          initImageUrl={bed.imagePath ?? ''}
          prefix={ImagePrefix.Bed}
          onChange={(value) => {
            onBedPhotoChange(value);
            setFieldTouched('rooms', true);
          }}
          size={'small'}
          isDeletionAvailable={isEdited}
          onClick={handlePhotoUploadClick}
          isDisabled={!isEditModeAvailable}
        />
      </BedCardPhotoContainer>
      <BedNameContainer>
        {isEdited ? (
          <BedEditContainer>
            <Autocomplete
              id="type"
              options={BED_TYPES_LIST}
              onChange={handleBedTypeChange}
              value={bed.type}
              clearOnBlur
              selectOnFocus
              handleHomeEndKeys
              renderInput={(params) => (
                <Field
                  {...params}
                  name="type"
                  component={BedEditTextField}
                  placeholder="Select Type"
                />
              )}
            />
            <BedEditTextField
              placeholder="Bed Name"
              value={editableBedName}
              onChange={handleBedNameChange}
            />
          </BedEditContainer>
        ) : (
          <>
            <p>{bed.name}</p>
            <BedTypeText>{bed.type}</BedTypeText>
          </>
        )}
      </BedNameContainer>
      <BedStatus isOccupied={bed.guest != null}>
        {bed.guest != null ? 'Occupied' : 'Vacant'}
      </BedStatus>
      <div>
        {bed.guest != null && (
          <>
            <BedGuestName>{GuestMethods.getFullName(bed.guest)}</BedGuestName>
            <BedGuestGender>{bed.guest.gender}</BedGuestGender>
          </>
        )}
      </div>
      <BedEditSlot>
        {isEditModeAvailable ? editModeSlot : userAssignSlot}
      </BedEditSlot>

      {/* Alert dialog for the case when user tries to delete occupied bed. */}
      <AlertDialog
        title="You can't remove the Bed"
        isOpened={isDeletionAlertOpened}
        onClose={onBedDeletionAlertClose}
      >
        <BedCardDialogText>{`The bed ${bed.name} in the ${roomName} is occupied.`}</BedCardDialogText>
        <BedCardDialogText>
          Please consider discharge the bed on Reservation page to edit or
          remove it
        </BedCardDialogText>
      </AlertDialog>

      {/* Alert dialog for the case when user tries to edit occupied bed. */}
      <AlertDialog
        title="You can't edit the Bed"
        isOpened={isEditAlertOpened}
        onClose={onBedEditAlertClose}
      >
        <BedCardDialogText>{`The ${bed.name} in ${roomName} is occupied.`}</BedCardDialogText>
        <BedCardDialogText>
          Please consider discharge the bed on Reservation page to edit or
          remove it.
        </BedCardDialogText>
      </AlertDialog>

      {/* Confirm dialog for bed deletion. */}
      <ConfirmDialog
        title={`Are you sure to delete the ${bed.name}?`}
        isOpened={isDeletionConfirmOpened}
        onClose={onBedDeletionConfirmClose}
        onConfirmationButtonClick={onBedDeletionConfirm}
        confirmButtonColor={'error'}
        confirmButtonText={'Delete the bed'}
        cancelButtonText={'Cancel'}
      >
        <BedCardDialogText>
          {`The ${bed.name} in ${roomName} will be deleted. You will not be able to undo this action.`}
        </BedCardDialogText>
      </ConfirmDialog>

      <ImagePreviewDialog
        isOpened={isImagePreviewDialogOpened}
        onClose={inImagePreviewClose}
        source={bed.imagePath ?? ''}
      ></ImagePreviewDialog>

      <Snackbar
        open={hasNotification}
        autoHideDuration={5000}
        onClose={onNotificationClose}
        message="An error occured while assigning the bed."
      />
    </BedCardContainer>
  );
};
