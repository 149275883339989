import React, { FC } from 'react';
import { BackLink, LinkWithoutStyle } from 'components/CancelLink/styled';
import { Gap } from 'components/Layout/Grid';
import { ArrowCircleLeftOutlined } from '@mui/icons-material';

export interface Props {
  onClick?: VoidFunction;
  label?: string;
  to?: any;
}

export const CancelLink: FC<Props> = ({ onClick, label, to, ...props }) => {
  const content = (
    <BackLink onClick={onClick}>
      <ArrowCircleLeftOutlined />
      <Gap size="sm" />
      {label ?? 'Cancel'}
    </BackLink>
  );

  if (onClick) {
    return content;
  }

  return (
    <LinkWithoutStyle to={to} {...props}>
      {content}
    </LinkWithoutStyle>
  );
};
