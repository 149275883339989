import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from 'styles/theme'

export const StyledSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  & > input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`

interface SwitchProps {

  /** Whether the slider is disabled. */
  readonly isDisabled?: boolean;
}

interface SliderProps extends SwitchProps {

  /** Whether the slider is checked. */
  readonly isChecked: boolean;
}

export const StyledSlider = styled.span<SliderProps>`
  position: absolute;
  cursor: ${props => props.isDisabled ? 'initial' : 'pointer'};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => {
    if (props.isDisabled) {
      return theme.colors.lightGray;
    };
    if (props.isChecked) {
      return theme.colors.blue;
    }
    return theme.colors.gray;
  }};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;

    ${(props) =>
      props.isChecked &&
      css`
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
      `}
  }
`

export const SwitchWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  padding: 5px 0;
`

export const SwitchLabel = styled.span<SwitchProps>`
  color: ${props => props.isDisabled ? theme.colors.darkerGray : theme.colors.dark};
  font-size: 0.75rem;
  padding-left: 15px;
  font-weight: 600;
`
