import React from 'react'
import { useHistory } from 'react-router-dom'
import { useUpdatePhone } from 'api/auth/useUpdatePhone/useUpdatePhone'
import { Layout } from 'components/Layout'
import { TwoFactorSetupForm } from 'forms/TwoFactorSetup'
import { IFormData } from 'forms/TwoFactorSetup/data'
import { ERoute } from 'router/data'

export const TwoFactorSetup = () => {
  const history = useHistory()
  const { updatePhone, isLoading } = useUpdatePhone()

  const handleSubmit = async (values: IFormData) => {
    await updatePhone(values)
    history.push({
      pathname: ERoute.TWO_FACTOR_CONFIRM,
      search: `?phone=${encodeURIComponent(values.phone)}`,
    })
  }

  return (
    <Layout grayBg>
      <TwoFactorSetupForm isLoading={isLoading} handleSubmit={handleSubmit} />
    </Layout>
  )
}
