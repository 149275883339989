import { useOrganization } from 'api/organizations/useOrganization';
import { ButtonIcon } from 'components/ButtonIcon/styled';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { Layout } from 'components/Layout';
import { NoContentBlock } from 'components/NoContentBlock/styled';
import { mapOrganizationTypeHrefToName, OrganizationsNav, OrganizationsNavItem } from 'components/OrganizationsNav/OrganizationsNav';
import { Organization } from 'models/organization/Organization';
import { LoadingPage } from 'pages/LoadingPage';
import React, { FC, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { FirebaseService } from 'services/firebase';
import { SiteCard } from './components/SiteCard/SiteCard';
import { UsersTable } from '../../components/UsersTable/Table';
import {
  OrganizationHeader,
  OrganizationHeaderButtonsContainer,
  OrganizationHeaderLogo,
  OrganizationHeaderName,
  OrganizationHeaderNameContainer,
  OrganizationNewSiteButtonContainer,
  OrganizationSitesGrid,
} from './styled';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';
import { Site } from 'models/site/Site';
import { routes } from 'router/data';
import { useSites } from 'api/sites/useSites';
import { useDeleteSite } from 'api/sites/useDeleteSite';
import { useOrganizationUsers } from 'api/users/useOrganizationUsers';
import { useOrganizationTypeHref } from '../../hooks/routing/useOrganizationTypeFromHref';
import { ErrorMessage } from 'components/FetchErrorMessage/FetchErrorMessage';
import { StyledButton } from 'components/StyledButton/styled';
import { useAuth } from '../../hooks/auth/useAuth';
import { useIsUserPrivileged } from 'hooks/auth/useIsUserPrivileged';

export interface OrganizationDetailsRouteProps {
  /** ID of the organization. */
  readonly organizationId: Organization['id'];
}

export const OrganizationDetailsPage: FC<RouteComponentProps<OrganizationDetailsRouteProps>> = ({ match }) => {

  const { organizationId } = match.params;

  const isUserPrivileged = useIsUserPrivileged(organizationId);

  const organizationsTypeHref = useOrganizationTypeHref();

  const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);
  const handleUsersModalOpen = () => setIsUsersModalOpen(true);
  const handleUsersModalClose = () => setIsUsersModalOpen(false);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setIsDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const [selectedSite, setSelectedSite] = useState<Site | null>(null);

  const { data: organization, error: organizationError } = useOrganization(organizationId);

  const { data: sitesData, revalidate: revalidateSites } = useSites(organizationId);

  const { data: usersData, error: userError, revalidate: revalidateUsers } = useOrganizationUsers(organizationId, undefined, isUserPrivileged);

  const { handleDelete } = useDeleteSite(selectedSite?.id ?? '');

  if (organizationError != null) {
    return (
      <Layout withSideBar>
        <ErrorMessage message={organizationError.toString()} />
      </Layout>
    );
  }

  if (
    organization == null ||
    sitesData == null ||
    isUserPrivileged && usersData == null
  ) {
    return <LoadingPage />;
  }

  const handleDeleteSiteButtonClick = (site: Site) => {
    setSelectedSite(site);
    handleDeleteDialogOpen();
  };

  const handleSiteDelete = async () => {
    await handleDelete();
    await revalidateSites();
    handleDeleteDialogClose();
  };

  const sites = sitesData.items;
  const users = usersData?.items;

  const navigationItems: readonly OrganizationsNavItem[] =[
    {
      name: mapOrganizationTypeHrefToName(organizationsTypeHref),
      linkPath: routes.OrganizationsList(organizationsTypeHref),
    },
    {
      name: organization.name,
    },
  ];

  return (
    <Layout withSideBar>
      <OrganizationsNav items={navigationItems} />
      <OrganizationHeader>
        <OrganizationHeaderNameContainer>
          <OrganizationHeaderLogo src={FirebaseService.getImageSrc(organization.logoPath)} alt="Logo" />
          <OrganizationHeaderName>{organization.name}</OrganizationHeaderName>
        </OrganizationHeaderNameContainer>
        {
          isUserPrivileged && (
            <OrganizationHeaderButtonsContainer>
              {organization.isCommercial &&
                <StyledButton component={Link} to={routes.OrganizationInsurances(organization.id, organizationsTypeHref)}>
                  Insurances List
                </StyledButton>
              }
              <StyledButton type="button" onClick={handleUsersModalOpen}>
                <ButtonIcon src="/assets/icons/admin-list-icon.svg" alt="" />
                Owners List
              </StyledButton>
              <StyledButton component={Link} to={routes.SiteCreate(organization.id)}>
                <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
                New Site
              </StyledButton>
            </OrganizationHeaderButtonsContainer>
          )
        }
      </OrganizationHeader>
      {sites.length !== 0 ? (
        <>
          <OrganizationSitesGrid>
            {sites.map((site) => (
              <SiteCard
                key={site.id}
                site={site}
                organizationId={organization.id}
                onDeleteButtonClick={handleDeleteSiteButtonClick}
              />
            ))}
          </OrganizationSitesGrid>
          {
            isUserPrivileged && (
            <OrganizationNewSiteButtonContainer>
              <StyledButton component={Link} to={routes.SiteCreate(organization.id)}>
                <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
                New Site
              </StyledButton>
            </OrganizationNewSiteButtonContainer>
            )
          }
        </>
      ) : (
        <NoContentBlock>No Sites yet</NoContentBlock>
      )}
      {
        isUserPrivileged && (
          <>
            <ModalWindow isScrollable isOpen={isUsersModalOpen} onClose={handleUsersModalClose}>
              <UsersTable
                siteId={sitesData.items[0]?.id}
                users={users ?? []}
                organizationId={organizationId}
                revalidateUsers={revalidateUsers}
                isOwnersOnlyTable
              />
            </ModalWindow>
            <ConfirmDialog
              isOpened={isDeleteDialogOpen}
              onClose={handleDeleteDialogClose}
              onConfirmationButtonClick={handleSiteDelete}
              confirmButtonColor="warning"
              confirmButtonText="Delete"
              title={selectedSite?.name}
            >
              Are you sure you want to delete {selectedSite?.name}?
            </ConfirmDialog>
          </>
        )
      }
    </Layout>
  );
};
