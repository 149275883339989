import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from 'styles/theme'

interface Props {
  isOpen: boolean
  isRight?: boolean
  isTop?: boolean
  centered?: boolean
}

export const TooltipWrap = styled.span<Props>`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  /* width: 200px; */
  max-width: 207px;
  height: fit-content;
  width: max-content;
  padding: 11px;
  background-color: ${theme.colors.dark};
  color: ${theme.colors.lightGray};
  font-size: 12px;
  text-align: left;
  border-radius: 6px;
  position: absolute;
  z-index: 1;

  ${(props) =>
    props.isTop
      ? css`
          top: 0;
        `
      : css`
          bottom: 0;
        `}

  ${(props) =>
    props.centered &&
    css`
      bottom: 50%;
      transform: translateY(50%);
    `}

  ${(props) =>
    props.isRight
      ? css`
          left: calc(100% + 10px);
        `
      : css`
          right: calc(100% + 10px);
        `}

  &::after {
    content: '';
    position: absolute;
    margin-left: -1px;
    border-width: 5px;
    border-style: solid;
    top: 13px;

    ${(props) =>
      props.isRight
        ? css`
            right: 100%;
          `
        : css`
            left: 100%;
          `}
    border-color: ${(props) =>
      props.isRight
        ? `transparent ${theme.colors.dark} transparent transparent`
        : `transparent transparent transparent ${theme.colors.dark}`};
  }
`
