/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useChallengePhone } from 'api/auth/useChallengePhone/useChallengePhone'
import { useTwoFactor } from 'api/auth/useTwoFactor/useTwoFactor'
import { Layout } from 'components/Layout'
import { TwoFactorConfirmForm } from 'forms/TwoFactorConfirm'
import { IFormData } from 'forms/TwoFactorConfirm/data'
import { searchParams } from 'services/http'

export const TwoFactorConfirm = () => {
  const { challengePhone } = useChallengePhone()
  const { authorize, isLoading, error } = useTwoFactor()
  const location = useLocation()
  const phone = searchParams('phone', location.search) ?? undefined

  useEffect(() => {
    challengePhone({ phone })
  }, [])

  const handleSubmit = (values: IFormData) => authorize(values.code)

  return (
    <Layout grayBg>
      <TwoFactorConfirmForm
        phone={phone}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        errorText={error ? 'Invalid confirmation code' : undefined}
      />
    </Layout>
  )
}
