import { Formik, FormikHelpers } from 'formik';
import React, { FC, useState } from 'react';
import { array, number, NumberSchema, object, ObjectSchema, Shape, string } from 'yup';
import { useSiteServices } from 'api/sites/useSiteServices';
import { InputField } from 'components/InputField';
import { Gap, RowView } from 'components/Layout/Grid';
import { FormSectionWrap } from 'components/Layout/Grid/FormSectionWrap';
import { MainHeading, SecondaryHeading } from 'components/Typography';
import { UniversalSelectFields } from 'components/UniversalSelectFields';
import { ServicesBlock } from 'pages/SiteCreate/components/Services/ServicesBlock';
import { apiClient } from 'services/api/apiClient';
import { getAuthHeaders } from 'services/auth/helpers';
import { guestTransformer } from 'services/transformers';
import { EServiceCategory, CreateGuestFormInfo, Option, SiteService, Services } from 'services/types';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { AdditionalInfoContainer, ButtonsRow, HorizontalLine } from './styled';
import { StyledButton } from 'components/StyledButton/styled';
import { Site } from 'models/site/Site';
import { LocationInput } from 'components/LocationInput/LocationInput';
import { PhoneInput } from 'components/PhoneInput/PhoneInput';
import { AgeServiceType, Service } from 'models/service';
import { enumToArray } from 'utils/enumToArray';
import { ErrorMessage } from '../../../../components/FetchErrorMessage/FetchErrorMessage';
import { PaymentMethod, PaymentMethodCategory } from '../../../../models/paymentMethod/PaymentMethod';
import { InsuranceCompany } from '../../../../models/insuranceCompany/InsuranceCompany';

const SERVICES_ERROR_MESSAGE = 'Add at least one service';

// This regexp checks that value starts with +1 and have 11 numbers in general.
const phoneRegexp = /[+]1[0-9]{10}$/;

type AgeGroupNumber = 1 | 2 | 3;

const MAP_AGE_SERVICE_TYPE_TO_NUMBER: Readonly<Record<AgeServiceType, AgeGroupNumber>> = {
  [AgeServiceType.FirstAgeGroup]: 1,
  [AgeServiceType.SecondAgeGroup]: 2,
  [AgeServiceType.ThirdAgeGroup]: 3,
}

/**
 * Validates that services checked in the form include at least one of the services provided as array.
 * @param serviceIds Ids which must include one of the services.
 * @param services Services which must be included in the array of the ids.
 * @returns True if at least one of the services provided as array included in the array of the ids, false otherwise.
 */
function checkServiceInclude(serviceIds: string[], services: SiteService[]): boolean {
  return serviceIds.some((serviceId) => services.map((service) => service.id).includes(serviceId));
}

/**
 * Gets validation schema for the new guest form.
 * @param services Object with all the services required for checked services validation.
 */
function getValidationSchema(
  services: Services,
  isCommercial: Site['isCommercial'],
): ObjectSchema<
  Shape<
    object | undefined,
    {
      gender: string;
      ageServiceId: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      serviceIds: unknown[] | undefined;
    }
  >
> {
  return object().shape({
    gender:
      string()
        .required('Gender is a required field.'),
    ageServiceId:
      string()
        .required('Age is a required field.'),
    firstName:
      string()
        .required('First name is a required field.')
        .max(50, 'Maximum length of first name is 50 symbols.'),
    lastName:
      string()
        .required('Last name is a required field.')
        .max(50, 'Maximum length of last name is 50 symbols.'),
    email:
      string()
        .required('Email is a required field.')
        .email('Email must be a valid email'),
    phone:
      string()
        .required('Phone number is a required field.')
        .test('Validate-phone', 'Phone number is incorrect', (value) => phoneRegexp.test(value)),
    serviceIds:
      array()
        .test('Validate-services', SERVICES_ERROR_MESSAGE, (values) => checkServiceInclude(values, services.General)),
    typeOfClientServiceId: isCommercial ?
      string()
        .required('Type of client is a required field.') :
      string(),
    paymentMethod: isCommercial ?
      object()
        .nullable()
        .required('Payment method is a required field.') :
      object()
         .nullable(),
    insuranceCompanyId:
      number()
        .nullable()
        .when('paymentMethod', {
          is: (value: CreateGuestFormInfo['paymentMethod']) => value !== null && value.category === PaymentMethodCategory.Insurance,
          then: (schema: NumberSchema) => schema.required('Please select insurance company.'),
        }),
  });
}

interface Props {
  /** On cancel callback. */
  readonly onCancel: () => void;

  /** On done callback. */
  readonly onDone: (response: any) => void;

  /** Site. */
  readonly site: Site;
}

export const CreateGuestForm: FC<Props> = ({ onCancel, onDone, site }) => {

  const { id: siteId, services: siteServices, isCommercial } = site;

  const { data: services, error } = useSiteServices(isCommercial);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  if (error) {
    return <div>failed to load services</div>;
  }

  if (services == null) {
    return <div>loading site services...</div>;
  }

  const parseServices = (values: CreateGuestFormInfo) => {
    const ageServices = services[EServiceCategory.AGE];
    const genderServices = services[EServiceCategory.GENDER];

    if (values.gender !== '') {
      const genderService = genderServices.find((val) => val.name == values.gender);
      if (genderService != null) {
        values.serviceIds.push(Number(genderService.id));
      }
    }
    if (values.ageServiceId !== '') {
      const ageService = ageServices.find((val) => val.id === values.ageServiceId);

      if (ageService != null) {
        // Using type assertion to pass service to map and includes method.
        const ageServiceType = ageService.type as AgeServiceType;
        if (enumToArray(AgeServiceType).includes(ageServiceType)) {
          values.ageGroup = MAP_AGE_SERVICE_TYPE_TO_NUMBER[ageServiceType]
        }
        values.serviceIds.push(Number(ageService.id));
      }
    }
    values.serviceIds = values.serviceIds.filter((val) => val !== undefined);
    return values;
  };

  const ageOptions: readonly Option<Service['id']>[] = services.Age.filter((service) =>
    siteServices.some((siteService) => siteService.id === service.id)
  ).map(service => {
    return {
      value: service.id,
      label: service.name,
    };
  });

  const genderOptions: readonly Option<Service['name']>[] = services.Gender.filter((service) =>
    siteServices.some((siteService) => siteService.id === service.id)
  ).map(service => {
    return {
      value: service.name,
      label: service.name,
    };
  });

  const typeOfClientOptions: readonly Option<Service['id']>[] = isCommercial ? services.Client.filter((service) =>
    siteServices.some((siteService) => siteService.id === service.id)
  ).map(service => {
    return {
      value: service.id,
      label: service.name,
    };
  }) : [];

  const paymentMethodOptions: readonly Option<PaymentMethod>[] = site.organizationReservationPaymentMethods.map(
    method => ({
      value: method,
      label: method.name,
    })
  );

  const insuranceCompanyOptions: readonly Option<InsuranceCompany['id']>[] = site.insuranceCompanies.map(
    company => ({
      value: company.id,
      label: company.name,
    })
  )

  const availableServiceIds = siteServices.map((service) => service.id);

  const initialValues: CreateGuestFormInfo = {
    gender: '',
    ageGroup: 0,
    ageServiceId: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    location: '',
    serviceIds: [],
    siteId: '',
    typeOfClientServiceId: '',
    paymentMethod: null,
    insuranceCompanyId: null,
  };

  const handleSubmit = (values: CreateGuestFormInfo, formikHelpers: FormikHelpers<CreateGuestFormInfo>) => {
    values = parseServices(values);
    apiClient({
      url: '/api/guests',
      method: 'post',
      data: {
        ...values,
        gender: values.gender === 'Male' ? 1 : values.gender === 'Female' ? 2 : 0,
        siteId,
      },
      headers: getAuthHeaders(),
      transformRequest: guestTransformer.to,
    })
      .then((response) => {
        onDone(response);
        formikHelpers.resetForm({ values: initialValues });
      })
      .catch((err) => {
        // TODO: refactor this whole function has to use the generic API client and use generic refresh token interceptor 🤯
        // errorHandle(err.response.errors)
        // if (err.response?.status === 401) {
        //   await authManager.refresh()
        // }
        setErrorMessage(err.response.data.message);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={() => getValidationSchema(services, isCommercial)}
    >
      {({ submitForm, resetForm, isSubmitting, errors, values, touched, setFieldValue, isValid }) => (
        <FormSectionWrap>
          <MainHeading>New Guest</MainHeading>
          <SecondaryHeading>Personal Information</SecondaryHeading>
          <HorizontalLine noMarginTop />
          <RowView alignItems="flex-start">
            <UniversalSelectFields
              name="gender"
              options={genderOptions}
              label="Gender"
              isRequired
              hasErrors={errors.gender != null && touched.gender}
            />
            <Gap />
            <UniversalSelectFields
              name="ageServiceId"
              options={ageOptions}
              label="Age"
              isRequired
              hasErrors={errors.ageServiceId != null && touched.ageServiceId}
            />
          </RowView>
          <RowView alignItems="flex-start" mb={isCommercial ? undefined : 30}>
            <InputField name="firstName" label="First Name" isRequired />
            <Gap />
            <InputField name="lastName" label="Last Name" isRequired />
          </RowView>
          {isCommercial && <AdditionalInfoContainer>
            <RowView>
              <UniversalSelectFields
                name="typeOfClientServiceId"
                options={typeOfClientOptions}
                label="Type of client"
                isRequired
                hasErrors={errors.typeOfClientServiceId != null && touched.typeOfClientServiceId}
              />
            </RowView>
            <RowView>
              <UniversalSelectFields
                name="paymentMethod"
                options={paymentMethodOptions}
                label="Payment method"
                isRequired
                hasErrors={errors.paymentMethod != null && touched.paymentMethod}
              />
            </RowView>
            {values.paymentMethod?.category === PaymentMethodCategory.Insurance && <RowView>
              <UniversalSelectFields
                name="insuranceCompanyId"
                options={insuranceCompanyOptions}
                label="Insurance company"
                isRequired
                hasErrors={errors.insuranceCompanyId != null && touched.insuranceCompanyId}
              />
            </RowView>}
          </AdditionalInfoContainer>}

          <SecondaryHeading>Contacts</SecondaryHeading>
          <HorizontalLine noMarginTop />
          <RowView alignItems="flex-start">
            <PhoneInput name="phone" label="Phone Number" isRequired />
            <Gap />
            <InputField name="email" label="Email" isRequired />
          </RowView>
          <LocationInput
            allowInvalidAddress
            name="location"
            locationType="(cities)"
            label="Current City/Location"
          />

          <ServicesBlock
            services={services[EServiceCategory.GENERAL]}
            heading="Services"
            withHeadingLine
            withTwoColumns
            availableServiceIds={availableServiceIds}
          />
          {(!checkServiceInclude(
            values.serviceIds.map((serviceId) => serviceId?.toString()),
            services.General
          ) ||
            !checkServiceInclude(
              values.serviceIds.map((serviceId) => serviceId?.toString()),
              services.Disability
            )) &&
            typeof errors.serviceIds === 'string' &&
            touched.serviceIds && <ValidationError message={errors.serviceIds ? errors.serviceIds : undefined} />}
          <ServicesBlock
            services={services[EServiceCategory.DISABILITY]}
            heading="Special Needs"
            withHeadingLine
            withTwoColumns
            availableServiceIds={availableServiceIds}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <ButtonsRow alignItems="flex-start" justifyContent="center">
            <StyledButton variant="contained" onClick={submitForm} disabled={isSubmitting || !isValid} width="50%">
              Add Guest
            </StyledButton>
            <Gap />
            <StyledButton
              variant="outlined"
              onClick={() => {
                resetForm();
                onCancel();
              }}
              disabled={isSubmitting}
              width="50%"
            >
              Cancel
            </StyledButton>
          </ButtonsRow>
        </FormSectionWrap>
      )}
    </Formik>
  );
};
