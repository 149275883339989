type ResubscribePaymentInfoConstructorData = ResubscribePaymentInfo;

/** Resubscribe payment info. */
export class ResubscribePaymentInfo {

  /** Id. */
  public readonly paymentId: number;

  /** Client secret. */
  public readonly clientSecret: string;

  public constructor(data: ResubscribePaymentInfoConstructorData) {
    this.paymentId = data.paymentId;
    this.clientSecret = data.clientSecret;
  }
}
