import React, { FC } from 'react'
import { SecondaryHeading } from 'components/Typography'

export interface Props {
    label: string
}

export const NoData: FC<Props> = ({ label }) => (
    <SecondaryHeading>{label}</SecondaryHeading>
)
