import styled from '@emotion/styled'

export const FormWrap = styled.div`
  display: grid;
  grid-template-columns: 260px 1fr;
  grid-template-rows: max-content;
`

export const FormContentWrap = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  grid-column: 2 / 3;
`

export const SideBarWrap = styled.div`
  width: 260px;
`
