/**
 * Formats date to human readable string with long month.
 * @param date Date to format.
 */
export function formatDateToReadableString(date: Date): string {
  return `
    ${date.toLocaleString('en-US', { month: 'long' })}
    ${date.getDate()}
    ${date.getFullYear()}
  `;
}
