import React, { FC } from 'react';
import {
  LayoutWrap,
  ContentWrap,
  Footer,
  TwoColumnsWrap,
  OuterWrap,
  TermsAndConditionsLink,
  PhoneLink,
} from 'components/Layout/styled';
import { SideBar } from 'components/SideBar';
import { TopHeader } from 'components/TopHeader';

interface Props {
  withSideBar?: boolean;
  aside?: React.ReactElement;
  grayBg?: boolean;
}

export const Layout: FC<Props> = ({
  withSideBar,
  aside,
  grayBg,
  children,
}) => (
  <OuterWrap grayBg={grayBg}>
    <LayoutWrap>
      <div>
        <TopHeader />

        <TwoColumnsWrap>
          {withSideBar && <SideBar />}
          {aside}

          <ContentWrap>{children}</ContentWrap>
        </TwoColumnsWrap>
      </div>

      <Footer>
        <PhoneLink href="tel:(800) 685-7460">(800) 685-7460</PhoneLink>
        <TermsAndConditionsLink
          href="https://theteenproject.com/privacy-policy/"
          target="_blank"
        >
          Privacy Notice
        </TermsAndConditionsLink>
      </Footer>
    </LayoutWrap>
  </OuterWrap>
);
