import styled from '@emotion/styled'
import { theme } from 'styles/theme'

export const SitesWrap = styled.div`
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
`

export const OrgBlockWrap = styled.div`
  width: 300px;
  border: 1px solid ${theme.colors.lightGray};
  border-radius: 8px;
  margin-right: 10px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
`

export const InfoContentWrap = styled.div`
  flex: 1;
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
`

export const OrgImage = styled.img`
  width: 100%;
  height: 128px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

export const HeaderBlockWrap = styled.div`
  margin: 10px 0;
  margin-bottom: 30px;
`

export const SubText = styled.span`
  color: ${theme.colors.grayText};
  font-size: 12px;
  margin-bottom: 5px;
`

export const NameTitle = styled.h2`
  color: ${theme.colors.dark};
  font-size: 20px;
  line-height: 26px;
  font-weight: 300;
`

export const BedsLabel = styled.h3`
  color: ${theme.colors.dark};
  font-size: 20px;
  font-weight: 700;
`

export const DarkText = styled.span`
  color: ${theme.colors.dark};
  font-size: 12px;
  font-weight: 400;
  padding: 3px 0;
`

export const ContactsWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const BlockWrap = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const AdditionalInfoWrap = styled.div`
  display: flex;
`
