import { ServiceDto } from 'dtos/ServiceDto';
import { useFetch } from 'hooks/api';
import { Service } from 'models/service';
import { servicesTransformer } from 'services/transformers';
import { EServiceCategory, SiteService } from 'services/types';
import { Organization } from '../../models/organization/Organization';
import { Site } from '../../models/site/Site';

export const useSiteServices = (isCommercial: Site['isCommercial'] | Organization['isCommercial'] = false) =>
  useFetch<{ [key in EServiceCategory]: Service[] }, ServiceDto>({
    url: `/api/SiteServices/${isCommercial ? 'commercial' : 'nonCommercial'}`,
    responseTransformer: servicesTransformer.from,
  });
