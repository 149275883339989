import { AgeServiceTypeDto, DisabilityServiceTypeDto, GenderServiceTypeDto, GeneralServiceTypeDto, ServiceCategoryDto, ServiceDto, ServiceTypeDto } from 'dtos/ServiceDto';
import { AgeServiceType, DisabilityServiceType, GenderServiceType, GeneralServiceType, Service, ServiceCategory, ServiceType } from 'models/service';
import { invertDictionary } from 'utils/invertDictionary';

const MAP_CATEGORY_DTO_TO_MODEL: Readonly<Record<ServiceCategoryDto, ServiceCategory>> = {
  [ServiceCategoryDto.General]: ServiceCategory.General,
  [ServiceCategoryDto.Disability]: ServiceCategory.Disability,
  [ServiceCategoryDto.Age]: ServiceCategory.Age,
  [ServiceCategoryDto.Gender] : ServiceCategory.Gender,
}

const MAP_CATEGORY_MODEL_TO_DTO = invertDictionary(MAP_CATEGORY_DTO_TO_MODEL);

const MAP_TYPE_DTO_TO_MODEL: Readonly<Record<ServiceTypeDto, ServiceType>> = {
  [GeneralServiceTypeDto.DrugTreatment]: GeneralServiceType.DrugTreatment,
  [GeneralServiceTypeDto.Perinatal]: GeneralServiceType.Perinatal,
  [GeneralServiceTypeDto.WithChildren]: GeneralServiceType.WithChildren,
  [DisabilityServiceTypeDto.PhysicalDisability]: DisabilityServiceType.PhysicalDisability,
  [DisabilityServiceTypeDto.Cognitive]: DisabilityServiceType.Cognitive,
  [DisabilityServiceTypeDto.MedicalNeeds]: DisabilityServiceType.MedicalNeeds,
  [DisabilityServiceTypeDto.Blind]: DisabilityServiceType.Blind,
  [DisabilityServiceTypeDto.Deaf]: DisabilityServiceType.Deaf,
  [DisabilityServiceTypeDto.MentalHealth]: DisabilityServiceType.MentalHealth,
  [GenderServiceTypeDto.Male]: GenderServiceType.Male,
  [GenderServiceTypeDto.Female]: GenderServiceType.Female,
  [GenderServiceTypeDto.Other]: GenderServiceType.Other,
  [AgeServiceTypeDto.FirstAgeGroup]: AgeServiceType.FirstAgeGroup,
  [AgeServiceTypeDto.SecondAgeGroup]: AgeServiceType.SecondAgeGroup,
  [AgeServiceTypeDto.ThirdAgeGroup]: AgeServiceType.ThirdAgeGroup,
}

const MAP_TYPE_MODEL_TO_DTO = invertDictionary(MAP_TYPE_DTO_TO_MODEL);

export namespace ServiceMapper {

  /** @inheritdoc */
  export function fromDto(dto: ServiceDto): Service {
    return new Service({
      id: dto.id,
      name: dto.name,
      category: MAP_CATEGORY_DTO_TO_MODEL[dto.category],
      type: MAP_TYPE_DTO_TO_MODEL[dto.type],
    })
  };

  /** @inheritdoc */
  export function toDto(model: Service): ServiceDto {
    return {
      id: model.id,
      name: model.name,
      category: MAP_CATEGORY_MODEL_TO_DTO[model.category],
      type: MAP_TYPE_MODEL_TO_DTO[model.type],
    }
  }
}
