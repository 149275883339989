import { UnderlinedHeading } from 'pages/OrganizationSiteDetails/components/SiteInformation/components/UnderlinedHeading/UnderlinedHeading';
import React, { FC, memo } from 'react';
import { SubscriptionContainer, SubscriptionsList } from './styled';
import { useAuth } from 'hooks/auth/useAuth';
import { OrganizationSubscription } from './components/OrganizationSubscription/OrganizationSubscription';

const CurrentSubscriptionsBlockComponent: FC = () => {

  const { roleOwnerOrganizationIds } = useAuth().state.userData;


  return (
    <SubscriptionContainer>
      <UnderlinedHeading text="Subscriptions" />
      <SubscriptionsList>
        {
          roleOwnerOrganizationIds.map(organizationId => <OrganizationSubscription organizationId={organizationId} key={organizationId} />)
        }
      </SubscriptionsList>
    </SubscriptionContainer>
  )
}

export const CurrentSubscriptionsBlock = memo(CurrentSubscriptionsBlockComponent);
