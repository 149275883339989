import { StrictOmit } from "../../utils/types/strictOmit";

type SubscriptionUpgradeInfoCreationData = StrictOmit<SubscriptionUpgradeInfo, 'formattedNextMonthlyPaymentDate'>;

/** Information about subscription upgrade. */
export class SubscriptionUpgradeInfo {

  /** Count of beds which need to pay for. */
  public readonly extraPaidBedCountDifference: number;

  /** One time payment amount. */
  public readonly oneTimePaymentAmount: number;

  /** Changed cost of subscription. */
  public readonly changedSubscriptionCost: number | null;

  /** Date of the next payment day. */
  public readonly nextMonthlyPaymentDate: Date | null;

  public constructor(data: SubscriptionUpgradeInfoCreationData) {
    this.extraPaidBedCountDifference = data.extraPaidBedCountDifference;
    this.oneTimePaymentAmount = data.oneTimePaymentAmount;
    this.changedSubscriptionCost = data.changedSubscriptionCost;
    this.nextMonthlyPaymentDate = data.nextMonthlyPaymentDate;
  }

  /** Formatted next monthly payment date. */
  public get formattedNextMonthlyPaymentDate(): string | null {
    return this.nextMonthlyPaymentDate?.toLocaleDateString('en-US', { month: 'long', day: 'numeric' }) ?? null;
  }
}
