import React, { FC, memo } from 'react';
import { SiteHeader, SiteHeaderLogo, SiteHeaderName } from './styled';
import { FirebaseService } from 'services/firebase';
import { Site } from 'models/site/Site';

interface Props {

  /** Site. */
  readonly site: Site;
}

const OrganizationSiteHeaderComponent: FC<Props> = ({ site }) => {
  return (
    <SiteHeader>
      <SiteHeaderLogo src={FirebaseService.getImageSrc(site.coverImage?.imagePath)} alt="" />
      <SiteHeaderName>{site.name}</SiteHeaderName>
    </SiteHeader>
  )
}

export const OrganizationSiteHeader = memo(OrganizationSiteHeaderComponent);
