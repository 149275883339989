import React, { FC } from 'react';
import { Logo } from 'components/Logo';
import { Header, LogoutLink, RightMenuWrap } from 'components/TopHeader/styled';
import { StyledLink } from 'components/Typography';
import { useAuth } from 'hooks/auth/useAuth';

export const TopHeader: FC = () => {
  const {
    state: { isSignedIn, isAuthorized },
    actions: { logout },
  } = useAuth();

  return (
    <Header>
      <Logo />

      <RightMenuWrap>
        {isSignedIn && isAuthorized && (
          <StyledLink to="/user-settings">Settings</StyledLink>
        )}
        {isSignedIn && <LogoutLink onClick={logout}>Logout</LogoutLink>}
      </RightMenuWrap>
    </Header>
  );
};
