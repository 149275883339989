import React, { useContext } from 'react'
import { Message } from 'components/Message'
import { MessagesContainer } from 'components/Message/styled'
import { FlashMessagesContext } from 'hooks/flash/Context'

export const Flash = () => {
  const {
    state: { messages },
    actions: { hideMessage },
  } = useContext(FlashMessagesContext)

  const handleHideMessage = (id: string) => () => hideMessage(id)

  return (
    <MessagesContainer>
      {messages.map(
        ({ message, id, type }) => (
          <Message onClose={handleHideMessage(id)} label={message} key={id} />
        ),
        messages
      )}
    </MessagesContainer>
  )
}
