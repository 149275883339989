type FailedLoginLogConstructorData = FailedLoginLog;

/** Failed login log. */
export class FailedLoginLog {

  /** ID. */
  public readonly id: number;

  /** Login. */
  public readonly login: string;

  /** Date of failed login. */
  public readonly loginFailedAt: Date;

  public constructor(data: FailedLoginLogConstructorData) {
    this.id = data.id;
    this.login = data.login;
    this.loginFailedAt = data.loginFailedAt;
  }
}
