import { Formik } from 'formik';
import React, { FC } from 'react';
import { InputField } from 'components/InputField';
import { Margin } from 'components/Spacing';
import { ThemedButton } from 'components/ThemedButton';
import { initialValues, validationSchema } from 'forms/ChangePassword/data';
import { Props } from 'forms/ChangePassword/types';
import { ControlsContainer } from 'forms/EditPhone/styled';
import { StyledForm } from 'forms/SignIn/styled';
import { ErrorMessageContainer } from './styled';
import { ValidationError } from 'components/ValidationError/ValidationError';

export const ChangePasswordForm: FC<Props> = ({
  handleSubmit,
  isLoading,
  handleCancel,
  error,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
  >
    {({ submitForm }) => (
      <StyledForm noCenter>
        <InputField
          label="Old Password"
          name="oldPassword"
          type="password"
          placeholder="••••••"
        />
        <Margin top={25} />
        <InputField
          description="Please use at least 8 characters, numbers, lower,  and upper case."
          label="New Password"
          name="password"
          type="password"
          placeholder="••••••"
        />
        <Margin top={25} />
        <InputField
          description="Please use at least 8 characters, numbers, lower,  and upper case."
          label="Confirm your password"
          name="passwordConfirmation"
          type="password"
          placeholder="••••••"
        />
        <ErrorMessageContainer>
          <ValidationError message={error} />
        </ErrorMessageContainer>
        <ControlsContainer>
          <ThemedButton
            disabled={isLoading}
            type="submit"
            width="100%"
            onClick={submitForm}
            isControl
          >
            Continue
          </ThemedButton>
          <Margin left={25} />
          <ThemedButton
            disabled={isLoading}
            type="button"
            width="100%"
            onClick={handleCancel}
            isControl
            outlined
          >
            Cancel
          </ThemedButton>
        </ControlsContainer>
      </StyledForm>
    )}
  </Formik>
);
