import { useFormikContext } from 'formik';
import React, { FC, memo, useCallback } from 'react';
import { Tariff } from '../../../models/Tariff/Tariff';
import { CardCheckbox } from '../../CardCheckbox/CardCheckbox';
import { ResubscriptionFormValues } from '../ResubscriptionForm';
import { TariffCheckboxDetailsContainer, TariffCheckboxSubtitle, TariffCheckboxTitle } from './Styled';

interface Props {

  /** Tariff. */
  readonly tariff: Tariff;
}

const TariffFormCheckboxComponent: FC<Props> = ({ tariff }) => {
  const { setValues, values } = useFormikContext<ResubscriptionFormValues>();
  const { tariffId: selectedTariffId } = values;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleCheckboxChange = (tariffId: Tariff['id']) => useCallback(() => {
    if (tariffId === selectedTariffId) {
      setValues({
        tariffId: null,
      }, true);
    } else {
      setValues({
        tariffId,
      }, true);
    }
  }, [tariffId, selectedTariffId]);

  const isSelected = tariff.id === selectedTariffId;

  const duration = `${tariff.duration} months`;
  const subtitle = `$${tariff.pricePerBed} / bed / mo. ${tariff.isEarly ? `for the first ${duration}` : ''}`;

  return (
    <CardCheckbox
      onChange={handleCheckboxChange(tariff.id)}
      value={String(tariff.id)}
      name="Tariff"
      isChecked={isSelected}
    >
      <TariffCheckboxTitle>{tariff.name}</TariffCheckboxTitle>
      <TariffCheckboxSubtitle>{subtitle}</TariffCheckboxSubtitle>
      <TariffCheckboxDetailsContainer>
        <p>Minimum {tariff.minBedCount} beds</p>
        <p>{duration}</p>
      </TariffCheckboxDetailsContainer>
    </CardCheckbox>
  );
};

export const TariffFormCheckbox = memo(TariffFormCheckboxComponent);
