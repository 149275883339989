import { MenuItem, Pagination, SelectChangeEvent } from '@mui/material';
import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { PaginationSelectContainer, PaginationSelectInput } from './styled';

const INIT_MAX_PAGE_COUNT = 1;

interface Props {

  /** Max count of pages. */
  readonly maxPageCount: number | undefined;

  /** Index of current page. */
  readonly currentPageIndex: number;

  /** Handles page selection. */
  readonly onPageSelect: (newPageIndex: number) => void;

  /** Type of pagination component. */
  readonly type?: 'select' | 'row';
}

const PaginationSelectComponent: FC<Props> = ({
  maxPageCount = INIT_MAX_PAGE_COUNT,
  currentPageIndex,
  onPageSelect,
  type = 'select',
}) => {

  const [countOfPages, setCountOfPages] = useState<number>(maxPageCount);

  useEffect(() => {
    if (maxPageCount !== countOfPages && maxPageCount !== INIT_MAX_PAGE_COUNT) {
      setCountOfPages(maxPageCount);
    }
  }, [maxPageCount])

  if (type === 'row') {
    // Need to subtract 1 to get page index and not page number.
    const handleItemChange = (event: ChangeEvent<unknown>, value: number) => onPageSelect(value - 1);

    return (
      <Pagination
        count={maxPageCount}
        page={currentPageIndex + 1}
        onChange={handleItemChange}
        siblingCount={3}
      />
    )
  }

  const handleItemChange = (event: SelectChangeEvent<number>) => onPageSelect(Number(event.target.value));

  const possibleValues: readonly number[] = Array(countOfPages).fill(0).map((value, index) => index);

  return (
    <PaginationSelectContainer>
      <p>Page</p>
      <PaginationSelectInput
        value={currentPageIndex}
        onChange={handleItemChange}
      >
        {possibleValues.map(value => <MenuItem value={value} key={value}>{value + 1}</MenuItem>)}
      </PaginationSelectInput>
      <p>{`from ${countOfPages}`}</p>
    </PaginationSelectContainer>
  )
};

export const PaginationSelect = memo(PaginationSelectComponent);
