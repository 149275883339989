import { PaginationWithMetadataDto } from '../../dtos/pagination/PaginationWithMetadataDto';
import { UserDto } from '../../dtos/userDto/UserDto';
import { useFetch } from '../../hooks/api';
import { PaginationWithMetadataMapper } from '../../mappers/PaginationWithMetadataMapper';
import { OrganizationUserMapper } from '../../mappers/UserMapper';
import { PaginationWithMetadata } from '../../models/pagination/PaginationWithMetadata';
import { User } from '../../models/user/User';
import { PaginationOptions } from '../PaginationOptions';

/**
 * Gets paginated list of all users.
 * @param pageSize Page size.
 * @param pageNumber Page number.
 * @param shouldFetch Whether should fetch.
 */
export const useUsers = ({ pageSize, pageNumber }: PaginationOptions , shouldFetch = true) =>
  useFetch<PaginationWithMetadata<User>, PaginationWithMetadataDto<UserDto>>({
    url: `/api/AccessUsers?PageSize=${pageSize}&PageNumber=${pageNumber}`,
    mapper: (data) => PaginationWithMetadataMapper.fromDto(data, OrganizationUserMapper.fromDto),
  }, shouldFetch);
