import React, { FC } from 'react';
import { useSocialLogin } from 'api/auth/useSocialLogin/useSocialLogin';
import { Container, SocialIcon } from 'components/SocialButton/styled';
import { Props } from 'components/SocialButton/types';

export const SocialButton: FC<Props> = ({ type }) => {
  const { signIn, isLoading } = useSocialLogin();

  const getLabel = () => {
    if (type === 'google') {
      return 'Sign in with Google';
    }

    return 'Log in with Microsoft ID';
  };

  const handleClick = () => signIn(type);

  return (
    <Container type="button" onClick={handleClick} disabled={isLoading}>
      <SocialIcon image={type} />
      {getLabel()}
    </Container>
  );
};
