import React, { FC, useState, MouseEvent } from 'react';
import {
  OverlayButton,
  MultiPreviewContainer,
  StyledImagePreview,
} from 'components/UploadPhoto/styled';
import { FirebaseService } from 'services/firebase';
import { Edit } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { theme } from 'styles/theme';
import { Image, ImageMoveDirection } from 'models/image/Image.';

interface Props {

  /** Initial images. */
  readonly initialImages: readonly Image[]

  /** URL of current photo. */
  readonly url: string;

  /** Index of photo. */
  readonly index: number;

  /** On change callback. */
  readonly onChange: (val: Image[]) => void;

  /** On image remove callback. */
  readonly onImageRemove: (index: number) => void;
}

export const PhotoPreview: FC<Props> = ({ initialImages, url, index, onChange, onImageRemove }) => {

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const isMenuOpen = Boolean(menuAnchor);

  const handleMenuOpenButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleMenuOpen(event);
  }

  const handleImageRemove = (index: number) => {
    const filteredImages = initialImages.filter(image => index !== image.index);
    onChange(filteredImages);
  };

  const handleImageMove = (currentImageIndex: number, moveTo: ImageMoveDirection | 'cover') => {
    const newImages = [...initialImages];

    const currentImage = newImages.find(image => image.index === currentImageIndex);

    const nextImage = moveTo === 'cover' ?
      Image.getFirstOrLastImage(newImages, 'first') :
      Image.getNearestImage(newImages, currentImageIndex, moveTo);

    if (currentImage == null || nextImage == null) {
      handleMenuClose()
      return;
    }

    const currentArrayIndex = newImages.indexOf(currentImage);
    const nextArrayIndex = newImages.indexOf(nextImage);

    if (currentArrayIndex === -1 || nextArrayIndex === -1) {
      handleMenuClose()
      return;
    }

    const newCurrentImage = new Image({
      imagePath: currentImage.imagePath,
      index: nextImage.index,
    });
    const newNextImage = new Image({
      imagePath: nextImage.imagePath,
      index: currentImageIndex,
    });

    newImages[nextArrayIndex] = newCurrentImage;
    newImages[currentArrayIndex] = newNextImage;

    onChange(newImages);
    handleMenuClose();
  }

  const isLastImage = index === Image.getFirstOrLastImage(initialImages, 'last')?.index ?? true;
  const isFirstImage = index === Image.getFirstOrLastImage(initialImages, 'first')?.index ?? true;

  return (
    <MultiPreviewContainer className="image-item">
      <StyledImagePreview src={FirebaseService.getImageSrc(url)} alt="" />
      <OverlayButton
        onClick={handleMenuOpenButtonClick}
      >
        <Edit />
        <span>Edit</span>
      </OverlayButton>
      <Menu open={isMenuOpen} onClose={handleMenuClose} anchorEl={menuAnchor}>
        <MenuItem
          onClick={() => {
            onImageRemove(index);
            handleImageRemove(index)
          }}
          sx={{ color: theme.colors.red }}
        >
          Delete
        </MenuItem>
        {
          isLastImage || (
            <MenuItem
              onClick={() => handleImageMove(index, 'forward')}
              sx={{ color: theme.colors.blue }}
            >
              Move forward
            </MenuItem>
          )
        }
        {
          isFirstImage || (
            [
              <MenuItem
                onClick={() => handleImageMove(index, 'backward')}
                sx={{ color: theme.colors.blue }}
                key="Move backward"
              >
                Move backward
              </MenuItem>,
              <MenuItem
                onClick={() => handleImageMove(index, 'cover')}
                sx={{ color: theme.colors.blue }}
                key="Make cover"
              >
                Make cover
              </MenuItem>
            ]
          )
        }
      </Menu>
    </MultiPreviewContainer>
  )
}
