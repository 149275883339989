import styled from '@emotion/styled'
import { EColors, theme } from 'styles/theme'

interface Props {
  small?: boolean
  color?: EColors
}

export const BadgeWrap = styled.div<Props>`
  position: relative;
  padding: ${(props) => (props.small ? '3px 8px' : '11px 12px')};
  background: ${(props) => theme.colors[props.color ?? 'lightGray']};
  border-radius: ${(props) => (props.small ? '4px' : '6px')};
  cursor: pointer;

  & > p {
    color: ${(props) => theme.colors[props.color ? 'white' : 'grayText']};
    font-size: 12px;
    font-weight: 500;
    width: auto;
  }
`
