import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { Organization } from 'models/organization/Organization';
import { TableHeader } from 'pages/Reservation/Components/GuestsTable/styled';
import React, { FC, memo } from 'react';
import { InsuranceCompanyTableRow } from './InsuranceCompanyTableRow';
import { InsuranceTableContainer } from './styled';

interface Props {

  /** Organization. */
  readonly organization: Organization;
}

const InsuranceCompaniesTableComponent: FC<Props> = ({ organization }) => {
  return (
    <InsuranceTableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableHeader sx={{width: '100%'}}>Name</TableHeader>
            <TableHeader />
          </TableRow>
        </TableHead>
        <TableBody>
          {organization.insuranceCompanies.map(company =>
            <InsuranceCompanyTableRow
              company={company}
              organization={organization}
              key={company.id}
            />
          )}
        </TableBody>
      </Table>
    </InsuranceTableContainer>
  )
}

export const InsuranceCompaniesTable = memo(InsuranceCompaniesTableComponent);
