import { BasePayment } from './BasePayment';

type PaymentResultCreationData = PaymentResult;

/** Result of the previous payment. */
export class PaymentResult extends BasePayment {

  /** Count of beds to pay for. */
  public readonly bedCountForPayment: number;

  /** Amount of payment in dollars. */
  public readonly amountInDollars: number;

  public constructor(data: PaymentResultCreationData) {
    super(data);
    this.bedCountForPayment = data.bedCountForPayment;
    this.amountInDollars = data.amountInDollars;
  }
}
