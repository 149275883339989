import { ResubscribeInfoDto } from "dtos/Subscriptions/ResubscribeInfoDto";
import { ResubscribeInfo } from "models/Subscriptions/ResubscribeInfo";

/** ResubscribeInfo mapper. */
export namespace ResubscribeInfoMapper {

  /** Maps dto to model. */
  export function fromDto(dto: ResubscribeInfoDto): ResubscribeInfo {
    return new ResubscribeInfo({
      oneTimePaymentAmountInDollars: dto.oneTimePaymentAmountInDollars,
      extraPaidBedCount: dto.extraPaidBedCount,
    })
  }
}
