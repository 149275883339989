import { IGuestType } from 'api/guests/types';
import { Organization } from 'models/organization/Organization';
import { Site } from 'models/site/Site';

export enum OrganizationTypeHref {
  AllOrganizations = 'organizations',
  FreeRehabs = 'free-rehabs',
  PaidRehabs = 'paid-rehabs',
};

/**
 * Gets type href of organization.
 * @param isGlobal Whether the user is global admin.
 * @param isCommercial Whether the site or organization is commercial.
 */
export function getOrganizationTypeHref(
  isGlobal: boolean,
  isCommercial: Site['isCommercial'] | Organization['isCommercial'],
): OrganizationTypeHref {
  if (!isGlobal) {
    return OrganizationTypeHref.AllOrganizations;
  };

  return isCommercial ? OrganizationTypeHref.PaidRehabs : OrganizationTypeHref.FreeRehabs;
}

export enum ERoute {
  HOMEPAGE = '/',
  SITES_LIST = '/sites',
  SITE_CREATE = '/sites/new/:organizationId',
  SITE_EDIT = '/sites/:siteId/edit',
  RESERVATION = '/reservations',
  RESERVATION_DETAIL = '/reservations/:siteId',
  RESERVATION_REQUEST = '/reservations-request/:guestId',
  SITE_DETAIL = '/sites/:siteId',
  HISTORY = '/history',
  HISTORY_DETAIL = '/history/:siteId',
  FAQ = '/faq',
  SIGN_UP = '/signup',
  SIGN_UP_CHECK_EMAIL = '/signup-check-email',
  SIGN_UP_EXPIRED = '/signup-expired',
  RESTORE_PASSWORD = '/restore-password',
  RESET_PASSWORD = '/reset-password',
  LOGIN = '/login',
  LOGOUT = '/logout',
  TWO_FACTOR_SETUP = '/two-factor-setup',
  TWO_FACTOR_CONFIRM = '/two-factor-confirm',
  SIGN_IN_CALLBACK = '/login/callback',
  CREATE_PASSWORD = '/create-password',
  USER_SETTINGS = '/user-settings',
  USER_MANAGEMENT = '/user-management',
  USER_MANAGEMENT_DETAIL = '/user-management/:siteId',
  GUEST_DETAILS_PAGE = '/reservations/guest/:siteId/:guestId',
  ORGANIZATIONS_LIST = '/:organizationType',
  ORGANIZATION_DETAILS = '/:organizationType/:organizationId',
  ORGANIZATION_SITE_DETAILS = '/:organizationType/:organizationId/:siteId',
  ORGANIZATION_INSURANCES = '/:organizationType/:organizationId/insurances',
  ORGANIZATION_CARDS_DETAILS = '/cards/:organizationId',
  ORGANIZATION_PAYMENT_DETAILS = '/payment-details/:organizationId',
  ALL_ORGANIZATIONS_LIST = '/organizations',
  FREE_REHABS_LIST = '/free-rehabs',
  PAID_REHABS_LIST = '/paid-rehabs',
  ERROR = '/error',
  INSURANCE_COMPANIES = '/insurances',
  AUDIT_LOG = '/logs'
}

export const routes = {
  SiteCreate: (id: Organization['id']) => `/sites/new/${id}`,
  SiteEdit: (id: Site['id']) => `/sites/${id}/edit`,
  ReservationDetails: (id: string) => `/reservations/${id}`,
  HistoryDetails: (id: string) => `/history/${id}`,
  UserManagementDetails: (id: string) => `/user-management/${id}`,
  OrganizationsList: (organizationsTypeHref = OrganizationTypeHref.AllOrganizations) => `/${organizationsTypeHref}`,
  OrganizationDetails: (id: Organization['id'], organizationsTypeHref = OrganizationTypeHref.AllOrganizations) =>
    `/${organizationsTypeHref}/${id}`,
  OrganizationInsurances: (id: Organization['id'], organizationTypeHref = OrganizationTypeHref.AllOrganizations) =>
    `/${organizationTypeHref}/${id}/insurances`,
  OrganizationCardsDetails: (id: Organization['id']) => `/cards/${id}`,
  OrganizationSiteDetails:
    (organizationId: Organization['id'], siteId: Site['id'], organizationsTypeHref = OrganizationTypeHref.AllOrganizations) =>
    `/${organizationsTypeHref}/${organizationId}/${siteId}`,
  GuestDetails: (siteId: Site['id'], guestId: IGuestType['id']) => `/reservations/guest/${siteId}/${guestId}`,
  OrganizationPaymentDetails: (organizationId: Organization['id']) => `/payment-details/${organizationId}`,
};
