import { InsuranceCompany } from './../insuranceCompany/InsuranceCompany';

type OrganizationCreationData = Organization;

/** Organization. */
export class Organization {
  /** Id of the organization. */
  public readonly id: string;

  /** Name of the organization. */
  public readonly name: string;

  /** Path to the logo. */
  public readonly logoPath: string;

  /** Count of the sites in the organization. */
  public readonly siteCount: number;

  /** Whether the organization is commercial. */
  public readonly isCommercial: boolean;

  /** Insurance companies organization supports. */
  public readonly insuranceCompanies: readonly InsuranceCompany[];

  public constructor(data: OrganizationCreationData) {
    this.id = data.id;
    this.name = data.name;
    this.logoPath = data.logoPath;
    this.siteCount = data.siteCount;
    this.isCommercial = data.isCommercial;
    this.insuranceCompanies = data.insuranceCompanies;
  }
}
