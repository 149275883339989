import { PaymentResultDto } from 'dtos/Subscriptions/PaymentResultDto';
import { PaymentResult } from 'models/Subscriptions/PaymentResult';
import { PaymentState } from '../models/Subscriptions/BasePayment';
import { MAP_PAYMENT_PURPOSE_DTO_TO_MODEL } from './PaymentMapper';

/** Payment result mapper. */
export namespace PaymentResultMapper {

  /** Maps dto to model. */
  export function fromDto(dto: PaymentResultDto): PaymentResult {
    return new PaymentResult({
      id: dto.id,
      state: dto.isSuccessfull ? PaymentState.Succeed : PaymentState.Failed,
      purpose: MAP_PAYMENT_PURPOSE_DTO_TO_MODEL[dto.purpose],
      createdAt: new Date(dto.createdAt),
      bedCountForPayment: dto.bedCountForPayment,
      amountInDollars: dto.amountInDollars,
    })
  }
}
