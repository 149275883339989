import React, { FC, memo, useState } from 'react';
import { SiteTabContentContainer } from 'pages/OrganizationSiteDetails/styled';
import { Site } from 'models/site/Site';
import { SiteMapper } from 'mappers/SiteMapper';
import { getCountOfBedsFromSiteEditValues, SiteEditingValues } from 'models/site/SiteEditingValues';
import { Formik, FormikHelpers } from 'formik';
import { useUpdateSite } from 'api/sites/useUpdateSite';
import { errorsTransform } from 'services/transformers';
import { RowView, Gap } from 'components/Layout/Grid';
import { ThemedButton } from 'components/ThemedButton';
import { RoomsList } from 'pages/SiteCreate/components/Rooms/RoomsList';
import { SmartButton } from 'components/SmartButton';
import { useSiteDetails } from 'api/sites/useSiteDetails';
import { getSiteEditValidationSchema } from '../../../SiteEdit';
import { useSubscriptionUpgradeInfo } from '../../../../api/Subscriptions/useSubscriptionUpgradeInfo';
import { BedsChangeConfirmDialog } from '../../../../components/BedsChangeConfirmDialog/BedsChangeConfirmDialog';
import { ErrorMessage } from '../../../../components/FetchErrorMessage/FetchErrorMessage';
import { useEditedSiteDraftContext } from '../../../SiteEdit/EditedSiteContext/EditedSiteContext';
import { Loader } from '../../../../components/Loader/Loader';
import { LoaderWrapper } from '../../../../components/Loader/styled';
import { useOrganizationSubscription } from '../../../../api/subscription/useOrganizationSubscription';
import { useIsUserPrivileged } from 'hooks/auth/useIsUserPrivileged';
import { useAuth } from 'hooks/auth/useAuth';

interface Props {

  /** Site. */
  readonly site: Site;

  /** Callback which triggers when the rooms change. */
  readonly handleRoomsChange: (roomsChanged: boolean) => void;
}

const DEFAULT_INTAKE_PERIOD_FROM = '09:00:00';
const DEFAULT_INTAKE_PERIOD_TO = '17:00:00';

const SiteRoomsComponent: FC<Props> = ({ site, handleRoomsChange }) => {

  const { handleUpdate, error: siteUpdateErrorMessage } = useUpdateSite(site.id, site.isCommercial);

  const [editedSiteDraft, setEditedSiteDraft] = useEditedSiteDraftContext();

  const { mutate: mutateSite } = useSiteDetails(site.id);

  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);

  const [newBedsCount, setNewBedsCount] = useState<number>(0);

  const { roleOwnerOrganizationIds } = useAuth().state.userData;

  const isSubscriptionAvailable = site.isCommercial && roleOwnerOrganizationIds.includes(site.organizationId.toString());
  const { data: subscription } = useOrganizationSubscription(site.organizationId.toString(), isSubscriptionAvailable);

  const isSubscriptionAllowsSiteEditing = subscription?.isSubscriptionAllowsSiteEditing || false;

  const { data: subscriptionUpgradeInfo, error: subscriptionUpgradeInfoError } = useSubscriptionUpgradeInfo(
    {
      siteId: site.id,
      newSiteBedCount: newBedsCount,
    },
    site.isCommercial && isSubscriptionAllowsSiteEditing,
  );

  const initSiteValues = SiteMapper.mapSiteToSiteEditionValues(site);

  /** Whether loading is in action or not. */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isUserPrivileged = useIsUserPrivileged(site.organizationId.toString());

  const isUserCanEditRooms = site.isCommercial ?
    isUserPrivileged && isSubscriptionAllowsSiteEditing :
    true;

  const handleUpdateSite = async (values: SiteEditingValues, setErrors: FormikHelpers<any>['setErrors'], setTouched: FormikHelpers<any>['setTouched']) => {
    try {
      setIsLoading(true);
      values.intakePeriods.long.map((item) => {
        if (item.enabled) {
          if (item.to == '' && item.from == '') {
            item.from = '09:00:00';
            item.to = '17:00:00';
          }
        }
        return item;
      });
      values.intakePeriods.short.map((item) => {
        if (item.enabled) {
          if (item.to == '' && item.from == '') {
            item.from = '09:00:00';
            item.to = '17:00:00';
          }
        }
        return item;
      });

      const updatedData = await handleUpdate(values, (errors: any) => {
        const newErrors = errorsTransform(errors);

        setErrors({
          ...newErrors,
        });
      });

      await mutateSite(updatedData, true);

      if (editedSiteDraft?.id == updatedData.id) {
        setEditedSiteDraft(null);
      }

      setTouched({});
    } catch (error) {

      console.log(error);

    } finally {
      setIsLoading(false);
    }
  }

  const needToConfirmBedsChange =
      subscriptionUpgradeInfo?.changedSubscriptionCost != null &&
      subscriptionUpgradeInfo.oneTimePaymentAmount !== 0 ||
      subscriptionUpgradeInfo?.extraPaidBedCountDifference != null &&
      subscriptionUpgradeInfo.extraPaidBedCountDifference < 0;

  const handleSubmit = async (values: SiteEditingValues, formikHelpers: FormikHelpers<any>) => {


    if (site.isCommercial && needToConfirmBedsChange) {
      setIsPaymentDialogOpen(true);
    } else {
      await handleUpdateSite(values, formikHelpers.setErrors, formikHelpers.setTouched);
    }
  };

  const handleCountOfBedsChange = (values: SiteEditingValues) => {
    const currentCountOfBeds = getCountOfBedsFromSiteEditValues(values);
    setNewBedsCount(currentCountOfBeds);
  }

  const isLoadingSubscriptionUpgradeInfo = subscriptionUpgradeInfo == null;

  const isButtonsDisabled = site.isCommercial && isUserCanEditRooms ?
    isLoading || isLoadingSubscriptionUpgradeInfo :
    isLoading;

  return (
    <SiteTabContentContainer>
      {isLoading && (
        <LoaderWrapper isGlobal>
          <Loader
            size='large'
            isPrimary
          />
        </LoaderWrapper>
      )}
      <Formik initialValues={initSiteValues} onSubmit={handleSubmit} validationSchema={getSiteEditValidationSchema(site.isCommercial, true)}>
        {({ submitForm, resetForm, touched, setTouched, values, setErrors }) => {
          handleRoomsChange(touched.rooms != null);
          return (
            <>
              <div style={{ position: 'relative' }}>
              <RoomsList
                withoutMainHeader
                onCountOfBedsChange={handleCountOfBedsChange}
                isEditable={isUserCanEditRooms}
                areAssignButtonsHidden
                site={site}
                isPrintable
              />
              {siteUpdateErrorMessage && <ErrorMessage message={siteUpdateErrorMessage} />}
              <RowView justifyContent="space-between">
                {touched.rooms && <RowView>
                  <SmartButton disabled={isButtonsDisabled} onClick={async() => {
                    await submitForm();
                  }} color="blue">
                    Save
                  </SmartButton>
                  <Gap />
                  <ThemedButton
                    type="button"
                    outlined
                    onClick={() => {
                      resetForm({ values: initSiteValues });
                    }}
                    color="red"
                    disabled={isButtonsDisabled}
                  >
                    Cancel
                  </ThemedButton>
                </RowView>}
              </RowView>
            </div>
            {needToConfirmBedsChange && <BedsChangeConfirmDialog
              site={site}
              isOpen={isPaymentDialogOpen}
              onClose={() => setIsPaymentDialogOpen(false)}
              bedsCount={newBedsCount}
              subscriptionUpgradeInfo={subscriptionUpgradeInfo}
              errorMessage={subscriptionUpgradeInfoError?.message}
              onConfirmButtonClick={() => handleUpdateSite(values, setErrors, setTouched)}
            />}
          </>)
        }}
      </Formik>
    </SiteTabContentContainer>
  );
};

export const SiteRooms = memo(SiteRoomsComponent);
