import { FailedLoginLogDto } from '../dtos/logs/FailedLoginLogDto';
import { FailedLoginLog } from '../models/logs/FailedLoginLog';

/** Mapper for failed login logs. */
export namespace FailedLoginLogMapper {

  /** Maps dto to model. */
  export function fromDto(dto: FailedLoginLogDto): FailedLoginLog {
    return new FailedLoginLog({
      id: dto.id,
      login: dto.login,
      loginFailedAt: new Date(dto.loginFailedAt),
    })
  }
}
