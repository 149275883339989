import styled from '@emotion/styled';
import { TableCell } from '@mui/material';
import { StyledButton } from 'components/StyledButton/styled';
import { theme } from 'styles/theme';

export const UsersTableHeader = styled.h2`
  font-size: var(--font-size-lg);
  font-weight: 600;
  margin-bottom: 30px;
`;

export const UsersTableContainer = styled.div`
  --spacing-md: 20px;
  --spacing-s: 10px;
`;

export const UsersTableCell = styled(TableCell)`
  font-size: var(--font-size-xs);
  font-weight: 500;
  min-width: 50px;
  padding: var(--spacing-md) 0;
  word-break: break-word;
`;

export const UsersTableHeaderCell = styled(UsersTableCell)`
  font-weight: 600;
  color: ${theme.colors.semiLightGray};
`;

export const LeftAlignedTableCell = styled(UsersTableCell)`
  text-align: right;
`;

export const CurrentAccountTableCell = styled(LeftAlignedTableCell)`
  color: ${theme.colors.noContentColor};
  font-weight: 600;
`;

export const EditTableCell = styled(LeftAlignedTableCell)`
  text-align: right;
  width: 170px;
`;

export const RemoveOrganizationAdminButton = styled.button`
  border: none;
  padding: 0;
  font-size: var(--font-size-xs);
  font-weight: 600;
  color: ${theme.colors.red};
  background-color: transparent;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:disabled {
    color: ${theme.colors.grayText};
    cursor: default;
    text-decoration: none;
  }
`;

export const NewAdminForm = styled.form`
  display: flex;
  margin-top: var(--spacing-s);
`;

export const NewAdminSubmitButton = styled(StyledButton)`
  margin-left: var(--spacing-s);
  height: 38px;
`;

export const MembershipStatusLabel = styled.label`
  display: flex;
  gap: 4px;
`;

export const EditControls = styled.div`
  display: flex;
  gap: 8px;
`;
