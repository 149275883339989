import React, { useState } from 'react';
import { useUpdateEmail } from 'api/auth/useUpdateEmail/useUpdateEmail';
import { Container } from 'components/ChangeUserSettingsPhone/styled';
import { CheckLinkEmail } from 'components/CheckLinkEmail';
import { InputPreview } from 'components/InputPreview';
import { EditEmail as EditEmailForm } from 'forms/EditEmail';
import { IFormData } from 'forms/EditEmail/data';
import { useAuth } from 'hooks/auth/useAuth';

export const ChangeUserEmail = () => {
  const { updateEmail, isLoading } = useUpdateEmail();
  const [step, setStep] = useState(1);
  const [newEmail, setNewEmail] = useState('');
  const {
    state: { userData },
  } = useAuth();

  const goNextStep = () => {
    setStep((step) => step + 1);
  };

  const goStepBack = () => {
    setStep((step) => step - 1);
  };

  const handleStartEdit = () => {
    goNextStep();
  };

  const handleEditPhone = async (data: IFormData) => {
    await updateEmail(data.email);
    setNewEmail(data.email);
    goNextStep();
  };

  return (
    <Container>
      {step === 1 && (
        <InputPreview
          label="Email"
          text={userData.email}
          onEdit={handleStartEdit}
        />
      )}
      {step === 2 && (
        <EditEmailForm
          handleCancel={goStepBack}
          handleSubmit={handleEditPhone}
          isLoading={isLoading}
        />
      )}
      {step === 3 && <CheckLinkEmail email={newEmail} />}
    </Container>
  );
};
