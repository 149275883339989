import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { usePaymentResults } from 'api/Subscriptions/usePaymentResults';
import { Loader } from 'components/Loader/Loader';
import React, { FC, memo, useState } from 'react';
import { PaginationSelect } from '../../../../components/PaginationSelect/PaginationSelect';
import { Organization } from '../../../../models/organization/Organization';
import { BillingTableRow } from './BillingTableRow';
import { BillingTableContainer, PaymentInfoTableHeader } from './styled';
import { PaginationContainer } from '../../../../components/PaginationContainer/PaginationContainer';

interface Props {

  /** Organization ID. */
  readonly organizationId: Organization['id'];
};

const PAGE_SIZE = 10;

const BillingTableComponent: FC<Props> = ({ organizationId }) => {

  const [pageIndex, setPageIndex] = useState<number>(0);

  const { data: paginatedPaymentResults } = usePaymentResults({
    organizationId,
    pageSize: PAGE_SIZE,
    pageNumber: pageIndex + 1,
  });

  const handlePageChange = (newPageIndex: number) => setPageIndex(newPageIndex);

  return (
    <BillingTableContainer>
      {paginatedPaymentResults != null ?
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <PaymentInfoTableHeader>Date</PaymentInfoTableHeader>
              <PaymentInfoTableHeader>Description</PaymentInfoTableHeader>
              <PaymentInfoTableHeader>Beds Number</PaymentInfoTableHeader>
              <PaymentInfoTableHeader>Invoice Total</PaymentInfoTableHeader>
              <PaymentInfoTableHeader>Status</PaymentInfoTableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedPaymentResults.items.map(payment => <BillingTableRow key={payment.id} payment={payment} />)}
          </TableBody>
        </Table> :
        <Loader size="medium" isPrimary />
      }
      <PaginationContainer>
        <PaginationSelect
          maxPageCount={paginatedPaymentResults?.totalPages}
          currentPageIndex={pageIndex}
          onPageSelect={handlePageChange}
        />
      </PaginationContainer>
    </BillingTableContainer>
  )
};

export const BillingTable = memo(BillingTableComponent);
