import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAuth } from 'hooks/auth/useAuth'
import { ERoute } from 'router/data'

export const AuthRoute = ({ isPublic, ...props }: any) => {
  const {
    state: { isAuthorized },
  } = useAuth()

  if (isPublic && isAuthorized) {
    return <Redirect to={ERoute.HOMEPAGE} />
  }

  if (!isPublic && !isAuthorized) {
    return <Redirect to={ERoute.LOGIN} />
  }

  return <Route {...props} />
}
