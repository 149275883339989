import React, { FC, memo } from 'react';
import { PasswordChangeLog } from '../../../../models/logs/PasswordChangeLog';
import { TableRow } from '@mui/material';
import { LogsTableCell } from '../TableCells/TableCells';
import { getTimeFromDate } from '../../../../utils/getTimeFromDate';

interface Props {

  /** Password change log. */
  readonly log: PasswordChangeLog;
}

const PasswordChangeTableRowComponent: FC<Props> = ({ log }) => {
  return (
    <TableRow>
      <LogsTableCell>{log.email}</LogsTableCell>
      <LogsTableCell>{log.role}</LogsTableCell>
      <LogsTableCell>{log.passwordChangedAt.toLocaleDateString()}</LogsTableCell>
      <LogsTableCell>{getTimeFromDate(log.passwordChangedAt)}</LogsTableCell>
    </TableRow>
  )
}

export const PasswordChangeTableRow = memo(PasswordChangeTableRowComponent);
