import { Room } from 'pages/SiteCreate/components/Rooms/domain/Room';
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';
import { SortDirection } from 'utils/types/SortingDirection';
import { getDaysDifference } from 'utils/getDaysDifference';
import { GuestMethods, IGuestType } from 'api/guests/types';
import { localeCompareWithDirection } from 'utils/localeCompareWithDirection';

/** Guest with room and bed he assigned to. */
export type GuestWithRoomAndBed = IGuestType & {

  /** Room. */
  readonly room: Room | null;

  /** Bed. */
  readonly bed: Bed | null;
}

export namespace GuestWithRoomAndBed {

  /** Sort function. */
  export type SortFunction = (guests: readonly GuestWithRoomAndBed[], direction: SortDirection) => GuestWithRoomAndBed[];

  /**
   * Sort guests by name.
   * @param guests Guests.
   * @param direction Sort direction.
   */
  export function sortGuestsByName(guests: readonly GuestWithRoomAndBed[], direction: SortDirection): GuestWithRoomAndBed[] {
    return [...guests].sort((firstGuest, secondGuest) => {

      const firstGuestFullName = GuestMethods.getFullName(firstGuest);
      const secondGuestFullName = GuestMethods.getFullName(secondGuest);

      return localeCompareWithDirection({
        targetValue: firstGuestFullName,
        compareValue: secondGuestFullName,
        direction,
      });
    })
  }

  /**
   * Sort guests by days stayed.
   * @param guests Guests.
   * @param direction Sort direction.
   */
  export function sortGuestsByDaysStayed(guests: readonly GuestWithRoomAndBed[], direction: SortDirection): GuestWithRoomAndBed[] {
    const currentDate = new Date();

    return [...guests].sort((firstGuest, secondGuest) => {
      if (firstGuest.acceptedAt == null || secondGuest.acceptedAt == null) {
        return 1;
      }

      const firstGuestAcceptedAt = new Date(firstGuest.acceptedAt);
      const secondGuestAcceptedAt = new Date(secondGuest.acceptedAt);

      const firstGuestDays = getDaysDifference(currentDate, firstGuestAcceptedAt);
      const secondGuestDays = getDaysDifference(currentDate, secondGuestAcceptedAt);

      return direction === 'asc' ? firstGuestDays - secondGuestDays : secondGuestDays - firstGuestDays;
    })
  }

  /**
   * Sort guests by room and bed names.
   * @param guests Guests.
   * @param direction Sort direction.
   */
  export function sortGuestsByRoomAndBedNames(guests: readonly GuestWithRoomAndBed[], direction: SortDirection): GuestWithRoomAndBed[] {
    return [...guests].sort((firstGuest, secondGuest) => {
      if (firstGuest.bed === null || firstGuest.room === null || secondGuest.bed === null || secondGuest.room === null) {
        return 1;
      }

      return (
        localeCompareWithDirection({
          targetValue: firstGuest.room.name,
          compareValue: secondGuest.room.name,
          direction,
        }) ||
        localeCompareWithDirection({
          targetValue: firstGuest.bed.name,
          compareValue: secondGuest.bed.name,
          direction,
        })
      );
    })
  }
}
