import styled from '@emotion/styled';

export const AddInsuranceModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 70vh;
  width: 100%;
`;

export const InsurancesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
`;

export const InsurancesButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
`;
