import { TableRow } from '@mui/material';
import { PaymentResult } from 'models/Subscriptions/PaymentResult';
import { PaymentPurpose } from 'models/Subscriptions/BasePayment';
import React, { FC, memo } from 'react';
import { PaymentInfoTableCell } from './styled';
import { formatDateToReadableString } from '../../../../utils/formatDateToString';

const MAP_PAYMENT_PURPOSE_TO_DESCRIPTION: Readonly<Record<PaymentPurpose, string>> = {
  [PaymentPurpose.SubscriptionCreation]: 'Subscription creation',
  [PaymentPurpose.SubscriptionMonthlyPayment]: 'Monthly payment',
  [PaymentPurpose.ExtraPaidBedCountChange]: 'Adding additional beds'
}

interface Props {

  /** Payment. */
  readonly payment: PaymentResult
}

const BillingTableRowComponent: FC<Props> = ({ payment }) => {

  return (
    <TableRow>
      <PaymentInfoTableCell>
        {formatDateToReadableString(payment.createdAt)}
      </PaymentInfoTableCell>
      <PaymentInfoTableCell>
        {MAP_PAYMENT_PURPOSE_TO_DESCRIPTION[payment.purpose]}
      </PaymentInfoTableCell>
      <PaymentInfoTableCell>
        {payment.bedCountForPayment}
      </PaymentInfoTableCell>
      <PaymentInfoTableCell>
        {`$${payment.amountInDollars}`}
      </PaymentInfoTableCell>
      <PaymentInfoTableCell>
        {payment.state}
      </PaymentInfoTableCell>
    </TableRow>
  )
}

export const BillingTableRow = memo(BillingTableRowComponent);
