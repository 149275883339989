import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { theme } from 'styles/theme'

export const Stepper = styled.nav`
  margin-right: 40px;
  width: 170px;
`;

export const StyledOl = styled.ol`
  list-style: none;
  counter-reset: inst;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  margin-top: 15px;
`;

export const StyledStep = styled.li<{ active: boolean; completed: boolean }>`
  padding: 5px 6px;
  font-size: 14px;
  color: ${theme.colors.dark};
  font-weight: ${(props) => (props.active ? 'bold' : 300)};
  margin-bottom: 30px;

  counter-increment: inst;
  padding-top: 1em;
  display: block;
  position: relative;

  &:before {
    content: counter(inst);
    background: ${(props) =>
      props.active ? theme.colors.dark : theme.colors.lightGray};
    color: ${(props) =>
      props.active ? theme.colors.white : theme.colors.dark};
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    width: 20px;
    height: 20px;
    padding: 5px 9.5px;
    border-radius: 20px;
    margin-right: 20px;

    ${(props) =>
      props.completed &&
      css`
        background: transparent;
        border: 1px solid ${theme.colors.green};
      `}
  }
`;

export const BackButton = styled.button`
  color: ${theme.colors.grayText};
  font-size: var(--font-size-md);
  border: none;
  background: none;
  cursor: pointer;

  transition: 0.5s;

  &:hover {
    color: ${theme.colors.black};
  }
`;
