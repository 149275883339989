import { SavedPaymentMethodDto } from '../../dtos/Subscriptions/SavedPaymentMethodDto';
import { useFetch } from '../../hooks/api';
import { SavedPaymentMethodMapper } from '../../mappers/SavedPaymentMethodMapper';
import { Site } from '../../models/site/Site';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';

interface SavedPaymentMethodsResponse {

  /** Saved cards. */
  readonly cards: readonly SavedPaymentMethodDto[];
}

/**
 * Gets saved payment methods.
 * @param organizationId Id of the organization to get payment method of.
 */
export const useSavedPaymentMethods = (organizationId: Site['organizationId']) =>
  useFetch<SavedPaymentMethod[], SavedPaymentMethodsResponse>({
    url: `/api/Subscriptions/savedPaymentMethods?organizationId=${organizationId}`,
    mapper: response => response.cards.map(dto => SavedPaymentMethodMapper.fromDto(dto)),
  });
