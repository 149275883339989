import styled from '@emotion/styled';

export const SiteTabContentContainer = styled.div`
  margin-top: 32px;
`;

export const RoomsWarningText = styled.p`
  font-weight: 500;
  font-size: var(--font-size-s);
`
