/* eslint-disable @typescript-eslint/no-misused-promises */
import { ISetupTwoFactorData } from 'api/auth/useUpdatePhone/types'
import { useMutate } from 'hooks/api'

export const useUpdatePhone = () => {
  const { mutate, isLoading, error } = useMutate<unknown, unknown, ISetupTwoFactorData>(
    'put',
    {
      url: '/api/Users/phone',
      headers: {
        Accept: 'application/json',
      },
    }
  )

  const updatePhone = (data: ISetupTwoFactorData) => mutate(data)

  return { updatePhone, isLoading, error }
}
