import styled from '@emotion/styled';
import { TableCell } from '@mui/material';
import { theme } from '../../../../styles/theme';

const BasicTableCell = styled(TableCell)`
  padding-left: 0;
  font-family: 'Inter';
  font-weight: 500;
`;

export const LogsTableHeader = styled(BasicTableCell)`
  font-size: var(--font-size-xs);
  color: ${theme.colors.semiLightGray};
`;

export const LogsTableCell = styled(BasicTableCell)`
  font-size: var(--font-size-s);
  color: ${theme.colors.black};
`;
