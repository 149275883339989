import { useMutate } from 'hooks/api';
import { SavedPaymentMethodCreateValues } from 'models/Subscriptions/SavedPaymentMethodCreateValues';
import { SavedPaymentMethodMapper } from 'mappers/SavedPaymentMethodMapper';
import { SavedPaymentMethodDto } from 'dtos/Subscriptions/SavedPaymentMethodDto';

/**
 * Update saved payment methods.
 * @param redirectUri Redirect uri.
 */
export const useUpdateSavedPaymentMethods = (redirectUri?: string) => {
  const { mutate, isLoading, error } = useMutate<SavedPaymentMethodDto['id'], undefined, SavedPaymentMethodCreateValues>('post', {
    url: '/api/Subscriptions/savedPaymentMethods',
    redirectUri,
    requestTransformer: (data: SavedPaymentMethodCreateValues) => JSON.stringify(SavedPaymentMethodMapper.toCreateValuesDto(data)),
  });

  return { updateSavedPaymentMethods: mutate, isLoading, errorMessage: error };
};
