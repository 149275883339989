import React, { FC, memo } from 'react';
import { GuestCreationLog } from '../../../../models/logs/GuestCreationLog';
import { TableRow } from '@mui/material';
import { LogsTableCell } from '../TableCells/TableCells';
import { getTimeFromDate } from '../../../../utils/getTimeFromDate';

interface Props {

  /** Guest creation log. */
  readonly log: GuestCreationLog;
}

const GuestLogTableRowComponent: FC<Props> = ({ log }) => {

  const staffValue = log.createdByEmail !== null && log.createdByRole !== null ?
    `${log.createdByEmail} - ${log.createdByRole}` :
    '-';

  return (
    <TableRow>
      <LogsTableCell>{`${log.firstName} ${log.lastName}`}</LogsTableCell>
      <LogsTableCell>{log.siteName}</LogsTableCell>
      <LogsTableCell>{staffValue}</LogsTableCell>
      <LogsTableCell>{log.guestCreatedAt.toLocaleDateString()}</LogsTableCell>
      <LogsTableCell>{getTimeFromDate(log.guestCreatedAt)}</LogsTableCell>
    </TableRow>
  )
}

export const GuestLogTableRow = memo(GuestLogTableRowComponent);
