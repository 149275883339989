import { StrictOmit } from 'utils/types/strictOmit';
import { Bed } from '../components/Bed/domain/Bed';

export type RoomCreationData = StrictOmit<Room,
  | 'hasBeds'
  | 'bedsCount'
  | 'hasPhoto'
  | 'occupiedBedsCount'
  | 'vacantBedsCount'
  | 'hasAnyGuests'>;

/** Room. */
export class Room {

  /** ID. */
  public readonly id: string | null;

  /** Name. */
  public readonly name: string;

  /** Beds that placed in the room. */
  public readonly beds: readonly Bed[];

  /** Room photo. */
  public readonly photo: string | null;

  /** Creation date. */
  public readonly createdAt: Date;

  public constructor(data: RoomCreationData) {
    this.id = data.id;
    this.name = data.name;
    this.beds = data.beds;
    this.photo = data.photo;
    this.createdAt = data.createdAt;
  }

  /** Generate empty room. */
  public static generateEmpty(name: string): Room {
    return new Room({
      id: null,
      name,
      beds: [],
      photo: null,
      createdAt: new Date(),
    });
  }

  /** Whether room has any beds or not. */
  public get hasBeds(): boolean {
    return this.beds.length > 0;
  }

  /** Count of beds in the room. */
  public get bedsCount(): number {
    return this.beds.length;
  }

  /** Whether room has photo or not. */
  public get hasPhoto(): boolean {
    return this.photo != null ? this.photo.length > 0 : false;
  }

  /** Number of beds that is occupied. */
  public get occupiedBedsCount(): number {
    return this.beds.filter(bed => bed.guest != null).reduce(prev => prev + 1, 0);
  }

  /** Number of beds that is vacant (free). */
  public get vacantBedsCount(): number {
    return this.beds.filter(bed => bed.guest == null).reduce(prev => prev + 1, 0);
  }

  /** Whether room has any guests. */
  public get hasAnyGuests(): boolean {
    return this.beds.find(bed => bed.guest != null) != null;
  }
}
