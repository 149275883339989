type InsuranceCompanyCreationData = InsuranceCompany;

/** Insurance company model. */
export class InsuranceCompany {

  /** ID. */
  public readonly id: number;

  /** Name. */
  public readonly name: string;

  /** Whether the insurance company is primary. */
  public readonly isPrimary: boolean;

  /** Logo path. */
  public readonly logoPath: string | null;

  public constructor(data: InsuranceCompanyCreationData) {
    this.id = data.id;
    this.name = data.name;
    this.isPrimary = data.isPrimary;
    this.logoPath = data.logoPath;
  }
}
