import { SubscriptionRecreationDataDto } from '../dtos/Subscriptions/SubscriptionRecreationDataDto';
import { SubscriptionRecreationData } from '../models/Subscriptions/SubscriptionRecreationData';

/** Subscription recreation data mapper. */
export namespace SubscriptionRecreationDataMapper {

  /** Maps model to dto. */
  export function toDto(model: SubscriptionRecreationData): SubscriptionRecreationDataDto {
    return {
      organizationId: model.organizationId,
      subscriptionOptionId: model.subscriptionOptionId,
    }
  }
}
