import styled from '@emotion/styled';
import { theme } from 'styles/theme';
import { ArrowUpward } from '@mui/icons-material';
import { SortDirection } from 'utils/types/SortingDirection';

export const SortableTableHeaderButton = styled.button`
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  background: inherit;
  color: inherit;
  font: inherit;
`;

interface ArrowProps {

  /** Sort direction. */
  readonly sortDirection: SortDirection;

  /** Whether the sorting is active and the arrow should be highlighted. */
  readonly isActive: boolean;
}

export const SortableTableHeaderArrow = styled(ArrowUpward)<ArrowProps>`
  font-size: var(--font-size-md);
  transform: ${props => props.sortDirection === 'desc' ? 'rotate(180deg)' : null};
  color: ${props => props.isActive ? 'inherit' : theme.colors.middleGray};
  transition: transform 0.5s;
`;
