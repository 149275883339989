/** Payment method name dto. */
export enum PaymentMethodCategoryDto {
  PrivatePay = 'PrivatePay',
  Insurance = 'Insurance',
  MediCal = 'MediCal',
}

/** Payment method dto. */
export interface PaymentMethodDto {

  /** ID of the payment method. */
  readonly id: number;

  /** Name of the payment method. */
  readonly reservationPaymentMethodName: string;

  /** Category. */
  readonly reservationPaymentMethodCategory: PaymentMethodCategoryDto;
}
