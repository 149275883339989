import Cookies from 'js-cookie'
import { IUserData } from 'hooks/auth/types'

const COOKIE_KEYS = {
  preTwoFactorAccessToken: 'preTwoFactorAccessToken' as const,
  accessToken: 'accessToken' as const,
  refreshToken: 'refreshToken' as const,
  userData: 'userData' as const,
}

export const authStorage = {
  clearLoginData: () => {
    Cookies.remove(COOKIE_KEYS.preTwoFactorAccessToken)
    Cookies.remove(COOKIE_KEYS.accessToken)
    Cookies.remove(COOKIE_KEYS.refreshToken)
    Cookies.remove(COOKIE_KEYS.userData)
  },
  isLoggedIn: () => Boolean(authStorage.getPreTwoFactorAccessToken()),
  isAuthorized: () =>
    Boolean(authStorage.getAccessToken() && authStorage.getRefreshToken()),

  getToken: (key: keyof typeof COOKIE_KEYS) => Cookies.get(key),
  getPreTwoFactorAccessToken: () =>
    authStorage.getToken(COOKIE_KEYS.preTwoFactorAccessToken),
  getAccessToken: () => authStorage.getToken(COOKIE_KEYS.accessToken),
  getRefreshToken: () => authStorage.getToken(COOKIE_KEYS.refreshToken),
  getUserData: () => {
    const data = authStorage.getToken(COOKIE_KEYS.userData)

    if (data) {
      return JSON.parse(data)
    }

    return undefined
  },

  setToken: (key: keyof typeof COOKIE_KEYS, value: string | object) =>
    Cookies.set(key, value),
  setPreTwoFactorAccessToken: (token: string) =>
    authStorage.setToken(COOKIE_KEYS.preTwoFactorAccessToken, token),
  setAccessToken: (token: string) =>
    authStorage.setToken(COOKIE_KEYS.accessToken, token),
  setRefreshToken: (token: string) =>
    authStorage.setToken(COOKIE_KEYS.refreshToken, token),
  setUserData: (userData: IUserData) =>
    authStorage.setToken(COOKIE_KEYS.userData, userData),
}
