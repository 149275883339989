import { Avatar, Paper } from '@mui/material';
import { styled } from '@mui/system';

export const RoomInfoContainer = styled(Paper)({
  padding: '20px',
});

export const RoomInfo = styled('div')({
  marginTop: '30px',
  display: 'flex',
});

export const RoomAvatar = styled(Avatar)({
  minHeight: '100px',
  minWidth: '116px',
});

export const BedInfo = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 4fr 3fr 4fr 3fr 3fr',
  columnGap: '10px',
  marginLeft: '30px',
  maxHeight: '36px',
});

export const BedAvatar = styled(Avatar)({
  minHeight: '36px',
  minWidth: '36px',
});

const CircledText = styled('p')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '10px',
  fontSize: '0.875rem',
});

export const OccupiedText = styled(CircledText)({
  backgroundColor: '#FF00001A',
  color: '#FF0000',
  maxWidth: '80px',
  maxHeight: '20px',
});

export const VacantText = styled(CircledText)({
  backgroundColor: '#54A2FF1A',
  color: '#54A2FF',
  maxWidth: '50px',
  maxHeight: '20px',
});

export const DischargeDialogInfo = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '1.5rem',
});

export const DischargeDialogRoomInfo = styled('div')({
  gridColumn: '1 / 3',
  fontSize: '0.875rem',
});

export const DischargeDialogRoomInfoItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const DischargeDialogTextSmall = styled('p')({
  fontSize: '0.75rem',
});

export const DischargeDialogText = styled('p')({
  fontSize: '0.875rem',
});
