import { Button, MenuItem, Select, SelectChangeEvent, TableRow } from '@mui/material';
import { useDeleteUser } from 'api/users/useDeleteUser';
import { useUpdateUser } from 'api/users/useUpdateSiteUser';
import { BaseMenu } from 'components/BaseMenu/BaseMenu';
import { Loader } from 'components/Loader/Loader';
import { LoaderWrapper } from 'components/Loader/styled';
import { ToggleSwitch } from 'components/ToggleSwitch';
import { Site } from 'models/site/Site';
import { User, UserRole, UserMembershipStatus } from 'models/user/User';
import React, { FC, memo, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import {
  CurrentAccountTableCell,
  UsersTableCell,
  EditControls,
  EditTableCell,
} from './styled';

interface Props {

  /** User. */
  readonly user: User;

  /** Whether user in the row is current. */
  readonly isCurrentUser?: boolean;

  /** Whether the row contains organization owner. */
  readonly isOwnerRow?: boolean;

  /** Users revalidation function. */
  readonly revalidateUsers: () => Promise<boolean>;

  /** Current site id. */
  readonly siteId?: Site['id'];
}

const UsersTableRowComponent: FC<Props> = ({ user, isCurrentUser, isOwnerRow, revalidateUsers, siteId }) => {
  /** Used to show loaders while update or delete is going on. */
  const [isProcessing, setIsProcessing] = useState(false);

  const { handleDelete } = useDeleteUser(user.id);
  const handleUserDelete = async () => {
    setIsProcessing(true);
    await handleDelete();
    await revalidateUsers();
    setIsProcessing(false);
  };

  const [isEditing, setIsEditing] = useState(false);

  /** Change mode to editing. */
  const onEditButtonClick = () => {
    setIsEditing(true);
  };

  const editMenuBlock = (
    <BaseMenu>
      {() => (
        <>
          <MenuItem
            onClick={onEditButtonClick}
            sx={{ color: theme.colors.blue }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={handleUserDelete}
            sx={{ color: theme.colors.red }}
          >
            Remove
          </MenuItem>
        </>
      )}
    </BaseMenu>
  );

  const { setActive, setDisabled, changeRole } = useUpdateUser(user.id);
  /** Uses local values to update user. */
  const handleSave = async () => {
    setIsProcessing(true);

    const promises: Promise<unknown>[] = []
    if (role !== UserRole.GlobalAdmin && role !== UserRole.Owner && siteId != null) {
      promises.push(
        changeRole({ role, siteId })
      );
    }
    if (user.membershipStatus !== UserMembershipStatus.PendingInvitation) {
      if (isDisabled) {
        promises.push(
          setDisabled()
        );
      } else {
        promises.push(
          setActive()
        );
      }
    }

    await Promise.all(promises);
    await revalidateUsers();

    setIsProcessing(false);
    setIsEditing(false);
  }

  const handleCancel = () => {
    setIsEditing(false);
  }

  const editControlsBlock = (
    <EditControls>
      <Button onClick={handleSave} variant="contained">
        Save
      </Button>
      <Button onClick={handleCancel} variant="outlined">
        Cancel
      </Button>
    </EditControls>
  );

  const editModeBlock = isEditing ? editControlsBlock : editMenuBlock;

  const [role, setRole] = useState(user.role);
  const [isDisabled, setIsDisabled] = useState(user.membershipStatus === UserMembershipStatus.Disabled);

  /**
   * Update local role value.
   * @param event Select change event.
   */
  const handleRoleChange = (event: SelectChangeEvent<UserRole>) => {
    const newRole = event.target.value as UserRole;
    setRole(newRole);
  }

  /** Update local membership status value. */
  const handleMembershipStatusChange = () => {
    setIsDisabled(isDisabled => !isDisabled);
  }

  const roleOptions = useMemo(() => {
    const roles = [UserRole.Staff, UserRole.Intake, UserRole.Admin];

    if (isOwnerRow) {
      roles.push(UserRole.Owner)
    }

    return roles.map(role => ({
      value: role,
      label: role,
    }))
  }, [isOwnerRow])

  return (
    <TableRow sx={{position: 'relative'}}>
      <UsersTableCell>
        <>
          {isProcessing && (
            <LoaderWrapper>
              <Loader isPrimary size="small"></Loader>
            </LoaderWrapper>
          )}
          {user.userInfo.email}
        </>
      </UsersTableCell>
      {isEditing ? (
        <>
        <UsersTableCell>
          <Select
            sx={{ minWidth: '96px' }}
            size="small"
            value={role}
            label="Role"
            onChange={handleRoleChange}
          >
            {roleOptions.map(option => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem> )}
          </Select>
        </UsersTableCell>
        <UsersTableCell>
          <ToggleSwitch label="Disabled" isChecked={isDisabled} onClick={handleMembershipStatusChange} />
        </UsersTableCell>
        </>
      ) : (
        <>
          {isOwnerRow || <UsersTableCell>{user.role}</UsersTableCell>}
          <UsersTableCell>{user.membershipStatus}</UsersTableCell>
        </>
      )}
      {isCurrentUser ? (
        <CurrentAccountTableCell>Your Account</CurrentAccountTableCell>
      ) : (
        <EditTableCell>
          {editModeBlock}
        </EditTableCell>
      )}
    </TableRow>
  );
};

export const UsersTableRow = memo(UsersTableRowComponent);
