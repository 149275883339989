import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { object, string } from 'yup';
import { RowView } from 'components/Layout/Grid';
import { ThemedButton } from 'components/ThemedButton';
import { SecondaryHeading } from 'components/Typography';
import { initialValues, ROLES } from 'forms/InviteUser/data';
import { SubText, NoMarginInputField, Dropdown, InputGridWrap } from 'forms/InviteUser/styled';
import { Props } from 'forms/InviteUser/types';
import { useAuth } from 'hooks/auth/useAuth';
import { UserRole } from 'models/user/User';
import { SiteUserInvitationValues } from 'models/user/UserInvitationValues';
import { useInviteSiteUser } from 'api/users/useInviteSiteUser';
import { useSiteUsers } from 'api/users/useSiteUsers';

const roleOptions = [
  {
    value: UserRole.Staff,
    label: UserRole.Staff,
  },
  {
    value: UserRole.Intake,
    label: UserRole.Intake,
  },
  {
    value: UserRole.Admin,
    label: UserRole.Admin,
  },
];

export const InviteUserForm: FC<Props> = ({ selectedSite, closeModal }) => {
  const {
    state: { userData },
  } = useAuth();
  const { handleCreate } = useInviteSiteUser(location.origin);
  const { revalidate: revalidateUsers } = useSiteUsers(selectedSite.id);

  return (
    <Formik
      initialValues={initialValues(selectedSite.id)}
      onSubmit={async (data: SiteUserInvitationValues, { setErrors }) => {
        try {
          await handleCreate(data);
          await revalidateUsers();
          closeModal();
        } catch (error: any) {
          setErrors({
            email: error,
          });
        }
      }}
      validationSchema={object().shape({
        email: string().email().required('Please enter your new email'),
      })}
    >
      {({ submitForm, isSubmitting, values, errors, touched }) => (
        <Form>
          <SecondaryHeading withoutMargin>Invite user</SecondaryHeading>
          <SubText mt="12px">with email: </SubText>
          <InputGridWrap>
            <NoMarginInputField name="email" placeholder="Email or user name" isCutRight />
            <Dropdown name="role" options={roleOptions} isCutLeft hasErrors={errors.email != null && touched.email} />
          </InputGridWrap>

          <RowView mt="20px" justifyContent="space-between">
            <ThemedButton disabled={isSubmitting} type="submit" width="100%" onClick={submitForm} isInDialog>
              Send invite
            </ThemedButton>
          </RowView>
        </Form>
      )}
    </Formik>
  );
};
