import React, { FC, memo, PropsWithChildren } from 'react';
import { SiteTabChildrenContainer, SiteTabHeadingContainer, SiteTabHeadingCount, SiteTabHeadingText } from './styled';

interface Props {

  /** Heading text. */
  readonly headingText: string;

  /** Count of the items presented on the tab which need to be displayed next to heading */
  readonly count?: number;
}

const SiteTabHeadingComponent: FC<PropsWithChildren<Props>> = ({ headingText, count, children }) => {
  return (
    <SiteTabHeadingContainer>
      <SiteTabHeadingText>
        {headingText} {count != null && <SiteTabHeadingCount>{count}</SiteTabHeadingCount>}
      </SiteTabHeadingText>
      <SiteTabChildrenContainer>{children}</SiteTabChildrenContainer>
    </SiteTabHeadingContainer>
  );
};

export const SiteTabHeading = memo(SiteTabHeadingComponent);
