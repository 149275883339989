import { useFetch } from 'hooks/api';
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';
import { BedDto } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/BedDto';
import { BedMapper } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/BedMapper';

export const useBeds = (id: string | null) =>
  useFetch<Bed[], BedDto[]>(
    {
      url: `/api/beds?siteId=${id}`,
      mapper: (data) => data.map((bedDto: BedDto) => BedMapper.fromDto(bedDto)),
    },
    id !== null
  );
