import { Formik } from 'formik';
import React, { FC } from 'react';
import { InputField } from 'components/InputField';
import { Label } from 'components/InputPreview/styled';
import { Margin } from 'components/Spacing';
import { ThemedButton } from 'components/ThemedButton';
import { initialValues, validationSchema } from 'forms/EditEmail/data';
import { ControlsContainer } from 'forms/EditEmail/styled';
import { Props } from 'forms/EditEmail/types';
import { InputWrap } from 'forms/EditPhone/styled';

export const EditEmail: FC<Props> = ({
  handleSubmit,
  isLoading,
  handleCancel,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
  >
    {({ submitForm }) => (
      <>
        <Label>Email</Label>
        <Margin top={16} />
        <InputWrap>
          <InputField name="email" type="text" placeholder="" />
        </InputWrap>
        <Margin top={10} />
        <ControlsContainer>
          <ThemedButton
            disabled={isLoading}
            type="submit"
            width="100%"
            onClick={submitForm}
            isControl
          >
            Save
          </ThemedButton>
          <Margin left={25} />
          <ThemedButton
            disabled={isLoading}
            type="button"
            width="100%"
            onClick={handleCancel}
            isControl
            outlined
          >
            Cancel
          </ThemedButton>
        </ControlsContainer>
      </>
    )}
  </Formik>
);
