import React, {FC, useEffect, useState} from 'react'
import { SpaceProps } from 'styled-system'
import { ITableColumn, TableComponent } from 'components/TableComponent'
import {generateUserManagementRow } from 'components/TableComponent/RowRenderers/userManagementRow'
import { StyledText } from 'components/Typography'
import {useAuth} from "hooks/auth/useAuth";
import { useSiteUsers } from 'api/users/useSiteUsers'
import { Site } from 'models/site/Site'

export interface IUsersTableProps {

  /** Current site id. */
  readonly siteId: Site['id'];

  /** Search query. */
  readonly query?: string;
}

export const UsersTable: FC<IUsersTableProps & SpaceProps> = ({
  siteId,
  query,
  ...props
}) => {
  const [sortBy, setSortBy] = useState<string>('site.name')
  const [isDescendingOrder, setIsDescendingOrder] = useState<boolean>(false)
  const { state: { userData}} = useAuth()

  const columns: ITableColumn[] = [
    {
      name: 'E-mail',
      width: '1fr',
      sortable: true,
    },
    {
      name: 'Status',
      width: '130px',
      sortable: false,
    },
    {
      name: 'Role',
      width: '130px',
      sortable: true,
    },
    {
      name: '', // edit button
      width: '55px',
      sortable: false,
    },
  ]

  const { data: siteUsers, revalidate, error } = useSiteUsers(
    siteId,
  )

  if (error != null) {
    return <div>Failed to load</div>
  }

  if (siteUsers == null) {
    return (
      <StyledText mt="31px" bigText>
        Loading...
      </StyledText>
    )
  }

  return (
    <TableComponent
      columns={columns}
      data={siteUsers.items}
      renderRow={generateUserManagementRow(revalidate, userData, siteId)}
      elementIdKey="user.id"
      {...props}
    />
  )
}
