/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import { theme } from 'styles/theme';

interface Props {
  large?: boolean;
  medium?: boolean;
  isOpen: boolean;
}

export const ModalContentWrap = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  z-index: 1000;
`

export const StyledModalWindow = styled.div<Props>`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  background: ${theme.colors.white};
  border-radius: 8px;
  padding: 32px 24px;
  width: ${(props) =>
    props.large ? '800px' : props.medium ? '600px' : '350px'};
  height: ${(props) => (props.large ? '82vh' : 'auto')};
  min-height: ${(props) => (props.large ? '600px' : 'auto')};

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  transition: 0.15s all ease-in-out;
  transform: ${(props) =>
    props.isOpen ? 'translate(0, 0)' : 'translate(0, -50px)'};
  pointer-events: ${(props) => (props.isOpen ? 'auto' : 'none')};

  /* display: flex;
  flex-direction: column; */
`

export const ModalContent = styled.div`
  /* overflow: scroll; */

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props) => (props.isOpen ? 990 : -1)};
  /* width: 100vw; */
  /* height: 100vh; */
  backdrop-filter: blur(15px);
  background-color: rgba(0, 0, 0, 0.8);
  transition: 0.15s all ease-in-out;

  opacity: ${(props) => (props.isOpen ? 1 : 0)};
`

export const CloseButton = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.08);
  position: absolute;
  top: 0;
  right: -44px;
  margin: auto;
  z-index: 1000;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`
