import React from 'react'
import { useSites } from 'api/sites/useSites'
import { Layout } from 'components/Layout'
import { FormHeading, Header } from 'forms/SignIn/styled'
import { useAuth } from "hooks/auth/useAuth";
import { LinkWithoutStyle } from 'components/Typography';
import { SitesWrap } from 'pages/SiteList/List/styled';
import { routes } from 'router/data';
import { NoData } from 'components/NoData';
import { SiteBlock } from 'components/SiteBlock/SiteBlock';

export const UserManagementList = () => {
  const {
    data: sitesData,
    error: sitesError,
  } = useSites()
  const { state: { userData } } = useAuth();


  if (sitesError) {
    return <div>Failed to load</div>
  }

  if (!sitesData) {
    return <NoData label='No records available in reservations.' />
  }

  // Only display to admins the allowed sites
  const availableSites = sitesData.items
    .filter(site => userData.roleAdminSiteIds.includes(site.id.toString()) || userData.roleGlobal);

  return (
    <Layout withSideBar>
      <Header>
        <FormHeading>Users</FormHeading>
      </Header>

      {availableSites.length > 0 ?

        <SitesWrap>
          {availableSites.map((site: any) => (
            <LinkWithoutStyle key={site.id} to={routes.UserManagementDetails(site.id)}>
              <SiteBlock key={site.id} site={site} />
            </LinkWithoutStyle>
          ))}
        </SitesWrap>
        :
        <NoData label='No records available in users.' />
      }
    </Layout>
  )
}
