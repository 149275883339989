import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'
import { ITableColumn } from 'components/TableComponent'
import { MediumText } from 'components/Typography'
import { theme } from 'styles/theme'

export const Container = styled.div<SpaceProps & { columns: ITableColumn[] }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.columns.map((col) => ` ${col.width}`)};
  border-bottom: 1px solid ${theme.colors.middlePlusGray};
  ${space}
`

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  padding: 14px 12px;
`

export const Text = styled(MediumText)`
  color: ${theme.colors.grayText};
`
