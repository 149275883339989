import { useMutate } from 'hooks/api';
import { Organization } from 'models/organization/Organization';

export const useDeleteOrganization = (id: Organization['id']) => {
  const { mutate } = useMutate('delete', {
    url: `/api/Organizations/${id}`,
  })

  return { handleDelete: mutate }
}
