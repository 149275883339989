import { useOrganizationSubscription } from 'api/subscription/useOrganizationSubscription';
import { Formik } from 'formik';
import React, { FC, memo, useState } from 'react';
import { number, object } from 'yup';
import { SubscriptionService } from '../../api/Subscriptions/SubscriptionService';
import { useSubscriptionTariffs } from '../../api/Subscriptions/useSubscriptionTariffs';
import { Organization } from '../../models/organization/Organization';
import { PaymentState } from '../../models/Subscriptions/BasePayment';
import { Tariff } from '../../models/Tariff/Tariff';
import { StripeService } from '../../services/stripe/StripeService';
import { Loader } from '../Loader/Loader';
import { StyledButton } from '../StyledButton/styled';
import { ResubscriptionConfirmation } from './ResubscriptionConfirmation/ResubscriptionConfirmation';
import {
  ResubscriptionFormButtonsContainer,
  ResubscriptionFormContainer,
  ResubscriptionFormErrorMessage,
} from './styled';
import { TariffFormCheckbox } from './TariffCheckbox/TariffChexkbox';
import { LoaderWrapper } from '../Loader/styled';

const POLL_TIME_MS = 1000;
const POLL_TIMEOUT_COUNT = 10;

/** Resubscription form values. */
export interface ResubscriptionFormValues {

  /** Tariff id. */
  readonly tariffId: Tariff['id'] | null;
}

const validationSchema = object().shape<ResubscriptionFormValues>({
  tariffId: number()
    .nullable()
    .required('Please choose tariff.'),
})

interface Props {

  /** Organization id. */
  readonly organizationId: Organization['id'];

  /** On cancel button click callback. */
  readonly onClose: () => void;
}

const ResubscriptionFormComponent: FC<Props> = ({ organizationId, onClose }) => {

  const { data: tariffs } = useSubscriptionTariffs();

  const { revalidate: revalidateSubscription } = useOrganizationSubscription(organizationId);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isPaymentInProcess, setIsPaymentInProcess] = useState(false);

  const [paymentError, setPaymentError] = useState<string | null>(null);

  const [isConfirmingPayment, setIsConfirmingPayment] = useState<boolean>(false);

  const handleContinueButtonClick = () => setIsConfirmingPayment(true);
  const handleBackButtonClick = () => {
    setIsConfirmingPayment(false);
    setPaymentError(null);
  };

  const handleSubscriptionRecreate = async(tariffId: Tariff['id'] | null) => {
    if (tariffId == null) {
      return;
    }
    setIsLoading(true);
    setIsPaymentInProcess(true);
    setPaymentError(null);
    const { clientSecret, paymentId } = await SubscriptionService.recreateSubscription({
      subscriptionOptionId: tariffId,
      organizationId,
    })

    const { error } = await StripeService.confirmCardPayment(clientSecret);

    if (error != null) {
      setPaymentError(error.message ?? 'Unexpected error occurred. Please try again later.');
      setIsLoading(false);
      setIsPaymentInProcess(false);
      return;
    }

    let pollCounter = 0;
    let timeoutId: NodeJS.Timeout;

    const checkPayment = async() => {
      const payment = await SubscriptionService.getPayment(paymentId);

      if (payment.state === PaymentState.Succeed) {
        clearTimeout(timeoutId);
        await revalidateSubscription();
        onClose();
        setIsLoading(false);
        setIsPaymentInProcess(false);
        return;
      } else if (pollCounter >= POLL_TIMEOUT_COUNT) {
        clearTimeout(timeoutId);
        setIsLoading(false);
        setIsPaymentInProcess(false);
        return;
      }

      pollCounter++;

      timeoutId = setTimeout(() => checkPayment(), POLL_TIME_MS);
    };

    await checkPayment();
  }

  if (tariffs == null) {
    return <Loader size="medium" isPrimary />
  }

  const handleSubmit = async(values: ResubscriptionFormValues) => await handleSubscriptionRecreate(values.tariffId)

  return (
    <Formik<ResubscriptionFormValues>
      initialValues={{
        tariffId: null,
      }}
      onSubmit={isConfirmingPayment ? handleSubmit : handleContinueButtonClick}
      validationSchema={validationSchema}
    >
      {
        ({ errors, handleSubmit, values }) => (
          <ResubscriptionFormContainer onSubmit={handleSubmit}>
            <h2>Resubscribe</h2>
            {
              isConfirmingPayment ?
                <>
                  <ResubscriptionConfirmation organizationId={organizationId} tariffId={values.tariffId} onLoadingChange={setIsLoading} />
                  {paymentError  && <ResubscriptionFormErrorMessage>{paymentError}</ResubscriptionFormErrorMessage>}
                </> :
                <>
                  {tariffs.map(tariff => <TariffFormCheckbox tariff={tariff} key={tariff.id} />)}
                  {errors.tariffId && <ResubscriptionFormErrorMessage>{errors.tariffId}</ResubscriptionFormErrorMessage>}
                </>
            }
            <ResubscriptionFormButtonsContainer>
              <StyledButton
                type="button"
                variant="outlined"
                color="warning"
                onClick={isConfirmingPayment ? handleBackButtonClick : onClose}
                width="50%"
                disabled={isLoading || isPaymentInProcess}
              >
                {isConfirmingPayment ? 'Back' : 'Cancel'}
              </StyledButton>
              <StyledButton
                type="submit"
                variant="outlined"
                width="50%"
                disabled={isLoading || isPaymentInProcess}
              >
                {isConfirmingPayment ? 'Confirm' : 'Continue'}
              </StyledButton>
            </ResubscriptionFormButtonsContainer>
            {isPaymentInProcess && (
              <LoaderWrapper>
                <Loader
                  size='large'
                  isPrimary
                />
              </LoaderWrapper>
            )}
          </ResubscriptionFormContainer>
        )
      }
    </Formik>
  )
};

export const ResubscriptionForm = memo(ResubscriptionFormComponent);
