import React, { FC, memo } from 'react';
import { UnderlinedHeadingLine, UnderlinedHeadingText } from './styled';

interface Props {

  /** Heading text. */
  readonly text: string;
}

const UnderlinedHeadingComponent: FC<Props> = ({ text }) => {
  return (
    <>
      <UnderlinedHeadingText>{text}</UnderlinedHeadingText>
      <UnderlinedHeadingLine />
    </>
  );
};

export const UnderlinedHeading = memo(UnderlinedHeadingComponent);
