import React, { FC, memo } from 'react';
import { TableRow } from '@mui/material';
import { LogsTableCell } from '../TableCells/TableCells';
import { getTimeFromDate } from '../../../../utils/getTimeFromDate';
import { FailedLoginLog } from '../../../../models/logs/FailedLoginLog';

interface Props {

  /** Failed login log. */
  readonly log: FailedLoginLog;
}

const FailedLogTableRowComponent: FC<Props> = ({ log }) => {

  return (
    <TableRow>
      <LogsTableCell>{log.login}</LogsTableCell>
      <LogsTableCell>{log.loginFailedAt.toLocaleDateString()}</LogsTableCell>
      <LogsTableCell>{getTimeFromDate(log.loginFailedAt)}</LogsTableCell>
    </TableRow>
  )
}

export const FailedLogTableRow = memo(FailedLogTableRowComponent);
