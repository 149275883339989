import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { theme } from 'styles/theme'

export const LinkWithoutStyle = styled(Link)`
  text-decoration: none;
`
export const BackLink = styled.span`
  color: ${theme.colors.semiLightBlue};
  font-weight: 600;
  font-size: 0.75rem;
  text-decoration: none;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  cursor: pointer;
`
