import React from "react";
import { FC } from "react";
import { ErrorText } from './styled';

/** Validation error props. */
export interface ValidationErrorProps {
  /** Message of the error. */
  readonly message?: string;

  /** Show if error should be centered inside its parent flex container. */
  readonly isCentered?: boolean;
}

export const ValidationError: FC<ValidationErrorProps> = ({ message, ...props }) => message != null ? <ErrorText {...props}>{message}</ErrorText> : <></>
