import React, { ChangeEvent, FC, memo, useCallback } from 'react';
import { TextField } from '@mui/material';

interface Props {

  /** Class name. */
  readonly className?: string;

  /** Current value. */
  readonly value: string;

  /** On change callback. */
  readonly onChange: (newValue: string) => void;
}

const GuestFeedbackFieldComponent: FC<Props> = ({ className, onChange, value }) => {

  const handleValueChange = useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => onChange(event.target.value), [onChange])

  return (
    <TextField
      value={value}
      onChange={handleValueChange}
      multiline
      rows={5}
      placeholder="Feedback"
      className={className}
    />
  )
}

export const GuestFeedbackField = memo(GuestFeedbackFieldComponent);
