import { PaginationWithMetadataDto } from '../dtos/pagination/PaginationWithMetadataDto';
import { PaginationWithMetadata } from '../models/pagination/PaginationWithMetadata';
import { PaginationMetadataMapper } from './PaginationMetadataMapper';

/** Mapper for paginated items with metadata. */
export namespace PaginationWithMetadataMapper {

  /** Maps paginated dtos to models. */
  export function fromDto<TDto, TModel>(dto: PaginationWithMetadataDto<TDto>, fromDtoMapper: (dto: TDto) => TModel): PaginationWithMetadata<TModel> {
    return {
      metadata: PaginationMetadataMapper.fromDto(dto.metadata),
      items: dto.items.map(itemDto => fromDtoMapper(itemDto)),
    }
  }
}
