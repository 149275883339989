import { FailedLoginLogDto } from '../../dtos/logs/FailedLoginLogDto';
import { GuestCreationLogDto } from '../../dtos/logs/GuestCreationLogDto';
import { PasswordChangeLogDto } from '../../dtos/logs/PasswordChangeLogDto';
import { PaginationWithMetadataDto } from '../../dtos/pagination/PaginationWithMetadataDto';
import { useFetch } from '../../hooks/api';
import { FailedLoginLogMapper } from '../../mappers/FailedLoginLogMapper';
import { GuestCreationLogMapper } from '../../mappers/GuestCreationLogMapper';
import { PaginationWithMetadataMapper } from '../../mappers/PaginationWithMetadataMapper';
import { PasswordChangeLogMapper } from '../../mappers/PasswordChangeLogMapper';
import { FailedLoginLog } from '../../models/logs/FailedLoginLog';
import { GuestCreationLog } from '../../models/logs/GuestCreationLog';
import { PasswordChangeLog } from '../../models/logs/PasswordChangeLog';
import { PaginationWithMetadata } from '../../models/pagination/PaginationWithMetadata';
import { PaginationOptions } from '../PaginationOptions';

enum LogTypeUrl {
  PasswordChange = 'passwordChanged',
  GuestCreation = 'guestCreated',
  FailedLogin = 'loginFailed',
}

/**
 * Gets url for logs.
 * @param logTypeUrl
 * @param pageSize Page size.
 * @param pageNumber Page number.
 */
function getLogsUrl(logTypeUrl: LogTypeUrl, { pageSize, pageNumber }: PaginationOptions): string {
  return `/api/EventLogs/${logTypeUrl}?${new URLSearchParams({
    PageSize: pageSize.toString(),
    PageNumber: pageNumber.toString(),
  })}`
}

/**
 * Gets password change logs.
 * @param paginationOptions Pagination options.
 * @param shouldFetch Whether should fetch.
 */
export const usePasswordChangedLogs = (paginationOptions: PaginationOptions , shouldFetch = true) =>
  useFetch<PaginationWithMetadata<PasswordChangeLog>, PaginationWithMetadataDto<PasswordChangeLogDto>>({
    url: getLogsUrl(LogTypeUrl.PasswordChange, paginationOptions),
    mapper: (data) => PaginationWithMetadataMapper.fromDto(data, PasswordChangeLogMapper.fromDto),
  }, shouldFetch);

  /**
 * Gets guest creation logs.
 * @param paginationOptions Pagination options.
 * @param shouldFetch Whether should fetch.
 */
export const useGuestCreationLogs = (paginationOptions: PaginationOptions , shouldFetch = true) =>
useFetch<PaginationWithMetadata<GuestCreationLog>, PaginationWithMetadataDto<GuestCreationLogDto>>({
  url: getLogsUrl(LogTypeUrl.GuestCreation, paginationOptions),
  mapper: (data) => PaginationWithMetadataMapper.fromDto(data, GuestCreationLogMapper.fromDto),
}, shouldFetch);

/**
 * Gets failed login logs.
 * @param paginationOptions Pagination options.
 * @param shouldFetch Whether should fetch.
 */
export const useFailedLoginLogs = (paginationOptions: PaginationOptions , shouldFetch = true) =>
  useFetch<PaginationWithMetadata<FailedLoginLog>, PaginationWithMetadataDto<FailedLoginLogDto>>({
    url: getLogsUrl(LogTypeUrl.FailedLogin, paginationOptions),
    mapper: (data) => PaginationWithMetadataMapper.fromDto(data, FailedLoginLogMapper.fromDto),
  }, shouldFetch);
