import { Modal } from '@mui/material';
import React, { memo, PropsWithChildren } from 'react';
import { FC } from 'react';
import {
  ModalCloseButton,
  ModalContentWrapper,
  ScrollableModalWrapper,
} from './styled';

interface Props {
  /** Shows if the modal is open. */
  readonly isOpen: boolean;

  /** Handles on close action. */
  readonly onClose: () => void;

  /** Whether modal should be scrollable. */
  readonly isScrollable?: boolean;
}

const ModalWindowComponent: FC<PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
  isScrollable,
  children,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ScrollableModalWrapper>
        <ModalContentWrapper isScrollable={isScrollable}>{children}</ModalContentWrapper>
        <ModalCloseButton onClick={onClose}>
          <img src="/assets/icons/close.svg" alt="X" />
        </ModalCloseButton>
      </ScrollableModalWrapper>
    </Modal>
  );
};

export const ModalWindow = memo(ModalWindowComponent);
