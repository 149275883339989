import React, { FC, memo, useCallback, useState } from 'react';
import { PaginatedLogsTableContainer } from '../PaginatedLogsTableContainer';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { LogsTableHeader } from '../TableCells/TableCells';
import { PaginationContainer } from '../../../../components/PaginationContainer/PaginationContainer';
import { PaginationSelect } from '../../../../components/PaginationSelect/PaginationSelect';
import { Loader } from '../../../../components/Loader/Loader';
import { useUsers } from '../../../../api/users/useUsers';
import { InternalTableRow } from './InternalTableRow';

const PAGE_SIZE = 10;

const InternalLogsComponent: FC = () => {

  const [pageIndex, setPageIndex] = useState<number>(0);

  const handlePageChange = useCallback((newPageIndex: number) => setPageIndex(newPageIndex), [setPageIndex]);

  const { data: paginatedUsers } = useUsers({
    pageNumber: pageIndex + 1,
    pageSize: PAGE_SIZE,
  });

  if (paginatedUsers == null) {
    return <Loader size="medium" isPrimary />
  }

  return (
    <PaginatedLogsTableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <LogsTableHeader>Organization</LogsTableHeader>
            <LogsTableHeader>Site</LogsTableHeader>
            <LogsTableHeader>Email</LogsTableHeader>
            <LogsTableHeader>Role</LogsTableHeader>
            <LogsTableHeader>Date</LogsTableHeader>
            <LogsTableHeader>Time</LogsTableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedUsers.items.map(user => <InternalTableRow key={user.id} user={user} />)}
        </TableBody>
      </Table>
      <PaginationContainer>
        <PaginationSelect
          maxPageCount={paginatedUsers.metadata.totalPages}
          currentPageIndex={pageIndex}
          onPageSelect={handlePageChange}
          type="row"
        />
      </PaginationContainer>
    </PaginatedLogsTableContainer>
  )
}

export const InternalLogs = memo(InternalLogsComponent);
