import styled from '@emotion/styled';
import { MenuItem, Select } from '@mui/material';
import { theme } from '../../styles/theme';

export const PaymentMethodSelectInput = styled(Select)`
  border: 1px solid ${theme.colors.veryLightGray};
  border-radius: 8px;
  height: 35px;
  font-weight: 600;
  font-size: var(--font-size-xs);
  font-family: inherit;

  .MuiSelect-select {
    display: flex;
    gap: 10px;
    height: 100% !important;
    align-items: center;
  }
` as unknown as typeof Select;

export const PaymentMethodSelectItem = styled(MenuItem)`
  display: flex;
  gap: 10px;
  font-weight: 600;
  font-size: var(--font-size-xs);
  font-family: inherit;
`;

export const PaymentMethodBrandLogo = styled.img`
  max-height: 16px;
`;
