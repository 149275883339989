import React, { FC } from 'react'
import { useSites } from 'api/sites/useSites'
import { Layout } from 'components/Layout'
import { LinkWithoutStyle } from 'components/Typography'
import { routes } from 'router/data'
import { SitesWrap } from 'pages/SiteList/List/styled'
import { Header } from 'components/TopHeader/styled'
import { FormHeading } from 'forms/SignIn/styled'
import { useAuth } from 'hooks/auth/useAuth'
import { NoData } from 'components/NoData'
import { SiteBlock } from 'components/SiteBlock/SiteBlock'

export const ReservationMainPage: FC = () => {
  const { data: sitesData, error } = useSites()
  const { state: { userData } } = useAuth();

  if (error != null) {
    return <div>failed to load</div>
  }
  if (sitesData == null) {
    return <NoData label='No records available in reservations.' />
  }

  // Only display to admins and staff the allowed sites
  const availableSites = sitesData.items
    .filter(site => userData.roleAdminSiteIds.includes(site.id) ||
      userData.roleIntakeSiteIds.includes(site.id) ||
      userData.roleStaffSiteIds.includes(site.id) ||
      userData.roleGlobal
    );

  return (
    <Layout withSideBar>
      <Header>
        <FormHeading>Reservations</FormHeading>
      </Header>
      {availableSites.length > 0 ?

        <SitesWrap>
          {availableSites.map(site => (
            <LinkWithoutStyle key={site.id} to={routes.ReservationDetails(site.id)}>
              <SiteBlock key={site.id} site={site} />
            </LinkWithoutStyle>
          ))}
        </SitesWrap>
        :
        <NoData label='No records available in reservations.' />
      }
    </Layout>
  )
}
