/** Image movement direction. */
export type ImageMoveDirection = 'forward' | 'backward';

type ImageConstructorData = Image;

/** Image. */
export class Image {

  /** Image path. */
  public readonly imagePath: string;

  /** Index of image. */
  public readonly index: number;

  public constructor(data: ImageConstructorData) {
    this.imagePath = data.imagePath;
    this.index = data.index;
  }

  /**
   * Gets image with lowest or highest index from provided array.
   * @param images Images.
   * @param mode Whether to look for first or last image.
   */
  public static getFirstOrLastImage(images: readonly Image[], mode: 'first' | 'last'): Image | null {
    if (images.length === 0) {
      return null;
    }

    const isLookingForLastImage = mode === 'last';

    let maximumOrMinimumIndex = isLookingForLastImage ? 0 : Infinity;

    return images.reduce((image, currentImage) => {
      if (
        isLookingForLastImage ?
          currentImage.index > maximumOrMinimumIndex :
          currentImage.index < maximumOrMinimumIndex
      ) {
        maximumOrMinimumIndex = currentImage.index;
        return currentImage;
      }
      return image;
    }, images[0]);
  }

  /**
   * Gets image which index is nearest to provided.
   * @param images Images.
   * @param index Index to find nearest to.
   * @param direction Forward means to look after provided index, backward means to look before provided index.
   */
  public static getNearestImage(images: readonly Image[], index: number, direction: ImageMoveDirection): Image | null {
    if (images.length === 0) {
      return null;
    }

    let minimumDifference = Infinity;
    return images.reduce((image, currentImage) => {
      const difference = direction === 'forward' ?
        currentImage.index - index :
        index - currentImage.index;

      if (difference > 0 && difference < minimumDifference) {
        minimumDifference = difference;
        return currentImage;
      }

      return image;
    }, images[0]);
  }
}
