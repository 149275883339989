import { Pagination } from 'services/types';

export namespace PaginationMapper {
  /** @inherirdoc */
  export function fromDto<TDto, TModel>(
    paginationDto: Pagination<TDto>,
    itemsMapper: (dto: TDto) => TModel
  ): Pagination<TModel> {
    return {
      hasNextPage: paginationDto.hasNextPage,
      hasPreviousPage: paginationDto.hasPreviousPage,
      pageIndex: paginationDto.pageIndex,
      totalCount: paginationDto.totalCount,
      totalPages: paginationDto.totalPages,
      items: paginationDto.items.map((item) => itemsMapper(item)),
    };
  }
}
