import React, { ChangeEventHandler, FC, memo, PropsWithChildren } from 'react';
import { CardCheckboxLabel } from './styled';

interface CheckboxProps {

  /** On change callback. */
  readonly onChange: ChangeEventHandler<HTMLInputElement>;

  /** Value of the checkbox. */
  readonly value: string;

  /** Name of the checkbox. */
  readonly name: string;

  /** Whether the checkbox is checked. */
  readonly isChecked: boolean;

  /** Whether content of the checkbox should be positioned in column. */
  readonly isColumn?: boolean;
}

const CardCheckboxComponent: FC<PropsWithChildren<CheckboxProps>> = ({
  onChange,
  isColumn,
  value,
  name,
  isChecked,
  children,
}) => {
  return (
    <CardCheckboxLabel isChecked={isChecked} isColumn={isColumn}>
      {children}
      <input
        className="visually-hidden"
        type="checkbox"
        value={value}
        name={name}
        onChange={onChange}
        checked={isChecked}
      />
    </CardCheckboxLabel>
  );
};

export const CardCheckbox = memo(CardCheckboxComponent);
