import styled from '@emotion/styled';
import CSS from 'csstype';
import React, { FC, useMemo, useState } from 'react';
import { EditButton } from 'components/TableComponent/RowRenderers/CommonComponents/EditButton';
import { RowCell } from 'components/TableComponent/RowRenderers/CommonComponents/RowCell';
import { DropdownList, IDropdownOption } from 'components/TableComponent/RowRenderers/userManagementRow/Components/DropdownList';
import { Grid } from 'components/TableComponent/RowRenderers/userManagementRow/styled';
import { MediumText } from 'components/Typography';
import { IUserData } from 'hooks/auth/types';
import { User, UserMembershipStatus, UserRole } from 'models/user/User';
import { useUpdateUser } from 'api/users/useUpdateSiteUser';
import { Site } from 'models/site/Site';
import { Alert, Snackbar } from '@mui/material';

const Icon = styled.img`
  cursor: pointer;
`;

const availableRoles = [UserRole.Admin, UserRole.Staff, UserRole.Intake] as const;
type AvailableRole = typeof availableRoles[number]

interface Props {

  /** User. */
  readonly user: User;

  /** CSS grid template. */
  readonly columnsTemplate?: CSS.Property.GridTemplateColumns;

  /** Revalidate handler. */
  readonly revalidate: () => void;

  /** User data. */
  readonly userData: IUserData;

  /** Site id. */
  readonly siteId: Site['id']
}

export const generateUserManagementRow = (
  revalidate: () => Promise<boolean>,
  userData: IUserData,
  siteId: Site['id']
) => (
  user: User,
  columnsTemplate?: CSS.Property.GridTemplateColumns
) => (
  <UserManagementRow
    user={user}
    columnsTemplate={columnsTemplate}
    revalidate={revalidate}
    userData={userData}
    siteId={siteId}
  />
);

// eslint-disable-next-line react/no-multi-comp
export const UserManagementRow: FC<Props> = ({ user, columnsTemplate, revalidate, userData, siteId }) => {
  const { setActive, setDisabled, deleteUser, changeRole } = useUpdateUser(user.id);

  const canEdit = user.id.toString() !== userData.userId;
  const isNotPending = user.membershipStatus !== UserMembershipStatus.PendingInvitation;

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const hasError = errorMessage != null;
  const handleSnackbarClose = () => setErrorMessage(null);

  const editOptions = [];
  if (canEdit) {
    if (user.role !== UserRole.GlobalAdmin && isNotPending) {
      if (user.membershipStatus === UserMembershipStatus.Disabled) {
        editOptions.push({ name: 'Enable', code: UserMembershipStatus.Active });
      } else {
        editOptions.push({ name: 'Disable', code: UserMembershipStatus.Disabled });
      }
    }

    editOptions.push({ name: 'Delete', code: 'delete' });
  }

  const options = useMemo(
    () => availableRoles
      .filter(role => role !== user.role)
      .map(role => ({ name: role, code: role })),
    [user.role],
  );

  const handleOptionClick = async (option: IDropdownOption) => {
    await changeRole(
      {
        role: option.code as AvailableRole,
        siteId,
      },
      (error: unknown) => {
        if (typeof error === 'string') {
          setErrorMessage(error)
        } else {
          setErrorMessage('Unknown error appeared while changing user\'s role.')
        }
      },
    );
    revalidate();
  }

  return (
    <Grid columnsTemplate={columnsTemplate ?? '1fr'}>
      <RowCell text={user.userInfo.email} />
      <RowCell text={user.membershipStatus} />
      {canEdit && isNotPending ? (
        <DropdownList
          selectedOptionId={user.role}
          preview={(option: any) => (
            <>
              <MediumText>{option}</MediumText>
              <Icon src="/assets/icons/arrow_down.svg" alt="arrow_down" />
            </>
          )}
          options={options}
          onClick={handleOptionClick}
        />
      ) : (
        <MediumText lineHeight="50px" style={{ padding: '7px 12px' }}>
          {user.role}
        </MediumText>
      )}
      {canEdit && (
        <DropdownList
          selectedOptionId={user.role}
          preview={() => <EditButton elementId={user.id} />}
          options={editOptions}
          onClick={async (option: any) => {
            switch (option.code) {
              case UserMembershipStatus.Disabled: {
                await setDisabled();
                break;
              }
              case UserMembershipStatus.Active: {
                await setActive();
                break;
              }
              case 'delete': {
                await deleteUser();
                break;
              }
              default:
            }
            revalidate();
          }}
        />
      )}
      <Snackbar
        open={hasError}
        onClose={handleSnackbarClose}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          onClose={handleSnackbarClose}
        >{errorMessage}</Alert>
      </Snackbar>
    </Grid>
  );
};
