import { AboutForm } from 'pages/SiteCreate/components/About';
import { ContactsForm } from 'pages/SiteCreate/components/Contacts';
import {
  DAYS_STRUCTURE,
  IntakePeriodsForm,
} from 'pages/SiteCreate/components/IntakePeriods';
import { ServicesForm } from 'pages/SiteCreate/components/Services';
import { RoomsList } from './components/Rooms/RoomsList';
import { Room } from './components/Rooms/domain/Room';
import {
  commercialSiteAboutValidationSchema,
  nonCommercialSiteAboutValidationSchema,
  siteAccomodationsValidationSchema,
  siteCreationContactsValidationSchema,
  siteIntakePeriodsValidationSchema,
  siteServicesValidationSchema
} from './validationSchemas';
import { Organization } from '../../models/organization/Organization';
import React, { FC } from 'react';
import { ObjectSchema } from 'yup';
import { StrictOmit } from '../../utils/types/strictOmit';

interface SiteCreationStep {

  /** Id of the step. */
  readonly id: string;

  /** Label of the step. */
  readonly label: string;

  /** Form component. */
  readonly component: FC;

  /** Initial values. */
  readonly initialValues: Record<string, unknown>;

  /** Yup validation schema. */
  readonly validationSchema: ObjectSchema;
}

const SITE_ABOUT_STEP_BASE: StrictOmit<SiteCreationStep, 'validationSchema'> = {
  id: 'about',
  label: 'About',
  component: AboutForm,
  initialValues: {
    name: '',
    alias: '',
    description: '',
    images: [],
  },
}

const nonCommercialSiteAboutStep: SiteCreationStep = {
  ...SITE_ABOUT_STEP_BASE,
  validationSchema: nonCommercialSiteAboutValidationSchema,
};

const commercialSiteAboutStep: SiteCreationStep = {
  ...SITE_ABOUT_STEP_BASE,
  validationSchema: commercialSiteAboutValidationSchema,
}

const siteContactsStep: SiteCreationStep = {
  id: 'contacts',
  label: 'Contacts',
  component: ContactsForm,
  initialValues: {
    address: '',
    contactPhone: '',
    contactWeb: '',
    contactEmail: '',
    adminEmail: '',
    organizationId: '',
  },
  validationSchema: siteCreationContactsValidationSchema,
};

const siteIntakePeriodsStep: SiteCreationStep = {
  id: 'intakePeriods',
  label: 'Intake hours',
  component: IntakePeriodsForm,
  initialValues: {
    showDaysOfWeek: true,
    intakePeriods: DAYS_STRUCTURE,
  },
  validationSchema: siteIntakePeriodsValidationSchema,
}

const siteAccomodationsStep: SiteCreationStep = {
  id: 'accomodations',
  label: 'Accomodations',
  component: RoomsList,
  initialValues: {
    rooms: [
      new Room({
        name: 'Room #1',
        beds: [],
        id: null,
        photo: null,
        createdAt: new Date(),
      },),
    ],
  },
  validationSchema: siteAccomodationsValidationSchema,
};

const getSiteServicesStep = (isCommercial: Organization['isCommercial']): SiteCreationStep => ({
  id: 'services',
  label: 'Services',
  component: () => <ServicesForm isCommercial={isCommercial} />,
  initialValues: {
    lengthOfStayDaysMin: null,
    lengthOfStayDaysMax: null,
    screeningPeriod: '',
    languageIds: [],
    serviceIds: [],
  },
  validationSchema: siteServicesValidationSchema,
});

/**
 * Get site creation form steps.
 * @param isCommercial Whether the organization in which to create a site is commercial or not.
 */
export const getSiteCreationSteps = (isCommercial: Organization['isCommercial']): SiteCreationStep[] => {
  return isCommercial ?
    [
      commercialSiteAboutStep,
      siteContactsStep,
      siteIntakePeriodsStep,
      getSiteServicesStep(isCommercial),
    ] :
    [
      nonCommercialSiteAboutStep,
      siteContactsStep,
      siteIntakePeriodsStep,
      siteAccomodationsStep,
      getSiteServicesStep(isCommercial),
    ]
}
