import { TariffDto } from '../dtos/Tariff/TariffDto';
import { Tariff } from '../models/Tariff/Tariff';

/** Tariff mapper. */
export namespace TariffMapper {

  /** Maps dto to model. */
  export function fromDto(dto: TariffDto): Tariff {
    // Tariff always have one tariff period.
    const tariffPeriodDto = dto.periods[0];
    return new Tariff({
      id: dto.id,
      name: dto.name,
      minBedCount: dto.limit,
      price: dto.subscriptionCostInDollars,
      pricePerBed: tariffPeriodDto.costPerBedInDollars,
      duration: tariffPeriodDto?.durationInMonth,
    });
  }
}

