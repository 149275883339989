/* eslint-disable @typescript-eslint/prefer-regexp-exec */
import React, { FC, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { BlockView } from 'components/Layout/Grid'
import { MenuItemLink } from 'components/SideBar/styled'
import { ERoute, routes } from 'router/data'
import { useAuth } from 'hooks/auth/useAuth'
import { Organization } from '../../models/organization/Organization'
import { useOrganization } from '../../api/organizations/useOrganization'

interface Params {

  /** Organization ID. */
  readonly organizationId: string | undefined;
}

interface MenuItem {

  /** Route path. */
  readonly route: string;

  /** Label. */
  readonly label: string;

  /** Whether the menu item is active for user. */
  readonly isActive?: boolean;
}

/**
 * Gets menu items for user.
 * @param organizationId Organization id of the user.
 * @param isCommercial Whether the user is in commercial organization.
 * @param shouldShowOwnerTabs Whether need too show owner tabs.
 */
function getMenuItems(
  organizationId: Organization['id'],
  isCommercial: Organization['isCommercial'],
  shouldShowOwnerTabs: boolean,
): readonly MenuItem[] {
  return [
    {
      route: routes.OrganizationsList(),
      label: 'Organizations',
    },
    ...shouldShowOwnerTabs ?
    [
      {
        route: routes.OrganizationCardsDetails(organizationId),
        label: 'Credit cards',
        isActive: isCommercial,
      },
      {
        route: routes.OrganizationPaymentDetails(organizationId),
        label: 'Payment details',
        isActive: isCommercial,
      },
    ] :
    []
  ];
};

const ROLE_GLOBAL_MENU: readonly MenuItem[] = [
  {
    route: ERoute.FREE_REHABS_LIST,
    label: 'Rehabs (no charge)',
  },
  {
    route: ERoute.PAID_REHABS_LIST,
    label: 'Rehabs (other)',
  },
  {
    route: ERoute.INSURANCE_COMPANIES,
    label: 'Insurances',
  },
  {
    route: ERoute.AUDIT_LOG,
    label: 'Audit Log',
  },
]

export const SideBar: FC = () => {
  const location = useLocation()

  const { organizationId } = useParams<Params>();

  const { roleGlobal: isRoleGlobal, roleOwnerOrganizationIds } = useAuth().state.userData;

  const shouldShowOwnerTabs = organizationId != null ? roleOwnerOrganizationIds.includes(organizationId) : false;

  const { data: organizationData } = useOrganization(organizationId ?? '', organizationId != null)

  const menuItems = useMemo<readonly MenuItem[]>(() => {
    if (isRoleGlobal) {
      return ROLE_GLOBAL_MENU;
    }

    const isOrganizationCommercial = organizationData != null && organizationData.isCommercial;

    return getMenuItems(organizationId ?? '', isOrganizationCommercial, shouldShowOwnerTabs);
  }, [isRoleGlobal, organizationData, shouldShowOwnerTabs, organizationId])

  const activeMenuItems = menuItems.filter(item => item.isActive == null || item.isActive);

  return (
    <nav>
      <BlockView mb={10}>
        {activeMenuItems.map((menuItem) => (
          <MenuItemLink
            key={menuItem.label}
            active={Boolean(location.pathname.match(menuItem.route))}
            to={menuItem.route}
          >
            {menuItem.label}
          </MenuItemLink>
        ))}
      </BlockView>
    </nav>
  )
}
