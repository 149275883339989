import React, { FC, PropsWithChildren, memo, useEffect, useRef, useState } from 'react';
import { ConfirmDialog, ConfirmDialogProps } from '../Dialogs/ConfirmDialog/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import { UnregisterCallback } from 'history';
import { StrictOmit } from '../../utils/types/strictOmit';

interface Props
  extends StrictOmit<ConfirmDialogProps, 'isOpened' | 'onClose' | 'onConfirmationButtonClick'> {

  /** Whether need to confirm navigation. */
  readonly needToConfirm: boolean;
};

const NavigationConfirmModalComponent: FC<PropsWithChildren<Props>> = ({ children, needToConfirm, ...props }) => {

  const history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const unblockRef = useRef<UnregisterCallback>();

  const handleShowModal = () => {
    setShowModal(true);
  }

  useEffect(() => {
    unblockRef.current = history.block((location) => {
      if (needToConfirm) {
        setCurrentPath(location.pathname);
        handleShowModal();
        return false;
      }
    });

    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [needToConfirm]);

  const handleConfirm = () => {
    if (unblockRef.current != null) {
      unblockRef.current();
    }
    setShowModal(false);
    history.push(currentPath);
  }

  const handleCancelButtonClick = () => {
    setShowModal(false);
  }

  return (
    <ConfirmDialog
      isOpened={showModal}
      onClose={handleCancelButtonClick}
      onConfirmationButtonClick={handleConfirm}
      {...props}
    >
      {children}
    </ConfirmDialog>
  );
};

export const NavigationConfirmModal = memo(NavigationConfirmModalComponent);
