import { useState } from 'react'
import { config } from 'config'
import { encodeQueryParams } from 'services/http'

/* eslint-disable camelcase */
const createSocialLoginData = (type: 'google' | 'microsoft') => ({
  client_id: 'login',
  redirect_uri: `${location.origin}/login/callback`,
  response_type: 'token id_token',
  scope: 'openid profile auth',
  state: config.state,
  nonce: config.nonce,
  acr_values: type === 'google' ? 'idp:Google' : 'idp:Microsoft',
  prompt: 'login',
})

export const useSocialLogin = () => {
  const [isLoading, setIsLoading] = useState(false)

  const signIn = (type: 'google' | 'microsoft') => {
    setIsLoading(true)
    location.href = `${config.apiBaseUrl}/connect/authorize?${encodeQueryParams(
      createSocialLoginData(type)
    )}`
  }

  return { signIn, isLoading }
}
