import React, { FC, memo, useState } from 'react';

import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { BedsDialogButtonsContainer, BedsDialogContentContainer, BedsDialogTitle } from './styled';
import { SubscriptionUpgradeInfo } from '../../models/Subscriptions/SubscriptionUpgradeInfo';
import { AddedBedsDialogContent } from './AddedBedsDialogContent';
import { Site } from '../../models/site/Site';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';
import { StyledButton } from '../StyledButton/styled';
import { SubscriptionService } from '../../api/Subscriptions/SubscriptionService';
import { RemovedBedsDialogContent } from './RemovedBedsDialogContent';
import { ErrorMessageText } from '../FetchErrorMessage/styled';
import { AddCardForm } from 'components/AddCardForm/AddCardForm';
import { Loader } from '../Loader/Loader';
import { LoaderWrapper } from '../Loader/styled';

export const INITIAL_PAYMENT_METHOD_ID: SavedPaymentMethod['id'] = -1;

const DEFAULT_ERROR_MESSAGE = 'Couldn\'t perform payment with this payment method.'

interface Props {

  /** Site. */
  readonly site: Site;

  /** Count of beds */
  readonly bedsCount: number;

  /** Whether the dialog is open. */
  readonly isOpen: boolean;

  /** On close callback. */
  readonly onClose: () => void;

  /** Subscription upgrade info. */
  readonly subscriptionUpgradeInfo: SubscriptionUpgradeInfo;

  /** Subscription upgrade error message. */
  readonly errorMessage?: string;

  /** On payment success callback. */
  readonly onConfirmButtonClick: () => void;
}

const BedsChangeConfirmDialogComponent: FC<Props> = ({ site, isOpen, onClose, bedsCount, subscriptionUpgradeInfo, errorMessage, onConfirmButtonClick }) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [paymentErrorMessage, setPaymentErrorMessage] = useState<string | null>(null);

  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<SavedPaymentMethod['id']>(INITIAL_PAYMENT_METHOD_ID);

  const handlePaymentMethodSelect = (selectedPaymentMethodId: SavedPaymentMethod['id']) => setSelectedPaymentMethodId(selectedPaymentMethodId);

  const isAddedBeds = subscriptionUpgradeInfo.extraPaidBedCountDifference > 0;

  const isConfirmButtonDisabled = selectedPaymentMethodId === INITIAL_PAYMENT_METHOD_ID || isLoading;

  const handleConfirmButtonClick = async() => {

    setPaymentErrorMessage(null);
    setIsLoading(true);

    const paymentInfo = await SubscriptionService.upgradeSubscription({
      newSiteBedCount: bedsCount,
      siteId: site.id,
      savedPaymentMethodId: selectedPaymentMethodId,
    });

    const { isSuccessful, errorMessage } = paymentInfo;

    if (!isSuccessful) {
      setPaymentErrorMessage(errorMessage ?? DEFAULT_ERROR_MESSAGE);
      setIsLoading(false);
      return;
    }

    onConfirmButtonClick();
    setIsLoading(false);
    onClose();
  }

  const [isAddCardFormVisible, setIsAddCardFormVisible] = useState(false);

  const handleAddCardFormOpen = () => setIsAddCardFormVisible(true)
  const handleAddCardFormClose = () => setIsAddCardFormVisible(false)

  const handleAddCardSave = (savedPaymentMethodId: SavedPaymentMethod['id']) => {
    handlePaymentMethodSelect(savedPaymentMethodId);
    handleAddCardFormClose();
  }

  const confirmButtonText = isAddedBeds ? 'Confirm & Pay' : 'Confirm';

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose}>
      {isAddCardFormVisible ? (
        <AddCardForm
          onClose={handleAddCardFormClose}
          onSave={handleAddCardSave}
          organizationId={site.organizationId}
        />
      ) : (
        <>
          {errorMessage != null ? (
            <p>{errorMessage}</p>
          ) : (
            <BedsDialogContentContainer>
              {isLoading && (
                <LoaderWrapper>
                  <Loader
                    size='large'
                    isPrimary
                  />
                </LoaderWrapper>
              )}
              <BedsDialogTitle>Please, confirm changes</BedsDialogTitle>
              {isAddedBeds ? (
                <AddedBedsDialogContent
                  site={site}
                  subscriptionUpgradeInfo={subscriptionUpgradeInfo}
                  bedsCount={bedsCount}
                  selectedPaymentMethodId={selectedPaymentMethodId}
                  onPaymentMethodSelect={handlePaymentMethodSelect}
                  onNewPaymentMethod={handleAddCardFormOpen}
                />
              ) : (
                <RemovedBedsDialogContent
                  site={site}
                  subscriptionUpgradeInfo={subscriptionUpgradeInfo}
                  newCountOfBeds={bedsCount}
                  handlePaymentMethodSelect={handlePaymentMethodSelect}
                />
              )}
              {paymentErrorMessage !== null &&
                <ErrorMessageText>{paymentErrorMessage}</ErrorMessageText>
              }
              <BedsDialogButtonsContainer>
                <StyledButton
                  variant="outlined"
                  onClick={onClose}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  disabled={isConfirmButtonDisabled}
                  onClick={handleConfirmButtonClick}
                >
                  {confirmButtonText}
                </StyledButton>
              </BedsDialogButtonsContainer>
            </BedsDialogContentContainer>
          )}
        </>
      )}
    </ModalWindow>
  );
};

export const BedsChangeConfirmDialog = memo(BedsChangeConfirmDialogComponent);
