import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { StyledButton } from 'components/StyledButton/styled'
import { theme } from 'styles/theme'

export const StyledListTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${theme.colors.grayText};
`

export const StyledUl = styled.ul`
  margin: 5px 0 20px 0;
  padding-left: 16px;
`
export const StyledItem = styled.li`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
`

export const StyledInfoText = styled.p`
  font-size: 14px;
  font-weight: 400;
  padding: 3px 0;
`

export const AddGuestButton = styled(StyledButton)`
  margin-left: auto;
  line-height: 1;
  padding-block: 0.5rem;
`
