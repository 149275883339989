/* eslint-disable no-nested-ternary,react/jsx-handler-names */
import { Field, getIn } from 'formik';
import React, { FC } from 'react';
import Select from 'react-select';
import { StyledLabel } from 'components/InputField/styled';
import {
  customStyles,
  customTheme,
  SelectWrap,
} from 'components/UniversalSelectFields/styled';
import { Option } from 'services/types';
import { FieldLabelRequiredMark } from 'components/FieldLabelRequiredMark/FieldLabelRequiredMark';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { EColors } from 'styles/theme';

interface Props {

  /** Name. */
  readonly name: string;

  /** Label. */
  readonly label?: string;

  /** Placeholder. */
  readonly placeholder?: string;

  /** Whether the field is disabled. */
  readonly disabled?: boolean;

  /** Whether the field can have multiple selected options. */
  readonly multiSelect?: boolean;

  /** POssible options. */
  readonly options: readonly Option<unknown>[];

  /** Whether the field is cuted left. */
  readonly isCutLeft?: boolean;

  /** Default index. */
  readonly defaultIndex?: number;

  /** Whether the field is required. */
  readonly isRequired?: boolean;

  /** Whether the field has errors. */
  readonly hasErrors?: boolean;
}

export const SelectField: FC<any> = ({
  options,
  field,
  form,
  multiSelect,
  disabled,
  isCutLeft,
  defaultIndex,
  hasErrors,
  ...restProps
}) => {
  return (
    <Select
      options={options}
      name={field.name}
      styles={customStyles(isCutLeft, undefined, hasErrors)}
      value={
        options ?
          multiSelect ?
            options.filter((option: Option<string>) =>
              field.value ?
                field.value.includes(option.value) :
                options[defaultIndex] ?
                [options[defaultIndex]?.value] :
                []
            ) :
          field.value ?
            options.find((option: Option<string>) =>
              field.value ?
                option.value === field.value :
                option.value === options[defaultIndex]?.value
            ) :
          '' :
        ''
      }
      onChange={(options: any) => {
        form.setFieldValue(
          field.name,
          multiSelect
            ? options?.map((option: Option<string>) => option.value) || []
            : options.value
        );
      }}
      onBlur={field.onBlur}
      isMulti={multiSelect}
      isSearchable
      isDisabled={disabled}
      {...restProps}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          ...customTheme,
        },
      })}
    />
  );
};

// TODO: refactor
// eslint-disable-next-line react/no-multi-comp
export const UniversalSelectFields: FC<Props> = ({
  label,
  name,
  placeholder,
  disabled,
  multiSelect,
  options,
  isCutLeft,
  defaultIndex,
  isRequired,
  hasErrors,
  ...props
}) => (
  <SelectWrap {...props}>
    <Field
      name={name}
    >
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }: any) => (
        <>
          {label && (
            <StyledLabel
              htmlFor={name}
              hasError={Boolean(form.touched?.[name] && form.errors?.[name])}
            >
              {label}
              {isRequired && <FieldLabelRequiredMark />}
            </StyledLabel>
          )}

          <SelectField
            id={name}
            placeholder={placeholder}
            defaultIndex={defaultIndex}
            disabled={disabled}
            error={Boolean(form.touched?.[name] && form.errors?.[name])}
            options={options}
            multiSelect={multiSelect}
            field={field}
            form={form}
            isCutLeft={isCutLeft}
            hasErrors={hasErrors}
          />

          <ValidationError
            message={getIn(form.touched, name) && getIn(form.errors, name)}
          />
        </>
      )}
    </Field>
  </SelectWrap>
);
