import { MoreVert } from '@mui/icons-material';
import { IconButton, Menu } from '@mui/material';
import React, { FC, memo, useState, MouseEvent } from 'react';
import { useDeleteInsuranceCompany } from '../../../api/insuranceCompany/useDeleteInsuranceCompany';
import { useUpdateInsuranceCompany } from '../../../api/insuranceCompany/useUpdateInsuranceCompany';
import { ConfirmDialog } from '../../../components/Dialogs/ConfirmDialog/ConfirmDialog';
import { ModalWindow } from '../../../components/Dialogs/ModalWindow/ModalWindow';
import { InsuranceCompany } from '../../../models/insuranceCompany/InsuranceCompany';
import { InsuranceCompanyUpdateValues } from '../../../models/insuranceCompany/InsuranceCompanyUpdateValues';
import { FirebaseService } from '../../../services/firebase';
import { InsuranceCompanyForm, InsuranceCompanyFormValues } from './InsuranceCompanyForm';
import { InsuranceCompanyContainer, InsuranceCompanyLogo, InsuranceCompanyMenuButton, InsuranceCompanyMenuItem, InsuranceCompanyName, PrimaryInsuranceMark } from './styled';

interface Props {

  /** Insurance company. */
  readonly company: InsuranceCompany;

  /** Revalidate insurance companies callback. */
  readonly revalidateCompanies: () => Promise<boolean>;
}

const InsuranceCompanyCardComponent: FC<Props> = ({ company, revalidateCompanies }) => {

  const { handleDelete: deleteInsuranceCompany } = useDeleteInsuranceCompany(company.id);
  const { handleUpdate: updateInsuranceCompany } = useUpdateInsuranceCompany(company.id);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const isMenuOpen = Boolean(menuAnchor);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const handleDeleteDialogOpen = () => setIsDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleEditModalOpen = () => setIsEditModalOpen(true);
  const handleEditModalClose = () => setIsEditModalOpen(false);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleDeleteButtonClick = () => {
    handleMenuClose();
    handleDeleteDialogOpen();
  };

  const handleCompanyDelete = async () => {
    await deleteInsuranceCompany()
    await revalidateCompanies();
    handleDeleteDialogClose();
  };

  const handleEditButtonClick = () => {
    handleMenuClose();
    handleEditModalOpen();
  };

  const handleMenuOpenButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    handleMenuOpen(event);
  };

  const handleInsuranceCompanyEditSubmit = async(formValues: InsuranceCompanyFormValues) => {
    const insuranceCompanyUpdateValues: InsuranceCompanyUpdateValues = {
      name: formValues.name,
      logoPath: formValues.logoPath,
      isPrimary: formValues.isPrimary,
      id: company.id,
    };
    await updateInsuranceCompany(insuranceCompanyUpdateValues)
    await revalidateCompanies();
    handleEditModalClose();
  };

  return (
    <>
      <InsuranceCompanyContainer
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {company.isPrimary && <PrimaryInsuranceMark src="/assets/icons/primary-insurance-mark.svg" alt="Primary" />}
        <InsuranceCompanyLogo src={FirebaseService.getImageSrc(company.logoPath ?? undefined)} />
        <InsuranceCompanyName>{company.name}</InsuranceCompanyName>
        <InsuranceCompanyMenuButton>
          {(isHovering || isMenuOpen) && (
            <IconButton
              onClick={handleMenuOpenButtonClick}
              type="button"
            >
              <MoreVert />
            </IconButton>
          )}
        </InsuranceCompanyMenuButton>
      </InsuranceCompanyContainer>
      <Menu open={isMenuOpen} onClose={handleMenuClose} anchorEl={menuAnchor}>
        <InsuranceCompanyMenuItem onClick={handleEditButtonClick} color="blue">
          Edit
        </InsuranceCompanyMenuItem>
        <InsuranceCompanyMenuItem onClick={handleDeleteButtonClick} color="red">
          Delete
        </InsuranceCompanyMenuItem>
      </Menu>
      <ConfirmDialog
        isOpened={isDeleteDialogOpen}
        onConfirmationButtonClick={handleCompanyDelete}
        onClose={handleDeleteDialogClose}
        title={`Are you sure you want to Delete ${company.name}?`}
        confirmButtonText="Delete Insurance"
        confirmButtonColor="error"
        cancelButtonText="Cancel"
      />
      <ModalWindow isOpen={isEditModalOpen} isScrollable onClose={handleEditModalClose}>
        <InsuranceCompanyForm
          company={company}
          onSubmit={handleInsuranceCompanyEditSubmit}
          onCancel={handleEditModalClose}
        />
      </ModalWindow>
    </>
  );
};

export const InsuranceCompanyCard = memo(InsuranceCompanyCardComponent);
