import React, { FC, memo } from 'react';
import { IntakePeriod } from 'services/types';
import { parseTimeString } from 'utils/parseTimeString';
import { IntakePeriodsTableCell, StyledIntakePeriodsTableRow } from './styled';

interface Props {
  /** Intake period. */
  readonly intakePeriod: IntakePeriod;
}

const IntakePeriodsTableRowComponent: FC<Props> = ({ intakePeriod }) => {
  const intakePeriodValue =
    intakePeriod.from === '00:01:00' ? // 00:01:00 is time string for all day case.
      'All Day' :
      `${parseTimeString(intakePeriod.from)} — ${parseTimeString(intakePeriod.to)}`;

  return (
    <StyledIntakePeriodsTableRow>
      <IntakePeriodsTableCell>{intakePeriod.timePeriod}</IntakePeriodsTableCell>
      <IntakePeriodsTableCell>{intakePeriodValue}</IntakePeriodsTableCell>
    </StyledIntakePeriodsTableRow>
  );
};

export const IntakePeriodsTableRow = memo(IntakePeriodsTableRowComponent);
