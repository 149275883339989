import React, { FC, Fragment, memo } from 'react';
import { OrganizationTypeHref } from '../../router/data';
import { ArrowImage, OrganizationNavText, OrganizationsNavLink, OrganizationsNavWrapper } from './styled';

const MAP_ORGANIZATION_TYPE_HREF_TO_LINK_NAME: Readonly<Record<OrganizationTypeHref, string>> = {
  [OrganizationTypeHref.AllOrganizations]: 'Organizations',
  [OrganizationTypeHref.FreeRehabs]: 'Free Rehabs',
  [OrganizationTypeHref.PaidRehabs]: 'Paid Rehabs',
}

/**
 * Maps organization type href to the organization type name.
 * @param organizationTypeHref - Organization type from href.
 */
export function mapOrganizationTypeHrefToName(organizationTypeHref?: OrganizationTypeHref): string {
  return organizationTypeHref != null ? MAP_ORGANIZATION_TYPE_HREF_TO_LINK_NAME[organizationTypeHref] : 'Organizations';
}

/** Organizations page navbar item. */
export interface OrganizationsNavItem {

  /** Name of the item to display. */
  readonly name: string;

  /** Whether the item is available to user. */
  readonly isAvailable?: () => boolean;

  /** If presented, link will be added leading to the provided path. */
  readonly linkPath?: string;
}

interface Props {

  /** Navbar items. */
  readonly items: readonly OrganizationsNavItem[];
}

const OrganizationsNavComponent: FC<Props> = ({ items }) => {

  return (
    <OrganizationsNavWrapper>
      {items.map((item, index) => {
        if (item.isAvailable != null && !item.isAvailable()) {
          return;
        }
        if (index < items.length - 1) {
          if (item.linkPath != null) {
            return (
              <Fragment key={index}>
                <OrganizationsNavLink to={item.linkPath}>{item.name}</OrganizationsNavLink>
                <ArrowImage src="/assets/icons/arrow-right.svg" alt="" />
              </Fragment>
            )
          } else {
            return (
              <Fragment key={index}>
                <OrganizationNavText>{item.name}</OrganizationNavText>
                <ArrowImage src="/assets/icons/arrow-right.svg" alt="" />
              </Fragment>
            )
          }
        } else {
          if (item.linkPath != null) {
            return <OrganizationsNavLink to={item.linkPath} key={index}>{item.name}</OrganizationsNavLink>
          } else {
            return <OrganizationNavText key={index}>{item.name}</OrganizationNavText>
          }
        }
      })}
    </OrganizationsNavWrapper>
  )
};

export const OrganizationsNav = memo(OrganizationsNavComponent);
