import { PasswordChangeLogDto } from '../dtos/logs/PasswordChangeLogDto';
import { PasswordChangeLog } from '../models/logs/PasswordChangeLog';
import { MAP_ROLE_DTO_TO_MODEL } from './UserMapper';

/** Mapper for password change logs. */
export namespace PasswordChangeLogMapper {

  /** Maps dto to model. */
  export function fromDto(dto: PasswordChangeLogDto): PasswordChangeLog {
    return new PasswordChangeLog({
      id: dto.id,
      email: dto.email,
      role: MAP_ROLE_DTO_TO_MODEL[dto.role],
      passwordChangedAt: new Date(dto.passwordChangedAt),
    })
  }
}
