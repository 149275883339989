/**
 * Inverts a dictionary from key-to-value to value-to-key. Works only for the dictionaries which values may be keys.
 * @param dictionary Dictionary to revert.
 * @param mapFn Function to map inverted value to one that needed.
 */
export function invertDictionary<
  K extends keyof Record<string, unknown>,
  T extends keyof Record<string, unknown>,
  R = void
>(dictionary: Readonly<Record<K, T>>, mapFn?: (key: K) => R): Record<T, K> {
  return (Object.entries(dictionary) as [K, T][]).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [value]: mapFn ? mapFn(key) : key,
    }),
    {} as Record<T, K>
  );
}
