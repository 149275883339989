import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { nanoid } from 'nanoid';
import { config } from '../../config';

const firebaseApp = initializeApp(config.firebase);
const storage = getStorage(firebaseApp);

export namespace FirebaseService {
  const FOLDER_BASE_PATH = 'uploads';

  /**
   * Uploads one image to firebase.
   * @param image Image to upload.
   */
  export async function uploadImage(
    image: File,
  ): Promise<string> {

    const fileRef = ref(
      storage,
      `${FOLDER_BASE_PATH}/${nanoid()}/${image.name}`,
    );

    const snapshot = await uploadBytes(fileRef, image);

    return snapshot.ref.fullPath;
  }

  /**
   * Uploads several images to firebase.
   * @param images Image files.
   */
  export async function uploadImages(
    images: readonly File[],
  ): Promise<string[]> {
    const paths = await Promise.all(
      images.map(image => uploadImage(image)),
    );

    return paths;
  }

  /**
   * Gets path to the image uploaded on firebase.
   * @param fileName File name.
   */
  export function getImageSrc(fileName?: string): string {
    return fileName != null ?
      `https://storage.googleapis.com/${config.firebase.storageBucket}/${fileName}` :
      '/assets/placeholder-150.jpg';
  }
}
