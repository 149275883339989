import React, { FC } from 'react'
import { TooltipWrap } from 'components/ErrorTooltip/styled'

export interface Props {
  isOpen: boolean
  text: string
  isRight?: boolean
  isTop?: boolean
  centered?: boolean
}

export const ErrorTooltip: FC<Props> = ({ text, ...props }) => (
  <TooltipWrap {...props}>{text}</TooltipWrap>
)
