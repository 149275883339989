import TableRow from '@mui/material/TableRow';
import { GuestMethods, IGuestType } from 'api/guests/types';
import { useOrganization } from 'api/organizations/useOrganization';
import { useUpdateOrganization } from 'api/organizations/useUpdateOrganization';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';
import { StyledButton } from 'components/StyledButton/styled';
import { InsuranceCompany } from 'models/insuranceCompany/InsuranceCompany';
import { Organization } from 'models/organization/Organization';
import { OrganizationUpdateValues } from 'models/organization/OrganizationUpdateValues';
import React, { FC, memo, useState } from 'react';
import { InsurancesTableCell, InsuranceTableRow } from './styled';

interface Props {

  /** Insurance company on the row. */
  readonly company: InsuranceCompany;

  /** Organization. */
  readonly organization: Organization;
}

const InsuranceCompanyTableRowComponent: FC<Props> = ({ company, organization }) => {

  const { revalidate } = useOrganization(organization.id);

  const { handleUpdate } = useUpdateOrganization(organization.id);

  const [isLoading, setIsLoading] = useState(false);

  const [isDeleteDealogOpen, setIsDeleteDialogOpen] = useState(false);

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => setIsHovering(true);

  const handleMouseLeave = () => setIsHovering(false);

  const handleDeleteDialogOpen = () => {
    setIsHovering(false);
    setIsDeleteDialogOpen(true)
  };

  const handleDeleteDialogClose = () => setIsDeleteDialogOpen(false);

  const handleInsuranceCompanyDelete = async() => {

    const updatedInsuranceCompanyIds = organization.insuranceCompanies
      .map(company => company.id)
      .filter(id => id !== company.id);

    const updateValues: OrganizationUpdateValues = {
      id: organization.id,
      name: organization.name,
      logoPath: organization.logoPath,
      insuranceCompanyIds: updatedInsuranceCompanyIds
    }

    setIsLoading(true);
    await handleUpdate(updateValues);
    await revalidate();
    setIsLoading(false);
    handleDeleteDialogClose();
  };

  return (
    <InsuranceTableRow
      isHovering={isHovering}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <InsurancesTableCell>
        {company.name}
      </InsurancesTableCell>
      <InsurancesTableCell>
        {isHovering && (
          <StyledButton
            color="error"
            onClick={handleDeleteDialogOpen}
            type="button"
            disabled={isLoading}
          >
            Delete
          </StyledButton>
        )}
      </InsurancesTableCell>
      <ConfirmDialog
        isOpened={isDeleteDealogOpen}
        onClose={handleDeleteDialogClose}
        onConfirmationButtonClick={handleInsuranceCompanyDelete}
      >
        {`Are you sure you want to delete ${company.name} from your organization?`}
      </ConfirmDialog>
    </InsuranceTableRow>
  );
};

export const InsuranceCompanyTableRow = memo(InsuranceCompanyTableRowComponent);
