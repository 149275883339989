type PaymentMethodCreationData = PaymentMethod;

/** Payment method name. */
export enum PaymentMethodCategory {
  PrivatePay = 'PrivatePay',
  Insurance = 'Insurance',
  MediCal = 'MediCal',
}

/** Payment method model. */
export class PaymentMethod {

  /** ID of the payment method. */
  public readonly id: number;

  /** Name of the payment method. */
  public readonly name: string;

  /** Category */
  public readonly category: PaymentMethodCategory;

  public constructor(data: PaymentMethodCreationData) {
    this.id = data.id;
    this.name = data.name;
    this.category = data.category;
  }
}
