import { Delete } from '@mui/icons-material';
import { Box, MenuItem, Tabs, Typography } from '@mui/material';
import { IGuestType } from 'api/guests/types';
import { BaseMenu } from 'components/BaseMenu/BaseMenu';
import { EditableTextFiled } from 'components/EditableTextField/EditableTextField';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { RowView } from 'components/Layout/Grid';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { SecondaryHeading } from 'components/Typography';
import { SinglePhotoUpload } from 'components/UploadPhoto/Single';
import { FieldArray, FieldArrayRenderProps, useFormikContext } from 'formik';
import React, { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { Room } from '../../domain/Room';
import { PlaceholderText } from '../../styled';
import { BedCard } from '../Bed/BedCard';
import { Bed } from '../Bed/domain/Bed';
import { BedCreationForm, BedCreationValues } from '../BedCreationForm/BedCreationForm';
import { ImpossibleRoomDeletionDialog } from '../ImpossibleRoomDeletionDialog/ImpossibleRoomDeletionDialog';
import {
  BedsPlaceholder,
  EditPencelIcon,
  RoomBeds,
  RoomBedsContainer,
  RoomBedsTabsContainer,
  RoomContent,
  RoomCreationFormContainer,
  RoomHeader,
  RoomHeaderMenu,
  RoomPhotoContainer,
  RoomPhotoUploader,
  RoomWrap,
} from './styled';
import { CounterTab } from 'components/Tabs/Tabs';
import { SiteEditingValues } from 'models/site/SiteEditingValues';
import { ImagePrefix } from '../../../../../../components/UploadPhoto/ImagePrefix';
import { ImagePreviewDialog } from '../../../../../../components/Dialogs/ImagePreviewDialog/ImagePreviewDialog';
import { ConfirmDialog } from '../../../../../../components/Dialogs/ConfirmDialog/ConfirmDialog';
import { sortEntities } from 'utils/sortEntities';

const ALL_BEDS_TAB_INDEX = 0;
const VACANT_BEDS_TAB_INDEX = 1;
const OCCUPIED_BEDS_TAB_INDEX = 2;

interface Props {
  /** Room. */
  readonly room: Room;

  /** Whether edit mode available or not. */
  readonly isEditModeAvailable: boolean;

  /** Handle room deletion. */
  readonly onRoomDeletion?: () => void;

  /** Guest. */
  readonly guest?: IGuestType;

  /** Error message */
  readonly errorMessage?: string;

  /** Whether the assign buttons are hidden */
  readonly areAssignButtonsHidden?: boolean;

  /** Callback to determine whether editing mode is enabled.  */
  readonly onEditingModeChange?: (isEditing: boolean) => void;
}

export const RoomCard: FC<Props> = ({
  room,
  onRoomDeletion,
  isEditModeAvailable,
  guest,
  errorMessage,
  areAssignButtonsHidden = false,
  onEditingModeChange
}) => {
  /** Form values. */
  const { values, setFieldValue, setFieldTouched } = useFormikContext<SiteEditingValues>();

  /** Whether edit mode is on or not. */
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  /** Current tab number. */
  const [currentTabNumber, setCurrentTabNumber] = useState<number>(0);

  /** Whether room deletion modal opened or not. */
  const [isRoomDeletionModalOpened, setIsRoomDeletionModalOpened] = useState<boolean>(false);

  /** Number of the room on form array. */
  const roomNumber = values.rooms.indexOf(room);

  /** Reference to the room in form. */
  const roomReference = `rooms.${roomNumber}`;

  /** Handle editing finish event. */
  const onEditFinish = useCallback(() => {
    setIsEditMode(false);
    onEditingModeChange && onEditingModeChange(false);
    setFieldTouched('rooms', true);
  }, []);

  /** Handle editing start event. */
  const onEditStart = useCallback(() => {
    setIsEditMode(true);
    onEditingModeChange && onEditingModeChange(true);
  }, []);

  /**
   * Handle bed photo change event.
   * @param bedNumber Bed number where photo was changed.
   * @param newBedPhoto New photo of bed that was uploaded.
   */
  const onBedPhotoChanged = useCallback((bedNumber: number, newBedPhoto?: string | null) => {
    setFieldValue(`${roomReference}.beds.${bedNumber}.imagePath`, newBedPhoto);
  }, []);

  /**
   * Handle tab change event.
   * @param _ Synthetic event.
   * @param newTabNumber Number of active tab.
   */
  const onTabChange = useCallback((_: SyntheticEvent, newTabNumber: number) => {
    setCurrentTabNumber(newTabNumber);
  }, []);

  /**
   * Handle room deletion.
   * @param onMenuClose Menu close event.
   */
  const onRoomDeleteClick = useCallback((onMenuClose: () => void) => {
    onMenuClose();
    setIsRoomDeletionModalOpened(true);
  }, []);

  /** Handle close event of room deletion dialog. */
  const onRoomDeletionDialogClose = useCallback(() => {
    setIsRoomDeletionModalOpened(false);
  }, []);

  /** Handle room deletion event. */
  const onRoomDeletionDialogConfirmation = useCallback(() => {
    setIsRoomDeletionModalOpened(false);
    if (onRoomDeletion != null) {
      onRoomDeletion();
      setFieldTouched('rooms', true);
    }
  }, []);

  /** Handle bad adding event. */
  const onBedAdd = useCallback(
    ({ name, type, source, count }: BedCreationValues, helpers: FieldArrayRenderProps) => {
      const lastBedIndex = room.beds[room.beds.length - 1]?.index ?? 0;
      const beds = Bed.generateFromType(name, type, count, lastBedIndex, source ?? void 0);
      beds.forEach((bed) => helpers.push(bed));
    },
    [room]
  );

  /** Get bed card component. */
  const getBedCard = useCallback(
    (bed: Bed, helpers: FieldArrayRenderProps) => {

      const bedNumber = room.beds.indexOf(bed);

      return (
        <BedCard
          key={`${bed.id}-${bedNumber}`}
          onBedPhotoChange={(bedPhoto) => onBedPhotoChanged(bedNumber, bedPhoto)}
          bed={bed}
          isEditModeAvailable={isEditModeAvailable}
          onBedDeletion={() => helpers.remove(bedNumber)}
          bedControlName={`${roomReference}.beds.${bedNumber}`}
          guest={guest}
          isAssignButtonHidden={areAssignButtonsHidden}
        />
      )
    },
    [room, isEditModeAvailable]
  );

  const sortedBeds = useMemo(() => sortEntities(room.beds, 'name'), [room.beds]);

  /** Beds that will be displayed. */
  const getBeds = (arrayHelpers: FieldArrayRenderProps) =>
    room.hasBeds ? (
      <RoomBeds>
        <RoomBedsTabsContainer>
          <Tabs value={currentTabNumber} onChange={onTabChange}>
            <CounterTab label={`All Beds`} count={room.bedsCount} />
            <CounterTab label={`Vacant`} count={room.vacantBedsCount} />
            <CounterTab label={`Occupied`} count={room.occupiedBedsCount} />
          </Tabs>
          {isEditModeAvailable && (
          <RoomHeaderMenu>
            <BaseMenu>
              {({ onMenuClose }) => (
                <MenuItem onClick={() => onRoomDeleteClick(onMenuClose)}>
                  <Delete sx={{ fill: theme.colors.red }} />
                  <Typography sx={{ color: theme.colors.red, marginLeft: '5px' }}>Delete Room</Typography>
                </MenuItem>
              )}
            </BaseMenu>
          </RoomHeaderMenu>
        )}
        </RoomBedsTabsContainer>
        <TabPanel value={currentTabNumber} index={ALL_BEDS_TAB_INDEX}>
          {sortedBeds.map(bed => getBedCard(bed, arrayHelpers))}
        </TabPanel>
        <TabPanel value={currentTabNumber} index={VACANT_BEDS_TAB_INDEX}>
          {sortedBeds.filter((bed) => !bed.isOccupied).map(bed => getBedCard(bed, arrayHelpers))}
        </TabPanel>
        <TabPanel value={currentTabNumber} index={OCCUPIED_BEDS_TAB_INDEX}>
          {sortedBeds.filter((bed) => bed.isOccupied).map(bed => getBedCard(bed, arrayHelpers))}
        </TabPanel>
      </RoomBeds>
    ) : (
      <BedsPlaceholder>
        <PlaceholderText>No Beds in the room {room.name}</PlaceholderText>
      </BedsPlaceholder>
    );

  /**
   * Gets count of beds on currently active tab.
   * @param room Room.
   */
  const getBedsCountOfCurrentTab = (room: Room): number => {
    if (currentTabNumber === VACANT_BEDS_TAB_INDEX) {
      return room.vacantBedsCount;
    } else if (currentTabNumber === OCCUPIED_BEDS_TAB_INDEX) {
      return room.occupiedBedsCount;
    } else {
      return room.bedsCount;
    }
  }

  const editableRoomName = (
    <EditableTextFiled controlName={`${roomReference}.name`} isEditMode={isEditMode} onEditFinish={onEditFinish}>
      <SecondaryHeading withoutMargin onClick={onEditStart} display="flex" cursor="pointer">
        {room.name}
        <EditPencelIcon src="/assets/icons/edit-pencil.svg" alt="" />
      </SecondaryHeading>
    </EditableTextFiled>
  );

    /** Whether dialog with image preview is opened or not. */
  const [isImagePreviewDialogOpened, setIsImageDialogOpened] = useState<boolean>(false);

  const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);

  /** Handle close event of image preview dialog. */
  const handleImagePreviewClose = useCallback(
    () => setIsImageDialogOpened(false),
    [setIsImageDialogOpened],
  );

  const handleImageChange = useCallback((value: string | null | undefined) => {
    setFieldValue(`${roomReference}.photo`, value);
    setFieldTouched('rooms', true);
  }, [setFieldValue, setFieldTouched, roomReference])

  const handleImageLoading = useCallback((isLoading: boolean) => {
    setIsUploadingImage(isLoading);
  }, [setIsUploadingImage]);


  const nonEditableRoomName = <SecondaryHeading withoutMargin>{room.name}</SecondaryHeading>;

  return (
    <RoomWrap errorMessage={errorMessage}>
      <RoomHeader>
        {isEditModeAvailable ? editableRoomName : nonEditableRoomName}
      </RoomHeader>

      <FieldArray
        name={`rooms.${roomNumber}.beds`}
        render={(arrayHelpers) => (
          <RoomContent>
            <RoomPhotoContainer>
              <RoomPhotoUploader>
                <SinglePhotoUpload
                  initImageUrl={isUploadingImage ? '' : room.photo ?? ''}
                  prefix={ImagePrefix.Room}
                  onChange={handleImageChange}
                  size={'large'}
                  isDeletionAvailable={false}
                  isDisabled={!isEditModeAvailable || isUploadingImage}
                  label="Add Photo"
                  onClick={() => room.hasPhoto && !isUploadingImage ? setIsImageDialogOpened(true) : undefined}
                  isLoading={isUploadingImage}
                />
              </RoomPhotoUploader>
              {
                room.hasPhoto && isEditModeAvailable &&
                <SinglePhotoUpload
                  prefix={ImagePrefix.Room}
                  onChange={handleImageChange}
                  label="Edit Photo"
                  onLoading={handleImageLoading}
                  isDisabled={!isEditModeAvailable || isUploadingImage}
                  size="medium"
                  isDeletionAvailable={false}
                  isButton
                />
              }
            </RoomPhotoContainer>

            <RoomBedsContainer>
              {getBeds(arrayHelpers)}
              <RoomCreationFormContainer style={{ height: 'auto' }}>
                {isEditModeAvailable && (
                  <BedCreationForm
                    onSubmit={(values) => {
                      onBedAdd(values, arrayHelpers);
                      setFieldTouched('rooms', true);
                    }}
                  />
                )}
              </RoomCreationFormContainer>
            </RoomBedsContainer>
            <ValidationError message={errorMessage} isCentered />
          </RoomContent>
        )}
      />

      {
        room.hasAnyGuests ?
        (
          <ImpossibleRoomDeletionDialog
          isOpened={isRoomDeletionModalOpened}
          onClose={onRoomDeletionDialogClose}
          room={room}
        />
        ) :
        (
          <ConfirmDialog
            isOpened={isRoomDeletionModalOpened}
            onClose={onRoomDeletionDialogClose}
            onConfirmationButtonClick={onRoomDeletionDialogConfirmation}
            title={`Are you sure to Delete the ${room.name}?`}
            confirmButtonColor='error'
            confirmButtonText='Delete the House and Beds'
          >
            <p>{`All Beds in ${room.name} will be deleted too. You will not be able to undo this action.`}</p>
          </ConfirmDialog>
        )
      }


      <ImagePreviewDialog
        isOpened={isImagePreviewDialogOpened}
        onClose={handleImagePreviewClose}
        source={room.photo ?? ''}
      />
    </RoomWrap>
  );
};
