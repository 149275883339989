import { ScreeningPeriodDto } from 'dtos/screeningPeriod/ScreeningPeriodDto';
import { ScreeningPeriod } from 'models/screeningPeriod/ScreeningPeriod';

/** Screening period mapper. */
class ScreeningPeriodMapper {

  /** Maps dto to model. */
  public fromDto(dto: ScreeningPeriodDto): ScreeningPeriod {
    return new ScreeningPeriod({
      id: dto.id,
      value: dto.value,
    });
  };

  /**
   * Maps time string to screening period model.
   * @param time Time string in "dd:hh:mm:ss" format.
   */
  public fromTimeString(time: string): ScreeningPeriod {
    return new ScreeningPeriod({
      id: null,
      value: time,
    });
  };
}

export const screeningPeriodMapper = new ScreeningPeriodMapper();
