import { Field } from 'formik';
import React, { FC } from 'react';
import { StyledCheckmark, StyledLabel } from 'components/Checkbox/styled';

export interface Props {
  name: string;
  label: string;
  checked?: boolean;
}

const CustomCheckbox: FC<any> = ({ label, field, type }) => {
  const checked = field.value;

  return (
    <StyledLabel>
      {label}
      <input {...field} type={type} checked={checked} />
      <StyledCheckmark checked={checked} />
    </StyledLabel>
  );
};

// TODO: extract to separate file
// eslint-disable-next-line react/no-multi-comp
export const CheckboxField: FC<Props> = ({ name, label }) => (
  <Field name={name} type="checkbox" label={label} component={CustomCheckbox} />
);
