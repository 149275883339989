import faker from 'faker'
import * as R from 'ramda'

export const enumFactory = <TEnum>(values: any) => (type?: TEnum) =>
  type ?? (faker.random.arrayElement(Object.values(values)) as TEnum)

export const entityFactory = <TData extends object>(
  defaultData: () => TData
) => (data?: Optional<TData>): TData =>
  R.mergeRight(defaultData(), data ?? {}) as TData

export const entityArrayFactory = <TData>(
  factory: (data?: Optional<TData>) => TData
) => (count?: number) =>
  new Array(count ?? faker.random.number(5)).fill('').map(() => factory())
