import { SiteDto } from 'dtos/site/SiteDto';
import { useFetch } from 'hooks/api';
import { PaginationMapper } from 'mappers/PaginationMapper';
import { SiteMapper } from 'mappers/SiteMapper';
import { Organization } from 'models/organization/Organization';
import { Site } from 'models/site/Site';
import { Pagination } from 'services/types';

export const useSites = (organizationId?: Organization['id']) =>
  useFetch<Pagination<Site>, Pagination<SiteDto>>({
    url: `/api/Sites${organizationId != null ?  `?organizationId=${organizationId}` : ''}`,
    mapper: (data) => PaginationMapper.fromDto(data, SiteMapper.fromDto),
  });
