import React from 'react'
import { useCreatePassword } from 'api/auth/useCreatePassword/useCreatePassword'
import {ErrorTooltip} from "components/ErrorTooltip";
import { Layout } from 'components/Layout'
import { CreatePasswordForm } from 'forms/CreatePassword'
import { IFormData } from 'forms/CreatePassword/data'

export const CreatePassword = () => {
  const { createPassword, isLoading, error } = useCreatePassword()

  const handleSubmit = (values: IFormData) =>
    createPassword({ password: values.password })

  return (
    <Layout grayBg>
      <CreatePasswordForm isLoading={isLoading} handleSubmit={handleSubmit} error={error} />
    </Layout>
  )
}
