/**
 * Gets time in `HH:MM` format from date.
 * @param date Date
 */
export function getTimeFromDate(date: Date): string {

  const hoursValue = date.getHours();
  const hours = hoursValue < 10 ? `0${hoursValue}` : hoursValue

  const minutesValue = date.getMinutes();
  const minutes = minutesValue < 10 ? `0${minutesValue}` : minutesValue

  return `${hours}:${minutes}`
}
