import styled from '@emotion/styled';
import { RowView } from 'components/Layout/Grid';
import { theme } from 'styles/theme';

interface Props {
  readonly noMarginTop?: boolean;
}

export const HorizontalLine = styled.hr<Props>`
  border: 1px solid ${theme.colors.gray};
  border-bottom: none;
  margin-block: 20px;
  margin-top: ${(props) => props.noMarginTop && 0};
`;

export const ButtonsRow = styled(RowView)`
  margin-top: 20px;
`

export const AdditionalInfoContainer = styled.div`
  margin-bottom: 30px;
`
