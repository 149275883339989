import styled from '@emotion/styled';
import { Tab } from '@mui/material';
import { theme } from 'styles/theme';

interface Props {
  /** Counter value which display on the tab. */
  readonly count: number;
}

export const StyledTab = styled(Tab)`
  color: ${theme.colors.black};
  font-size: var(--font-size-xs);
  font-weight: 600;
  text-transform: none;
  display: flex;
  flex-direction: row;
`;


export const CounterTab = styled(StyledTab)<Props>`
  &::after {
    content: '${(props) => props.count}';
    color: #0000004d;
    margin-left: 0.5rem;
  }

  &.Mui-selected::after {
    color: inherit;
  }
`;
