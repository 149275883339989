import { useMutate } from 'hooks/api';
import { OrganizationUserMapper } from 'mappers/UserMapper';
import { SiteUserInvitationValues } from 'models/user/UserInvitationValues';

export const useInviteSiteUser = (locationOrigin: string) => {
  const { mutate } = useMutate<undefined, undefined, SiteUserInvitationValues>('post', {
    url: '/api/AccessUsers/siteScopeUsers',
    requestTransformer: (data) => JSON.stringify(OrganizationUserMapper.mapSiteUserInvitationValues(data, locationOrigin)),
  });

  return { handleCreate: mutate };
};
