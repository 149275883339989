import { OrganizationUserInvitationDto, SiteUserInvitationDto } from 'dtos/userDto/UserInvitationDto';
import { UserDto, UserMembershipStatusDto, UserRoleDto } from 'dtos/userDto/UserDto';
import { OrganizationUserInvitationValues, SiteUserInvitationValues } from 'models/user/UserInvitationValues';
import { User, UserMembershipStatus, UserRole } from 'models/user/User';
import { ERoute } from 'router/data';
import { invertDictionary } from 'utils/invertDictionary';
import { OrganizationMapper } from './OrganizationMapper';

const MAP_STATUS_DTO_TO_MODEL: Readonly<Record<UserMembershipStatusDto, UserMembershipStatus>> = {
  [UserMembershipStatusDto.Active]: UserMembershipStatus.Active,
  [UserMembershipStatusDto.Disabled]: UserMembershipStatus.Disabled,
  [UserMembershipStatusDto.PendingInvitation]: UserMembershipStatus.PendingInvitation,
};

/** Maps user role from dto. */
export const MAP_ROLE_DTO_TO_MODEL: Readonly<Record<UserRoleDto, UserRole>> = {
  [UserRoleDto.GlobalAdmin]: UserRole.GlobalAdmin,
  [UserRoleDto.Admin]: UserRole.Admin,
  [UserRoleDto.Staff]: UserRole.Staff,
  [UserRoleDto.Intake]: UserRole.Intake,
  [UserRoleDto.Owner]: UserRole.Owner,
};

/** Maps user role to dto. */
export const MAP_ROLE_TO_DTO = invertDictionary(MAP_ROLE_DTO_TO_MODEL);

export namespace OrganizationUserMapper {
  /** @inheritdoc */
  export function fromDto(dto: UserDto): User {
    return new User({
      id: dto.id,
      // TODO (Maxim K.): Remove null when backend fix the issue with missing organizations.
      organization: dto.organization && OrganizationMapper.fromDto(dto.organization),
      membershipStatus: MAP_STATUS_DTO_TO_MODEL[dto.membershipStatus],
      role: MAP_ROLE_DTO_TO_MODEL[dto.role],
      siteId: dto.siteId,
      siteName: dto.siteName,
      userInfo: {
        id: dto.user.id,
        email: dto.user.email,
      },
      createdAt: new Date(dto.createdAt),
    });
  }

  /**
   * Maps organization user invitation values to dto.
   * @param values Invitation values to map.
   * @param locationOrigin Current location origin.
   */
  export function mapOrganizationUserInvitationValues(
    values: OrganizationUserInvitationValues,
    locationOrigin: string
  ): OrganizationUserInvitationDto {
    return {
      redirectUri: `${locationOrigin}${ERoute.LOGIN}/callback`,
      email: values.email,
      organizationId: parseInt(values.organizationId),
      role: MAP_ROLE_TO_DTO[values.role],
    };
  }

  /**
   * Maps site user invitation values to dto.
   * @param values Invitation values to map.
   * @param locationOrigin Current location origin.
   */
  export function mapSiteUserInvitationValues(
    values: SiteUserInvitationValues,
    locationOrigin: string
  ): SiteUserInvitationDto {
    return {
      redirectUri: `${locationOrigin}${ERoute.LOGIN}/callback`,
      email: values.email,
      siteId: parseInt(values.siteId),
      role: MAP_ROLE_TO_DTO[values.role],
    };
  }
}
