import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Layout } from 'components/Layout'
import { useAuth } from 'hooks/auth/useAuth'
import { searchParams } from 'services/http'

export const SingInCallback = () => {
  const location = useLocation()
  const {
    actions: { signIn },
  } = useAuth()

  const accessToken = searchParams('access_token', location.hash)

  useEffect(() => {
    if (accessToken) {
      signIn(accessToken)
    }
  }, [accessToken])

  return <Layout grayBg />
}
