/** @jsx jsx */
import { FC } from 'react';
import { css, keyframes, jsx } from '@emotion/react';
import { theme } from 'styles/theme';
import { Size as LoaderSize } from 'utils/types/size';

type PixeledDimensionSize = `${number}px`;

const DimensionSizeMap: Readonly<Record<LoaderSize, PixeledDimensionSize>> = {
  large: '80px',
  medium: '60px',
  small: '20px',
}

const LoaderAnimationSizeMap: Readonly<Record<LoaderSize, PixeledDimensionSize>> = {
  large: '64px',
  medium: '46px',
  small: '22px',
}

interface LoaderProps {

  readonly size: LoaderSize;

  readonly isPrimary?: boolean;
}

const dualRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

/** Loading component. */
export const Loader: FC<LoaderProps> = ({ size, isPrimary }) => {
  const color = isPrimary
    ? theme.colors.blue
    : theme.colors.white

  return (
    <div css={css`
      display: inline-block;
      width: ${DimensionSizeMap[size]};
      height: ${DimensionSizeMap[size]};

      &:after {
        content: " ";
        display: block;
        width: ${LoaderAnimationSizeMap[size]};
        height: ${LoaderAnimationSizeMap[size]};
        margin: ${size === 'small' ? '0' : '8px'};
        border-radius: 50%;
        border: 6px solid ${color};
        border-color: ${color} transparent ${color} transparent;
        animation: ${dualRing} 1.2s linear infinite;
      }
  `}></div>
  )
}
