import styled from '@emotion/styled';
import { Button, TextField, Autocomplete } from '@mui/material';
import { SinglePhotoUpload } from 'components/UploadPhoto/Single';
import { theme } from '../../../../../../styles/theme';

const FORM_HEIGHT = '35px';

export const BedCreationFormContent = styled.fieldset`
  padding-top: 20px;
  display: grid;
  gap: 15px;
  grid-template-columns: 35px 3fr 3fr minmax(125px, 1.5fr) 2fr;
  grid-template-rows: ${FORM_HEIGHT};
  align-items: center;
  width: 100%;
  border: none;
`;

export const BedCreationImageUploader = styled(SinglePhotoUpload)`
  margin: 0;
`;

export const BedCreationConfirmButton = styled(Button)`
  height: ${FORM_HEIGHT};
  min-width: 100px;
  white-space: nowrap;
  text-transform: none;
`;

export const BedCreationCounterContainer = styled.div`
  width: fit-content;
`;

export const BedCreationCounterField = styled(TextField)`
  & .MuiInputBase-root {
    border-radius: 0;
  }

  input {
    padding: 10px;
    max-width: 30px;
    height: 15px;
    text-align: center;
  }

  fieldset {
    border-color: ${theme.colors.grayBorderColor};
    border-inline: none;
  }
`;

interface CounterButtonProps {

  /** Orientation of the button. */
  readonly orientation: 'right' | 'left';
}

export const BedCreationCounterButton = styled(Button)<CounterButtonProps>`
  height: ${FORM_HEIGHT};
  padding-inline: 0;
  min-width: 35px;
  border-radius: ${props => props.orientation === 'left' ? '7px 0px 0px 7px' : '0px 7px 7px 0px'};
  border-color: ${theme.colors.grayBorderColor};
`;

export const BedCreationNameInput = styled(TextField)`
  height: ${FORM_HEIGHT};

  & .MuiTextField-root {
    height: ${FORM_HEIGHT};
  }

  & .MuiInputBase-root {
    height: ${FORM_HEIGHT};
    padding: 0;
  }

  & .MuiInputBase-input {
    height: 12px;
  }
`

export const BedCreationTypeAutocomplete = styled(Autocomplete)`
  height: ${FORM_HEIGHT};

  & .MuiTextField-root {
    height: ${FORM_HEIGHT};
  }

  & .MuiInputBase-root {
    height: ${FORM_HEIGHT};
    padding: 0;
  }

  & .MuiInputBase-input {
    height: 12px;
  }
`;
