/**
 * Parses date time to a 12 hours format.
 * @param date Date to parse time from.
 */
export function parseDateClockTime(date: Date): string {
  let prefix = 'AM';
  let hours = date.getHours();
  const minutes = date.getMinutes();
  if (hours > 12) {
    prefix = 'PM';
    hours -= 12;
  }
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutesString} ${prefix}`
}
