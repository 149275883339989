import styled from '@emotion/styled'
import React, { FC, SyntheticEvent, useState } from 'react'
import { EColors, theme } from 'styles/theme'

interface Props {
  text: string
  color?: EColors
}

const CHARS_LIMIT = 135
const SHORT_TEXT_CHARS = 49

const StyledParagraph = styled.p<{ color?: EColors }>`
  color: ${(props) => theme.colors[props.color ?? 'grayText']};
  font-size: 12px;
  margin: 5px 0;
  line-height: 16px;
  word-break: break-word;

  /* height: 40px; */
  /* overflow: hidden; */
  /* display: -webkit-box; */
  /* -webkit-box-orient: vertical; */
  /* -webkit-line-clamp: 2; */
`

const MoreLink = styled.span`
  color: ${theme.colors.blue};
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const truncateText = (text: string) =>
  text.length >= CHARS_LIMIT ? `${text.slice(0, SHORT_TEXT_CHARS)}... ` : text

export const LongText: FC<Props> = ({ text, color }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [textToShow, setTextToShow] = useState(truncateText(text))

  const handleShowMore = (event: SyntheticEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(!isOpen)
    isOpen ? setTextToShow(truncateText(text)) : setTextToShow(text)
  }

  return (
    <StyledParagraph color={color}>
      {textToShow}
      {text.length > CHARS_LIMIT && (
        <MoreLink onClick={handleShowMore}>{isOpen ? 'less' : 'more'}</MoreLink>
      )}
    </StyledParagraph>
  )
}
