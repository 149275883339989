import { MoreVert } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { LongText } from 'components/LongText';
import { Organization } from 'models/organization/Organization';
import { Site } from 'models/site/Site';
import { MenuLink } from 'pages/Reservation/Components/GuestsTable/styled';
import React, { FC, memo, useState } from 'react';
import { routes } from 'router/data';
import { FirebaseService } from 'services/firebase';
import { theme } from 'styles/theme';
import { useOrganizationTypeHref } from 'hooks/routing/useOrganizationTypeFromHref';
import {
  AlternativeSiteName,
  MenuOpenButton,
  SiteCardContainer,
  SiteCardContent,
  SiteCardImage,
  SiteCardName,
  SiteCardNameContainer,
} from './styled';
import { useAuth } from 'hooks/auth/useAuth';
import { useIsUserPrivileged } from 'hooks/auth/useIsUserPrivileged';

interface Props {
  /** Site. */
  readonly site: Site;

  /** Organization id. */
  readonly organizationId: Organization['id'];

  /** Handles delete button click. */
  readonly onDeleteButtonClick: (site: Site) => void;
}

const SiteCardComponent: FC<Props> = ({ site, onDeleteButtonClick, organizationId }) => {

  const organizationsTypeHref = useOrganizationTypeHref();

  const isUserAllowedToEditOrDelete = useIsUserPrivileged(organizationId);

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchor(event.currentTarget);
  const handleMenuClose = () => setMenuAnchor(null);
  const isMenuOpen = menuAnchor != null;

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const handleMenuOpenButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    handleMenuOpen(event);
  };

  const handleDeleteButtonClick = () => {
    handleMenuClose();
    onDeleteButtonClick(site);
  };

  return (
    <>
      <SiteCardContainer
        to={routes.OrganizationSiteDetails(organizationId, site.id, organizationsTypeHref)}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        <SiteCardImage src={FirebaseService.getImageSrc(site.coverImage?.imagePath)} alt="" />
        <SiteCardContent>
          <AlternativeSiteName>{site.alias}</AlternativeSiteName>
          <SiteCardNameContainer>
            <SiteCardName>{site.name}</SiteCardName>
            {((isHovering || isMenuOpen) && isUserAllowedToEditOrDelete) && (
              <MenuOpenButton onClick={handleMenuOpenButtonClick} type="button">
                <MoreVert />
              </MenuOpenButton>
            )}
          </SiteCardNameContainer>
          <LongText text={site.description} />
        </SiteCardContent>
      </SiteCardContainer>
      <Menu open={isMenuOpen} onClose={handleMenuClose} anchorEl={menuAnchor}>
        <MenuLink
          to={{
            pathname: `/sites/${site.id}/edit`,
          }}
        >
          <MenuItem onClick={handleMenuClose}>Edit</MenuItem>
        </MenuLink>
        <MenuItem onClick={handleDeleteButtonClick} sx={{ color: theme.colors.red }}>
          Delete
        </MenuItem>
      </Menu>
    </>
  );
};

export const SiteCard = memo(SiteCardComponent);
