import { SavedPaymentMethodDto } from './SavedPaymentMethodDto';

/** Subscription state dto. */
export enum SubscriptionStateDto {
  ActiveAndPaid = 'ActiveAndPaid',
  ActiveAndNotPaid = 'ActiveAndNotPaid',
  DeletedAndPaid = 'DeletedAndPaid',
  DeletedAndNotPaid = 'DeletedAndNotPaid',
}

/** Subscription period dto. */
export interface SubscriptionPeriodDto {

  /** Cost per bed in dollars. */
  readonly costPerBedInDollars: number;

  /** Duration in months. */
  readonly durationInMonth: number;

  /** Order. */
  readonly order: number;
}

/** Organization subscription dto. */
export interface OrganizationSubscriptionDto {

  /** ID. */
  readonly id: number;

  /** Name. */
  readonly subscriptionOptionName: string | null;

  /** Subscription periods. */
  readonly subscriptionOptionPeriods: readonly SubscriptionPeriodDto[] | null;

  /** Default card for payments. */
  readonly defaultSavedCard: SavedPaymentMethodDto;

  /** Next period payment date. */
  readonly nextPeriodPaymentDate: Date;

  /** Next period payment amount. */
  readonly nextPeriodPaymentAmount: number | null;

  /** Total count of paid beds. */
  readonly totalPaidBedCount: number;

  /** State of the subscription. */
  readonly state: SubscriptionStateDto;
};
