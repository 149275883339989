import { Formik } from 'formik';
import React, { FC } from 'react';
import { CancelLink } from 'components/CancelLink';
import { InputField } from 'components/InputField';
import { ThemedButton } from 'components/ThemedButton';
import {
  Footer,
  FormHeading,
  StyledForm,
  FormText,
  Center,
  Header,
  Content,
} from 'forms/SignIn/styled';
import { initialValues, validationSchema } from 'forms/TwoFactorSetup/data';
import { Props } from 'forms/TwoFactorSetup/types';
import { ERoute } from 'router/data';

export const TwoFactorSetupForm: FC<Props> = ({ handleSubmit, isLoading }) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
  >
    {({ submitForm }) => (
      <StyledForm>
        <Header>
          <CancelLink to={ERoute.LOGIN} label="Back" />
          <FormHeading>Two-Factor Authentication</FormHeading>
          <FormText>
            We’ll set up the number in your personal information and two-factor
            authentication settings. <br />
            <br />
            Next, we’ll send you a confirmation code.
          </FormText>
        </Header>

        <Center>
          <Content>
            <InputField
              name="phone"
              label="Phone number"
              type="text"
              placeholder="+1 949 555-0100"
            />
          </Content>
        </Center>

        <Footer>
          <ThemedButton
            disabled={isLoading}
            type="submit"
            width="100%"
            onClick={submitForm}
          >
            Continue
          </ThemedButton>
        </Footer>
      </StyledForm>
    )}
  </Formik>
);
