import styled from '@emotion/styled'
import CSS from 'csstype'
import { theme } from 'styles/theme'

interface Props {

  /** Whether the row is clickable and therefore should use pointer cursor on hover. */
  readonly isClickable: boolean;

  /** Columns template. */
  readonly columnsTemplate: CSS.Property.GridTemplateColumns
}

export const Grid = styled.div<Props>`
  display: grid;
  padding: 23px 0;
  border-radius: 12px;
  grid-template-columns: ${(props) => props.columnsTemplate};

  &:hover {
    background-color: ${theme.colors.lightGreen};
    cursor: ${(props) => props.isClickable && 'pointer'};
  }
`;
