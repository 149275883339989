import React, { FC } from 'react'
import {
  OrgBlockWrap,
  OrgImage,
  InfoContentWrap,
  HeaderBlockWrap,
  SubText,
  NameTitle,
} from 'pages/SiteList/List/styled'
import { FirebaseService } from 'services/firebase'
import { Site } from 'models/site/Site'
import { Image } from 'models/image/Image.'

interface Props {
  /** Site. */
  readonly site: Site

  /** Image to display instead of site's cover image. */
  readonly coverImage?: Image;
}

export const SiteBlock: FC<Props> = ({ site, coverImage }) => (
  <OrgBlockWrap key={site.id}>
    <OrgImage src={FirebaseService.getImageSrc(coverImage?.imagePath ?? site.coverImage?.imagePath)} alt={site.name} />

    <InfoContentWrap>
      <HeaderBlockWrap>
        <SubText>{site.alias}</SubText>
        <NameTitle>{site.name}</NameTitle>
      </HeaderBlockWrap>
    </InfoContentWrap>
  </OrgBlockWrap>
)
