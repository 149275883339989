import React, { FC, SyntheticEvent, useCallback, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useBeds } from 'api/beds';
import { useGuests } from 'api/guests';
import { GuestMethods } from 'api/guests/types';
import { useSiteDetails } from 'api/sites/useSiteDetails';
import { Layout } from 'components/Layout';
import { BlockView } from 'components/Layout/Grid';
import { MainHeading } from 'components/Typography';
import { LoadingPage } from 'pages/LoadingPage';
import { CancelLink } from 'components/CancelLink';
import { ERoute, routes } from 'router/data';
import { SiteBlock } from 'components/SiteBlock/SiteBlock';
import Tabs from '@mui/material/Tabs';
import { TabPanel } from 'components/TabPanel/TabPanel';
import { GuestsTable } from 'pages/Reservation/Components/GuestsTable/GuestsTable';
import { ReservationsTable } from './Components/ReservationsTable/ReservationsTable';
import { CreateGuestForm } from './Components/CreateGuestForm/CreateGuestForm';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { AddGuestButton } from './styled';
import { CounterTab, StyledTab } from 'components/Tabs/Tabs';
import { Site } from 'models/site/Site';
import { useAuth } from 'hooks/auth/useAuth';
import { ReservationRequestRooms } from '../../components/ReservationRequestRooms/ReservationRequestRooms';

interface Props {

  /** Site id. */
  readonly siteId: Site['id'];
}

export const ReservationsPage: FC<RouteComponentProps<Props>> = ({ match }) => {
  const { siteId } = match.params;

  const [isModalOpen, setModalOpen] = useState(false);
  const [activeTabNumber, setActiveTabNumber] = useState(0);
  const { data: siteData, error: siteError } = useSiteDetails(siteId);
  const { data: bedsData, error: bedsError } = useBeds(siteId);
  const { data: allGuestsData, revalidate: revalidateGuests, error: guestsError } = useGuests(siteId);

  const { roleStaffSiteIds } = useAuth().state.userData;
  const isUserRoleStaff = roleStaffSiteIds.includes(siteId);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, [setModalOpen]);

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const handleTabChange = useCallback((event: SyntheticEvent, newActiveTabNumber: number): void => {
    setActiveTabNumber(newActiveTabNumber);
  }, [setActiveTabNumber]);

  if (siteError || bedsError || guestsError) {
    return <div>failed to load</div>;
  }
  if (
    siteData == null ||
    bedsData == null ||
    allGuestsData == null
  ) {
    return <LoadingPage />;
  }

  const guests = allGuestsData.items.filter((guest) => GuestMethods.isAssignedGuest(guest));
  const reservationGuests = allGuestsData.items.filter((guest) => GuestMethods.isReservationGuest(guest));

  const rooms = siteData.rooms;
  const beds = bedsData;

  const backButtonRoute = routes.OrganizationSiteDetails(siteData.organizationId.toString(), siteData.id);

  return (
    <Layout withSideBar>
      <CancelLink to={backButtonRoute} />

      <MainHeading>Reservation details</MainHeading>
      <BlockView mt={10} mb={10}>
        <SiteBlock site={siteData} />
      </BlockView>

      <Tabs value={activeTabNumber} onChange={handleTabChange}>
        <StyledTab label="Overview" />
        <CounterTab label={`Reservations`} count={reservationGuests.length} />
        <CounterTab label={`Guests`} count={guests.length} />
        {isUserRoleStaff || (
          <AddGuestButton type="button" variant="contained" onClick={handleModalOpen}>
            + Add New Guest
          </AddGuestButton>
        )}
      </Tabs>

      <TabPanel index={0} value={activeTabNumber}>
        <ReservationRequestRooms rooms={rooms} site={siteData} />
      </TabPanel>

      <TabPanel index={1} value={activeTabNumber}>
        <ReservationsTable
          guests={reservationGuests}
          siteId={siteId}
          isCommercial={siteData.isCommercial}
        />
      </TabPanel>

      <TabPanel index={2} value={activeTabNumber}>
        <GuestsTable guests={guests} rooms={rooms} beds={beds} siteId={siteId} />
      </TabPanel>

      <ModalWindow isScrollable isOpen={isModalOpen} onClose={handleModalClose}>
        <CreateGuestForm
          onCancel={handleModalClose}
          onDone={() => {
            handleModalClose();
            revalidateGuests();
          }}
          site={siteData}
        />
      </ModalWindow>
    </Layout>
  );
};
