import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import { theme } from '../../../styles/theme';

export const InsuranceCompanyContainer = styled.div`
  display: grid;
  grid-template-columns: 36px 1fr 36px;
  grid-column-gap: 12px;
  padding: 20px;
  border: 1px solid ${theme.colors.gray};
  border-radius: 8px;
  color: ${theme.colors.black};
  transition: background-color 200ms linear;
  align-items: center;
  position: relative;

  &:hover {
    background-color: ${theme.colors.lightGray};
  }
`;

export const PrimaryInsuranceMark = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  max-width: 15px;
`;

export const InsuranceCompanyLogo = styled.img`
  grid-column: 1;
  width: 36px;
  height: 36px;
  border-radius: 4px;
`;

export const InsuranceCompanyName = styled.h3`
  grid-column: 2;
  font-size: var(--font-size-s);
  font-weight: 600;
`;

export const InsuranceCompanyMenuButton = styled.div`
  grid-column: 3;
  width: 36px;
  height: 36px;
`;

interface MenuItemProps {

  /** Color of the item */
  readonly color: 'blue' | 'red';
}

export const InsuranceCompanyMenuItem = styled(MenuItem)<MenuItemProps>`
  font-size: var(--font-size-xs);
  font-weight: 600;
  min-width: 100px;
  color: ${props => props.color === 'red' ? theme.colors.red : theme.colors.blue};
`;
