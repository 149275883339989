import styled from '@emotion/styled'
import { theme } from 'styles/theme'

interface IWithError {

  /** Whether field has error. */
  readonly hasError?: boolean;
}

interface InputProps extends IWithError {
  readonly isCutRight?: boolean;
}

interface LabelProps extends IWithError {}

export const InputWrap = styled.div`
  flex: 1;
  margin-bottom: 15px;
  /* min-height: 80px; */
  position: relative;
`

export const StyledInput = styled.input<InputProps>`
  border-radius: 4px;
  border: 1px solid
    ${(props) => (props.hasError ? theme.colors.red : theme.colors.middleGray)};
  padding: 10px 16px;
  display: block;
  width: 100%;
  min-width: 185px;
  ${(props) =>
    props.isCutRight &&
    `
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `}

  font-family: 'Inter-Regular', 'Lucida Sans', 'Lucida Sans Regular',
    'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: ${theme.colors.dark};
  box-sizing: border-box;
`

export const StyledTextarea = StyledInput.withComponent('textarea')

export const StyledLabel = styled.label<LabelProps>`
  color: ${props => props.hasError ?
    theme.colors.red :
    theme.colors.black
  };
  font-size: var(--font-size-xs);
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
`

export const Description = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${theme.colors.dark};
  opacity: 0.5;
  margin-top: 8px;
  display: inline-block;
`
