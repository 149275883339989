import { StrictOmit } from 'utils/types/strictOmit';

type ScreeningPeriodCreationData = StrictOmit<ScreeningPeriod, 'totalHours'>;

/** Screening period. */
export class ScreeningPeriod {

  /** ID of the period. */
  public readonly id: number | null;

  /** Value of the period in format "dd:hh:mm:ss". */
  public readonly value: string;

  private readonly days: number;

  private readonly hours: number;

  private readonly minutes: number;

  private readonly seconds: number;

  public constructor(data: ScreeningPeriodCreationData) {
    this.id = data.id;
    this.value = data.value;

    try {
      const partsOfValue = data.value.split(':');
      this.days = Number(partsOfValue[0]);
      this.hours = Number(partsOfValue[1]);
      this.minutes = Number(partsOfValue[2]);
      this.seconds = Number(partsOfValue[3]);
    } catch (error: unknown) {
      throw new Error(
        `${data.value} is incorrect value of screening period with id ${data.id}.
        Valid screening period must have 'dd:hh:mm:ss' format`,
      );
    }

  }

  /** Total hours of screening period. */
  public get totalHours(): number {
    return this.days * 24 + this.hours;
  }
}
