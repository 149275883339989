import { IconButton, Menu } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { FC, ReactNode } from 'react';

interface BaseMenuExportProps {
  /** Handle menu closing. */
  readonly onMenuClose: () => void;
}

interface BaseMenuProps {
  readonly children: (props: BaseMenuExportProps) => ReactNode;
}

/** Base menu component. */
export const BaseMenu: FC<BaseMenuProps> = ({ children }) => {
  const [menuItem, setMenuItem] = React.useState<null | HTMLElement>(null);

  const isOpened = menuItem != null;

  const onMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuItem(event.currentTarget);
  };

  const onMenuClose = () => {
    setMenuItem(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={isOpened ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isOpened ? 'true' : undefined}
        onClick={onMenuButtonClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={menuItem}
        open={isOpened}
        onClose={onMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children?.({
          onMenuClose,
        })}
      </Menu>
    </>
  );
};
