import { Button } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { CommonDialogProps } from '../CommonDialogProps';
import { ColumnModal, DialogTitleText } from '../styled';

/** Alert dialog. */
export const AlertDialog: FC<PropsWithChildren<CommonDialogProps>> = ({
  isOpened,
  onClose,
  cancelButtonText,
  title,
  children,
}) => {
  return (
    <ColumnModal isOpen={isOpened} onClose={onClose}>
      <DialogTitleText>{title ?? 'Alert'}</DialogTitleText>
      {children}
      <Button onClick={onClose} variant="contained" type="button">
        {cancelButtonText ?? 'OK'}
      </Button>
    </ColumnModal>
  );
};
