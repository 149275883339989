import React, { FC } from 'react';
import { Container, Label, CloseButton } from 'components/Message/styled';

export interface Props {
  label: string;
  onClose: VoidFunction;
}

export const Message: FC<Props> = ({ label, onClose }) => (
  <Container>
    <Label>{label}</Label>
    <CloseButton type="button" onClick={onClose} />
  </Container>
);
