import { normalizeUserInfo } from 'api/auth/useUserInfo/normalize'
import { AuthInfoDto } from 'api/auth/useUserInfo/types'
import { useMutate } from 'hooks/api'
import { IUserData } from 'hooks/auth/types'

export const useUserInfo = () => {
  const { mutate, isLoading, error } = useMutate<
    IUserData,
    AuthInfoDto,
    undefined
  >('get', {
    url: '/connect/userinfo',
    headers: {
      Accept: 'application/json',
    },
    mapper: normalizeUserInfo,
  })

  const loadUserInfo = () => mutate()

  return { loadUserInfo, isLoading, error }
}
