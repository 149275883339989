import { GuestWithRoomAndBed } from 'models/guest/GuestWithRoomAndBed';
import { PaymentMethodDto } from './../../dtos/paymentMethodDto/PaymentMethodDto';
import { Site } from 'models/site/Site';
import { Room } from 'pages/SiteCreate/components/Rooms/domain/Room';
import { EGender, SiteService } from 'services/types'

// TODO: translate comments
// TODO (Maxim K.): Add model, dto and mapper for guest, and remove all appearences of guest dto in components.
export interface IGuestType {
  id: number;
  siteId: Site['id'];
  firstName: string;
  lastName: string;
  code: string;
  gender: EGender; // predvyplnis podle filtru co byl v hledani
  birthDate: string;
  phone?: string;
  email?: string;
  location?: string;
  services: SiteService[]; // posilas serviceIds jako pro site
  // stavy rezervace pro update
  acceptedAt?: string; // datum prijeti, nastavi assignedBedId
  rejectedAt?: string; // datum zamitnuti rezervace
  dischargedAt?: string; // datum propusteni, uvolni tim postel
  assignedBedId?: number;
  createdAt: string;

  /** Chosen payment method. */
  readonly organizationReservationPaymentMethod: PaymentMethodDto | null;

  /** Insurance company name. */
  readonly insuranceCompanyName: string | null;

  /** Feedback. */
  readonly feedback?: string;
}

export enum EGuestStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  REASSIGNED = 'reassigned',
  DISCHARGED = 'discharged',
  PENDING = 'pending',
}

export namespace GuestMethods {
  /**
   * Converts string date to Date object.
   * @param stringDate String date to convert.
   */
  export function parseReservationDate(stringDate: string): Date {
    return new Date(stringDate);
  }

  /**
   * Gets guest full name shortening last name to one letter.
   * @param guest Guest to get name of.
   */
  export function getShortenedName(guest: IGuestType): string {
    return `${guest.firstName} ${guest.lastName.charAt(0)}.`;
  }

  /**
   * Gets guest full name shortening last name to one letter and adding guest code at the end.
   * @param guest Guest to get name of.
   */
  export function getFullName(guest: IGuestType): string {
    return `${getShortenedName(guest)} ${guest.code}`;
  }

  /**
   * Check if the guest have assigned bed.
   * @param guest Guest to check.
   */
  export function isAssignedGuest(guest: IGuestType): boolean {
    return guest.assignedBedId != null;
  }

  /**
   * Check if the guest have reservation.
   * @param guest Guest to check.
   */
  export function isReservationGuest(guest: IGuestType): boolean {
    return guest.assignedBedId == null && guest.rejectedAt == null && guest.acceptedAt == null;
  }

  /**
   * Finds room and bed the guest assigned to from provided array of rooms.
   * @param guest Guest.
   * @param rooms List of rooms to find room and bed from.
   */
  export function findRoomAndBedOfGuest(guest: IGuestType, rooms: readonly Room[]): GuestWithRoomAndBed {
    const room = rooms.find(room =>
      room.beds.find(bed => bed.id === guest.assignedBedId?.toString())
    );
    const bed = room?.beds.find((bed) => bed.id === guest.assignedBedId?.toString());

    return {
      ...guest,
      room: room ?? null,
      bed: bed ?? null,
    }
  }
}
