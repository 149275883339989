import styled from '@emotion/styled';
import { Table, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import { theme } from '../../../../styles/theme';

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PaymentInfoTable = styled(Table)`
  --cell-padding: 0 10px 0 0;
`;

const BasicTableCell = styled(TableCell)`
  padding: var(--cell-padding);
  border: none;
  font-family: 'Inter';
  font-weight: 500;
`

export const PaymentInfoTableHeader = styled(BasicTableCell)`
  font-size: var(--font-size-xs);
  width: 25%;
  color: ${theme.colors.semiLightGray};
`;

export const PaymentInfoTableCell = styled(BasicTableCell)`
  font-size: var(--font-size-md);
  color: ${theme.colors.black};
`;

export const PaymentInfoFlexContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const PaymentInfoTableLink = styled(Link)`
  font-family: 'Inter';
  font-size: var(--font-size-xs);
  font-weight: 500;
  color: ${theme.colors.semiLightBlue};
  text-decoration: none;
`;
