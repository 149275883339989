import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const NoContentBlock = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  background-color: ${theme.colors.noContentBackgroundColor};
  color: ${theme.colors.noContentColor};
  font-size: 0.75rem;
  font-weight: 600;
`;
