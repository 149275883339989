import { InputField } from 'components/InputField';
import React, { FC, PropsWithChildren } from 'react'

interface Props {

  /** Name of the control. */
  readonly controlName: string;

  /** Whether editable mode is on or not. */
  readonly isEditMode: boolean;

  /** Handler for edit finished action. */
  readonly onEditFinish: () => void;
}

/** Editable text title component. */
export const EditableTextFiled: FC<PropsWithChildren<Props>> = ({
  controlName,
  isEditMode,
  children,
  onEditFinish,
}) => {

  const input = (
    <InputField shouldAutoFocus={true} onBlur={onEditFinish} name={controlName} />
  );

  return (
    <>
      {isEditMode ? input : children}
    </>
  )
}
