import { IGuestType } from 'api/guests/types';
import { Site } from 'models/site/Site';
import { SiteTabContentContainer } from 'pages/OrganizationSiteDetails/styled';
import { GuestsTable } from 'pages/Reservation/Components/GuestsTable/GuestsTable';
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';
import { Room } from 'pages/SiteCreate/components/Rooms/domain/Room';
import React, { FC, memo } from 'react';
import { SiteTabHeading } from '../SiteTabHeading/SiteTabHeading';

interface Props {

  /** Guests which need to show in the table. */
  readonly guests: readonly IGuestType[];

  /** Beds available to the guests. */
  readonly beds: readonly Bed[];

  /** Rooms available to the guests. */
  readonly rooms: readonly Room[];

  /** Site id. */
  readonly siteId: Site['id'];
}

const SiteGuestsComponent: FC<Props> = ({ guests, beds, rooms, siteId }) => {
  return (
    <>
      <SiteTabContentContainer>
        <SiteTabHeading headingText="Guests" count={guests.length} />
        <GuestsTable guests={guests} beds={beds} rooms={rooms} siteId={siteId} />
      </SiteTabContentContainer>
    </>
  );
};

export const SiteGuests = memo(SiteGuestsComponent);
