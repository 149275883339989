import { useMutate } from 'hooks/api';
import { Organization } from 'models/organization/Organization';
import { Site } from 'models/site/Site';
import { User, UserRole } from 'models/user/User';

type ChangeRoleOwnerParams = {
  role: UserRole.Owner,
  siteId?: Site['id'],
  organizationId: Organization['id'],
}

type ChangeRoleAdminAndLowerParams = {
  role: UserRole.Admin | UserRole.Staff | UserRole.Intake,
  siteId: Site['id'],
  organizationId?: Organization['id'],
}

type ChangeRole = (
  params: ChangeRoleOwnerParams | ChangeRoleAdminAndLowerParams,
  onError?: (error: unknown) => void
) => Promise<unknown>

export const useUpdateUser = (id: User['id']) => {
  const { mutate: deleteUser } = useMutate('delete', {
    url: `/api/AccessUsers/${id}`,
  });

  const { mutate } = useMutate('patch', {
    url: `/api/AccessUsers/${id}`,
  });

  const setActive = () =>
    mutate({
      isDisabled: false,
    });

  const setDisabled = () =>
    mutate({
      isDisabled: true,
    });

  const changeRole: ChangeRole = (params, onError) =>
    mutate(params, onError);

  return { setActive, setDisabled, changeRole, deleteUser };
};
