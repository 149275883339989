import TableRow from '@mui/material/TableRow';
import { GuestMethods, IGuestType } from 'api/guests/types';
import { StyledButton } from 'components/StyledButton/styled';
import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';
import { formatDateToMDY } from 'utils/formatDateToMDY';
import { parseDateClockTime } from 'utils/parseDateClockTime';
import { reduceGuestServices } from 'components/GuestInfo/reduceGuestServices';
import {
  ReservationsTableCell,
  ReservationsTableContent,
  ReservationsTableContentSmall,
  CellButtonsContainer,
  CellFlexContainer,
  PhoneNumberTableCell,
} from './styled';
import { useAuth } from 'hooks/auth/useAuth';
import { Site } from 'models/site/Site';
import { PaymentMethodCategoryDto } from 'dtos/paymentMethodDto/PaymentMethodDto';

interface ReservationsTableRowProps {
  /** Guest on the row. */
  readonly guest: IGuestType;

  /** Site id. */
  readonly siteId: Site['id'];

  /** Handler for decline button click. */
  readonly onDeclineButtonClick: (guest: IGuestType) => void;
}

const ReservationsTableRowComponent: FC<ReservationsTableRowProps> = ({ guest, siteId, onDeclineButtonClick }) => {
  const [isHovering, setIsHovering] = useState(false);

  const { roleStaffSiteIds } = useAuth().state.userData;

  /** Whether user has staff role. */
  const isStaff = roleStaffSiteIds.includes(siteId);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const reservationDate = GuestMethods.parseReservationDate(guest.createdAt);

  const shouldShowPaymentMethod = guest.organizationReservationPaymentMethod !== null;

  return (
    <TableRow
      sx={{
        height: 'auto',
        backgroundColor: isHovering ? '#F0F0F0' : '#FFFFFF',
        wordBreak: 'break-word',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ReservationsTableCell>
        <ReservationsTableContent>{GuestMethods.getFullName(guest)}</ReservationsTableContent>
        <ReservationsTableContentSmall>{guest.gender}</ReservationsTableContentSmall>
      </ReservationsTableCell>
      {isStaff || <PhoneNumberTableCell>{guest.phone}</PhoneNumberTableCell>}
      <ReservationsTableCell>
        <ReservationsTableContent>{formatDateToMDY(reservationDate)}</ReservationsTableContent>
        <ReservationsTableContentSmall>{parseDateClockTime(reservationDate)}</ReservationsTableContentSmall>
      </ReservationsTableCell>
      {shouldShowPaymentMethod && <ReservationsTableCell>
        <ReservationsTableContent>{reduceGuestServices(guest.services)}</ReservationsTableContent>
      </ReservationsTableCell>}
      <ReservationsTableCell sx={{ width: shouldShowPaymentMethod ? '30%' : '50%', padding: '0' }}>
        <CellFlexContainer>
          {shouldShowPaymentMethod ?
            <>
              {
                // TODO(Maxim K.): Remove dto from component
                guest.organizationReservationPaymentMethod.reservationPaymentMethodCategory === PaymentMethodCategoryDto.Insurance ?
                  guest.insuranceCompanyName :
                  guest.organizationReservationPaymentMethod.reservationPaymentMethodName
              }
            </> :
            <ReservationsTableContent>{reduceGuestServices(guest.services)}</ReservationsTableContent>
          }
          {isStaff || (
            <CellButtonsContainer>
              {isHovering && (
                <>
                  <StyledButton
                    color="error"
                    variant="outlined"
                    size="small"
                    onClick={() => onDeclineButtonClick(guest)}
                    type="button"
                  >
                    Decline
                  </StyledButton>
                  <StyledButton
                    variant="outlined"
                    component={Link}
                    size="small"
                    to={`/reservations-request/${guest.id}`}
                    type="button"
                    sx={{
                      border: `1px solid ${theme.colors.grayBorderColor}`,
                      textTransform: 'none',
                      fontSize: '0.75rem',
                      fontWeight: '600',
                    }}
                  >
                    Accept
                  </StyledButton>
                </>
              )}
            </CellButtonsContainer>
          )}
        </CellFlexContainer>
      </ReservationsTableCell>
    </TableRow>
  );
};

export const ReservationsTableRow = ReservationsTableRowComponent;
