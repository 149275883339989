import React, { FC, memo } from 'react';
import { Room } from '../../../../pages/SiteCreate/components/Rooms/domain/Room';
import { ReservationRoomBedsList, ReservationRoomCardContainer, ReservationRoomCardName, ReservationRoomImage } from './styled';
import { FirebaseService } from '../../../../services/firebase';
import { ReservationBedRow } from '../ReservationBedRow/ReservationBedRow';
import { Site } from '../../../../models/site/Site';
import { IGuestType } from '../../../../api/guests/types';
import { sortEntities } from 'utils/sortEntities';

interface Props {

  /** Room. */
  readonly room: Room;

  /** Site. */
  readonly site: Site;

  /** Guest. */
  readonly guest?: IGuestType;
}

const ReservationRoomCardComponent: FC<Props> = ({ room, site, guest }) => {

  const { beds } = room;

  return (
    <ReservationRoomCardContainer>
      <ReservationRoomCardName>{room.name}</ReservationRoomCardName>
      <ReservationRoomImage src={FirebaseService.getImageSrc(room.hasPhoto ? room.photo ?? undefined : undefined)} />
      <ReservationRoomBedsList>
        {sortEntities(beds, 'name').map(bed => <ReservationBedRow bed={bed} key={bed.id} site={site} guest={guest} />)}
      </ReservationRoomBedsList>
    </ReservationRoomCardContainer>
  )
};

export const ReservationRoomCard = memo(ReservationRoomCardComponent);
