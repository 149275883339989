import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { theme } from '../../../../styles/theme';

const generalBedStyles = css`
  border-radius: 10px;
  padding-inline: 10px;
  padding-block: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  min-height: 50px;
  font-weight: 600;
  font-size: var(--font-size-md);
`;

export const OccupiedBedContainer = styled.div`
  ${generalBedStyles};
  flex-direction: column;
  align-items: start;
  gap: 5px;
  background-color: ${theme.colors.lightRed};
`;

export const OccupiedBedInfo = styled.p`
  font-size: var(--font-size-s);
`;

export const VacantBedButton = styled.button`
  ${generalBedStyles};
  border: none;
  background-color: ${theme.colors.lightGreen};
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${theme.colors.green};
  }
`;

export const VacantBedContainer = styled.div`
  ${generalBedStyles};
  background-color: ${theme.colors.lightGreen};
`;
