import { object, string } from 'yup'

export const initialValues = {
  email: '',
}

export const validationSchema = object().shape({
  email: string().email().required('Please enter your new email'),
})

export type IFormData = typeof initialValues
