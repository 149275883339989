import { useFetch } from 'hooks/api';
import { InsuranceCompany } from '../../models/insuranceCompany/InsuranceCompany';
import { InsuranceCompanyDto } from '../../dtos/insuranceCompany/InsuranceCompanyDto';
import { insuranceCompanyMapper } from '../../mappers/InsuranceCompanyMapper';

/** Insurance companies get hook. */
export const useInsuranceCompanies = () =>
  useFetch<InsuranceCompany[], InsuranceCompanyDto[]>({
    url: `/api/InsuranceCompanies`,
    mapper: data => data.map(dto => insuranceCompanyMapper.fromDto(dto)),
  });
