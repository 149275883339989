import { useMutate } from 'hooks/api';
import { insuranceCompanyMapper } from '../../mappers/InsuranceCompanyMapper';
import { InsuranceCompany } from '../../models/insuranceCompany/InsuranceCompany';
import { InsuranceCompanyUpdateValues } from '../../models/insuranceCompany/InsuranceCompanyUpdateValues';

/**
 * Insurance company delete hook.
 * @param id Id of the company.
 */
export const useUpdateInsuranceCompany = (id: InsuranceCompany['id']) => {
  const { mutate } = useMutate<undefined, undefined, InsuranceCompanyUpdateValues>('put', {
    url: `/api/InsuranceCompanies/${id}`,
    requestTransformer: data => JSON.stringify(insuranceCompanyMapper.mapUpdateValuesToDto(data)),
  });

  return { handleUpdate: mutate };
};
