import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { theme } from 'styles/theme';
import { StrictOmit } from 'utils/types/strictOmit';
import { ValidationErrorProps } from './ValidationError';

type Props = StrictOmit<ValidationErrorProps, 'message'>;

export const ErrorText = styled.p<Props>`
  color: ${theme.colors.red};
  font-size: var(--font-size-xs);
  font-weight: 500;
  max-width: 100%;
  ${(props) =>
    props.isCentered &&
    css`
      align-self: center;
    `}
`;
