import styled from '@emotion/styled';
import { theme } from 'styles/theme';

export const BackButtonContainer = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  gap: 10px;
  color: ${theme.colors.semiLightBlue};
  font-weight: 600;
  font-size: var(--font-size-xs);
`;
