import { StrictOmit } from '../../utils/types/strictOmit';

type SavedPaymentMethodCreationData = StrictOmit<SavedPaymentMethod, 'brandLogoImageSrc' | 'formattedExpirationDate'>;

/** Payment method brand. */
export enum PaymentMethodBrand {
  Visa = 'visa',
  Mastercard = 'mastercard',
}

const LOGO_ROOT = '/assets/icons';

const MAP_BRAND_TO_IMAGE_SRC: Readonly<Record<PaymentMethodBrand, string>> = {
  [PaymentMethodBrand.Visa]: `${LOGO_ROOT}/visa-logo.svg`,
  [PaymentMethodBrand.Mastercard]: `${LOGO_ROOT}/mastercard-logo.svg`,
}

/** Saved payment method model. */
export class SavedPaymentMethod {

  /** ID. */
  public readonly id: number;

  /** Brand of the card. */
  public readonly brand: PaymentMethodBrand;

  /** Last four digits of the card number. */
  public readonly lastFourDigits: string;

  /** Whether the payment method is default one. */
  public readonly isDefault: boolean;

  /** Expiration date in `mm/yy` format. */
  public readonly expirationDate: Date;

  public constructor(data: SavedPaymentMethodCreationData) {
    this.id = data.id;
    this.brand = data.brand;
    this.lastFourDigits = data.lastFourDigits;
    this.isDefault = data.isDefault;
    this.expirationDate = data.expirationDate;
  }

  public get brandLogoImageSrc(): string {
    return MAP_BRAND_TO_IMAGE_SRC[this.brand];
  }

  /** `mm/yy` formatted expiration date. */
  public get formattedExpirationDate(): string {
    const month = this.expirationDate.getMonth() + 1;
    const year = this.expirationDate.getFullYear().toString().slice(2)
    return `${month}/${year}`;
  }
}
