import { FieldLabelRequiredMark } from 'components/FieldLabelRequiredMark/FieldLabelRequiredMark';
import { SinglePhotoUpload } from 'components/UploadPhoto/Single';
import { ValidationError } from 'components/ValidationError/ValidationError';
import React, { FC, memo } from 'react';
import { LogoUploadContainer, LogoUploadInfo, LogoUploadLabel, LogoUploadRow } from './styled';

const MAX_IMAGE_SIZE_IN_BYTES = 5000000;

interface Props {

  /** Handle change event. */
  readonly onChange: (val?: string | null) => void;

  /** Prefix to give image name. */
  readonly prefix: string;

  /** Init image url. */
  readonly initImageUrl: string;

  /** Validation error message. */
  readonly errorMessage?: string;

  /** Whether required mark should be added to label. */
  readonly isRequired?: boolean;
}

const LogoUploadComponent: FC<Props> = ({ onChange, initImageUrl, errorMessage, isRequired, prefix }) => {
  return (
    <LogoUploadContainer>
      <LogoUploadRow>
        <LogoUploadLabel hasError={errorMessage != null}>
          Logo image {isRequired && <FieldLabelRequiredMark />}{' '}
        </LogoUploadLabel>
        <SinglePhotoUpload
          initImageUrl={initImageUrl}
          prefix={prefix}
          onChange={(value) => onChange(value)}
          size="small"
          acceptableTypeFormats={['jpeg', 'jpg']}
          bytesMaxImageSize={MAX_IMAGE_SIZE_IN_BYTES}
          isDeletionAvailable
        />
        <LogoUploadInfo>JPEG file up to 5 Mb</LogoUploadInfo>
      </LogoUploadRow>
      <ValidationError message={errorMessage} />
    </LogoUploadContainer>
  );
};

export const LogoUpload = memo(LogoUploadComponent);
