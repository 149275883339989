import { Form, Formik, FormikHelpers } from 'formik';
import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Layout } from 'components/Layout';
import { BlockView, Gap, RowView } from 'components/Layout/Grid';
import { SmartButton } from 'components/SmartButton';
import { ThemedButton } from 'components/ThemedButton';
import { MainHeading } from 'components/Typography';
import { LoadingPage } from 'pages/LoadingPage';
import { AboutForm } from 'pages/SiteCreate/components/About';
import { ContactsForm } from 'pages/SiteCreate/components/Contacts';
import { IntakePeriodsForm } from 'pages/SiteCreate/components/IntakePeriods';
import { ServicesForm } from 'pages/SiteCreate/components/Services';
import { DeleteSiteModal } from 'pages/SiteEdit/DeleteSiteModal';
import { ERoute, getOrganizationTypeHref, routes } from 'router/data';
import { errorsTransform } from 'services/transformers';
import { SiteBlock } from 'components/SiteBlock/SiteBlock';
import { getCountOfBedsFromSiteEditValues, SiteEditingValues } from 'models/site/SiteEditingValues';
import { SiteMapper } from 'mappers/SiteMapper';
import { useSiteDetails } from 'api/sites/useSiteDetails';
import { useUpdateSite } from 'api/sites/useUpdateSite';
import {
  commercialSiteAboutValidationSchema,
  nonCommercialSiteAboutValidationSchema,
  siteAccomodationsValidationSchema,
  siteContactsValidationSchema,
  siteIntakePeriodsValidationSchema,
  siteServicesValidationSchema,
} from 'pages/SiteCreate/validationSchemas';
import { useAuth } from 'hooks/auth/useAuth';
import { FormikUnmountEffect } from 'components/FormikUnmountEffect/FormikUnmountEffect';
import { useEditedSiteDraftContext } from './EditedSiteContext/EditedSiteContext';
import { useOrganizationBackButtonRoute } from 'hooks/routing/useOrganizationBackButtonRoute';
import { Site } from '../../models/site/Site';
import { ObjectSchema } from 'yup';
import { BedsChangeConfirmDialog } from 'components/BedsChangeConfirmDialog/BedsChangeConfirmDialog';
import { useSubscriptionUpgradeInfo } from '../../api/Subscriptions/useSubscriptionUpgradeInfo';
import { ErrorMessage } from '../../components/FetchErrorMessage/FetchErrorMessage';
import { useOrganizationSubscription } from '../../api/subscription/useOrganizationSubscription';
import { ConfirmDialog } from 'components/Dialogs/ConfirmDialog/ConfirmDialog';
import { BackButton } from 'components/BackButton/BackButton.';
import { MainHeadingContainer } from './styled';
import { Image } from 'models/image/Image.';

interface RouteParams {
  /** Site id. */
  readonly siteId: string;
}

/** Common props for site form block. */
export interface SiteFormBlockProps {
  /** Whether main header of block is visible. */
  readonly withoutMainHeader?: boolean;
}

/**
 * Gets site edition validation schema.
 * @param isCommercial Whether the site is commercial.
 */
export function getSiteEditValidationSchema(isCommercial: Site['isCommercial'], shouldValidateRooms: boolean): ObjectSchema {
  const siteAboutValidationSchema = isCommercial ? commercialSiteAboutValidationSchema : nonCommercialSiteAboutValidationSchema;
  const siteValidationSchemaWithoutRooms = siteAboutValidationSchema
    .concat(siteContactsValidationSchema)
    .concat(siteIntakePeriodsValidationSchema)
    .concat(siteServicesValidationSchema);
  return shouldValidateRooms ? siteValidationSchemaWithoutRooms.concat(siteAccomodationsValidationSchema) : siteValidationSchemaWithoutRooms;
}

const EditSiteComponent: FC = () => {
  const { siteId } = useParams<RouteParams>();
  const [isModalOpen, setModalOpen] = useState(false);

  const history = useHistory();

  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);

  const [newBedsCount, setNewBedsCount] = useState<number>(0);

  const { data: site, error, mutate } = useSiteDetails(siteId);

  const { roleStaffSiteIds, roleIntakeSiteIds, roleAdminSiteIds, roleGlobal } = useAuth().state.userData;
  const isAdmin = roleAdminSiteIds.includes(siteId);

  const siteRoute = useMemo(() => routes.OrganizationSiteDetails(
    site?.organizationId.toString() ?? '',
    site?.id ?? '',
    getOrganizationTypeHref(roleGlobal, site?.isCommercial ?? false),
  ), [site, roleGlobal]);

  const { handleUpdate, error: siteUpdateErrorMessage } = useUpdateSite(
    siteId,
    site?.isCommercial ?? false,
    siteRoute,
  );

  const [isBackDialogOpen, setIsBackDialogOpen] = useState(false);

  const toggleBackDialog = useCallback(() => setIsBackDialogOpen(isOpen => !isOpen), [setIsBackDialogOpen]);

  const handleCancelButtonClick = useCallback((isFormDirty: boolean) => {
    if (isFormDirty) {
      toggleBackDialog();
    } else {
      history.push(siteRoute);
    }
  }, [toggleBackDialog, siteRoute, history]);

  /** Whether loading is in action or not. */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [editedSiteDraft, setEditedSiteDraft] = useEditedSiteDraftContext();

  const onUnmount = useCallback((values: SiteEditingValues) => setEditedSiteDraft(values), [setEditedSiteDraft]);

  const { data: subscription } = useOrganizationSubscription(site?.organizationId.toString() ?? '', site?.isCommercial ?? false);

  const isSubscriptionAllowsSiteEditing = subscription?.isSubscriptionAllowsSiteEditing || false;

  const { data: subscriptionUpgradeInfo, error: subscriptionUpgradeInfoError } = useSubscriptionUpgradeInfo(
    {
      siteId,
      newSiteBedCount: newBedsCount,
    },
    site != null && site.isCommercial && !isAdmin && isSubscriptionAllowsSiteEditing,
  );

  useEffect(() => {
    if (site != null) {
      setNewBedsCount(site.countOfBeds);
    }
  }, [site]);

  if (roleIntakeSiteIds.includes(siteId) || roleStaffSiteIds.includes(siteId)) {
    return <Redirect to={ERoute.SITES_LIST} />;
  }

  if (error != null) {
    return <div>failed to load</div>;
  }
  if (site == null) {
    return <LoadingPage />;
  }

  const initSiteValues = editedSiteDraft !== null &&editedSiteDraft.id === site.id
      ? editedSiteDraft
      : SiteMapper.mapSiteToSiteEditionValues(site);

  const isUserCanEditRooms = site.isCommercial ? !roleGlobal : true;

  const handleUpdateSite = async (values: SiteEditingValues, setErrors: FormikHelpers<any>['setErrors']) => {
    try {
      setIsLoading(true);
      values.intakePeriods.long.map((item) => {
        if (item.enabled) {
          if (item.to == '' && item.from == '') {
            item.from = '09:00:00';
            item.to = '17:00:00';
          }
        }
        return item;
      });
      values.intakePeriods.short.map((item) => {
        if (item.enabled) {
          if (item.to == '' && item.from == '') {
            item.from = '09:00:00';
            item.to = '17:00:00';
          }
        }
        return item;
      });
      const updatedData = await handleUpdate(values, (errors: any) => {
        const newErrors = errorsTransform(errors);

        setErrors({
          ...newErrors,
        });
      });

      await mutate(updatedData, true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  const needToConfirmBedsChange =
      subscriptionUpgradeInfo?.changedSubscriptionCost != null &&
      subscriptionUpgradeInfo.oneTimePaymentAmount !== 0 ||
      subscriptionUpgradeInfo?.extraPaidBedCountDifference != null &&
      subscriptionUpgradeInfo.extraPaidBedCountDifference < 0;

  const handleSubmit = async (values: SiteEditingValues, formikHelpers: FormikHelpers<any>) => {
    if (site.isCommercial && needToConfirmBedsChange) {
      setIsPaymentDialogOpen(true);
    } else {
      await handleUpdateSite(values, formikHelpers.setErrors);
    }
  };

  const handleCountOfBedsChange = (values: SiteEditingValues) => {
    const currentCountOfBeds = getCountOfBedsFromSiteEditValues(values);
    setNewBedsCount(currentCountOfBeds);
  }

  const isLoadingSubscriptionUpgradeInfo = subscriptionUpgradeInfo == null;

  const isSaveButtonDisabled = site.isCommercial && isUserCanEditRooms && !isAdmin ?
    isLoading || isLoadingSubscriptionUpgradeInfo :
    isLoading;

  return (
    <Layout withSideBar>
      <Formik initialValues={initSiteValues} onSubmit={handleSubmit} validationSchema={getSiteEditValidationSchema(site.isCommercial, false)} >
        {({ submitForm, setValues, values, setErrors, dirty }) => (
          <>
          <MainHeadingContainer>
            <BackButton label="Back" onClick={() => handleCancelButtonClick(dirty)} />
            <MainHeading>Site details</MainHeading>
          </MainHeadingContainer>
          <BlockView mt={10} mb={10}>
            <SiteBlock site={site} coverImage={Image.getFirstOrLastImage(values.images, 'first') ?? undefined} />
          </BlockView>
          <Form>
            <FormikUnmountEffect onUnmount={onUnmount} />
            <AboutForm withoutMainHeader />
            <ContactsForm withoutMainHeader withoutAdminEmailField />
            <IntakePeriodsForm withoutMainHeader />
            <ServicesForm
              withoutMainHeader
              isCommercial={site.isCommercial}
            />
            {siteUpdateErrorMessage && <ErrorMessage message={siteUpdateErrorMessage} />}
            <RowView justifyContent="space-between">
              <RowView>
                <SmartButton disabled={isSaveButtonDisabled} onClick={submitForm} color="blue">
                  Save
                </SmartButton>
                <Gap />
                <ThemedButton
                  type="button"
                  outlined
                  onClick={() => handleCancelButtonClick(dirty)}
                  color="red"
                >
                  Cancel
                </ThemedButton>
              </RowView>
              <Gap />
              <ThemedButton type="button" width={270} outlined color="red" onClick={() => setModalOpen(true)}>
                Delete site
              </ThemedButton>
            </RowView>
          </Form>
          <ConfirmDialog
            onConfirmationButtonClick={() => history.push(siteRoute)}
            isOpened={isBackDialogOpen}
            onClose={toggleBackDialog}
            title={'Confirm'}
            confirmButtonVariant="contained"
          >
            Are you sure you want to leave this page? The changes you made will not be saved.
          </ConfirmDialog>
            {needToConfirmBedsChange && <BedsChangeConfirmDialog
              site={site}
              isOpen={isPaymentDialogOpen}
              onClose={() => setIsPaymentDialogOpen(false)}
              bedsCount={newBedsCount}
              subscriptionUpgradeInfo={subscriptionUpgradeInfo}
              errorMessage={subscriptionUpgradeInfoError?.message}
              onConfirmButtonClick={() => handleUpdateSite(values, setErrors)}
            />}
          </>

        )}
      </Formik>

      <DeleteSiteModal siteId={site.id} isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </Layout>
  );
};

export const EditSite = memo(EditSiteComponent);
