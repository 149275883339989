import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { GuestMethods, IGuestType } from 'api/guests/types';
import { DischargeDialog } from 'components/DischargeDialog/DischargeDialog';
import { Site } from 'models/site/Site';
import { Bed } from 'pages/SiteCreate/components/Rooms/components/Bed/domain/Bed';
import { Room } from 'pages/SiteCreate/components/Rooms/domain/Room';
import React, { useMemo, useState } from 'react';
import { FC } from 'react';
import { GuestsTableRow } from './GuestsTableRow';
import { SortableTableHeader } from 'components/SortableTableHeader/SortableTableHeader';
import { enumToArray } from 'utils/enumToArray';
import { GuestsTableSettings } from './GuestsTable.settings';
import { useTableSortParams } from 'utils/useTableSortParams';

interface DischargeDialogInfo {

  /** Guest who is selected for discharge. */
  readonly selectedGuest: IGuestType;

  /** Bed index of the selected guest. */
  readonly bedIndex: number;

  /** Name of the room of the selected guest. */
  readonly roomName: string;
}

interface Props {

  /** Guests which need to show in the table. */
  readonly guests: readonly IGuestType[];

  /** Beds available to the guests. */
  readonly beds: readonly Bed[];

  /** Rooms available to the guests. */
  readonly rooms: readonly Room[];

  /** Site id. */
  readonly siteId: Site['id'];
}

const GuestsTableComponent: FC<Props> = ({
  guests,
  rooms,
  beds,
  siteId,
}) => {
  const [
    selectedGuestInfo,
    setSelectedGuestInfo,
  ] = useState<DischargeDialogInfo | null>(null);

  const [isDischargeDialogOpen, setIsDischargeDialogOpen] = useState(false);

  /** Opens dialog window to discharge a guest. */
  const openDischargeDialog = (guest: IGuestType) => {
    const bed = beds.find((bed) => bed.id == guest.assignedBedId?.toString());
    const room = rooms.find((room) =>
      room.beds.find((bed) => bed.id === guest.assignedBedId?.toString())
    );

    if (bed == null || room == null) {
      return;
    }

    setSelectedGuestInfo({
      selectedGuest: guest,
      bedIndex: bed.index,
      roomName: room.name,
    });
    setIsDischargeDialogOpen(true);
  };

  /** Closes dialog window to discharge a guest. */
  const closeDischargeDialog = () => {
    setIsDischargeDialogOpen(false);
    setSelectedGuestInfo(null);
  };

  const { sortParams, handleSortChange } = useTableSortParams(GuestsTableSettings.INITIAL_SORT_PARAMS);

  const guestsWithRoomAndBed = useMemo(() => {
    return guests.map(guest => GuestMethods.findRoomAndBedOfGuest(guest, rooms))
  }, [guests]);

  const sortedGuestsWithRoomAndBed = useMemo(() => {
    return GuestsTableSettings.MAP_COLUMN_TO_SORT_FUNCTION[sortParams.columnName](guestsWithRoomAndBed, sortParams.direction)
  }, [guestsWithRoomAndBed, sortParams]);

  return (
    <TableContainer sx={{ overflowY: 'auto', maxHeight: '70vh' }}>
      <Table>
        <TableHead>
          <TableRow>
            {
              enumToArray(GuestsTableSettings.GuestsTableColumn).map(column => (
                <SortableTableHeader<GuestsTableSettings.GuestsTableColumn>
                  columnName={column}
                  currentDirection={sortParams.direction}
                  currentColumnName={sortParams.columnName}
                  onClick={handleSortChange}
                  key={column}
                >
                  {column}
                </SortableTableHeader>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedGuestsWithRoomAndBed.map((guestWithRoomAndBed) => {

            if (guestWithRoomAndBed.room == null || guestWithRoomAndBed.bed == null) {
              return;
            }

            return (
              <GuestsTableRow
                guest={guestWithRoomAndBed}
                room={guestWithRoomAndBed.room}
                bed={guestWithRoomAndBed.bed}
                key={guestWithRoomAndBed.id}
                onDischargeButtonClick={openDischargeDialog}
                siteId={siteId}
              />
            );
          })}
        </TableBody>
      </Table>
      {selectedGuestInfo && (
        <DischargeDialog
          isOpen={isDischargeDialogOpen}
          onClose={closeDischargeDialog}
          guest={selectedGuestInfo.selectedGuest}
          bedIndex={selectedGuestInfo.bedIndex}
          roomName={selectedGuestInfo.roomName}
        />
      )}
    </TableContainer>
  );
};

export const GuestsTable = GuestsTableComponent;
