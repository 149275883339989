import styled from '@emotion/styled'

export interface Props {
  top?: number
  left?: number
}

export const Margin = styled.div<Props>`
  margin-top: ${(props) => props.top}px;
  margin-left: ${(props) => props.left}px;
`
