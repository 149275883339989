import { OrganizationDto } from 'dtos/organizationDto/OrganizationDto';
import { useFetch } from 'hooks/api';
import { OrganizationMapper } from 'mappers/OrganizationMapper';
import { Organization } from 'models/organization/Organization';
import { OrganizationType } from '../../models/organization/OrganizationType';

export const useOrganizations = (organizationType?: OrganizationType) =>
  useFetch<Organization[], OrganizationDto[]>({
    url: `/api/Organizations${organizationType != null ? `?SiteType=${organizationType}` : ''}`,
    mapper: (data) => data.map((dto: OrganizationDto) => OrganizationMapper.fromDto(dto)),
  });
