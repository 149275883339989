/* eslint-disable @typescript-eslint/no-misused-promises */
import { IRequestTwoFactorToken } from 'api/auth/useChallengePhone/types'
import { useMutate } from 'hooks/api'

export const useChallengePhone = () => {
  const { mutate, isLoading, error } = useMutate<
    IRequestTwoFactorToken,
    IRequestTwoFactorToken,
    unknown
  >('post', {
    url: '/api/Users/phone/challenge',
    headers: {
      Accept: 'application/json',
    },
  })

  const challengePhone = (data: IRequestTwoFactorToken) => mutate(data)

  return { challengePhone, isLoading, error }
}
