import React, { FC, useState } from 'react'
import { useSiteDetails } from 'api/sites/useSiteDetails'
import { Layout } from 'components/Layout'
import { ThemedButton } from 'components/ThemedButton'
import { InviteUserForm } from 'forms/InviteUser'
import { Header } from 'forms/SignIn/styled'
import { LoadingPage } from 'pages/LoadingPage'
import { BtnIcon, Row } from 'pages/UserManagement/styled'
import { UsersTable } from 'pages/UserManagement/UsersTable'
import { useParams } from 'react-router-dom';
import { CancelLink } from 'components/CancelLink';
import { ERoute } from 'router/data';
import { SiteBlock } from 'components/SiteBlock/SiteBlock';
import { BlockView } from 'components/Layout/Grid';
import { MainHeading } from 'components/Typography';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow'
import { Site } from 'models/site/Site'

interface Props {
  /** Site id. */
  readonly siteId: Site['id'];
}

export const UserManagementPage: FC<Props> = () => {

  const params = useParams<Props>();

  const { data: site, error: siteError } = useSiteDetails(
    params.siteId
  )

  const [isInviteModalOpen, setIsInviteModalOpen] = useState<boolean>(false)

  if (siteError != null) {
    return <div>Failed to load</div>
  }

  if (site == null) {
    return <LoadingPage />
  }

  return (
    <Layout withSideBar>
      <Header>
        <CancelLink to={ERoute.USER_MANAGEMENT} />
      </Header>

      <MainHeading>User details</MainHeading>
      <BlockView mt={10} mb={10}>
        <SiteBlock site={site} />
      </BlockView>

      <Row>
        <ThemedButton
          p="11px 50px"
          wrapContent
          thinText
          onClick={() => setIsInviteModalOpen(true)}
        >
          <BtnIcon src="/assets/icons/plus.svg" alt="+" />
          Send invite
        </ThemedButton>
      </Row>
      <ModalWindow
        isOpen={isInviteModalOpen}
        onClose={() => setIsInviteModalOpen(false)}
      >
        <InviteUserForm
          selectedSite={site}
          closeModal={() => setIsInviteModalOpen(false)}
        />
      </ModalWindow>
      <UsersTable
        mt="8px"
        siteId={params.siteId}
      />
    </Layout>
  )
}
