import React, { FC, memo } from 'react';
import { ReservationRoomsContainer } from './styled';
import { ReservationRoomCard } from './components/ReservationRoomCard/ReservationRoomCard';
import { sortEntities } from '../../utils/sortEntities';
import { Room } from '../../pages/SiteCreate/components/Rooms/domain/Room';
import { Site } from '../../models/site/Site';
import { IGuestType } from '../../api/guests/types';

interface Props {

  /** Rooms. */
  readonly rooms: readonly Room[];

  /** Site. */
  readonly site: Site;

  /** Guest to assign. Pass undefined in case guest assignment should be unavailable. */
  readonly guest?: IGuestType;
}

const ReservationRequestRoomsComponent: FC<Props> = ({ rooms, site, guest }) => {
  return (
    <ReservationRoomsContainer>
      {sortEntities(rooms, 'name').map(
        room => <ReservationRoomCard room={room} site={site} key={room.id} guest={guest} />
      )}
  </ReservationRoomsContainer>
  )
}

export const ReservationRequestRooms = memo(ReservationRequestRoomsComponent);
