import { ButtonTypeMap } from '@mui/material';
import { StyledButton } from 'components/StyledButton/styled';
import React, { FC, PropsWithChildren } from 'react';
import { CommonDialogProps } from '../CommonDialogProps';
import { ModalWindow } from '../ModalWindow/ModalWindow';
import { DialogActionsContainer, DialogTitleText } from '../styled';
import { LoaderWrapper } from '../../Loader/styled';
import { Loader } from '../../Loader/Loader';

/** Props of confirm dialog component. */
export interface ConfirmDialogProps extends CommonDialogProps {
  /** Handle click on confirmation button click. */
  readonly onConfirmationButtonClick: () => void;

  /** Confirm button text. */
  readonly confirmButtonText?: string;

  /** Confirm button color. */
  readonly confirmButtonColor?: ButtonTypeMap['props']['color'];

  /** Variant of confirm button. */
  readonly confirmButtonVariant?: ButtonTypeMap['props']['variant'];

  /** Whether the confirm button is disabled. */
  readonly isConfirmButtonDisabled?: boolean;

  /** Whether should displays spinner which blocks content. */
  readonly shouldDisplaySpinner?: boolean;
}

/** Confirm dialog. */
export const ConfirmDialog: FC<PropsWithChildren<ConfirmDialogProps>> = ({
  isOpened,
  onClose,
  title,
  cancelButtonText,
  onConfirmationButtonClick,
  confirmButtonText,
  confirmButtonColor,
  children,
  isConfirmButtonDisabled,
  shouldDisplaySpinner,
  confirmButtonVariant,
}) => {
  return (
    <ModalWindow isScrollable isOpen={isOpened} onClose={onClose}>
      <DialogTitleText>{title ?? 'Confirm'}</DialogTitleText>
      {children}
      <DialogActionsContainer>
        <StyledButton onClick={onClose} variant="outlined">
          {cancelButtonText ?? 'Cancel'}
        </StyledButton>
        <StyledButton
          onClick={onConfirmationButtonClick}
          variant={confirmButtonVariant}
          color={confirmButtonColor ?? 'primary'}
          disabled={isConfirmButtonDisabled}
        >
          {confirmButtonText ?? 'Confirm'}
        </StyledButton>
      </DialogActionsContainer>
      {shouldDisplaySpinner && (
        <LoaderWrapper>
          <Loader
            size='large'
            isPrimary
          />
        </LoaderWrapper>
      )}
    </ModalWindow>
  );
};
