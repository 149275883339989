import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useUpdatePassword } from 'api/auth/useUpdatePassword/useUpdatePassword';
import { Layout } from 'components/Layout';
import { Margin } from 'components/Spacing';
import { ThemedButton } from 'components/ThemedButton';
import { CreatePasswordForm } from 'forms/CreatePassword';
import { IFormData } from 'forms/CreatePassword/data';
import {
  Footer,
  FormHeading,
  FormText,
  Header,
  StyledForm,
} from 'forms/SignIn/styled';
import { searchParams } from 'services/http';

export const ResetPassword = () => {
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { updatePassword, isLoading, error } = useUpdatePassword();
  const history = useHistory();

  const handleSubmit = async (values: IFormData) => {
    await updatePassword({
      newPassword: values.password,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      token: searchParams('token', location.search)!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      userId: searchParams('userId', location.search)!,
    });

    setIsPasswordChanged(true);
  };

  if (isPasswordChanged) {
    return (
      <Layout grayBg>
        <Header>
          <FormHeading>Access restored!</FormHeading>
          <FormText>You can sign in with new data.</FormText>
        </Header>
        <Margin top={50} />
        <Footer>
          <ThemedButton
            type="button"
            width="100%"
            onClick={() => {
              history.push('/login');
            }}
          >
            Open login
          </ThemedButton>
        </Footer>
      </Layout>
    );
  }

  return (
    <Layout grayBg>
      <CreatePasswordForm
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        error={error}
      />
    </Layout>
  );
};
