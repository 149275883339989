import { useFetch } from 'hooks/api';
import { PaginationMapper } from 'mappers/PaginationMapper';
import { MAP_ROLE_TO_DTO, OrganizationUserMapper } from 'mappers/UserMapper';
import { Pagination } from 'services/types';
import { User, UserRole } from 'models/user/User';
import { UserDto } from 'dtos/userDto/UserDto';
import { Site } from 'models/site/Site';

export const useSiteUsers = (
  siteId: Site['id'],
  role?: UserRole,
  shouldFetch = true,
) => {
  return useFetch<Pagination<User>, Pagination<UserDto>>({
    url: `/api/AccessUsers/siteScopeUsers?siteId=${siteId}${role != null ? `&Role=${MAP_ROLE_TO_DTO[role]}` : ''}`,
    mapper: data => PaginationMapper.fromDto(data, OrganizationUserMapper.fromDto),
  }, shouldFetch);
};
