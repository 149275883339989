import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { theme } from 'styles/theme'

export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
`
export const BedPhoto = styled.img`
  border-radius: 8px;
  width: 100%;
  height: 72px;
  margin: 16px 0;
  object-fit: cover;
`

export const BedBlockView = styled.div<{ last: boolean; clickable?: boolean }>`
  width: 180px;
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => (props.last ? 0 : '10px')};
  margin-bottom: 10px;

  ${(props) =>
    props.clickable &&
    css`
      cursor: pointer;
      border: 1px solid ${theme.colors.lightGray};
      border-radius: 8px;
      padding: 15px;
      padding-bottom: 0;
      width: 200px;

      &:hover {
        background: rgba(18, 25, 20, 0.02);
      }
    `};
`

export const BedDescBlock = styled.div`
  min-height: 40px;
  margin-top: 5px;
`
export const PhotoPlaceholder = styled.div`
  height: 72px;
  border-radius: 8px;
  margin: 16px 0;
  background: ${theme.colors.lightGray};
  display: flex;
  justify-content: center;
`
