import CSS from 'csstype'
import { path } from 'ramda'
import React, { FC, ReactElement } from 'react'
import { SpaceProps } from 'styled-system'
import { Header } from 'components/TableComponent/Header'
import { SpacedDiv } from 'components/TableComponent/styled'

export interface ITableColumn {
  name: string
  width: string
  sortable: boolean
}

export interface ITableComponentProps {
  columns: ITableColumn[]
  data: readonly any[]
  renderRow: (
    item: any,
    columnsTemplate?: CSS.Property.GridTemplateColumns,
  ) => ReactElement
  elementIdKey: string
}

export const TableComponent: FC<ITableComponentProps & SpaceProps> = ({
  columns,
  data,
  renderRow,
  elementIdKey,
  ...props
}) => {
  const columnTemplate = columns.reduce(
    (acc, column) => `${acc} ${column.width}`,
    ''
  )

  return (
    <SpacedDiv {...props}>
      <Header columns={columns} />
      {data.map((element) => {
        const elementId = path<string>(elementIdKey.split(/[.[\]]+/u), element)

        return (
          <React.Fragment key={elementId}>
            {renderRow(element, columnTemplate)}
          </React.Fragment>
        )
      })}
    </SpacedDiv>
  )
}
