import { PaymentMethodDto, PaymentMethodCategoryDto } from '../dtos/paymentMethodDto/PaymentMethodDto';
import { PaymentMethod, PaymentMethodCategory } from '../models/paymentMethod/PaymentMethod';

export const MAP_PAYMENT_METHOD_CATEGORY_FROM_DTO: Readonly<Record<PaymentMethodCategoryDto, PaymentMethodCategory>> = {
  [PaymentMethodCategoryDto.PrivatePay]: PaymentMethodCategory.PrivatePay,
  [PaymentMethodCategoryDto.Insurance]: PaymentMethodCategory.Insurance,
  [PaymentMethodCategoryDto.MediCal]: PaymentMethodCategory.MediCal,
};

/** Payment method mapper. */
export namespace PaymentMethodMapper {

  /** Maps dto to model. */
  export function fromDto(dto: PaymentMethodDto): PaymentMethod {
    return new PaymentMethod({
      id: dto.id,
      name: dto.reservationPaymentMethodName,
      category: MAP_PAYMENT_METHOD_CATEGORY_FROM_DTO[dto.reservationPaymentMethodCategory],
    });
  }
}
