import { BasePaymentDto } from './BasePaymentDto';

/** Payment dto state. */
export enum PaymentStateDto {
  InProgress = 'InProgress',
  Succeed = 'Succeed',
  Failed = 'Failed',
};

/** Payment dto. */
export interface PaymentDto extends BasePaymentDto {

  /** External message of the payment. */
  readonly externalMessage: string | null;

  /** Payment time. */
  readonly paidAt: string | null;

  /** State of payment. */
  readonly state: PaymentStateDto;
}
