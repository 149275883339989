import { InsuranceCompanyCreationValuesDto } from '../dtos/insuranceCompany/InsuranceCompanyCreationValuesDto';
import { InsuranceCompanyDto } from '../dtos/insuranceCompany/InsuranceCompanyDto';
import { InsuranceCompanyUpdateValuesDto } from '../dtos/insuranceCompany/InsuranceCompanyUpdateValuesDto';
import { InsuranceCompany } from '../models/insuranceCompany/InsuranceCompany';
import { InsuranceCompanyCreationValues } from '../models/insuranceCompany/InsuranceCompanyCreationValues';
import { InsuranceCompanyUpdateValues } from '../models/insuranceCompany/InsuranceCompanyUpdateValues';

/** Insurance company mapper. */
class InsuranceCompanyMapper {

  /** Maps dto to model. */
  public fromDto(dto: InsuranceCompanyDto): InsuranceCompany {
    return new InsuranceCompany({
      id: dto.id,
      name: dto.name,
      isPrimary: dto.isPrimary,
      logoPath: dto.imagePath,
    });
  };

  /**
   * Maps insurance company creation values to dto.
   * @param values Insurance company creation values.
   */
  public mapCreationValuesToDto(values: InsuranceCompanyCreationValues): InsuranceCompanyCreationValuesDto {
    return {
      name: values.name,
      isPrimary: values.isPrimary,
      imagePath: values.logoPath,
    };
  };

  /**
   * Maps insurance company update values to dto.
   * @param values Insurance company update values.
   */
  public mapUpdateValuesToDto(values: InsuranceCompanyUpdateValues): InsuranceCompanyUpdateValuesDto {
    return {
      id: values.id,
      name: values.name,
      isPrimary: values.isPrimary,
      imagePath: values.logoPath,
    };
  };
};

export const insuranceCompanyMapper = new InsuranceCompanyMapper;
