import React, { FC } from 'react';

export const Logo: FC = () => (
  <img
    src="/logo.svg"
    alt="rehab site admin logo"
    height={25}
    style={{ marginTop: '20px' }}
  />
);
