type GuestBedRoomInfoCreationData = GuestBedRoomInfo;


/** Information about the room in which the guest bed is located. */
export class GuestBedRoomInfo {

  /** Id. */
  public readonly id: number;

  /** Room name. */
  public readonly name: string;

  /** Image path. */
  public readonly imagePath: string | null;

  public constructor(data: GuestBedRoomInfoCreationData) {
    this.id = data.id;
    this.name = data.name;
    this.imagePath = data.imagePath;
  }
}

type GuestBedCreationData = GuestBed;

/** Guest bed. */
export class GuestBed {

  /** Id. */
  public readonly id: number;

  /** Image path. */
  public readonly imagePath: string | null;

  /** Bed type. */
  public readonly type: string;

  /** Index of the bed in the room. */
  public readonly index: number;

  /** Information about the room in which the bed is located. */
  public readonly room: GuestBedRoomInfo;

  public constructor(data: GuestBedCreationData) {
    this.id = data.id;
    this.imagePath = data.imagePath;
    this.type = data.type;
    this.room = data.room;
    this.index = data.index;
  }
}
