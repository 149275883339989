import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { theme } from 'styles/theme';

interface ModalProps {

  /** Whether modal should be scrollable. */
  readonly isScrollable?: boolean;
}

export const ModalContentWrapper = styled.div<ModalProps>`
  overflow-y: ${props => props.isScrollable ? 'auto' : 'visible'};
  max-width: 600px;
  max-height: 80vh;
  padding: 40px;
`;

export const ScrollableModalWrapper = styled(Box)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  max-height: 80vh;
  border-radius: 10px;
  background-color: ${theme.colors.white};
  overflow-x: visible;
`;

export const ModalCloseButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: none;
  background-color: rgba(255, 255, 255, 0.08);
  position: absolute;
  top: 0;
  right: -40px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }
`;
