import styled from '@emotion/styled';

export const RoomDeletionBox = styled.div`
  padding: 40px;
`;

export const RoomDeletionTitle = styled.h2`
  text-align: left;
  margin-bottom: 20px;
  padding: 0;
`;

export const RoomDeletionText = styled.p`
  margin-bottom: 20px;
`;
