import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'
import { InputField } from 'components/InputField'
import { UniversalSelectFields } from 'components/UniversalSelectFields'
import { theme } from 'styles/theme'

export const SubText = styled.p<SpaceProps>`
  font-size: 14px;
  margin: 0;
  line-height: 18px;
  color: ${theme.colors.grayText};
  ${space}
`

export const Highlight = styled.b`
  font-weight: 600;
  color: ${theme.colors.dark};
`

export const InputGridWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 115px;
`

export const NoMarginInputField = styled(InputField)`
  margin: 0;
`

export const Dropdown = styled(UniversalSelectFields)`
  height: auto;
  margin-bottom: auto;
`
