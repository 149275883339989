import { SubscriptionUpgradeInfoDto } from './../../dtos/Subscriptions/SubscriptionUpgradeInfoDto';
import { SubscriptionUpgradeInfo } from './../../models/Subscriptions/SubscriptionUpgradeInfo';
import { useFetch } from 'hooks/api';
import { SubscriptionUpgradeInfoMapper } from 'mappers/SubscriptionUpgradeInfoMapper';
import { SubscriptionUpgradeInfoCreationData } from '../../models/Subscriptions/SubscriptionUpgradeInfoCreationData';
import { SubscriptionUpgradeInfoCreationDataDto } from '../../dtos/Subscriptions/SubscriptionUpgradeInfoCreationDataDto';
import { SubscriptionUpgradeInfoCreationDataMapper } from '../../mappers/SubscriptionUpgradeInfoCreationDataMapper';

/**
 * Gets information about subscription upgrade.
 * @param data Data.
 * @param shouldFetch Whether should fetch or not.
 */
export const useSubscriptionUpgradeInfo = (data: SubscriptionUpgradeInfoCreationData, shouldFetch: boolean) =>
  useFetch<SubscriptionUpgradeInfo, SubscriptionUpgradeInfoDto, SubscriptionUpgradeInfoCreationDataDto>({
    url: `/api/Subscriptions/upgrade/calculate?SiteId=${data.siteId}&NewSiteBedCount=${data.newSiteBedCount}`,
    mapper: SubscriptionUpgradeInfoMapper.fromDto,
    method: 'POST',
    data: SubscriptionUpgradeInfoCreationDataMapper.toDto(data),
  },
  shouldFetch)
