/* eslint-disable react/jsx-handler-names */
import { Field } from 'formik'
import React, { FC } from 'react'

import {
  InputWrap,
  StyledInput,
  StyledLabel,
  StyledTextarea,
  Description,
} from 'components/InputField/styled'
import { FieldLabelRequiredMark } from 'components/FieldLabelRequiredMark/FieldLabelRequiredMark';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { EColors } from 'styles/theme';

export interface Props {

  /** Name. */
  readonly name: string;

  /** Label. */
  readonly label?: string;

  /** Placeholder */
  readonly placeholder?: string;

  /** Whether input is long. */
  readonly long?: boolean;

  /** Whether input disabled. */
  readonly disabled?: boolean;

  /** Description. */
  readonly description?: string;

  /** Type. */
  readonly type?: 'number' | 'text' | 'password' | 'date';

  /** Whether right side should be cut. */
  readonly isCutRight?: boolean;

  /** Handle blur event. */
  readonly onBlur?: () => void;

  /** Whether fields should be autofocused or not. */
  readonly shouldAutoFocus?: boolean;

  /** Indicates if required mark should be added to the label. */
  readonly isRequired?: boolean;
}

export const InputField: FC<Props> = ({
  name,
  label,
  long,
  placeholder,
  disabled,
  type = 'text',
  description,
  isCutRight,
  onBlur,
  shouldAutoFocus,
  isRequired,
  ...props
}) => (
  <InputWrap {...props}>
    <Field name={name}>
      {({ field, form: { touched, errors } }: any) => (
        <>
          {label && (
            <StyledLabel
              htmlFor={name}
              hasError={Boolean(touched[name] && errors[name])}
            >
              {label}
              {isRequired && <FieldLabelRequiredMark />}
            </StyledLabel>
          )}

          {long ? (
            <StyledTextarea
              id={name}
              name={name}
              rows={5}
              disabled={disabled}
              value={field.value}
              placeholder={placeholder}
              onChange={field.onChange}
              hasError={Boolean(touched[name] && errors[name])}
            />
          ) : (
            <StyledInput
              name={name}
              id={name}
              placeholder={placeholder}
              value={field.value}
              disabled={disabled}
              hasError={Boolean(touched[name] && errors[name])}
              onChange={field.onChange}
              type={type}
              isCutRight={isCutRight}
              onBlur={onBlur}
              autoFocus={shouldAutoFocus}
            />
          )}
          {description && <Description>{description}</Description>}
          <ValidationError
            message={touched[name] && errors[name]}
          />
        </>
      )}
    </Field>
  </InputWrap>
)
