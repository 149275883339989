import { FieldLabelRequiredMark } from 'components/FieldLabelRequiredMark/FieldLabelRequiredMark';
import { InputWrap, StyledLabel } from 'components/InputField/styled';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { Field, useField } from 'formik';
import React, { FC, memo, useState } from 'react';
import { StyledNumberFormat } from './styled';

interface Props {
  /** Field name. */
  readonly name: string;

  /** Label. */
  readonly label?: string;

  /** Indicates if required mark should be added to the label. */
  readonly isRequired?: boolean;
}

const PhoneInputComponent: FC<Props> = ({ name, label, isRequired }) => {
  const [field, meta, helpers] = useField(name);

  const { value } = field;
  const { touched, error } = meta;
  const { setValue } = helpers;

  return (
    <InputWrap>
      {label && (
        <StyledLabel htmlFor={name} hasError={Boolean(touched && error)}>
          {label}
          {isRequired && <FieldLabelRequiredMark />}
        </StyledLabel>
      )}
      <StyledNumberFormat
        value={value.slice(2)} // Slicing first 2 digits to remove '+1'.
        name={name}
        hasError={Boolean(touched && error)}
        type="tel"
        format="+1 (###) ###-####"
        mask="_"
        onValueChange={(value) => setValue(`+1${value.value}`)}
        allowEmptyFormatting={true}
      />
      {touched && <ValidationError message={error} />}
    </InputWrap>
  );
};

export const PhoneInput = memo(PhoneInputComponent);
