import { Site } from 'models/site/Site';
import { useAuth } from './useAuth';

/**
 * Checks whether the user is staff in site.
 * @param siteId Site ID.
 */
export function isUserSiteStaff(siteId: Site['id']): boolean {

  return useAuth().state.userData.roleStaffSiteIds.includes(siteId);
}
