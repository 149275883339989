import { GuestBedDto } from 'dtos/bed/GuestBedDto';
import { useFetch } from 'hooks/api';
import { GuestBedMapper } from 'mappers/GuestBedMapper';
import { GuestBed } from 'models/bed/GuestBed';

export const useGuestBed = (id: GuestBed['id'] | null) =>
  useFetch<GuestBed, GuestBedDto>(
    {
      url: `/api/Beds/${id}`,
      mapper: (data) => GuestBedMapper.fromDto(data),
    },
    id !== null
  );
