import { PaginationMetadataDto } from '../dtos/pagination/PaginationMetadataDto';
import { PaginationMetadata } from '../models/pagination/PaginationMetadata';

/** Mapper for pagination metadata. */
export namespace PaginationMetadataMapper {

  /** Maps dto to model. */
  export function fromDto(dto: PaginationMetadataDto): PaginationMetadata {
    return {
      totalCount: dto.totalCount,
      offset: dto.offset,
      limit: dto.limit,
      pageNumber: dto.page,
      pageSize: dto.pageSize,
      totalPages: dto.totalPages,
    }
  }
}
