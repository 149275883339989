import { SelectChangeEvent } from '@mui/material';
import React, { FC, memo, useEffect } from 'react';
import { useSavedPaymentMethods } from '../../api/Subscriptions/useSavedPaymentMethods';
import { Site } from '../../models/site/Site';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';
import { SubscriptionUpgradeInfo } from '../../models/Subscriptions/SubscriptionUpgradeInfo';
import { SavedPaymentMethodSelect } from '../SavedPaymentMethodSelect';
import { INITIAL_PAYMENT_METHOD_ID } from './BedsChangeConfirmDialog';
import { BedsDialogLine, BedsDialogText, ItemsTable, ItemsTableHeadingCell, ItemsTableItemCell, TotalPriceTableCell } from './styled';

interface Props {

  /** Site. */
  readonly site: Site;

  /** Count of beds */
  readonly bedsCount: number;

  /** Subscription upgrade info. */
  readonly subscriptionUpgradeInfo: SubscriptionUpgradeInfo;

  /** Selected payment method id. */
  readonly selectedPaymentMethodId: SavedPaymentMethod['id'];

  /** Handle payment method change. */
  readonly onPaymentMethodSelect: (selectedPaymentMethodId: SavedPaymentMethod['id']) => void;

  /** Handle new payment method. */
  readonly onNewPaymentMethod: () => void;
}

const AddedBedsDialogContentComponent: FC<Props> = ({
  site,
  subscriptionUpgradeInfo,
  bedsCount,
  selectedPaymentMethodId,
  onPaymentMethodSelect,
  onNewPaymentMethod,
}) => {

  const currentDate = new Date();
  const nextPaymentDate = subscriptionUpgradeInfo.nextMonthlyPaymentDate ?? new Date();

  const remainingDays = Math.ceil((nextPaymentDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));

  const { extraPaidBedCountDifference, changedSubscriptionCost, oneTimePaymentAmount } = subscriptionUpgradeInfo;

  const { data: savedPaymentMethods } = useSavedPaymentMethods(site.organizationId);

  useEffect(() => {
    if (savedPaymentMethods != null && selectedPaymentMethodId === INITIAL_PAYMENT_METHOD_ID) {
      onPaymentMethodSelect(savedPaymentMethods[0].id);
    }
  }, [savedPaymentMethods])

  const handlePaymentMethodSelect = (event: SelectChangeEvent<number>) => onPaymentMethodSelect(Number(event.target.value));

  // Rounding to 2 digits.
  const oneBedPrice = Math.round(oneTimePaymentAmount / extraPaidBedCountDifference * 100) / 100;

  return (
    <>
      <BedsDialogText>
        {`
          You have added ${extraPaidBedCountDifference} beds.
          Now you have ${bedsCount} beds.
        `}
        <br />
        {`
          The payment will be $${changedSubscriptionCost}/mo.
          from ${subscriptionUpgradeInfo.formattedNextMonthlyPaymentDate}
        `}
      </BedsDialogText>
      <BedsDialogLine />
      <BedsDialogText>
        {
          `Additional beds payment for the remaining days of current billing period (${remainingDays} days):`
        }
      </BedsDialogText>
      <ItemsTable>
        <thead>
          <tr>
            <ItemsTableHeadingCell textColor='primary' textAlignment='left'>Item</ItemsTableHeadingCell>
            <ItemsTableHeadingCell textColor='primary' textAlignment='right'>Qty</ItemsTableHeadingCell>
            <ItemsTableHeadingCell textColor='primary' textAlignment='right'>Price</ItemsTableHeadingCell>
          </tr>
        </thead>
        <tbody>
          <tr>
            <ItemsTableItemCell textColor='secondary' textAlignment='left'>{`1 bed for ${remainingDays} days`}</ItemsTableItemCell>
            <ItemsTableItemCell textColor='secondary' textAlignment='right'>{`x${extraPaidBedCountDifference}`}</ItemsTableItemCell>
            <ItemsTableItemCell textColor='primary' textAlignment='right'>{oneBedPrice}</ItemsTableItemCell>
          </tr>
        </tbody>
      </ItemsTable>
      <ItemsTable>
        <thead>
          <tr>
            <ItemsTableHeadingCell textColor='primary' textAlignment='right'>Total</ItemsTableHeadingCell>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TotalPriceTableCell textColor='primary' textAlignment='right'>{`$${oneTimePaymentAmount}`}</TotalPriceTableCell>
          </tr>
        </tbody>
      </ItemsTable>
      <BedsDialogLine />
      <SavedPaymentMethodSelect
        savedPaymentMethods={savedPaymentMethods ?? []}
        selectedPaymentMethodId={selectedPaymentMethodId}
        onSelect={handlePaymentMethodSelect}
        onNewPaymentMethod={onNewPaymentMethod}
      />
    </>
  )
}

export const AddedBedsDialogContent = memo(AddedBedsDialogContentComponent);
