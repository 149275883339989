import { useAuth } from "hooks/auth/useAuth";
import { Site } from "models/site/Site";

/**
 * Hook to check whether current user is site staff.
 * @param siteId Site id.
 */
export const useIsUserRoleStaff = (siteId?: Site['id']): boolean => {
  return siteId != null ?
    useAuth().state.userData.roleStaffSiteIds.includes(siteId) :
    false
}
