import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { InputField } from 'components/InputField';
import { Gap, RowView } from 'components/Layout/Grid';
import { FormSectionWrap } from 'components/Layout/Grid/FormSectionWrap';
import { MainHeading, SecondaryHeading } from 'components/Typography';
import { MultiPhotoUpload } from 'components/UploadPhoto/Multi';
import { IAboutForm } from 'services/types';
import { SiteFormBlockProps } from 'pages/SiteEdit';
import { ErrorMessage } from '../../../../components/FetchErrorMessage/FetchErrorMessage';
import { ImagePrefix } from '../../../../components/UploadPhoto/ImagePrefix';


export const AboutForm: FC<SiteFormBlockProps> = ({ withoutMainHeader }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<IAboutForm>();

  const { images: imagesError } = errors;
  const { images: isImagesTouched } = touched

  const imagesErrorMessage = imagesError && Array.isArray(imagesError) ? imagesError[0] : imagesError;

  return (
    <FormSectionWrap>
      {!withoutMainHeader && <MainHeading>Describe your site</MainHeading>}
      <SecondaryHeading>Basic info</SecondaryHeading>

      <RowView mt={10}>
        <InputField name="name" label="Name" placeholder="Enter name here" />
        <Gap />
        <InputField
          name="alias"
          label="Also known as"
          placeholder="Enter alternate name here"
        />
      </RowView>

      <InputField
        name="description"
        label="Description"
        placeholder="Enter description here"
        long
      />

      <MultiPhotoUpload
        initialImages={values.images}
        prefix={ImagePrefix.Organization}
        onChange={(val) => setFieldValue('images', val)}
      />
      {isImagesTouched && imagesErrorMessage && <ErrorMessage message={imagesErrorMessage} /> }
    </FormSectionWrap>
  );
};
