import React, { FC } from 'react';
import { Redirect, Route, RouteProps, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useAuth } from '../hooks/auth/useAuth';
import { ERoute, OrganizationTypeHref } from './data';
import { enumToArray } from 'utils/enumToArray';

interface Props {
  /** Page component of the route. */
  readonly component: RouteProps['component'];

  /** Path of the route. */
  readonly path: ERoute;
}

interface RouteParams {
  /** Organization type. */
  readonly organizationType?: OrganizationTypeHref;
}

/**
 * Allows paid and free organization pages for global role only.
 * In case of incorrect organization type param replaces it with all organizations.
 */
export const OrganizationsRoute: FC<Props> = ({ component, path }) => {

  const history = useHistory();

  const { userData, isAuthorized } = useAuth().state;

  const match = useRouteMatch<RouteParams>(ERoute.ORGANIZATIONS_LIST);

  const organizationType = match?.params.organizationType;

  if (!isAuthorized) {
    return <Redirect to={ERoute.HOMEPAGE} />;
  }

  if (
    organizationType != null &&
    organizationType !== OrganizationTypeHref.AllOrganizations &&
    (!enumToArray(OrganizationTypeHref).includes(organizationType) ||
    !userData.roleGlobal)
  ) {
    history.push(history.location.pathname.replace(organizationType, OrganizationTypeHref.AllOrganizations));
  }

  return <Route path={path} component={component} />;
};
