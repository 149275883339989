import React, { FC, memo } from 'react';
import { IntakePeriod, IntakePeriodType } from 'services/types';
import { enumToArray } from 'utils/enumToArray';
import { IntakePeriodsTableRow } from './IntakePeriodsTableRow';
import { StyledIntakePeriodsTable } from './styled';

interface Props {

  /** Intake periods. */
  readonly intakePeriods: readonly IntakePeriod[];
}

function getIntakePeriodRow(intakePeriods: readonly IntakePeriod[], periodType: IntakePeriodType) {
  const period = intakePeriods.find((period) => period.timePeriod === periodType);
  return period && <IntakePeriodsTableRow key={period.timePeriod} intakePeriod={period} />;
}

const IntakePeriodsTableComponent: FC<Props> = ({ intakePeriods }) => {
  return (
    <StyledIntakePeriodsTable>
      <tbody>{enumToArray(IntakePeriodType).map((periodType) => getIntakePeriodRow(intakePeriods, periodType))}</tbody>
    </StyledIntakePeriodsTable>
  );
};

export const IntakePeriodsTable = memo(IntakePeriodsTableComponent);
