import styled from '@emotion/styled';
import { TableCell } from '@mui/material';
import { theme } from 'styles/theme';

export const TableHeader = styled(TableCell)`
  font-weight: 600;
  color: ${theme.colors.semiLightGray};
  font-size: var(--font-size-xs);
  padding: 0;
  padding-block: var(--font-size-md);
`;

export const ReservationsTableCell = styled(TableCell)`
  --table-cell-padding: 10px;

  color: ${theme.colors.black};
  font-weight: 500;
  padding: 0;
  height: 64px;
  padding-right: var(--table-cell-padding);
  padding-block: var(--table-cell-padding);
  min-width: 120px;
`

export const ReservationsTableContentSmall = styled.p`
  font-size: var(--font-size-xs);
`

export const ReservationsTableContent = styled.p`
  font-size: var(--font-size-s);
`

export const CellButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: auto;
  min-width: 150px;
`

export const CellFlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const PhoneNumberTableCell = styled(ReservationsTableCell)`
  min-width: 120px;
`;
