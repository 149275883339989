import React, { FC, SyntheticEvent, memo, useCallback, useState } from 'react';
import { Layout } from '../../components/Layout';
import { PageHeaderLineContainer } from '../../components/PageHeaderLineContainer/PageHeaderLineContainer';
import { Tabs } from '@mui/material';
import { StyledTab } from '../../components/Tabs/Tabs';
import { TabPanel } from '../../components/TabPanel/TabPanel';
import { InternalLogs } from './components/InternalLogs/InternalLogs';
import { FailedLogs } from './components/FailedLogs/FailedLogs';
import { GuestsLogs } from './components/GuestsLogs/GuestsLogs';
import { PasswordChangeLogs } from './components/PasswordChangeLogs/PasswordChangeLogs';

const AuditLogPageComponent: FC = () => {

  const [currentTabIndex, setCurrentTabNumber] = useState<number>(0);
  const handleTabChange = useCallback((_: SyntheticEvent, newTabNumber: number) => {
    setCurrentTabNumber(newTabNumber);
  }, []);

  return (
    <Layout withSideBar>
      <PageHeaderLineContainer>
        <h1>Audit Log</h1>
      </PageHeaderLineContainer>
      <Tabs value={currentTabIndex} onChange={handleTabChange}>
        <StyledTab label="Internal" />
        <StyledTab label="Failed" />
        <StyledTab label="Guests" />
        <StyledTab label="Password" />
      </Tabs>
      <TabPanel index={0} value={currentTabIndex}>
        <InternalLogs />
      </TabPanel>
      <TabPanel index={1} value={currentTabIndex}>
        <FailedLogs />
      </TabPanel>
      <TabPanel index={2} value={currentTabIndex}>
        <GuestsLogs />
      </TabPanel>
      <TabPanel index={3} value={currentTabIndex}>
        <PasswordChangeLogs />
      </TabPanel>
    </Layout>
  )
}

export const AuditLogPage = memo(AuditLogPageComponent);
