import { Organization } from 'models/organization/Organization';
import { useAuth } from './useAuth';

/**
 * Checks whether the user is privileged in organization.
 * Privileged user can edit and delete organization, add, edit and delete sites,
 * see and edit insurances list, owners list, users list, payment details and credit cards.
 * @param organizationId Organization id.
 */
export function useIsUserPrivileged(organizationId: Organization['id']): boolean {
  const { roleGlobal, roleOwnerOrganizationIds } = useAuth().state.userData;

  return roleGlobal || roleOwnerOrganizationIds.includes(organizationId);
}
