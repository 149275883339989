import React, { useContext, useState } from 'react';
import { useChallengePhone } from 'api/auth/useChallengePhone/useChallengePhone';
import { useUpdatePhone } from 'api/auth/useUpdatePhone/useUpdatePhone';
import { Container } from 'components/ChangeUserSettingsPhone/styled';
import { InputPreview } from 'components/InputPreview';
import { EditPhoneForm } from 'forms/EditPhone';
import { IFormData as IEditPhoneFormData } from 'forms/EditPhone/data';
import { EditPhoneConfirmationForm } from 'forms/EditPhoneConfirmation';
import { IFormData } from 'forms/EditPhoneConfirmation/data';
import { useAuth } from 'hooks/auth/useAuth';
import { FlashMessagesContext } from 'hooks/flash/Context';

export const ChangeUserSettingsPhone = () => {
  const [newPhone, setNewPhone] = useState('');
  const {
    actions: { showInfo },
  } = useContext(FlashMessagesContext);
  const { challengePhone, isLoading: isChallenging } = useChallengePhone();
  const { updatePhone, isLoading: isUpdating, error } = useUpdatePhone();
  const {
    state: { userData },
  } = useAuth();
  const [step, setStep] = useState(1);
  const {
    actions: { refetchUserInfo },
  } = useAuth();

  const goNextStep = () => {
    setStep((step) => step + 1);
  };

  const goStepBack = () => {
    setStep((step) => step - 1);
  };

  const handleStartEdit = () => {
    goNextStep();
  };

  const handleEditPhone = async (data: IEditPhoneFormData) => {
    setNewPhone(data.phone);
    await challengePhone(data);
    goNextStep();
  };

  const handleEditPhoneConfirmation = async (data: IFormData) => {
    await updatePhone({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      phone: newPhone!,
      token: data.code,
    });
    await refetchUserInfo();
    setStep(1);
    showInfo('Phone has been updated');
  };

  return (
    <Container>
      {step === 1 && (
        <InputPreview
          label="Phone"
          text={userData.phoneNumber}
          onEdit={handleStartEdit}
        />
      )}
      {step === 2 && (
        <EditPhoneForm
          handleCancel={goStepBack}
          handleSubmit={handleEditPhone}
          isLoading={isChallenging}
        />
      )}
      {step === 3 && (
        <EditPhoneConfirmationForm
          handleCancel={goStepBack}
          handleSubmit={handleEditPhoneConfirmation}
          isLoading={isUpdating}
          phone={newPhone}
          errorText={error ? 'Invalid confirmation code' : undefined}
        />
      )}
    </Container>
  );
};
