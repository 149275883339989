import { useAuth } from 'hooks/auth/useAuth';
import { Organization } from 'models/organization/Organization';
import { Site } from 'models/site/Site';
import { routes } from 'router/data';

/**
 * @param organizationId Organization id.
 * @param siteId Site id.
 * @returns Route to site details in case if user has role owner or global, route to reservations otherwise.
 */
export const useSiteBackButtonRoute = (organizationId: Organization['id'], siteId: Site['id']) => {
  const { roleOwnerOrganizationIds, roleGlobal } = useAuth().state.userData;

  const shouldBackToSiteDetails = roleGlobal || roleOwnerOrganizationIds.includes(organizationId);

  return shouldBackToSiteDetails
  ? routes.OrganizationSiteDetails(organizationId, siteId)
  : routes.ReservationDetails(siteId);
};
