import styled from '@emotion/styled';

export const UserSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const UserContactInformation = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
