/**
 * Parses 24 hours format time string to 12 hours format.
 * @param time Time string to parse. Must have 'hh:mm:ss' format.
 * @returns 'hh:mm' in 12 hours format.
 */
export function parseTimeString(time: string) {
  let prefix = 'AM';
  let hours = Number(time.slice(0, 2));
  const minutes = Number(time.slice(3, 5));
  if (isNaN(hours) || isNaN(minutes)) {
    throw new Error(`Could not parse time string ${time}. The time must have 'hh:mm:ss' format.`);
  }
  if (hours >= 12) {
    prefix = 'PM';
    hours -= 12;
  }
  const minutesString = `${minutes}${minutes === 0 ? '0' : ''}`
  return `${hours === 0 ? 12 : hours}:${minutesString} ${prefix}`;
}
