import { Language } from 'api/languages/types';
import { Service } from 'models/service';
import { Room } from 'pages/SiteCreate/components/Rooms/domain/Room';
import { IntakePeriod } from 'services/types';
import { StrictOmit } from '../../utils/types/strictOmit';
import { InsuranceCompany } from '../insuranceCompany/InsuranceCompany';
import { PaymentMethod } from '../paymentMethod/PaymentMethod';
import { ScreeningPeriod } from '../screeningPeriod/ScreeningPeriod';
import { Image } from 'models/image/Image.';

type SiteCreationData = StrictOmit<Site, 'countOfBeds' | 'countOfVacantBeds' | 'coverImage'>;

/** Site. */
export class Site {

  /** Id. */
  public readonly id: string;

  /** Name. */
  public readonly name: string;

  /** Alias. */
  public readonly alias: string | null;

  /** Organization id. */
  public readonly organizationId: number;

  /** Contact web address. */
  public readonly contactWeb: string;

  /** Contact phone number. */
  public readonly contactPhone: string;

  /** Contact email. */
  public readonly contactEmail: string;

  /** Address of the site location. */
  public readonly address: string;

  /** Min length of stay. */
  public readonly lengthOfStayDaysMin: number | null;

  /** Max length of stay. */
  public readonly lengthOfStayDaysMax: number | null;

  /** Screening period. */
  public readonly screeningPeriod: ScreeningPeriod;

  /** Description. */
  public readonly description: string;

  /** Shows if days of week should be shown for intake periods. */
  public readonly showDaysOfWeek: boolean;

  // TODO(Maxim K.): add readonly to arrays and fix typing issues.
  /** Intake periods. */
  public readonly intakePeriods: IntakePeriod[];

  /** Rooms. */
  public readonly rooms: Room[];

  /** Languages. */
  public readonly languages: Language[];

  /** Services. */
  public readonly services: Service[];

  /** Site images. */
  public readonly images: readonly Image[];

  /** Whether the site is commercial. */
  public readonly isCommercial: boolean;

  /** Payment methods organization of site supports. */
  public readonly organizationReservationPaymentMethods: readonly PaymentMethod[];

  /** Insurance companies organization of site supports. */
  public readonly insuranceCompanies: readonly InsuranceCompany[];

  public constructor(data: SiteCreationData) {
    this.id = data.id;
    this.name = data.name;
    this.alias = data.alias;
    this.organizationId = data.organizationId;
    this.contactWeb = data.contactWeb;
    this.contactPhone = data.contactPhone;
    this.contactEmail = data.contactEmail;
    this.address = data.address;
    this.lengthOfStayDaysMin = data.lengthOfStayDaysMin;
    this.lengthOfStayDaysMax = data.lengthOfStayDaysMax;
    this.screeningPeriod = data.screeningPeriod;
    this.description = data.description;
    this.showDaysOfWeek = data.showDaysOfWeek;
    this.intakePeriods = data.intakePeriods;
    this.rooms = data.rooms;
    this.languages = data.languages;
    this.services = data.services;
    this.images = data.images;
    this.isCommercial = data.isCommercial;
    this.organizationReservationPaymentMethods = data.organizationReservationPaymentMethods;
    this.insuranceCompanies = data.insuranceCompanies;
  }

  /** Total count of beds in site. */
  public get countOfBeds(): number {
    return this.rooms.reduce((sum, room) => sum + room.bedsCount, 0);
  }

  /** Total count of vacant beds in site. */
  public get countOfVacantBeds(): number {
    return this.rooms.reduce((sum, room) => sum + room.vacantBedsCount, 0);
  }

  /** Cover image of site.  */
  public get coverImage(): Image | null {
    return Image.getFirstOrLastImage(this.images, 'first');
  }
}
