/* eslint-disable @typescript-eslint/no-misused-promises */
import { IResetPasswordData } from 'api/auth/useResetPassword/types'
import { useMutate } from 'hooks/api'

export const useResetPassword = () => {
  const { mutate, isLoading, error } = useMutate<unknown, unknown, IResetPasswordData>(
    'post',
    {
      url: '/api/Users/password/reset',
      headers: {
        Accept: 'application/json',
      },
    }
  )

  const resetEmail = (email: string) =>
    mutate({
      email,
      redirectUri: `${location.origin}/reset-password`,
    })

  return { resetEmail, isLoading, error }
}
