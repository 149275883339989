import { Gap, RowView } from 'components/Layout/Grid';
import { Formik } from 'formik';
import React, { FC, memo, useCallback } from 'react';
import { InputField } from 'components/InputField';
import { StyledButton } from 'components/StyledButton/styled';
import { useCreateOrganization } from 'api/organizations/useCreateOrganization';
import { ModalFormHeading, ModalFormWrapper } from '../styled';
import { object, string } from 'yup';
import { LogoUpload } from '../../../../../components/LogoUplaoder/LogoUploader';
import { OrganizationCreateValues } from 'models/organization/OrganizationCreateValues';
import { ImagePrefix } from '../../../../../components/UploadPhoto/ImagePrefix';

interface Props {
  /** On done callback. */
  readonly onDone: () => void;

  /** On cancel callback. */
  readonly onCancel: () => void;
}

const validationSchema = object().shape({
  name: string()
    .required('Organization name is a required field.'),
    ownerEmail: string()
    .required('Owner email is a required field.')
    .email('Invalid email.'),
  logoPath: string()
    .required('Please add a logo to organization.')
    .nullable(),
});

const initialValues: OrganizationCreateValues = {
  name: '',
  ownerEmail: '',
  logoPath: '',
};

const CreateOrganizationFormComponent: FC<Props> = ({ onDone, onCancel }) => {
  const { handleCreate: createOrganization } = useCreateOrganization(location.origin);

  const handleSubmit = async (values: OrganizationCreateValues) => {
    await createOrganization(values);
    onDone();
  };

  /**
   * Checks if all the values needed for organization creation are provided.
   * @param values Organization creation values.
   */
  const areValuesEmpty = useCallback((values: OrganizationCreateValues): boolean => {
    return values.ownerEmail === '' || values.logoPath === '' || values.name === '';
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnMount={true}
    >
      {({ submitForm, resetForm, isSubmitting, values, setFieldValue, errors, touched }) => (
        <ModalFormWrapper>
          <ModalFormHeading>Create New Organization</ModalFormHeading>
          <LogoUpload
            prefix={ImagePrefix.Organization}
            onChange={(value) => setFieldValue('logoPath', value)}
            initImageUrl={values.logoPath}
            errorMessage={touched['logoPath'] ? errors.logoPath : undefined}
            isRequired
          />
          <RowView>
            <InputField name="name" label="Organization Name" placeholder="Organization Name" isRequired />
          </RowView>
          <RowView>
            <InputField name="ownerEmail" label="Owner Email" placeholder="Owner Email" isRequired />
          </RowView>
          <RowView alignItems="flex-start" justifyContent="center" style={{ marginTop: '20px' }}>
            <StyledButton
              variant="outlined"
              onClick={() => {
                resetForm();
                onCancel();
              }}
              disabled={isSubmitting}
              width="50%"
            >
              Cancel
            </StyledButton>
            <Gap />
            <StyledButton
              variant="contained"
              onClick={submitForm}
              disabled={isSubmitting || areValuesEmpty(values)}
              width="50%"
            >
              Create Organization
            </StyledButton>
          </RowView>
        </ModalFormWrapper>
      )}
    </Formik>
  );
};

export const CreateOrganizationForm = memo(CreateOrganizationFormComponent);
