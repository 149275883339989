import { ResubscribeInfoDto } from './../../dtos/Subscriptions/ResubscribeInfoDto';
import { ResubscribeInfo } from './../../models/Subscriptions/ResubscribeInfo';
import { useFetch } from 'hooks/api';
import { ResubscribeInfoMapper } from 'mappers/ResubscriptionPaymentInfoMapper';
import { SubscriptionRecreationData } from '../../models/Subscriptions/SubscriptionRecreationData';
import { SubscriptionRecreationDataDto } from '../../dtos/Subscriptions/SubscriptionRecreationDataDto';
import { SubscriptionRecreationDataMapper } from '../../mappers/SubscriptionRecreationDataMapper';

/**
 * Gets information about possible subscription recreation.
 * @param data Recreation data needed to get the info.
 * @param shouldFetch Whether should fetch.
 */
export const useResubscribeInfo = (data: SubscriptionRecreationData, shouldFetch = true) =>
  useFetch<ResubscribeInfo, ResubscribeInfoDto, SubscriptionRecreationDataDto>({
    url: `/api/Subscriptions/recreate/calculate?subscriptionOptionId=${data.subscriptionOptionId}&organizationId=${data.organizationId}`,
    mapper: ResubscribeInfoMapper.fromDto,
    method: 'POST',
    data: SubscriptionRecreationDataMapper.toDto(data),
  },
  shouldFetch)
