import styled from '@emotion/styled';
import { TableCell, TableContainer, TableRow } from '@mui/material';
import { theme } from 'styles/theme';

export const InsuranceTableContainer = styled(TableContainer)`
  overflow-y: auto;
  max-height: 70%;
  width: 100%;
`;

interface RowProps {

  /** Whether is hovering the row. */
  readonly isHovering: boolean;
};

export const InsuranceTableRow = styled(TableRow)<RowProps>`
  background-color: ${props => props.isHovering ? theme.colors.lightGray : theme.colors.white};
  word-break: 'break-word';
`;

export const InsurancesTableCell = styled(TableCell)`
  color: ${theme.colors.black};
  font-weight: 500;
  padding: 10px;
  padding-left: 0;
  height: 64px;
`;
