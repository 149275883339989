import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { IThemedButtonProps, ThemedButton } from 'components/ThemedButton'
import { ERoute } from 'router/data'

interface Props {
  to?: ERoute | string
  onClick?: VoidFunction
}

export const SmartButton: FC<Props & IThemedButtonProps> = ({
  to,
  onClick,
  children,
  ...props
}) => {
  if (to) {
    return (
      <Link to={to}>
        <ThemedButton {...props}>{children}</ThemedButton>
      </Link>
    )
  }

  return (
    <ThemedButton onClick={onClick} {...props}>
      {children}
    </ThemedButton>
  )
}
