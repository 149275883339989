import { OrganizationDto } from 'dtos/organizationDto/OrganizationDto';
import { useFetch } from 'hooks/api';
import { OrganizationMapper } from 'mappers/OrganizationMapper';
import { Organization } from 'models/organization/Organization';

export const useOrganization = (organizationId: Organization['id'], shouldFetch = true) =>
  useFetch<Organization, OrganizationDto>({
    url: `/api/Organizations/${organizationId}`,
    mapper: (data) => OrganizationMapper.fromDto(data),
  }, shouldFetch);
