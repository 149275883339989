import { IUpdateEmailData } from 'api/auth/useUpdateEmail/types'
import { useMutate } from 'hooks/api'

export const useUpdateEmail = () => {
  const { mutate, isLoading, error } = useMutate<undefined, undefined, IUpdateEmailData>(
    'post',
    {
      url: '/api/Users/email/challenge',
      headers: {
        Accept: 'application/json',
      },
    }
  )

  const updateEmail = (email: string) =>
    mutate({
      email,
      redirectUri: `${location.origin}/user-settings`,
    })

  return { updateEmail, isLoading, error }
}
