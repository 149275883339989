import { Form, Formik } from 'formik';
import React, { FC } from 'react';
import { ErrorTooltip } from 'components/ErrorTooltip';
import { InputField } from 'components/InputField';
import { Label, Text } from 'components/InputPreview/styled';
import { Margin } from 'components/Spacing';
import { ThemedButton } from 'components/ThemedButton';
import { ControlsContainer, InputWrap } from 'forms/EditPhone/styled';
import {
  initialValues,
  validationSchema,
} from 'forms/EditPhoneConfirmation/data';
import { Props } from 'forms/EditPhoneConfirmation/types';
import { ButtonContainer } from 'forms/SignIn/styled';

export const EditPhoneConfirmationForm: FC<Props> = ({
  handleSubmit,
  isLoading,
  phone,
  handleCancel,
  errorText,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmit}
    validationSchema={validationSchema}
  >
    {({ submitForm }) => (
      <Form>
        <Label>Confirmation code</Label>
        <Margin top={12} />
        <Text>
          A message with your confirmation code has been sent to {phone}.<br />
          Provide the confirmation code below:
        </Text>
        <Margin top={12} />
        <InputWrap>
          <InputField name="code" type="text" placeholder="" />

          {errorText && <ErrorTooltip isOpen text={errorText} isRight />}
        </InputWrap>
        <Margin top={10} />
        <ControlsContainer>
          <ButtonContainer>
            <ThemedButton
              disabled={isLoading}
              type="submit"
              width="100%"
              onClick={submitForm}
              isControl
            >
              Continue
            </ThemedButton>
          </ButtonContainer>
          <Margin left={25} />
          <ThemedButton
            disabled={isLoading}
            type="button"
            width="100%"
            onClick={handleCancel}
            isControl
            outlined
          >
            Cancel
          </ThemedButton>
        </ControlsContainer>
      </Form>
    )}
  </Formik>
);
