import styled from '@emotion/styled'

export const FormSectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  max-height: max-content;
  max-width: 100%;
  padding-right: 24px;
`
