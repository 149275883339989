import styled from '@emotion/styled';
import { StyledButton } from '../../StyledButton/styled';
import { theme } from '../../../styles/theme';

export const ImageUploaderButton = styled(StyledButton)`
  color: ${theme.colors.semiLightRed};

  &:hover {
    border-color: ${theme.colors.semiLightRed};
  }

  @media print {
    display: none;
  }
`;
