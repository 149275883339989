import FormikWizard from 'formik-wizard';
import React, { FC, useMemo } from 'react';
import { useCreateSite } from 'api/sites/useCreateSite';
import { Layout } from 'components/Layout';
import { Gap } from 'components/Layout/Grid';
import { ThemedButton } from 'components/ThemedButton';
import { WizardSideNavigation } from 'pages/SiteCreate/components/WizardSideNavigation';
import { getSiteCreationSteps } from 'pages/SiteCreate/steps';
import {
  ButtonsWrap,
  FormContentWrap,
  FormWrap,
  SideBarWrap,
} from 'pages/SiteCreate/styled';
import { SiteCreationValues } from 'models/site/SiteCreationValues';
import { FormikWizardWrapperProps } from 'formik-wizard/dist/types';
import { ValidationError } from 'components/ValidationError/ValidationError';
import { RouteComponentProps } from 'react-router-dom';
import { routes } from 'router/data';
import { useOrganization } from '../../api/organizations/useOrganization';
import { Organization } from '../../models/organization/Organization';

type FormWrapperProps = FormikWizardWrapperProps<SiteCreationValues> & Pick<Organization, 'isCommercial'>;

const FormWrapper: FC<FormWrapperProps> = ({
  children,
  isLastStep,
  status,
  goToPreviousStep,
  canGoBack,
  currentStep,
  isCommercial,
}) => {

  const steps = useMemo(() => getSiteCreationSteps(isCommercial), [isCommercial]);

  return (
    <FormWrap>
      <SideBarWrap>
        <WizardSideNavigation
          steps={steps}
          currentStep={currentStep}
          status={status}
        />
      </SideBarWrap>

      <FormContentWrap>
        {/* The form */}
        {children}
        <ValidationError message={status} isCentered />
      </FormContentWrap>
      <ButtonsWrap>
        {canGoBack && (
          <ThemedButton
            width="50%"
            type="button"
            onClick={goToPreviousStep}
            outlined
          >
            Previous Step
          </ThemedButton>
        )}
        <Gap />
        <ThemedButton width="50%" type="submit">
          {isLastStep ? 'Save changes' : 'Next'}
        </ThemedButton>
      </ButtonsWrap>
    </FormWrap>
  );
}

interface RouteProps {

  /** Organization id. */
  readonly organizationId: string;
}

// TODO: refactor
// eslint-disable-next-line react/no-multi-comp
export const CreateSite: FC<RouteComponentProps<RouteProps>> = ({ match }) => {

  const { organizationId } = match.params;

  const { data: organization } = useOrganization(organizationId);

  const { handleCreate } = useCreateSite(
    location.origin,
    organization?.isCommercial,
    routes.OrganizationDetails(organizationId)
  );

  const handleSubmit = React.useCallback(
    (values: SiteCreationValues) =>
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      new Promise((resolve: (val: any) => void) => {

        const siteCreationValues: SiteCreationValues = {
          ...values,
          intakePeriods: {
            showDaysOfWeek: values.intakePeriods.showDaysOfWeek,
            intakePeriods: {
              short: values.intakePeriods.intakePeriods.short.map((item) => {
                if (item.enabled) {
                  if (item.to == '' && item.from == '') {
                    item.from = '09:00:00';
                    item.to = '17:00:00';
                  }
                }
                return item;
              }),
              long: values.intakePeriods.intakePeriods.long.map((item) => {
                if (item.enabled) {
                  if (item.to == '' && item.from == '') {
                    item.from = '09:00:00';
                    item.to = '17:00:00';
                  }
                }
                return item;
              }),
            }
          },
          accomodations: organization?.isCommercial ? {
            rooms: [],
          } : values.accomodations,
        }

        handleCreate(siteCreationValues, errors => {
          const errorMessage = errors[0].messages[0];
          resolve(errorMessage);
        });
      }),
    [handleCreate]
  );

  const steps = useMemo(() => getSiteCreationSteps(organization?.isCommercial ?? false), [organization?.isCommercial]);

  if (organization == null) {
    return <p>Loading...</p>;
  };

  return (
    <Layout>
      <FormikWizard
        steps={steps}
        onSubmit={handleSubmit}
        render={props => <FormWrapper {...props} isCommercial={organization.isCommercial} />}
      />
    </Layout>
  );
};
