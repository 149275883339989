import styled from '@emotion/styled'
import React, { FC } from 'react'
import { GenderIcon } from 'components/GenderIcon'
import { EGender } from 'services/types'

const Centering = styled.div`
  display: flex;
  align-items: center;
`

export interface IGenderCellProps {
  gender: EGender
}

export const GenderCell: FC<IGenderCellProps> = ({ gender, ...props }) => (
  <Centering {...props}>
    <GenderIcon gender={gender} />
  </Centering>
)
