import { useGuest } from 'api/guests';
import { useSiteDetails } from 'api/sites/useSiteDetails';
import { CancelLink } from 'components/CancelLink';
import { MainHeading } from 'components/GuestInfo/styled';
import { Layout } from 'components/Layout';
import { SecondaryHeading } from 'components/Typography';
import { LoadingPage } from 'pages/LoadingPage';
import React from 'react';
import { FC } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { ReservationBedsHeader, RoomAndBeds, VacantBedsCount } from './styled';
import { ReservationRequestRooms } from '../../components/ReservationRequestRooms/ReservationRequestRooms';
import { routes } from 'router/data';

interface RouteProps {
  readonly guestId: string;
}

export const ReservationRequestPage: FC<RouteComponentProps<RouteProps>> = ({ match }) => {
  // TODO(Maxim K.): Refactor
  let guestId;
  try {
    guestId = parseInt(match.params.guestId);
  } catch (error: unknown) {
    return <Redirect to="" />;
  }

  const { data: guest, error: guestError } = useGuest(guestId);

  const { data: site, error: siteError } = useSiteDetails(guest?.siteId || '', guest != null);

  if (guest?.id == null || site == null) {
    return <LoadingPage />;
  }

  if (guestError != null || siteError != null) {
    return <div>Error</div>;
  }

  const { rooms } = site;

  const backButtonRoute = routes.OrganizationSiteDetails(site.organizationId.toString(), site.id);

  return (
    <Layout withSideBar>
      <CancelLink to={backButtonRoute} label="Reservations & Guests" />
      <MainHeading>Reservation Request</MainHeading>

      <RoomAndBeds>
        <ReservationBedsHeader>
          <SecondaryHeading withoutMargin>Beds</SecondaryHeading>
          <VacantBedsCount>{`${site.countOfVacantBeds} vacant`}</VacantBedsCount>
        </ReservationBedsHeader>
        <ReservationRequestRooms rooms={rooms} site={site} guest={guest} />
      </RoomAndBeds>
    </Layout>
  );
};
