import React, { FC } from 'react'
import { useLanguages } from 'api/languages'
import { UniversalSelectFields } from 'components/UniversalSelectFields'

interface Props {
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
}

export const LangugePicker: FC<Props> = (props) => {
  const { data } = useLanguages()

  if (!data) {
    return null
  }

  const options = data.map((option) => ({
    value: option.id,
    label: option.name,
  }))

  return <UniversalSelectFields {...props} options={options} multiSelect />
}
