import React, { FC, memo } from 'react';
import { BackButtonContainer } from './styled';
import { ArrowCircleLeftOutlined } from '@mui/icons-material';

interface Props {

  /** On click handler. */
  readonly onClick: () => void;

  /** Label. */
  readonly label?: string;
}

const BackButtonComponent: FC<Props> = ({ onClick, label = 'Back' }) => {

  return (
    <BackButtonContainer type="button" onClick={onClick}>
      <ArrowCircleLeftOutlined />
      {label}
    </BackButtonContainer>
  )
}

export const BackButton = memo(BackButtonComponent);
