import React, { FC } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from 'hooks/auth/useAuth'
import { ERoute } from 'router/data'

interface Props {

  /** Page component of the route. */
  readonly component: RouteProps['component'];

  /** Path of the route. */
  readonly path: ERoute;
}

export const RoleGlobalRoute: FC<Props> = ({ component, path }) => {

  const { roleGlobal: isRoleGlobal } = useAuth().state.userData;

  if (!isRoleGlobal) {
    return <Redirect to={ERoute.HOMEPAGE} />
  }

  return <Route path={path} component={component} />
}
