import { object, ref, string } from 'yup'

export const initialValues = {
  password: '',
  passwordConfirmation: '',
}

export const validationSchema = object().shape({
  password: string()
    .min(8, 'Password should have at least 8 characters')
    .matches(/(?=.*[A-Z])/u, 'Password must have at least one capital letter')
    .matches(/(?=.*[0-9])/u, 'Password must have at least one number')
    .matches(/[\W]|_/, 'Passwords must have at least one non alphanumeric character')
    .matches(/[a-z]/, 'Password must have at least one lowercase letter')
    .required('New password is a required field'),
  passwordConfirmation: string()
    .oneOf([ref('password'), undefined], 'Passwords must match')
    .required('Confirm new password is a required field'),
})

export type IFormData = typeof initialValues
