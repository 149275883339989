import { PaymentDto } from '../../dtos/Subscriptions/PaymentDto';
import { PaymentInfoDto } from '../../dtos/Subscriptions/PaymentInfoDto';
import { ResubscribePaymentInfoDto } from '../../dtos/Subscriptions/ResubscribePaymentInfoDto';
import { PaymentInfoMapper } from '../../mappers/PaymentInfoMapper';
import { PaymentMapper } from '../../mappers/PaymentMapper';
import { ResubscribePaymentInfoMapper } from '../../mappers/ResubscribePaymentInfoMapper';
import { SubscriptionRecreationDataMapper } from '../../mappers/SubscriptionRecreationDataMapper';
import { Site } from '../../models/site/Site';
import { Payment } from '../../models/Subscriptions/Payment';
import { PaymentInfo } from '../../models/Subscriptions/PaymentInfo';
import { ResubscribePaymentInfo } from '../../models/Subscriptions/ResubscribePaymentInfo';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';
import { SubscriptionRecreationData } from '../../models/Subscriptions/SubscriptionRecreationData';
import { apiClient } from '../../services/api/apiClient';

interface UpgradeSubscriptionData {

  /** New count of beds. */
  readonly newSiteBedCount: number;

  /** Site id. */
  readonly siteId: Site['id'];

  /** Selected saved payment method id. */
  readonly savedPaymentMethodId: SavedPaymentMethod['id'];
}

/** Subscription service. */
export namespace SubscriptionService {

  /**
   * Upgrades subscription.
   * @param upgradeData Upgrade data.
   */
  export async function upgradeSubscription(upgradeData: UpgradeSubscriptionData): Promise<PaymentInfo> {
    const paymentInfoDto = await apiClient.post<UpgradeSubscriptionData, PaymentInfoDto>(
      '/api/Subscriptions/upgrade',
      upgradeData
    );

    return PaymentInfoMapper.fromDto(paymentInfoDto);
  }

  /**
   * Gets payment by id.
   * @param id Id of the payment.
   */
  export async function getPayment(id: Payment['id']): Promise<Payment> {
    const paymentDto = await apiClient.get<never, PaymentDto>(
      `api/Subscriptions/payment/${id}`,
    )

    return PaymentMapper.fromDto(paymentDto);
  }

  /**
   * Creates new subscription for the organization.
   * @param data Data needed for subscription creation.
   */
  export async function recreateSubscription(data: SubscriptionRecreationData): Promise<ResubscribePaymentInfo> {
    const paymentInfoDto = await apiClient.post<ResubscribePaymentInfoDto, ResubscribePaymentInfoDto>(
      '/api/Subscriptions/recreate',
      SubscriptionRecreationDataMapper.toDto(data),
    );

    return ResubscribePaymentInfoMapper.fromDto(paymentInfoDto);
  }
}
