import styled from '@emotion/styled';
import CSS from 'csstype';
import { Link } from 'react-router-dom';
import { space, SpaceProps, typography, TypographyProps } from 'styled-system';
import { EColors, theme } from 'styles/theme';

interface ITextProps {
  color?: EColors;
  withoutMargin?: boolean;
  display?: CSS.Property.Display;
  cursor?: CSS.Property.Cursor;
}

export const StyledLink = styled(Link)`
  color: ${theme.colors.grayText};
  font-size: 14px;
  font-weight: 400;
  margin: 10px 16px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const MainHeading = styled.h1<ITextProps & { small?: boolean }>`
  color: ${(props) => theme.colors[props.color ?? 'dark']};
  margin-bottom: ${(props) => (props.withoutMargin ? 0 : '40px')};
  font-size: ${(props) => (props.small ? '1.25rem' : '2rem')};
  line-height: 38px;
  font-weight: 600;
`;

export const SecondaryHeading = styled.h2<ITextProps & { withoutMargin?: boolean }>`
  color: ${theme.colors.dark};
  margin-bottom: ${(props) => (props.withoutMargin ? 0 : '15px')};
  font-weight: 600;
  font-size: 20px;
  display: ${(props) => props.display ?? 'block'};
  cursor: ${(props) => props.cursor ?? 'auto'};
`;

export const TertiaryHeading = styled.h2<
  SpaceProps & { withoutMargin?: boolean }
>`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  ${space}
`;

export const LinkWithoutStyle = styled(Link)`
  text-decoration: none;
`;

export const StyledText = styled.p<SpaceProps & { bigText?: boolean }>`
  color: ${(props) =>
    props.bigText ? theme.colors.grayText : theme.colors.dark};
  font-weight: 400;
  font-size: ${(props) => (props.bigText ? '20px' : '12px')};
  line-height: 18px;
  ${space}
`;

export const MediumText = styled.p<TypographyProps & { gray?: boolean }>`
  color: ${(props) => (props.gray ? theme.colors.grayText : theme.colors.dark)};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  ${typography}
`;
