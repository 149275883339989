import styled from '@emotion/styled'
import { space, SpaceProps } from 'styled-system'

export const Row = styled.div<SpaceProps>`
  display: flex;
  justify-content: space-between;
  ${space}
`

export const BtnIcon = styled.img`
  height: 8px;
  margin-right: 10px;
`
