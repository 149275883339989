// TODO: refactor, headers should be set only in /services/api and there is no need for this helper
import { authStorage } from 'services/auth/storage'

export const getAuthHeaders = () => {
  if (!authStorage.isAuthorized()) {
    return {}
  }

  return {
    Authorization: `Bearer ${
      authStorage.getAccessToken() ?? authStorage.getPreTwoFactorAccessToken()
    }`,
  }
}
