import { Avatar, Box, styled, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

export const TableHeader = styled(TableCell)({
  fontWeight: 600,
  color: '#00000080',
  fontSize: '0.75rem',
  padding: '0',
  paddingBlock: '1rem',
});

export const GuestsTableCell = styled(TableCell)({
  color: theme.colors.black,
  fontWeight: 500,
  position: 'relative',
  padding: '0',
  minHeight: '56px',
  paddingRight: '10px',
  paddingBlock: '10px',
});

export const GuestsTableContentSmall = styled('p')({
  fontSize: '0.75rem',
});

export const GuestsTableContent = styled('p')({
  fontSize: '0.875rem',
});

export const BedImage = styled(Avatar)({
  maxWidth: '36px',
  maxHeight: '36px',
  marginRight: '10px',
  cursor: 'pointer',
});

export const ModalImageContainer = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

export const ModalImage = styled('img')({
  maxWidth: '50vw',
});

export const DischargeDialogInfo = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  rowGap: '1.5rem',
});

export const DischargeDialogRoomInfo = styled('div')({
  gridColumn: '1 / 3',
  fontSize: '0.875rem',
});

export const DischargeDialogRoomInfoItem = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const RoomInfoItemTitle = styled('p')({
  fontSize: '0.75rem',
  color: '#00000080',
  marginBottom: '0.2rem',
});

export const RoomInfoItemText = styled('p')({
  fontSize: '1rem',
});

export const MenuLink = styled(Link)({
  textDecoration: 'none',
  color: '#54A2FF',
});

export const CellLink = styled(Link)({
  position: 'absolute',
  top: '0',
  right: '0',
  textDecoration: 'none',
  color: 'inherit',
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const CellFlexContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const MenuButtonContainer = styled('div')({
  marginLeft: 'auto',
  minWidth: '40px',
  minHeight: '40px',
});
