import styled from '@emotion/styled';
import { theme } from 'styles/theme'

export const AddCardForm  = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
  position: relative;
  max-width: 560px;
  width: 100%;
`

export const Buttons  = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  width: 100%;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const CardInfo = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
`

export const Field = styled.label`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const LabelText = styled.div`
  font-weight: 500;
  font-size: var(--font-size-s);
  line-height: 16px;
`

export const InputWrap = styled.div`
  height: 56px;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid ${theme.colors.lightGray};
`

export const CardAgreementInfo = styled.div`
  font-weight: 500;
  font-size: var(--font-size-s);
  line-height: 20px;
  color: ${theme.colors.grayText};
`
