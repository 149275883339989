import { SiteCreationValuesDto } from 'dtos/site/SiteCreationValuesDto';
import { SiteDto } from 'dtos/site/SiteDto';
import { SiteEditingValuesDto } from 'dtos/site/SiteEditingValuesDto';
import { Site } from 'models/site/Site';
import { SiteCreationValues } from 'models/site/SiteCreationValues';
import { SiteEditingValues } from 'models/site/SiteEditingValues';
import { RoomMapper } from 'pages/SiteCreate/components/Rooms/domain/RoomMapper';
import { omit } from 'ramda';
import { ERoute } from 'router/data';
import { transformIntakePeriods } from 'services/transformers';
import { insuranceCompanyMapper } from './InsuranceCompanyMapper';
import { PaymentMethodMapper } from './PaymentMethodMapper';
import { screeningPeriodMapper } from './ScreeningPeriodMapper';
import { ServiceMapper } from './ServiceMapper';
import { imageMapper } from './ImageMapper';

export namespace SiteMapper {
  /** @inheritdoc */
  export function fromDto(dto: SiteDto): Site {
    return new Site({
      id: dto.id.toString(),
      name: dto.name,
      alias: dto.alias,
      organizationId: dto.organizationId,
      contactWeb: dto.contactWeb,
      contactPhone: dto.contactPhone,
      contactEmail: dto.contactEmail,
      address: dto.address,
      lengthOfStayDaysMin: dto.lengthOfStayDaysMin,
      lengthOfStayDaysMax: dto.lengthOfStayDaysMax,
      screeningPeriod: screeningPeriodMapper.fromTimeString(dto.screeningPeriod),
      description: dto.description,
      showDaysOfWeek: dto.showDaysOfWeek,
      intakePeriods: dto.intakePeriods,
      rooms: dto.rooms.map((roomDto) => RoomMapper.fromDto(roomDto)),
      languages: dto.languages,
      services: dto.services.map((serviceDto) => ServiceMapper.fromDto(serviceDto)),
      images: dto.images.map(imageDto => imageMapper.fromDto(imageDto)),
      isCommercial: dto.isCommercial,
      organizationReservationPaymentMethods: dto.organizationReservationPaymentMethods.map(methodDto => PaymentMethodMapper.fromDto(methodDto)),
      insuranceCompanies: dto.insuranceCompanies.map(companyDto => insuranceCompanyMapper.fromDto(companyDto)),
    });
  }

  /**
   * Maps site creation values to dto.
   * @param values Site creation values.
   * @param locationOrigin Current location origin.
   */
  export function toSiteCreationValuesDto(values: SiteCreationValues, locationOrigin: string): SiteCreationValuesDto {
    return {
      ...values.about,
      alias: values.about.alias !== '' ? values.about.alias : null,
      ...values.contacts,
      ...values.intakePeriods,
      ...values.accomodations,
      ...values.services,
      organizationId: Number(values.contacts.organizationId),
      lengthOfStayDaysMin: values.services.lengthOfStayDaysMin !== null ? Number(values.services.lengthOfStayDaysMin) : null,
      lengthOfStayDaysMax: values.services.lengthOfStayDaysMax !== null ? Number(values.services.lengthOfStayDaysMax) : null,
      intakePeriods: values.intakePeriods.intakePeriods[values.intakePeriods.showDaysOfWeek ? 'long' : 'short']
        .filter((item) => item.enabled)
        .map((item) => omit(['enabled'], item)),
      serviceIds: Object.entries(values.services.serviceIds)
        .filter(([id, value]) => value)
        .map(([id, value]) => Number(id)),
      rooms: values.accomodations.rooms.map((room) => RoomMapper.toDto(room)),
      redirectUri: `${locationOrigin}${ERoute.LOGIN}/callback`,
      images: values.about.images.map(image => imageMapper.toDto(image)),
    };
  }

  /** Maps site edition values to dto. */
  export function toSiteEditionValuesDto(values: SiteEditingValues): SiteEditingValuesDto {
    return {
      ...values,
      alias: values.alias !== '' ? values.alias : null,
      id: Number(values.id),
      organizationId: Number(values.organizationId),
      lengthOfStayDaysMin: null,
      lengthOfStayDaysMax: null,
      intakePeriods: values.intakePeriods.long
        .filter((item) => item.enabled)
        .map((item) => omit(['enabled'], item)),
      serviceIds: Object.entries(values.serviceIds)
        .filter(([id, value]) => value)
        .map(([id, value]) => Number(id)),
      rooms: values.rooms.map((room) => RoomMapper.toDto(room)),
      showDaysOfWeek: true,
      images: values.images.map(image => imageMapper.toDto(image)),
    };
  }

  /** Maps site to site edition values. */
  export function mapSiteToSiteEditionValues(site: Site): SiteEditingValues {

    const serviceIds: string[] = [];
    site.services.forEach(service => serviceIds[Number(service.id)] = service.id)

    return {
      ...site,
      organizationId: site.organizationId.toString(),
      lengthOfStayDaysMin: site.lengthOfStayDaysMin !== null ? site.lengthOfStayDaysMin.toString() : null,
      lengthOfStayDaysMax: site.lengthOfStayDaysMax !== null ? site.lengthOfStayDaysMax.toString() : null,
      intakePeriods: transformIntakePeriods(site.intakePeriods),
      languageIds: site.languages.map((item) => item.id),
      serviceIds,
      screeningPeriod: site.screeningPeriod.value,
      alias: site.alias ?? '',
    };
  }
}
