import React, { FC } from 'react';
import {
  SinglePreviewContainer,
  StyledImagePreview,
  OverlayButton,
} from 'components/UploadPhoto/styled';
import { Size as PreviewSize } from 'utils/types/size';
import { Delete } from '@mui/icons-material';

interface Props {
  /** Photo source. */
  readonly src: string;

  /** Handle remove event. */
  readonly onRemove?: () => void;

  /** Size of photo preview. */
  readonly size: PreviewSize;

  /** Whether deletion is available or not. */
  readonly isDeletionAvailable: boolean;

  /** Whether any actions with photo disabled or not. */
  readonly isDisabled?: boolean;
}

export const PhotoPreview: FC<Props> = ({
  src,
  onRemove,
  size,
  isDeletionAvailable,
  isDisabled,
}) => (
  <SinglePreviewContainer className="image-item">
    <StyledImagePreview isActive={!isDisabled} src={src} alt="" />
    {isDeletionAvailable && !isDisabled && (
      <OverlayButton onClick={onRemove}>
        <Delete />
        {size !== 'small' && <span>Delete</span>}
      </OverlayButton>
    )}
  </SinglePreviewContainer>
);
