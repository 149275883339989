import React, { FC, useCallback, useState } from 'react';
import { useBeds } from 'api/beds';
import { useUpdateGuestStatus } from 'api/guests';
import { IGuestType } from 'api/guests/types';
import { Modal } from 'components/Modal';
import { LoadingPage } from 'pages/LoadingPage';
import { AssignBed } from 'pages/Reservation/ModalContent/AssignBed';
import { GuestInfo } from 'pages/Reservation/ModalContent/GuestInfo';
import { useSiteDetails } from 'api/sites/useSiteDetails';

interface Props {
  isOpen: boolean;
  guest: IGuestType;
  onClose: () => void;
}

export const GuestInfoModal: FC<Props> = ({ isOpen, guest, onClose }) => {
  const [isSecondModalOpen, setSecondModalOpen] = useState(false);
  const { data: bedsData, error: bedsError } = useBeds(guest.siteId);
  const { data: siteData, error: siteError } = useSiteDetails(guest.siteId);

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => setSecondModalOpen(false), 200);
  }, []);

  const showSecondModal = useCallback(() => {
    setSecondModalOpen(true);
  }, []);

  const goBackInModal = useCallback(() => {
    setSecondModalOpen(false);
  }, []);

  const { handleUpdateStatus } = useUpdateGuestStatus(guest.siteId, onClose);

  if (bedsData == null || siteData == null) {
    return <LoadingPage />;
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} large={isSecondModalOpen}>
      {!isSecondModalOpen && (
        <GuestInfo
          selectedGuest={guest}
          handleUpdateStatus={handleUpdateStatus}
          openAssignModal={showSecondModal}
          noBeds={bedsData.length === 0}
          maxDays={siteData.lengthOfStayDaysMax ?? 0}
        />
      )}
      {isSecondModalOpen && guest && (
        <AssignBed
          goBack={goBackInModal}
          vacantBeds={bedsData.filter((bed) => bed.guest == null)}
          selectedGuest={guest}
          handleUpdateStatus={handleUpdateStatus}
        />
      )}
    </Modal>
  );
};
