import { useMutate } from 'hooks/api';
import { OrganizationUserMapper } from 'mappers/UserMapper';
import { OrganizationUserInvitationValues } from 'models/user/UserInvitationValues';

export const useInviteOrganizationUser = (locationOrigin: string) => {
  const { mutate } = useMutate<undefined, undefined, OrganizationUserInvitationValues>('post', {
    url: '/api/AccessUsers/organizationScopeUsers',
    requestTransformer: (data) => JSON.stringify(OrganizationUserMapper.mapOrganizationUserInvitationValues(data, locationOrigin)),
  });

  return { handleCreate: mutate };
};
