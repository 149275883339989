import { useInsuranceCompanies } from 'api/insuranceCompany/useInsuranceCompanies';
import { useOrganization } from 'api/organizations/useOrganization';
import { useUpdateOrganization } from 'api/organizations/useUpdateOrganization';
import { StyledInput } from 'components/InputField/styled';
import { StyledButton } from 'components/StyledButton/styled';
import { InsuranceCompany } from 'models/insuranceCompany/InsuranceCompany';
import { Organization } from 'models/organization/Organization';
import { OrganizationUpdateValues } from 'models/organization/OrganizationUpdateValues';
import React, { ChangeEventHandler, FC, memo, useState } from 'react';
import { InsuranceCompanyCheckbox } from '../InsuranceCompanySelect/InsuranceCompanyCheckbox';
import { AddInsuranceModalContentContainer, InsurancesButtonsContainer, InsurancesList } from './styled';

interface Props {

  /** Organization. */
  readonly organization: Organization;

  /** On close callback. */
  readonly onClose: () => void;
};

const AddInsuranceModalWindowContentComponent: FC<Props> = ({ organization, onClose }) => {

  const { revalidate } = useOrganization(organization.id);

  const { handleUpdate } = useUpdateOrganization(organization.id);

  const { data: insuranceCompanies } = useInsuranceCompanies();

  const [selectedInsuranceCompanyIds, setSelectedInsuranceCompanyIds] = useState<readonly InsuranceCompany['id'][]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  if (insuranceCompanies == null) {
    return <p>Loading...</p>;
  }

  const organizationInsuranceCompanyIds = organization.insuranceCompanies.map(company => company.id);

  const filteredInsuranceCompanies = insuranceCompanies
    .filter(company => !organizationInsuranceCompanyIds.includes(company.id))
    .filter(company => company.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = event => {
    setSearchValue(event.target.value);
  };

  const handleInsuranceCompanySelect = (company: InsuranceCompany) => {

    if (isLoading) {
      return;
    }

    if (selectedInsuranceCompanyIds.includes(company.id)) {
      setSelectedInsuranceCompanyIds(ids => ids.filter(id => id !== company.id));
    } else {
      setSelectedInsuranceCompanyIds(ids => [...ids, company.id]);
    }
  }

  const handleAddInsurances = async() => {

    if (selectedInsuranceCompanyIds.length === 0) {
      onClose();
      return;
    }

    const updateValues: OrganizationUpdateValues = {
      id: organization.id,
      name: organization.name,
      logoPath: organization.logoPath,
      insuranceCompanyIds: [...organization.insuranceCompanies.map(company => company.id), ...selectedInsuranceCompanyIds],
    };

    setIsLoading(true);
    await handleUpdate(updateValues);
    await revalidate();
    setIsLoading(false);
    onClose();
  }

  return (
    <AddInsuranceModalContentContainer>
      <h2>Insurance companies</h2>
      <StyledInput onChange={handleSearchChange} type="search" placeholder="Search" />
      <InsurancesList>
        {filteredInsuranceCompanies.map(company =>
          <InsuranceCompanyCheckbox
            company={company}
            isSelected={selectedInsuranceCompanyIds.includes(company.id)}
            handleChange={handleInsuranceCompanySelect}
            key={company.id}
          />
        )}
      </InsurancesList>
      <InsurancesButtonsContainer>
        <StyledButton
          onClick={() => handleAddInsurances()}
          disabled={isLoading}
          type="button"
        >
          OK
        </StyledButton>
        <StyledButton
          onClick={onClose}
          disabled={isLoading}
          type="button"
        >
          Cancel
        </StyledButton>
      </InsurancesButtonsContainer>
    </AddInsuranceModalContentContainer>
  )
}

export const AddInsuranceModalWindowContent = memo(AddInsuranceModalWindowContentComponent);
