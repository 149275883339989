import { localeCompareWithDirection } from './localeCompareWithDirection';
import { SortDirection } from './types/SortingDirection';

/**
 * Sorts array of entities by provided field.
 * @param array Array of entities.
 * @param field Field to sort by.
 * @param direction Direction of the sorting.
 */
export function sortEntities<T>(array: readonly T[], field: keyof T, direction: SortDirection = 'asc'): T[] {
  return [...array].sort((firstItem, secondItem) => {

    const firstItemValue = firstItem[field];
    const secondItemValue = secondItem[field];

    if (typeof firstItemValue === 'string' && typeof secondItemValue === 'string') {
      return localeCompareWithDirection({
        targetValue: firstItemValue,
        compareValue: secondItemValue,
        direction,
      });
    }

    if (direction === 'asc') {
      return firstItemValue > secondItemValue ? 1 : -1;
    } else {
      return firstItemValue > secondItemValue ? -1 : 1
    }
  })
}
