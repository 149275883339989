import styled from '@emotion/styled';

export const ReservationRoomCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  word-break: break-word;
`;

export const ReservationRoomCardName = styled.p`
  font-size: var(--font-size-lg);
`;

export const ReservationRoomImage = styled.img`
  border-radius: 10px;
  max-height: 100px;
  width: 100%;
  object-fit: cover;
`;

export const ReservationRoomBedsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;
