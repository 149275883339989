import React, { createContext, FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useUserInfo } from 'api/auth/useUserInfo/useUserInfo'
import { IAuthContext, IUserData } from 'hooks/auth/types'
import { ERoute } from 'router/data'
import { authStorage } from 'services/auth/storage'

export const AuthContext = createContext<IAuthContext>(undefined as never)

export const AuthProvider: FC = ({ children }) => {
  const history = useHistory()
  const { loadUserInfo, isLoading: isFetchingUserInfo } = useUserInfo()

  const [userData, setUserData] = useState<IUserData>(
    authStorage.getUserData() as any
  )
  const [isSignedIn, setIsSignedIn] = useState(authStorage.isLoggedIn())
  const [isAuthorized, setIsAuthorized] = useState(authStorage.isAuthorized())

  const refetchUserInfo = async () => {
    try {
      const data = await loadUserInfo()

      if (data) {
        authStorage.setUserData(data)
        setUserData(data)
      }

      return data
    } catch (error: unknown) {
      return error instanceof Error ? error.message : 'Unknown error happened during user info fetching.';
    }
  }

  const chooseNextStep = async () => {
    const data = await refetchUserInfo()

    if (typeof data === 'string') {
      return;
    }

    const hasPassword = data?.hasExternalLogin || data?.hasPassword

    if (!hasPassword) {
      history.push(ERoute.CREATE_PASSWORD)
      // eslint-disable-next-line no-negated-condition
    } else if (!data.hasPhoneNumber) {
      history.push(ERoute.TWO_FACTOR_SETUP)
    } else {
      history.push(ERoute.TWO_FACTOR_CONFIRM)
    }
  }

  const signIn = (accessToken: string) => {
    authStorage.setPreTwoFactorAccessToken(accessToken)
    setIsSignedIn(true)

    return chooseNextStep()
  }

  const authorize = async (accessToken: string, refreshToken: string) => {
    authStorage.setAccessToken(accessToken)
    authStorage.setRefreshToken(refreshToken)
    setIsAuthorized(true)

    await refetchUserInfo()

    history.push(ERoute.HOMEPAGE)
  }

  const getPreTwoFactorAccessToken = () =>
    authStorage.getPreTwoFactorAccessToken()

  // TODO: call API?
  const logout = () => {
    authStorage.clearLoginData()
    setIsSignedIn(false)
    setIsAuthorized(false)

    history.push(ERoute.LOGIN)
  }

  return (
    <AuthContext.Provider
      value={{
        state: {
          isFetchingUserInfo,
          userData,
          isAuthorized,
          isSignedIn,
        },
        actions: {
          refetchUserInfo,
          authorize,
          signIn,
          logout,
          getPreTwoFactorAccessToken,
          chooseNextStep,
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
