import { PaymentInfoDto } from '../dtos/Subscriptions/PaymentInfoDto';
import { PaymentInfo } from '../models/Subscriptions/PaymentInfo';

/** Payment info mapper. */
export namespace PaymentInfoMapper {

  /** Maps dto to model. */
  export function fromDto(dto: PaymentInfoDto): PaymentInfo {
    return new PaymentInfo({
      isSuccessful: dto.isSuccessful,
      errorMessage: dto.errorMessage,
    });
  }
}
