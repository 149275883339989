import styled from '@emotion/styled';
import { TableCell, TableContainer } from '@mui/material';
import { theme } from '../../../../styles/theme';

export const BillingTableContainer = styled(TableContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  overflow-x: initial;
`;

const BasicTableCell = styled(TableCell)`
  padding-left: 0;
  font-family: 'Inter';
  font-weight: 500;
`;

export const PaymentInfoTableHeader = styled(BasicTableCell)`
  font-size: var(--font-size-xs);
  width: 20%;
  color: ${theme.colors.semiLightGray};
`;

export const PaymentInfoTableCell = styled(BasicTableCell)`
  font-size: var(--font-size-s);
  color: ${theme.colors.black};
`;
