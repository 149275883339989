
import { GuestWithRoomAndBed } from 'models/guest/GuestWithRoomAndBed';
import { SortParams } from 'utils/useTableSortParams';

export namespace GuestsTableSettings {

  /** Guests table column. */
  export enum GuestsTableColumn {
    Guest = 'Guest',
    Days = 'Days',
    RoomBed = 'Room / Bed',
  }

  /** Initial sort params. */
  export const INITIAL_SORT_PARAMS: SortParams<GuestsTableColumn> = {
    columnName: GuestsTableColumn.Guest,
    direction: 'asc'
  };

  /** Maps guests table column to sort function. */
  export const MAP_COLUMN_TO_SORT_FUNCTION: Readonly<Record<GuestsTableColumn, GuestWithRoomAndBed.SortFunction>> = {
    [GuestsTableColumn.Guest]: GuestWithRoomAndBed.sortGuestsByName,
    [GuestsTableColumn.Days]: GuestWithRoomAndBed.sortGuestsByDaysStayed,
    [GuestsTableColumn.RoomBed]: GuestWithRoomAndBed.sortGuestsByRoomAndBedNames,
  }
}
