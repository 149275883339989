import { AuthInfoDto } from 'api/auth/useUserInfo/types'
import { IUserData } from 'hooks/auth/types'

export const normalizeUserInfo = (data: AuthInfoDto): IUserData => ({
  hasExternalLogin: data.has_external_login === 'true',
  hasPassword: data.has_password === 'true',
  hasPhoneNumber: data.has_phone_number === 'true',
  hasPhoneNumberConfirmed: data.has_phone_number_confirmed === 'true',
  userId: data.sub,
  email: data.email,
  phoneNumber: data.phone_number,
  roleAdminSiteIds: !data.role_admin_site_ids ? [] : data.role_admin_site_ids.split(','),
  roleStaffSiteIds:  !data.role_staff_site_ids ? [] : data.role_staff_site_ids.split(','),
  roleIntakeSiteIds: !data.role_intake_site_ids ? [] : data.role_intake_site_ids.split(','),
  roleGlobal: data.role_global === 'true',
  roleOwnerOrganizationIds: !data.role_owner_organization_ids ? [] : data.role_owner_organization_ids.split(','),
})
