import { ISignInData, ISignInResponseData } from 'api/auth/useEmailLogin/types'
import { useMutate } from 'hooks/api'
import { useAuth } from 'hooks/auth/useAuth'
import { createFormData } from 'services/http'
import { sendFailedLoginLog } from '../sendFailedLoginLog'

export const useEmailLogin = () => {
  const {
    state: { isFetchingUserInfo },
    actions: { signIn: finishSignIn },
  } = useAuth()

  const { mutate, isLoading, error } = useMutate<
    ISignInResponseData,
    ISignInResponseData,
    URLSearchParams
  >(
    'post',
    {
      url: '/connect/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Accept': 'application/json',
      },
    },
    (data) => {
      finishSignIn(data.access_token)
    }
  )

  /* eslint-disable camelcase */
  const signIn = (data: ISignInData) =>
    mutate(
      createFormData({
        client_id: 'login',
        response_type: 'token',
        scope: 'openid profile auth',
        grant_type: 'password',
        ...data,
      }),
      () => data.username !== '' && sendFailedLoginLog(data.username),
    )

  return { signIn, isLoading: isLoading || isFetchingUserInfo, error }
}
