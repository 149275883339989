import styled from '@emotion/styled'

interface Props {
  readonly withTwoColumns?: boolean;
}

export const ServicesBlockWrap = styled.div<Props>`
  width: ${props => props.withTwoColumns ? '100%' : '50%'};
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 10px;
`

export const ServicesContainer = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(${props => props.withTwoColumns ? 2 : 1}, 1fr);
  grid-row-gap: 5px;
  grid-column-gap: 30px;
  justify-items: start;
`
