import { Grid, styled } from '@mui/material';

export const MainHeading = styled('h1')({
  fontSize: '2rem',
  fontWeight: 600,
  marginBottom: '1.5rem',
});

export const GuestNameHeading = styled('h3')({
  fontSize: '1.25rem',
  fontWeight: 600,
});

export const InfoGridContainer = styled(Grid)({
  fontWeight: 500,
});

export const InfoGridItem = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  marginBlock: '0.8rem',
});

export const GridItemHeading = styled('h4')({
  fontSize: '0.75rem',
  color: '#00000080',
});

export const GridItemContent = styled('p')({
  fontSize: '1rem',
  wordBreak: 'break-word',
});

export const HorisontalLine = styled('hr')({
  height: '1px',
  color: '#000000',
  opacity: '0.5',
  marginBlock: '1.2rem',
});
