import { SelectChangeEvent } from '@mui/material';
import React, { FC, memo } from 'react';
import { SavedPaymentMethod } from '../../models/Subscriptions/SavedPaymentMethod';
import { PaymentMethodBrandLogo, PaymentMethodSelectInput, PaymentMethodSelectItem } from './styled';

interface Props {

  /** Saved payment methods to display. */
  readonly savedPaymentMethods: readonly SavedPaymentMethod[]

  /** Selected payment method id. */
  readonly selectedPaymentMethodId: SavedPaymentMethod['id'];

  /** Handle select function. */
  readonly onSelect: (event: SelectChangeEvent<SavedPaymentMethod['id']>) => void;

  /** Handle new payment method. */
  readonly onNewPaymentMethod: () => void;
}

const SavedPaymentMethodSelectComponent: FC<Props> = ({
  savedPaymentMethods,
  selectedPaymentMethodId,
  onSelect,
  onNewPaymentMethod,
}) => {
  return (
    <PaymentMethodSelectInput
      value={selectedPaymentMethodId}
      onChange={onSelect}
    >
      {savedPaymentMethods.map(method => (
        <PaymentMethodSelectItem key={method.id} value={method.id}>
          <PaymentMethodBrandLogo src={method.brandLogoImageSrc} alt={method.brand} />
          {`•• ${method.lastFourDigits}`}
        </PaymentMethodSelectItem>
      ))}
      <PaymentMethodSelectItem onClick={onNewPaymentMethod}>
        New Payment Method
      </PaymentMethodSelectItem>
    </PaymentMethodSelectInput>
  )
}

export const SavedPaymentMethodSelect = memo(SavedPaymentMethodSelectComponent);
