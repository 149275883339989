import { EServiceCategory, SiteService } from 'services/types';

/**
 * Transforms an array of service to a string, putting age and gender services at the first place.
 * @param services Array of services.
 */
export function reduceGuestServices(services: SiteService[]): string {
  const genderServicesString = services
    .filter(service => service.category === EServiceCategory.GENDER)
    .map((service) => service.name)
    .reduce((servicesString, currentServiceName) => `${servicesString}, ${currentServiceName}`, '');

  const genderAndAgeServicesString = services
    .filter(service => service.category === EServiceCategory.AGE)
    .map((service) => service.name)
    .reduce((servicesString, currentServiceName) => `${servicesString}, ${currentServiceName}`, genderServicesString);

  const servicesString = services
    .filter(service => service.category !== EServiceCategory.GENDER && service.category !== EServiceCategory.AGE)
    .map((service) => service.name)
    .reduce((servicesString, currentServiceName) => `${servicesString}, ${currentServiceName}`, genderAndAgeServicesString);

  // If there are no gender service than genderServicesString will be empty and later reduces will add ', ' at the beginning so we need to slice it.
  return servicesString.startsWith(', ') ? servicesString.slice(2) : servicesString;
}
