type PaymentInfoCreationData = PaymentInfo;

/** Information required for payment model. */
export class PaymentInfo {

  /** Whether the payment was successful. */
  public readonly isSuccessful: boolean | null;

  /** Error message. */
  public readonly errorMessage: string | null;

  public constructor(data: PaymentInfoCreationData) {
    this.isSuccessful = data.isSuccessful;
    this.errorMessage = data.errorMessage;
  }
}
