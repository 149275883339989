import { ButtonIcon } from 'components/ButtonIcon/styled';
import { ModalWindow } from 'components/Dialogs/ModalWindow/ModalWindow';
import { StyledButton } from 'components/StyledButton/styled';
import { UsersTable } from 'components/UsersTable/Table';
import { InviteUserForm } from 'forms/InviteUser';
import { User } from 'models/user/User';
import { Site } from 'models/site/Site';
import { SiteTabContentContainer } from 'pages/OrganizationSiteDetails/styled';
import React, { FC, memo, useState } from 'react';
import { SiteTabHeading } from '../SiteTabHeading/SiteTabHeading';

interface Props {

  /** Users */
  readonly users: readonly User[];

  /** Site. */
  readonly site: Site;

  /** Users revalidation function. */
  readonly revalidateUsers: () => Promise<boolean>;
}

const SiteUsersComponent: FC<Props> = ({ users, site, revalidateUsers }) => {
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const handleInviteUserModalOpen = () => setIsInviteModalOpen(true);
  const handleInviteUserModalClose = () => setIsInviteModalOpen(false);

  return (
    <SiteTabContentContainer>
      <SiteTabHeading headingText="Users" count={users.length}>
        <StyledButton type="button" onClick={handleInviteUserModalOpen}>
          <ButtonIcon src="/assets/icons/plus-icon.svg" alt="" />
          New User
        </StyledButton>
      </SiteTabHeading>
      <UsersTable users={users} revalidateUsers={revalidateUsers} siteId={site.id} />
      <ModalWindow isOpen={isInviteModalOpen} onClose={handleInviteUserModalClose}>
        <InviteUserForm selectedSite={site} closeModal={handleInviteUserModalClose} />
      </ModalWindow>
    </SiteTabContentContainer>
  );
};

export const SiteUsers = memo(SiteUsersComponent);
