import { useMutate } from 'hooks/api';
import { insuranceCompanyMapper } from '../../mappers/InsuranceCompanyMapper';
import { InsuranceCompanyCreationValues } from '../../models/insuranceCompany/InsuranceCompanyCreationValues';

/** Create insurance company hook. */
export const useCreateInsuranceCompany = () => {
  const { mutate } = useMutate<undefined, undefined, InsuranceCompanyCreationValues>('post', {
    url: '/api/InsuranceCompanies',
    requestTransformer: data => JSON.stringify(insuranceCompanyMapper.mapCreationValuesToDto(data)),
  });

  return { handleCreate: mutate };
};
