import { BedMapper } from '../components/Bed/domain/BedMapper';
import { Room } from './Room';
import { RoomDto } from './RoomDto';

/** Room mapper. */
export namespace RoomMapper {

  /** @inheritdoc */
  export function fromDto(dto: RoomDto): Room {
    return new Room({
      id: String(dto.id),
      name: dto.name,
      beds: dto.beds.map(bedDto => BedMapper.fromDto(bedDto)),
      photo: dto.imagePath,
      createdAt: new Date(dto.createdAt),
    })
  }

  /** @inheritdoc */
  export function toDto(data: Room): RoomDto {
    return {
      id: data.id != null ? Number(data.id) : void 0,
      name: data.name,
      beds: data.beds.map(bed => BedMapper.toDto(bed)),
      imagePath: data.photo ?? '',
      createdAt: data.createdAt.toISOString(),
    }
  }
}
