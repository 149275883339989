import { useMutate } from 'hooks/api'

export const useDeleteUser = () => {
  const { mutate } = useMutate('delete', {
    url: '/api/Users',
  })

  const deleteUser = () => mutate()

  return { deleteUser }
}
