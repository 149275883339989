import styled from '@emotion/styled';
import { theme } from '../../styles/theme';

interface Props {

  /** Whether the loader should overlay whole screen. */
  readonly isGlobal?: boolean;
}

export const LoaderWrapper  = styled.div<Props>`
  position: ${props => props.isGlobal ? 'fixed' : 'absolute'};
  inset: 0 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.lightOverlay};
  z-index: ${theme.zIndices.overlay};
`
