import styled from '@emotion/styled';
import { IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import { theme } from 'styles/theme';

export const SiteCardContainer = styled(Link)`
  border: 1px solid ${theme.colors.grayBorderColor};
  border-radius: var(--border-radius);
  text-decoration: none;
  color: ${theme.colors.black};
  transition: background-color 200ms linear;

  &:hover {
    background-color: ${theme.colors.lightGray};
  }

  --spacing-xlg: 24px;
  --spacing-lg: 16px;
  --spacing-md: 12px;
  --spacing-s: 8px;
`;

export const SiteCardImage = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
`;

export const SiteCardContent = styled.div`
  width: 100%;
  padding-inline: var(--spacing-lg);
  padding-block: var(--spacing-md) var(--spacing-xlg);
  word-break: break-word;
`;

export const AlternativeSiteName = styled.p`
  font-size: var(--font-size-s);
  font-weight: 500;
  color: ${theme.colors.semiLightGray};
`

export const SiteCardNameContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-block: var(--spacing-s) var(--spacing-md);

  --menu-open-button-height: 40px;
`;

export const MenuOpenButton = styled(IconButton)`
  position: absolute;
  height: var(--menu-open-button-height);
  top: -8px;
  right: 0;
`;

export const SiteCardName = styled.h2`
  font-size: var(--font-size-lg);
  font-weight: 600;
  max-width: calc(100% - var(--menu-open-button-height));
`;
