import { PaymentCreateValuesDto } from 'dtos/Subscriptions/PaymentCreateValuesDto';
import { PaymentCreateValues } from 'models/Subscriptions/PaymentCreateValues';
import { PaymentPurposeDto } from '../dtos/Subscriptions/BasePaymentDto';
import { PaymentDto, PaymentStateDto } from '../dtos/Subscriptions/PaymentDto';
import { PaymentPurpose, PaymentState } from '../models/Subscriptions/BasePayment';
import { Payment } from '../models/Subscriptions/Payment';

/** Maps payment state dto to model. */
export const MAP_PAYMENT_STATE_DTO_TO_MODEL: Readonly<Record<PaymentStateDto, PaymentState>> = {
  [PaymentStateDto.InProgress]: PaymentState.InProgress,
  [PaymentStateDto.Succeed]: PaymentState.Succeed,
  [PaymentStateDto.Failed]: PaymentState.Failed,
}

/** Maps payment purpose dto to model. */
export const MAP_PAYMENT_PURPOSE_DTO_TO_MODEL: Readonly<Record<PaymentPurposeDto, PaymentPurpose>> = {
  [PaymentPurposeDto.ExtraPaidBedCountChange]: PaymentPurpose.ExtraPaidBedCountChange,
  [PaymentPurposeDto.SubscriptionCreation]: PaymentPurpose.SubscriptionCreation,
  [PaymentPurposeDto.SubscriptionMonthlyPayment]: PaymentPurpose.SubscriptionMonthlyPayment,
}

/** Payment mapper. */
export namespace PaymentMapper {

  /** Maps dto to model. */
  export function fromDto(dto: PaymentDto): Payment {
    return new Payment({
      id: dto.id,
      state: MAP_PAYMENT_STATE_DTO_TO_MODEL[dto.state],
      purpose: MAP_PAYMENT_PURPOSE_DTO_TO_MODEL[dto.purpose],
      externalMessage: dto.externalMessage,
      createdAt: new Date(dto.createdAt),
      paidAt: dto.paidAt !== null ? new Date(dto.paidAt) : null,
    })
  }

  /** Maps creation model to dto. */
  export function toCreateValuesDto(model: PaymentCreateValues): PaymentCreateValuesDto {
    return {
      ownerEmail: model.ownerEmail,
      subscriptionOptionId: model.subscriptionOptionId,
      organizationId: model.organizationId,
    }
  }
}
