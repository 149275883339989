import { insuranceCompanyMapper } from './InsuranceCompanyMapper';
import { OrganizationCreateValuesDto } from 'dtos/organizationDto/OrganizationCreateValuesDto';
import { OrganizationDto } from 'dtos/organizationDto/OrganizationDto';
import { Organization } from 'models/organization/Organization';
import { OrganizationCreateValues } from 'models/organization/OrganizationCreateValues';
import { OrganizationType } from 'models/organization/OrganizationType';
import { ERoute, OrganizationTypeHref } from 'router/data';

export namespace OrganizationMapper {

  /** Maps organization dto to model. */
  export function fromDto(dto: OrganizationDto): Organization {
    return new Organization({
      id: dto.id.toString(),
      name: dto.name,
      logoPath: dto.logoPath,
      siteCount: dto.siteCount,
      isCommercial: dto.isCommercial,
      insuranceCompanies: dto.insuranceCompanies.map(dto => insuranceCompanyMapper.fromDto(dto)),
    })
  }

  /** Maps organization creation values to dto. */
  export function mapCreateValuesToDto(values: OrganizationCreateValues, locationOrigin: string): OrganizationCreateValuesDto {
    return {
      name: values.name,
      ownerEmail: values.ownerEmail,
      logoPath: values.logoPath,
      redirectUri: `${locationOrigin}${ERoute.LOGIN}/callback`,
    }
  }
}

export const MAP_HREF_TO_ORGANIZATION_TYPE: Readonly<Record<OrganizationTypeHref, OrganizationType | undefined>> = {
  [OrganizationTypeHref.AllOrganizations]: undefined,
  [OrganizationTypeHref.FreeRehabs]: OrganizationType.FreeRehab,
  [OrganizationTypeHref.PaidRehabs]: OrganizationType.PaidRehab,
}
