import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { object, string } from 'yup';
import { useDeleteUser } from 'api/auth/useDeleteUser/useDeleteUser';
import { InputField } from 'components/InputField';
import { Modal } from 'components/Modal';
import { ThemedButton } from 'components/ThemedButton';
import { SecondaryHeading, StyledText } from 'components/Typography';
import { useAuth } from 'hooks/auth/useAuth';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const validationSchema = object().shape({
  confirmationText: string().required().equals(['DELETE']),
});

export const DeleteUserModal: FC<Props> = ({ isOpen, onClose }) => {
  const { deleteUser } = useDeleteUser();
  const {
    actions: { logout },
  } = useAuth();

  const handleSubmit = useCallback(async () => {
    await deleteUser();
    logout();
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          confirmationText: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <SecondaryHeading>
              Are you sure you want to delete your account?
            </SecondaryHeading>
            <StyledText mb={27}>
              Existing sites will be disabled and you account will be
              permanently removed from the system. This action cannot be undone.
            </StyledText>
            <InputField
              name="confirmationText"
              label="Enter “DELETE” to confirm"
              placeholder="DELETE"
            />
            <ThemedButton
              width="100%"
              type="submit"
              color="red"
              onClick={submitForm}
              disabled={isSubmitting}
            >
              Confirm
            </ThemedButton>
          </>
        )}
      </Formik>
    </Modal>
  );
};
