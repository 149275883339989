import { apiClient } from 'services/api/apiClient';

/**
 * Checks whether the email already registered.
 * @param email Email to check.
 */
export async function checkEmailExistence(email: string): Promise<boolean> {
  const isExists = await apiClient.get<boolean, boolean>(
    `/api/Users/emailBusyness`,
    {
      params: {
        email: email,
      }
    }
  );
  return isExists;
}
