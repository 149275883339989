import styled from '@emotion/styled'
import { theme } from 'styles/theme'

export const StyledDaysText = styled.p<{ danger?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  color: ${(props) =>
    props.danger ? theme.colors.red : theme.colors.grayText};

  & > strong {
    font-weight: 700;
  }
`
