import { useMutate } from 'hooks/api';
import { User } from 'models/user/User';

export const useDeleteUser = (id: User['id']) => {
  const { mutate } = useMutate('delete', {
    url: `/api/AccessUsers/${id}`,
  })

  return { handleDelete: mutate }
}
