import { Formik } from 'formik';
import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { object, string } from 'yup';
import { useDeleteSite } from 'api/sites/useDeleteSite';
import { InputField } from 'components/InputField';
import { Modal } from 'components/Modal';
import { ThemedButton } from 'components/ThemedButton';
import { SecondaryHeading, StyledText } from 'components/Typography';
import { Site } from 'models/site/Site';

interface Props {

  /** Site id. */
  readonly siteId: Site['id'];

  /** Where modal is open. */
  readonly isOpen: boolean;

  /** On close callback. */
  readonly onClose: () => void;
}

export const validationSchema = object().shape({
  confirmationText: string().required().equals(['DELETE']),
});

export const DeleteSiteModal: FC<Props> = ({ siteId, isOpen, onClose }) => {
  const { handleDelete } = useDeleteSite(siteId);
  const { go } = useHistory();

  const handleSubmit = useCallback(async () => {
    await handleDelete();
    go(-1);
  }, [siteId]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={{
          confirmationText: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <SecondaryHeading>
              Are you sure you want to delete the site?
            </SecondaryHeading>
            <StyledText mb={27}>
              All guests, history and settings for your site will be deleted.
              This action cannot be undone.
            </StyledText>
            <InputField
              name="confirmationText"
              label="Enter “DELETE” to confirm"
              placeholder="DELETE"
            />
            <ThemedButton
              width="100%"
              type="submit"
              color="red"
              onClick={submitForm}
              disabled={isSubmitting}
            >
              Confirm
            </ThemedButton>
          </>
        )}
      </Formik>
    </Modal>
  );
};
