import { GuestMethods, IGuestType } from 'api/guests/types';
import React from 'react';
import { FC } from 'react';
import { formatDateToMDY } from 'utils/formatDateToMDY';
import { parseDateClockTime } from 'utils/parseDateClockTime';
import { reduceGuestServices } from 'components/GuestInfo/reduceGuestServices';
import { InfoGridItem, GridItemContent, GridItemHeading, GuestNameHeading, HorisontalLine, InfoGridContainer } from './styled';

interface GuestInfoComponentProps {

  /** Guest which info to show. */
  readonly guest: IGuestType;
}

const GuestInfoHeaderComponent: FC<GuestInfoComponentProps> = ({ guest }) => {
  const reservationDate = GuestMethods.parseReservationDate(guest.createdAt);
  return (
    <>
      <GuestNameHeading>{GuestMethods.getFullName(guest)}</GuestNameHeading>
      <HorisontalLine />
      <InfoGridContainer container>
        <InfoGridItem item xs={3}>
          <GridItemHeading>Gender</GridItemHeading>
          <GridItemContent>{guest.gender}</GridItemContent>
        </InfoGridItem>
        <InfoGridItem item xs={9}>
          <GridItemHeading>Services</GridItemHeading>
          <GridItemContent>{reduceGuestServices(guest.services)}</GridItemContent>
        </InfoGridItem>
        <InfoGridItem item xs={3}>
          <GridItemHeading>Reservation Date</GridItemHeading>
          <GridItemContent>{`${formatDateToMDY(reservationDate)} ${parseDateClockTime(reservationDate)}`}</GridItemContent>
        </InfoGridItem>
      </InfoGridContainer>
      <HorisontalLine />
    </>
  )
}

export const GuestInfoHeader = GuestInfoHeaderComponent;
