import styled from '@emotion/styled'
import { theme } from 'styles/theme'

export const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 36px;
  cursor: pointer;
  font-size: 14px;
  color: ${theme.colors.dark};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & > input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
`

export const StyledCheckmark = styled.span<{ checked: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  background-color: ${(props) =>
    props.checked ? theme.colors.blue : theme.colors.lightGray};
  border: ${(props) =>
    props.checked ? 'none' : `1px solid ${theme.colors.gray}`};

  &::after {
    content: '';
    position: absolute;
    display: ${(props) => (props.checked ? 'block' : 'none')};
    left: 7px;
    top: 3px;
    width: 7px;
    height: 11px;
    border: solid #ffffff;
    border-width: 0 2px 2px 0;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`
